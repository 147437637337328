import * as React from "react";
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import BarcodeScanIcon from "mdi-react/BarcodeScanIcon";
import FileUpload from "shared/FileUpload";
import funciones from "services/funciones";
import * as Icon from "../../../images";
import { Field, InputNum } from "shared/Input";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const colores = ["#172ba8", "#fafa5a", "#faf050", "#fae646", "#172b94", "#fadc3c", "#329600", "#f5320d", "#f53c0d", "#509600", "#f5460d", "#ff2525", "#f5500d", "#f55a0d", "#172b80", "#172b6c", "#172b58", "#172b44"];

const EditContainerState = ({ item, list, update, remove }) => {
    const [codigo, setCodigo] = React.useState("");
    const [valorp, setValorp] = React.useState("");
    const [valord, setValord] = React.useState("");
    const [valorm, setValorm] = React.useState("");
    const [estado, setEstado] = React.useState(item.estado);
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [descripcion, setDescripcion] = React.useState(item.descripcion || "");
    const [attachments, setAttachments] = React.useState(item.attachments);
    const [filesAttached, setFilesAttached] = React.useState([]);
    const [recibiendo, setRecibiendo] = React.useState(false);
    const [verificando, setVerificando] = React.useState(false);
    const media_movil = window.matchMedia('(max-width: 500px)').matches;
    const isMounted = React.useRef(true);
    const [consigneeId, setConsigneeId] = React.useState("");
    const [consignee, setConsignee] = React.useState("");


    const createFile = async (url) => {
        if (isMounted.current) {
            let files = [];
            let only_urls = url.split(",");

            only_urls.forEach(async (_url, index) => {
                _url = _url.split("|")[1];

                let response = await fetch(_url, {
                    mode: 'no-cors',
                    headers: {
                        'Access-Control-Allow-Origin': '*'
                    }
                });
                let data = await response.blob();
                let file_name = _url.split("?")[0].split("%2F").pop();

                let file = new File([data],
                    file_name,
                    {
                        type: "image/" + file_name.split(".").pop(),
                        lastModified: Date.now()
                    });
                file = Object.assign(file, {
                    preview: _url,
                    url: _url,
                    path: file_name,
                    nameinserver: file_name.split("/").pop(),
                    isUpdate: true
                });

                files.push(file);

                if (files.length == only_urls.length) {
                    setFilesAttached(files);
                }
            });
        }
    }

    const validaCodigo = async (_codigo) => {
        if (_codigo.length < 1) {
            return;
        }

        setVerificando(true);

        let _data = await api.fetchJson({
            url: "verify-code",
            data: {
                codigo: _codigo
            }
        });

        if (_data.response == 1) {
            if (parseInt(_data.cantidad) < 1) {
                updatePackage("codigo", _codigo);
            } else {
                // updatePackage("codigo", "");
                Alert(`El código "${_codigo}" ya esta asignado a otro paquete`, "warning");
            }
        }

        setVerificando(false);
    }

    const handleScanner = (e) => {
        e.preventDefault();

        if (typeof android !== "undefined") {

            // eslint-disable-next-line no-undef
            scanner.response = async (_codigo) => {
                updatePackage("codigo", _codigo);
                validaCodigo(_codigo);
            };

            // eslint-disable-next-line no-undef
            scanner.error = (format) => Alert(`Error de lectura: \nEl formato del código es "${format}" y no es compatible con esta función.`, "warning");

            // eslint-disable-next-line no-undef
            android.scannerQR();
        }
    }

    const handlerBlur = (e) => {
        // setTimeout(() => {
        validaCodigo(codigo);
        // }, 2000);
    }

    const updatePackage = (param, value) => {

        switch (param) {
            case "codigo":
                // if (isNaN(value)) {
                //     return;
                // }

                break;
            case "attachments":
                console.log("attachments", value);

                break;
        }

        let _pack = {
            ...item,
            "id_client": param === "id_client" ? value : consigneeId,
            "estado": param === "estado" ? value : estado,
            "codigo": param === "codigo" ? value : codigo,
            "valorp": param === "valorp" ? value.replaceAll(".", "") : valorp.replaceAll(".", ""),
            "valord": param === "valord" ? value.replaceAll(".", "") : valord.replaceAll(".", ""),
            "valorm": param === "valorm" ? value.replaceAll(".", "") : valorm.replaceAll(".", ""),
            "descripcion": param === "descripcion" ? value : descripcion,
            "attachments": param === "attachments" ? value : attachments,
        };

        param === "id_client" && setConsigneeId(value);
        param === "estado" && setEstado(value);
        param === "codigo" && setCodigo(value);
        param === "valorp" && setValorp(value);
        param === "valord" && setValord(value);
        param === "valorm" && setValorm(value);
        param === "descripcion" && setDescripcion(value);
        param === "attachments" && setAttachments(value);

        update(_pack, item.index);
    }

    const BtnScaner = () => {
        let _btn = <Icon.Loading />;

        if (codigo.length >= 1) {
            if (verificando) {
                _btn = <Icon.Loading />
            } else {
                _btn = <CloseIcon onClick={() => updatePackage("codigo", "")} />;
            }
        } else {
            _btn = typeof android !== "undefined" ? <BarcodeScanIcon className="scan_icon" onClick={handleScanner} /> : null;
        }

        return _btn;
    }

    const addConsignee = async (val) => {
        setConsignee(val);
        // alertaRemove(ref_form.current.consignee);

        let item = list.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];

        if (typeof item === "object") {
            setConsigneeId(item.id_user);
        } else {
            setConsigneeId("");
        }
    }

    const changeConsignee = (val) => {
        setConsignee(val);
        // alertaRemove(ref_form.current.consignee);

        let item = list.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];

        if (typeof item === "object") {
            updatePackage("id_client", item.id_user);
        } else {
            updatePackage("id_client", "");
        }
    }
    
    const firstConsignee = (val) => {
        setConsigneeId(val);

        let item = list.filter(({ id_user }) => id_user === val);
        item = item[0];

        if (typeof item === "object") {
            setConsignee(item.nombre + " " + item.apellido);
        } else {
            setConsignee("");
        }
    }

    React.useEffect(() => {
        isMounted.current = true;

        setEstado(item.estado);
        setCodigo(item.codigo);
        setValorp(funciones.formatPrice(item.price).format);
        setValord(funciones.formatPrice(item.v_domicilio).format);
        setValorm(funciones.formatPrice(item.v_mensajero).format);
        setDescripcion(item.descripcion || "");
        setAttachments(item.attachments);
        createFile(item.attachments);
        setRecibiendo(item.recibiendo || false);
        firstConsignee(item.id_client);

        return () => isMounted.current = false;
    }, [item]);

    return (
        <div className="new_container">

            <fieldset className="new_features">
                <legend>Paquete {item.index}</legend>
                <div className="first_row">
                    <h5>
                        {data_user.nivel <= 2 ?
                            <>
                                <samp>Estado</samp>
                                <select name="estado" defaultValue={estado} onChange={e => updatePackage("estado", e.target.value)}
                                    style={{ backgroundColor: colores[estado] }}>
                                    <option value="0">Estado...</option>
                                    <option value="1">Por recoger</option>
                                    <option value="2">Recogido</option>
                                    <option value="3">En transito</option>
                                    <option value="4">Para salir</option>
                                    <option value="5">Por entregar</option>
                                    <option value="6">OK</option>
                                    <option value="9">OK+DV</option>
                                    <option value="7" >KO+CANCELADO</option>
                                    <option value="8" >KO+DOM</option>
                                    {/* <option value="12">KO+DOM+PAGO</option> */}
                                    <option value="10">KO+CF</option>
                                    <option value="13">KO+REPROGRAMADO</option>
                                </select>
                            </>
                            :
                            <>
                                {funciones.getState(item)}
                            </>
                        }
                    </h5>

                    <div className="pickup_column">
                        <Field
                            rol="6"
                            name="consignee"
                            titulo="Cliente bts"
                            list={list}
                            placeholder="Ingresa Cliente bts"
                            value={consignee}
                            onChange={changeConsignee}
                            onAdd={addConsignee}
                        />
                    </div>
                </div>

                <div className="f_row" style={{ maxWidth: 600 }}>

                    <div className="features_options" style={{ width: "180px", marginLeft: 10, marginRight: 10, marginTop: 12 }}>
                        <label>Código *</label>
                        <div className="input" onBlur={handlerBlur}>
                            <input
                                type="text"
                                name="codigo"
                                value={codigo}
                                placeholder="Ingresa el código"
                                onChange={({ target: { value } }) => updatePackage("codigo", value)} />
                            <BtnScaner />
                        </div>
                    </div>

                    {(codigo.length >= 1 || recibiendo) && <>

                        <div className="f_row" style={{ alignItems: "self-start", marginTop: 0 }}>

                            <fieldset className="_in_row _no_border">
                                <legend>Valor producto *</legend>
                                <InputNum name="precio" placeholder="Ingresa el precio"
                                    value={valorp} onChange={(value) => updatePackage("valorp", value)} />
                            </fieldset>

                            <fieldset className="_in_row _no_border">
                                <legend>Valor domicilio *</legend>
                                <InputNum name="precio" placeholder="Ingresa el precio"
                                    value={valord} onChange={(value) => updatePackage("valord", value)} />
                            </fieldset>

                            <fieldset className="_in_row _no_border">
                                <legend>Valor mensajero *</legend>
                                <InputNum name="precio" placeholder="Ingresa el precio"
                                    value={valorm} onChange={(value) => updatePackage("valorm", value)} />
                            </fieldset>

                        </div>

                        <div className="f_row" style={{ width: "100%", alignItems: "self-start", justifyContent: "center" }}>

                            <div className="features_options _static" style={{ marginLeft: 10, marginRight: 10 }}>
                                <label>Descriptión</label>
                                <textarea name="descripcion" rows={media_movil ? "2" : "5"} value={descripcion} placeholder="Ingresa una Descriptión"
                                    onChange={({ target: { value } }) => updatePackage("descripcion", value)} style={{ marginTop: 0, paddingTop: 12, paddingBottom: 16 }} />
                            </div>

                            <div className="features_options _static" style={{ marginLeft: 10, marginRight: 10 }}>
                                <label>Adjuntar archivos</label>
                                <FileUpload
                                    codigo={codigo}
                                    attach={filesAttached}
                                    onChange={(value) => updatePackage("attachments", value)} />
                            </div>

                        </div>
                    </>}

                </div>

            </fieldset>

        </div>
    );
}

export default EditContainerState;