import * as React from "react";
import DeleteIcon from "mdi-react/DeleteOutlineIcon";
import { Alert, Confirm } from "shared/Alert";
import EditIcon from "mdi-react/EditOutlineIcon";
import HistoryIcon from "mdi-react/HistoryIcon";
import OpenIcon from "mdi-react/OpenInNewIcon";
import History from "shared/History";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuRow = (params) => {
    const { history, data, blue, children, onSel, reload, current } = params;
    const [clases, setClases] = React.useState(blue == 0 ? "table_row bkg_blue" : "table_row");
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [_pos, setPos] = React.useState({ top: "0", left: "0" });
    const [showHistory, setShowHistory] = React.useState(false);
    const [showMenu, setShowMenu] = React.useState(false);
    const menu = React.useRef(null);


    const goOpen = (e) => {
        e.preventDefault()
        e.stopPropagation();

        history.push({
            pathname: "/orden-de-servicio",
            state: { data: data }
        });
    }

    const goEdit = (e) => {
        e.preventDefault()
        e.stopPropagation();

        history.push({
            pathname: "/editar-orden-de-servicio",
            state: { data: { ...data, date_received: data.date_received == null ? data.date_creation : data.date_received } }
        });
    }

    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm(`Seguro de borrar la orden Nº ${data.consecutivo}?`, async (response) => {
            if (response) {
                let _data = await api.deleteOrder({
                    id_order: data.id,
                    tipo: "orden de recepción"
                });

                if (_data.response == 1) {
                    reload();
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => history.push("/"));
                } else {
                    Alert(_data.msg, "warning");
                }
            }
        });

    }

    const callHistory = () => {
        setShowMenu(false);
        setShowHistory(true);
    }

    const onSelect = (e) => {
        e.preventDefault()
        e.stopPropagation();

        onSel(data);
    }

    const stopClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let _height = window.innerHeight;
        let _width = document.getElementById("root").offsetWidth;
        let _posx = (e.pageX + 220) > _width ? _width - 220 : e.pageX;
        let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;
        let _to_y = _to_border_bottom < 150 ? e.pageY - 150 : e.pageY;

        setPos({ top: _to_y + "px", left: _posx + "px", });
        setShowMenu(true);
    }

    const BtnDelete = () => {
        let _btn = <li><DeleteIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Borrar</span></li>;

        switch (parseInt(data.estado)) {
            case 0:
            case 1:
            case 2:
            case 4:
            case 5:
            case 7:
            case 8:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
                if (data_user.nivel < 4) {
                    _btn = <li onClick={goDelete}><DeleteIcon /> <span>Borrar</span></li>;
                }
                break;
        }

        return (_btn);
    }

    const BtnEdit = () => {
        let _btn = <li><EditIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Editar</span></li>;

        switch (parseInt(data.estado)) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 7:
            case 8:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
                if (data_user.nivel == 1 ||
                    data_user.nivel == 2 ||
                    data_user.nivel == 3 ||
                    data_user.nivel == 5) {
                    _btn = <li onClick={goEdit}><EditIcon /> <span>Editar</span></li>;
                }

                if (data_user.nivel == 6) {
                    _btn = <></>;
                }
                break;
        }

        return (_btn);
    }

    React.useEffect(() => {
        document.addEventListener("click", hiddenMenu);
        //row.current.addEventListener("contextmenu", handleContextMenu);

        return () => {
            document.removeEventListener("click", hiddenMenu);
            // if (row.current != null) {
            //     row.current.removeEventListener("contextmenu", handleContextMenu);
            // }
        }
    }, []);

    React.useEffect(() => {
        current === data.id && setClases(c => c + " bkg_select");
    }, [current]);

    return (
        <tr className={clases}
            onDoubleClick={goOpen}
            onClick={onSelect}
            onContextMenu={handleContextMenu}>

            {children}
            <td className="rowMenu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                {showMenu &&
                    <>
                        <div className="back_form" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu" style={_pos} ref={menu}>
                            <ul>
                                <li onClick={goOpen}><OpenIcon /> <span><strong>Abrir</strong></span></li>
                                <BtnEdit />
                                <hr />
                                <BtnDelete />

                                {data_user.nivel < 4 && <li onClick={callHistory}><HistoryIcon /> <span>Historial</span></li>}

                            </ul>
                        </nav>
                    </>}

                {showHistory && <History data={{ ...data, tipo: 1 }} pos={_pos} close={setShowHistory} />}
            </td>
        </tr>
    );

}

export default MenuRow;