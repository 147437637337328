import * as React from "react";
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import FileUpload from "shared/FileUpload";
import funciones from "services/funciones";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */

const EditContainer = ({ item, update, remove }) => {
    const [codigo, setCodigo] = React.useState("");
    const [valorp, setValorp] = React.useState("");
    const [valord, setValord] = React.useState("");
    const [valorm, setValorm] = React.useState("");
    const [descripcion, setDescripcion] = React.useState(item.descripcion || "");
    const [attachments, setAttachments] = React.useState(item.attachments);
    const [filesAttached, setFilesAttached] = React.useState([]);

    const media_movil = window.matchMedia('(max-width: 500px)').matches;


    const createFile = async (url) => {
        let files = [];
        let only_urls = url.split(",");

        only_urls.forEach(async (_url, index) => {
            _url = _url.split("|")[1];

            let response = await fetch(_url, {
                mode: 'no-cors',
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            });
            let data = await response.blob();
            let file_name = _url.split("?")[0].split("%2F").pop();

            let file = new File([data],
                file_name,
                {
                    type: "image/" + file_name.split(".").pop(),
                    lastModified: Date.now()
                });
            file = Object.assign(file, {
                preview: _url,
                url: _url,
                path: file_name,
                nameinserver: file_name,
                isUpdate: true
            });

            files.push(file);

            if (files.length == only_urls.length) {
                setFilesAttached(files);
            }
        });

        // setFilesAttached(files);
    }

    const updatePackage = (param, value) => {

        switch (param) {
            case "codigo":
                // if (isNaN(value)) {
                //     return;
                // }

                break;
            case "attachments":
                console.log("attachments", value);

                break;
        }

        let _pack = {
            ...item,
            "codigo": param === "codigo" ? value : codigo,
            "valorp": param === "valorp" ? value.replaceAll(".", "") : valorp.replaceAll(".", ""),
            "valord": param === "valord" ? value.replaceAll(".", "") : valord.replaceAll(".", ""),
            "valorm": param === "valorm" ? value.replaceAll(".", "") : valorm.replaceAll(".", ""),
            "descripcion": param === "descripcion" ? value : descripcion,
            "attachments": param === "attachments" ? value : attachments,
        };

        param === "codigo" && setCodigo(value);
        param === "valorp" && setValorp(value);
        param === "valord" && setValord(value);
        param === "valorm" && setValorm(value);
        param === "descripcion" && setDescripcion(value);
        param === "attachments" && setAttachments(value);

        update(_pack, item.index);
    }

    React.useEffect(() => {
        setCodigo(item.codigo);
        setValorp(funciones.formatPrice(item.price).format);
        setValord(funciones.formatPrice(item.v_domicilio).format);
        setValorm(funciones.formatPrice(item.v_mensajero).format);
        setDescripcion(item.descripcion || "");
        setAttachments(item.attachments);
        createFile(item.attachments);
    }, [item]);

    return (
        <div className="new_container">

            <fieldset className="new_features">
                <legend>Paquete {item.index}</legend>
                <h5>
                    <CloseIcon onClick={() => remove(item.index, item.id)} />
                    {funciones.getState(item)}
                </h5>

                <div className="f_row" style={{ maxWidth: 600 }}>

                    <div className="f_row" style={{ width: "100%", alignItems: "self-start", justifyContent: "center" }}>

                        <div className="features_options _static" style={{ marginLeft: 10, marginRight: 10 }}>
                            <label>Descriptión</label>
                            <textarea name="descripcion" rows={media_movil ? "2" : "5"} value={descripcion} placeholder="Ingresa una Descriptión"
                                onChange={({ target: { value } }) => updatePackage("descripcion", value)} style={{ marginTop: 6, paddingTop: 12, paddingBottom: 16 }} />
                        </div>

                        <div className="features_options _static" style={{ marginLeft: 10, marginRight: 10 }}>
                            <label>Adjuntar archivos</label>
                            <FileUpload
                                codigo={codigo}
                                attach={filesAttached}
                                onChange={(value) => updatePackage("attachments", value)} />
                        </div>

                    </div>

                </div>

            </fieldset>

        </div>
    );
}

export default EditContainer;