import * as React from "react";
import funciones from "services/funciones";
import MenuRow from "./components/MenuRow";
import useTitle from "hooks/useTitle";
import ToolBar from "shared/tool_bar";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const MyPickUps = (params) => {
    const { history } = params;
    const [dataList, setDataList] = React.useState([]);
    const [allDataList, setAllDataList] = React.useState([]);
    const [selected, setSelected] = React.useState({ id: 0 });
    const [queryOpt, setQueryOpt] = React.useState({});
    const { setTitulo } = useTitle();
    const media_movil = window.matchMedia('(max-width: 500px)').matches;
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };


    const Row = ({ data, blue }) => {
        let status = funciones.getStatus(data);
        let date_pickup = new Date(data.date_pickup + "T05:00:00");

        return (
            <MenuRow data={data} blue={blue}
                history={history}
                current={selected.id}
                onSel={setSelected}
                reload={getList}>

                {status}
                <td>{data.consecutivo}</td>
                {!media_movil && <td>{data.piezas ?? 0}</td>}
                {data_user.nivel < 4 && !media_movil ? <td>{data.carrier_name ?? "Sin asignar"}</td> : null}
                
                {!media_movil && <td>{data.pickup_name}</td>}
                {/* <td>{_address.zone}</td> */}
                <td>{data.pickup_address}</td>
                {!media_movil && <td>{format(date_pickup.getDate())}/{meses[date_pickup.getMonth()]}/{date_pickup.getFullYear()}</td>}
            </MenuRow>
        );
    }

    const AllRow = () => {
        let _data = [];
        let _blue = 0;

        dataList.map(item => {
            _data.push(<Row key={item.id} blue={_blue} data={item} />);
            _blue = _blue >= 1 ? 0 : _blue + 1;
        });

        if (_data.length < 1) {
            return <h4 className="_titulo">No hay resultados</h4>;
        } else {
            return <table className="table">
                <tbody>

                    <tr className="table_title">
                        <th>Estado</th>
                        <th>Número</th>
                        {!media_movil && <th>Piezas</th>}
                        {data_user.nivel < 4 && !media_movil ? <th>Mensajero</th> : null}
                        {!media_movil && <th>Cliente final</th>}
                        <th>Lugar de recogida</th>
                        {!media_movil && <th>Fecha de recogida</th>}
                    </tr>

                    {_data}
                </tbody>
            </table>;
        }
    }

    const getList = async (_filter) => {
        let _info = typeof _filter === "undefined" ? queryOpt : { ..._filter, type: 2 };
        let _data = await api.fetchJson({
            url: "mis-pickup-list",
            data: _info
        });

        if (_data.response == 1) {
            let _packs = _data.data;

            setAllDataList(_packs);

            if (data_user.nivel == 3 && data_user.zona != null) {
                let _data = [];


                if (data_user.zona == "Todas") {
                    _data = _packs;
                } else {
                    _packs.map(item => {
                        let _address = funciones.formatZone(item.pickup_city, item.pickup_address);

                        if (_address.zone === data_user.zona) {
                            _data.push(item);
                        }
                    });
                }

                setDataList(_data);
            } else {
                setDataList(_packs);
            }

            setQueryOpt(_info);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const filterList = (zona) => {
        let _data = [];

        if (zona == "Todas") {
            setDataList(allDataList);
        } else {

            allDataList.map(item => {
                let _address = funciones.formatZone(item.pickup_city, item.pickup_address);

                if (_address.zone === zona) {
                    _data.push(item);
                }
            });

            setDataList(_data);
        }
    }

    React.useEffect(() => {
        setTitulo("Ordenes de recogida");
    }, []);

    return (
        <div className="cont_principal">
            <h3 className="_title _in_filters">Ordenes de recogida</h3>
            <ToolBar {...params}
                data={selected}
                reload={getList}
                filterList={filterList}
                estado="1"
                borrar={{ msg: "¿Seguro de borrar la orden Nº " + selected.consecutivo + "?", tipo: "orden de recogida" }}
                editar="/editar-orden-de-recogida"
                crear={() => history.push("/nueva-orden")} />

            <AllRow />
        </div>
    );
}

export default MyPickUps;
