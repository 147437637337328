import * as React from 'react';
import LogInForm from './components/LogInForm';
import { useTranslation } from 'react-i18next';


const LogIn = ({ changeTheme, history }) => {
  const { t } = useTranslation('common');

  console.log("android", typeof android);

  const clearSesion = () => {
    localStorage.removeItem('usuario');
  }
  
  React.useEffect(() => {
    document.title = t('form_login.sign_in');
    let theme = localStorage.getItem("theme");
    changeTheme(theme ? theme : "theme-light");
    clearSesion();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="account">
      <div className="account_background">
        <div className="account__wrapper">

          <div className="account__card">

            <LogInForm history={history} />

            <p>© 2021 - {new Date().getFullYear()} <b>Best Transport Service</b> todos los derechos reservados.</p>

          </div>
        </div>
      </div>
    </div>
  );
}

export default LogIn;