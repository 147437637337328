import * as React from "react";
import funciones from "services/funciones";
import * as Rdom from "react-router-dom";
import VisorImg from "shared/VisorImg";
import useTitle from "hooks/useTitle";
import { Alert } from "shared/Alert";
import BarCode from "shared/BarCode";
import Loading from "shared/Loading";
import api from "services/api";
import Menu from "./Menu";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
const mes = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const Detalle = ({ history, location }) => {
    const params = Rdom.useParams();
    const [media_movil] = React.useState(window.matchMedia('(max-width: 576px)').matches);
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [fecha_recibido, setFechaRecibido] = React.useState(new Date());
    const [mensaje, setMensaje] = React.useState("Cargando...");
    const [showLoading, setShowLoading] = React.useState(true);
    const [data, setData] = React.useState();
    const [observaciones, setObservaciones] = React.useState([]);
    const { setTitulo } = useTitle();


    const handleZoom = (e, imagen) => {
        e.preventDefault();

        VisorImg([imagen]);
    }

    const Attachments = ({ files }) => {
        let _list = [];
        files = files.split(",");

        files.map((_file, index) => {
            let _data_file = _file.split("|");

            let _new_file = new File([_data_file[1]], _data_file[0], { type: "image/" + _data_file[0].split('.').pop() });

            _new_file = Object.assign(_new_file, { preview: _data_file[1] });

            _list.push(<img src={process.env.REACT_APP_SERVER + _data_file[1]} key={index}
                alt={_data_file[0] + " en el paquete " + data.codigo}
                onClick={e => handleZoom(e, _new_file)} />);
        });

        observaciones.map((_obj, _i) => {
            if (_obj.attachments && _obj.attachments.length > 0) {
                let _nombre = _obj.attachments.split("/").pop();
                let _file_attach = new File([_obj.attachments], _nombre, { type: "image/" + _nombre.split('.').pop() });

                _file_attach = Object.assign(_file_attach, { preview: _obj.attachments });

                _list.push(<div className="_evidence">
                    <i>{_obj.who_received ?? "..."}</i>
                    <img src={process.env.REACT_APP_SERVER + _obj.attachments} key={"o_" + _i}
                        alt={"Imagen de evidencia " + data.codigo}
                        onClick={e => handleZoom(e, _file_attach)} />
                </div>);
            }
        });

        return <div className="contain_files">{_list}</div>;
    }

    const getData = async (_codebar) => {
        setShowLoading(true);
        let _data = await api.fetchJson({
            url: "get-packages",
            data: {
                type: "por_codigo",
                codebar: _codebar
            }
        });

        let _notes = await api.fetchJson({
            url: "get-packages",
            data: {
                type: "notas_pack",
                codebar: _codebar
            }
        });

        if (_data.response == 1) {
            if (_data.data.length > 0) {
                let _datos = _data.data[0];
                setData(_datos);
                setObservaciones(_notes.data);
                setFechaRecibido(new Date(_datos.date_received + "T05:00:00"));
            } else {
                setData();
                setMensaje(`No se encontró ningún paquete asignado al código "${_codebar}"`);
            }
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const handleReload = (_codebar) => {
        setMensaje("Cargando...");
        setShowLoading(true);
        getData(_codebar);
    }

    React.useEffect(() => {
        if (params.codebar) {
            setTitulo("Detalle del paquete " + params.codebar);
            getData(params.codebar);
        } else {
            if (location.state) {
                let _data = location.state.data;
                setTitulo("Detalle del paquete " + _data.codigo);
                getData(_data.codigo);
            } else {
                history.push("/todos-los-paquetes");
            }
        }
    }, [params.codigo, location.pathname]);

    return (
        <>
            {data ?
                <Menu data={data} history={history} reload={() => handleReload(data.codigo)}>
                    <div className="t_header">

                        <div className="_columna" >
                            <h3>Detalle del paquete</h3>
                            {data.carrier ?
                                <h5>Mensajero: <b>{window.atob(data_user.auth) == data.carrier ? "( tú )" : ""} {data.carrier_name}</b></h5>
                                :
                                <h5><b>Sin mensajero asignado</b></h5>
                            }
                            <h5 className="estado">Estado: {funciones.getState(data)}</h5>
                        </div>

                        <div className="_columna">
                            <BarCode code={data.codigo} />
                        </div>

                    </div>

                    <div className="_tabla">

                        <div className="columna">
                            <h4 className="_title_inside">Información del paquete</h4>

                            <div className="cont_info">
                                <p style={{ marginTop: 6 }}>Cliente: <b>{data.nombre_cliente}</b></p>
                                {media_movil ?
                                    <>
                                        <p style={{ marginTop: 6, marginBottom: 8 }}>Consecutivo cliente: <br /><b>{data.consecutivo + "-" + data.consecutive_client}</b></p>
                                        <p style={{ marginTop: 6, marginBottom: 8 }}>Fecha de recepción: <br /><b>{fecha_recibido.getDate()} de {meses[fecha_recibido.getMonth()]} del {fecha_recibido.getFullYear()}</b></p>
                                    </>
                                    :
                                    <>
                                        <p style={{ marginBottom: 8 }}>Consecutivo cliente: <b>{data.consecutivo + "-" + data.consecutive_client}</b></p>
                                        <p style={{ marginTop: 6, marginBottom: 8 }}>Fecha de recepción: <b>{fecha_recibido.getDate()} de {meses[fecha_recibido.getMonth()]} del {fecha_recibido.getFullYear()}</b></p>
                                    </>
                                }

                                <p>Valor producto:  <b>${funciones.formatPrice(data.price).format}</b></p>
                                {data.pago_al_vendedor && <p style={{ color: "rgb(255,10,10)" }}>Pago al vendedor: <b>${funciones.formatPrice(data.pago_al_vendedor).format}</b></p>}
                                {(data_user.nivel == 6 || data_user.nivel < 4) && <p>Valor domicilio: <b>${funciones.formatPrice(data.v_domicilio).format}</b></p>}
                                {data_user.nivel <= 4 && <p>Valor Mensajero: <b>${funciones.formatPrice(data.v_mensajero).format}</b></p>}

                            </div>
                        </div>

                        <div className="columna">
                            <h4 className="_title_inside">Descripción y observaciones</h4>

                            <div className="cont_info">
                                <h3>Descripción</h3>
                                <p style={{ marginTop: 0, marginBottom: 16 }}>{data.descripcion ?? "..."}</p>

                                {observaciones.length > 0 && <h3>Observaciones</h3>}
                                {observaciones.map((_obj, _i) => {
                                    let _fecha = new Date(_obj.date + "T05:00:00");

                                    return (
                                        <p key={_i} className="observacion">{_obj.tipo_dv ? <i className="_dv_type">{funciones.getState(_obj)} {_obj.tipo_dv}</i> : funciones.getState(_obj)}
                                            <b>{_fecha.getDate()}/{mes[_fecha.getMonth()]}/{_fecha.getFullYear()}</b>
                                            {media_movil && <br />}
                                            <samp>{_obj.gestor_name}</samp>
                                            <br />
                                            <i>{_obj.note}</i></p>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    <h4 style={{ padding: 0 }}>Imagen del paquete</h4>
                    <div className="_tabla">
                        <div className="fila">
                            {data.attachments && <Attachments files={data.attachments} />}
                        </div>
                    </div>
                </Menu>
                :
                <div className="main_container">
                    <h2 style={{ textAlign: "center", paddingTop: 64, paddingBottom: 82 }}>{mensaje}</h2>
                </div>
            }
            {showLoading && <Loading />}
        </>
    );
}

export default Detalle;