import * as React from "react";
import AccountGroupIcon from "mdi-react/AccountGroupIcon";
import ReporIcon from "mdi-react/ChartAreasplineIcon";
import CajaIcon from "mdi-react/CashRegisterIcon";
import SidebarCategory from "./SidebarCategory";
import ArchiveIcon from "mdi-react/ArchiveIcon";
import HomeIcon from "mdi-react/HomeIcon";
import ChatIcon from "mdi-react/ChatIcon";
import * as Iconos from "../../../images";
import SidebarLink from "./SidebarLink";
import PropTypes from "prop-types";

/* eslint eqeqeq: 0 */

const SidebarContent = ({ onClick }) => {
  const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };

  const handleHideSidebar = () => {
    onClick();
  };

  return (
    <div className="sidebar__content">

      <ul className="sidebar__block">
        <SidebarLink title="Inicio" icon={<HomeIcon />} route="/home" onClick={handleHideSidebar} />
      </ul>

      <ul className="sidebar__block">

        {data_user.nivel < 4 ? <>
          <SidebarCategory title="Administración" icon={<Iconos.StoreCog />}>
            {data_user.nivel < 3 && <SidebarLink title="Generar Códigos" route="/generar-codigos" onClick={handleHideSidebar} />}
            {data_user.nivel <= 3 && <SidebarLink title="Reportes" route="/reportes-admin" onClick={handleHideSidebar} />}
            {data_user.nivel <= 3 && <SidebarLink title="Caja mensajeros" route="/caja-mensajeros" onClick={handleHideSidebar} />}
            {data_user.nivel <= 3 && <SidebarLink title="Caja clientes" route="/caja-clientes" onClick={handleHideSidebar} />}
            {data_user.nivel <= 3 && <SidebarLink title="Caja bts" route="/caja-bts" onClick={handleHideSidebar} />}
          </SidebarCategory>

          <SidebarCategory title="Usuarios" icon={<AccountGroupIcon />}>
            {data_user.nivel == 1 && <SidebarLink title="Administradores" route={{ pathname: "/administradores", state: { rol: 2 } }} onClick={handleHideSidebar} />}
            {data_user.nivel < 3 && <SidebarLink title="Coordinadores" route={{ pathname: "/coordinadores", state: { rol: 3 } }} onClick={handleHideSidebar} />}
            {data_user.nivel < 4 && <SidebarLink title="Mensajeros" route="/mensajeros" onClick={handleHideSidebar} />}
            {data_user.nivel < 3 && <SidebarLink title="Recepcionistas" route={{ pathname: "/recepcionistas", state: { rol: 5 } }} onClick={handleHideSidebar} />}
            {data_user.nivel < 3 && <SidebarLink title="Cajeros" route={{ pathname: "/cajeros", state: { rol: 8 } }} onClick={handleHideSidebar} />}
            {data_user.nivel < 3 && <SidebarLink title="Clientes" route={{ pathname: "/clientes", state: { rol: 6 } }} onClick={handleHideSidebar} />}
          </SidebarCategory>
        </> : null}

        {data_user.nivel < 6 && <SidebarLink title="Recogidas" icon={<Iconos.BoxMarker />} route="/ordenes-de-recogida" onClick={handleHideSidebar} />}
        {(data_user.nivel < 4 || data_user.nivel == 5) && <SidebarLink title="Recepción" icon={<Iconos.BoxReception />} route="/ordenes-de-recepcion" onClick={handleHideSidebar} />}
        {data_user.nivel < 6 && <SidebarLink title="Entregas" icon={<ArchiveIcon />} route="/todos-los-paquetes" onClick={handleHideSidebar} />}

        {data_user.nivel == 8 && <SidebarLink title="Reportes" icon={<ReporIcon />} route="/reportes-mensajeros" onClick={handleHideSidebar} />}
        {data_user.nivel == 8 && <SidebarLink title="Caja" icon={<CajaIcon />} route="/caja-mensajeros" onClick={handleHideSidebar} />}

        {data_user.nivel == 6 && <SidebarLink title="Recogidas" icon={<Iconos.BoxMarker />} route="/mis-ordenes" onClick={handleHideSidebar} />}
        {data_user.nivel == 6 && <SidebarLink title="Historial" icon={<Iconos.BoxHistory />} route="/todos-mis-paquetes" onClick={handleHideSidebar} />}
        {data_user.nivel == 6 && <SidebarLink title="Entregas" icon={<ArchiveIcon />} route="/mis-paquetes" onClick={handleHideSidebar} />}

        {(data_user.nivel == 4 || data_user.nivel == 6) && <SidebarLink title="Caja" icon={<CajaIcon />} route="/reportes" onClick={handleHideSidebar} />}

        {(data_user.nivel == 1 ||
          data_user.nivel == 2 ||
          data_user.nivel == 3 ||
          data_user.nivel == 4 ||
          data_user.nivel == 6 ||
          data_user.nivel == 7) && <SidebarLink title="Chat" icon={<ChatIcon />} route="/chat" onClick={handleHideSidebar} />}
      </ul>

    </div>
  );
};

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SidebarContent;
