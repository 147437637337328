import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from './sidebar/Sidebar';
import Topbar from './topbar/Topbar';

const Layout = (params) => {
  const [isSidebarShown, setIsSidebarShown] = useState(false);

  const changeMobileSidebarVisibility = () => {
    setIsSidebarShown(!isSidebarShown);
  };

  return (
    <div className="layout">
      <Topbar
        {...params}
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
      />
      <Sidebar
        sidebarShow={isSidebarShown}
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
      />
    </div>
  );
};

export default withRouter(Layout);
