import * as React from "react";
import EditContainer from "./EditContainerState";
import useTitle from "hooks/useTitle";
import Loading from "shared/Loading";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const EditPackage = ({ history, location }) => {
    const data = location.state.data;
    const { setTitulo } = useTitle();
    const [packages, setPackages] = React.useState({});
    const [allPaquages, setAllPaquages] = React.useState([]);
    const [showLoading, setShowLoading] = React.useState(true);
    const ref_form = React.useRef();

    const getAllList = async () => {
        let _data = await api.getUsers("clients");
        let _users = [];

        if (_data.response == 1) {
            _data.data.map(item => {
                item.nivel > "2" && _users.push(item);
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        return _users;
    }

    const configPackages = async (_data) => {
        let _pack = {};
        let _allPack = [];
        let _lista = await getAllList();

        _data.map((item) => {
            item.estado_real = item.estado;
            item.addressee = item.addressee ?? "";
            item.estado = data.comentario == null ? item.estado : 4;


            _pack = { ..._pack, [item.index]: item };
            _allPack.push(<ContainerEdit key={item.index} pack={item} lista={_lista} />);
        });

        setPackages(_pack);
        setAllPaquages(_allPack);
        setShowLoading(false);
    }

    const updatePackage = (pack, index) => {
        setPackages(p => ({ ...p, [index]: pack }))
    }

    const saveOrder = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "save-package",
            data: {
                old_client: data.id_client,
                order: data.orden,
                packages: packages
            }
        });

        if (_data.response == 1) {
            Alert("¡Modificado correctamente!", "ok", () => history.push("/todos-los-paquetes"));
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _is_empty = false;
        let _message = "";

        if (typeof packages !== "object" || Object.entries(packages).length < 1) {
            Alert("Debes agregar almenos un paquete!");
            return;
        }

        for (let i in packages) {
            if (parseInt(packages[i]["estado"]) < 1) {
                _is_empty = true;
                _message = `¡Debes seleccionar un estado!`;
                break
            }

            if (packages[i]["codigo"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar un "código" del paquete!`;
                break
            }

            if (packages[i]["pieces"] < 1) {
                _is_empty = true;
                _message = `La cantidad de "piezas" no puede ser menor a uno!`;
                break
            }

            // if (packages[i]["addressee"].length < 1) {
            //     _is_empty = true;
            //     _message = `Debes ingresar el "destinatario" del paquete!`;
            //     break
            // }

            // let delivery = new Date(packages[i]["delivery"] + "T06:00:00");
            let now = new Date();
            now.setHours("05");
            now.setMinutes("00");
            now.setSeconds("00");

            // if (delivery < now) {
            //     _is_empty = true;
            //     _message = `La fecha de entrega no puede ser menor a la fecha actual!`;
            //     break
            // }

            if (packages[i]["valorp"] == null) {
                _is_empty = true;
                _message = `¡No hay cambios para guardar!`;
                break
            }

            if (packages[i]["valorp"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar el valor del producto!`;
                break
            }

            if (packages[i]["valord"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar el valor del domicilio!`;
                break
            }

            if (packages[i]["valorm"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar el valor del mensajero!`;
                break
            }

            if (packages[i]["attachments"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar almenos una imagen para el paquete`;
                break
            }
        }

        if (_is_empty) {
            Alert(_message);
            return;
        }

        saveOrder();
    }

    const removePackage = (_index, _id) => {
        // Alert("Para borrar este paque lo tienes que hacer desde la orden " + data.consecutivo, "warning");
        history.goBack();
    }

    const ContainerEdit = ({ pack, lista }) => {

        return (
            <EditContainer
                item={pack}
                list={lista}
                remove={removePackage}
                update={(p, i) => updatePackage(p, i)} />
        );
    }

    React.useEffect(() => {
        setTitulo("Editar paquete " + data.codigo);

        if (location.state) {
            configPackages([{ ...data, index: data.codigo }]);
        } else {
            history.push("/todos-los-paquetes");
        }

    }, [data]);

    return (
        <form className="pickup" ref={ref_form}>
            <h3 className="_title">Editar paquete</h3>

            <div className="pickup_container" style={{ marginBottom: 28 }}>
                {allPaquages}
            </div>

            <div className="boton_agregar">

                <button type="button" className="btn btn-common width-static" onClick={validateFields}>Guardar</button>
                <button type="button" className="btn btn-common width-static" onClick={() => history.goBack()}>Cancelar</button>

            </div>

            {showLoading && <Loading />}
        </form >
    );
}

export default EditPackage;