import * as React from "react";
import AutorenewIcon from 'mdi-react/AutorenewIcon';
import DownloadIcon from "mdi-react/DownloadIcon";
import { Card, CardBody, Col } from "reactstrap";
import ReactExport from "react-export-excel";
import funciones from "services/funciones";
import * as Iconos from "../../../images";
import { useHistory } from "react-router";
import * as Icon from "../../../images";
import { Alert } from "shared/Alert";
import api from "services/api";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const ReportsExcelAdmin = ({ userToClose, sales, children }) => {
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const media_movil = window.matchMedia('(max-width: 576px)').matches;
    const [showLoading, setShowLoading] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);
    const [conteo, setConteo] = React.useState(0);
    const history = useHistory();


    const resalta = (e) => {
        e.preventDefault();
        let _row = e.target.parentNode

        if (_row.classList.contains("_resalta")) {
            _row.classList.remove("_resalta");
        } else {
            _row.classList.add("_resalta");
            // _row.className = "_resalta";
        }
    }

    const handlerRefresh = (e) => {
        e.preventDefault();

        getList();
    }

    const getList = async () => {
        setShowLoading(true);
        let _obj = {
            task: "with_where",
            nivel: "user"
        };

        if (userToClose && userToClose.id_user) {

            switch (parseInt(data_user.nivel)) {
                case 1:
                case 2:
                case 3:
                    _obj = {
                        task: "with_where",
                        nivel: "admin",
                        user_close: userToClose ? userToClose.id_user : "",
                        nivel_close: userToClose ? userToClose.nivel : ""
                    };
                    break;
                case 4:
                    _obj = {
                        task: "with_where",
                        nivel: "carrier"
                    };
                    break;
                case 8:
                    _obj = {
                        task: "with_where",
                        nivel: "recepcion",
                        user_close: userToClose ? userToClose.id_user : "",
                        nivel_close: userToClose ? userToClose.nivel : ""
                    };
                    break;
                case 6:
                    _obj = {
                        task: "with_where",
                        nivel: "cliente"
                    };
                    break;
            }


            let _data = await api.fetchJson({
                url: "reportes",
                data: _obj
            });

            if (_data.response == 1) {

                /**
                 * 
                 *  0 Sin asignar    
                 *  4 En almacén     
                 * 14 En bodega      
                 * 15 Devolución     
                 * 
                 *  1 Por recoger    
                 *  2 Recogido       
                 *  3 En transito    
                 *  5 Por entregar   
                 * 
                 *  6 OK             
                 *  9 OK+DV          
                 * 
                 * 11 Borrado        
                 *  7 KO+CANCELADO   
                 *  8 KO+DOM         
                 * 10 KO+CF          
                 * 12 KO+DOM+PAGO    
                 * 13 KO+REPROGRAMADO
                 * 
                 */
                let _dataList = _data.data.map(item => {
                    item.price = parseInt(item.price);
                    item.v_mensajero = parseInt(item.v_mensajero);
                    item.v_domicilio = parseInt(item.v_domicilio);
                    item.carrier_name = item.carrier ? item.carrier_name : "Sin asignar";

                    if (parseInt(userToClose.nivel) == 4) {
                        /**
                         * =SI(D54="OK";E54-F54;SI(D54="KO";0;SI(D54="KO+DOM";F54*-1)))
                         * "OK"              = valor - mensajero
                         * "OK+DV"           = valor - mensajero
                         *
                         * "KO+CANCELADO"    = 0
                         * "KO+CF"           = 0
                         * "KO+REPROGRAMADO" = 0
                         *
                         * "KO+DOM"          = mensajero * -1
                         * "KO+DOM+PAGO"     = domicilio_ko - mensajero
                         * 
                         */
                        switch (parseInt(item.estado)) {
                            case 6:
                            case 9:
                                item.subtotal_mensajero = item.price - item.v_mensajero;
                                break;
                            case 7:
                                item.subtotal_mensajero = 0;
                                break;
                            case 8:
                                item.subtotal_mensajero = item.v_mensajero * -1;
                                break;
                            case 12:
                                let _ko_dom_all_value = 0;
                                let _ko_obj = item.domicilio_ko ? item.domicilio_ko.split(",").pop() : [];
                                let _ko_dom_value = _ko_obj ? parseInt(_ko_obj.split(":")[1]) : 0;
                                let _ko_dom_name = _ko_obj ? _ko_obj.split(":")[0] : "";
                                _ko_dom_all_value += _ko_dom_value;

                                switch (_ko_dom_name) {
                                    case "Efectivo":
                                        item.efectivo = _ko_dom_value;
                                        break;
                                    case "Nequi":
                                        item.nequi = _ko_dom_value;
                                        break;
                                    case "DaviPlata":
                                        item.daviplata = _ko_dom_value;
                                        break;
                                    case "Bold":
                                        item.bold = _ko_dom_value;
                                        break;
                                }

                                item.subtotal_mensajero = _ko_dom_all_value - item.v_mensajero;
                                break;
                            default:
                                item.subtotal_mensajero = "No aplica";
                                break;
                        }
                    }

                    if (parseInt(userToClose.nivel) == 6) {
                        /**
                         * =SI(D54="OK";E54-G54;SI(D54="KO";0;SI(D54="KO+DOM";G54*-1)))
                         * "OK"              = valor - domicilio
                         * "OK+DV"           = valor - domicilio
                         *
                         * "KO+CANCELADO"    = 0
                         * "KO+CF"           = 0
                         * "KO+REPROGRAMADO" = 0
                         *
                         * "KO+DOM"          = domicilio * -1
                         * "KO+DOM+PAGO"     = domicilio_ko - domicilio
                         */
                        switch (parseInt(item.estado)) {
                            case 6:
                            case 9:
                                item.subtotal = item.price - item.v_domicilio;
                                break;
                            case 7:
                                item.subtotal = 0;
                                break;
                            case 8:
                                item.subtotal = item.v_domicilio * -1;
                                break;
                            case 12:
                            case 15:
                                let _ko_dom_all_value = 0;
                                let _ko_dom_all = item.domicilio_ko ? item.domicilio_ko.split(",") : [];

                                _ko_dom_all.map(_ko_obj => {
                                    let _ko_dom_value = _ko_obj ? parseInt(_ko_obj.split(":")[1]) : 0;
                                    let _ko_dom_name = _ko_obj ? _ko_obj.split(":")[0] : "";
                                    _ko_dom_all_value += _ko_dom_value;



                                    switch (_ko_dom_name) {
                                        case "Efectivo":
                                            item.efectivo += _ko_dom_value;
                                            break;
                                        case "Nequi":
                                            item.nequi += _ko_dom_value;
                                            break;
                                        case "DaviPlata":
                                            item.daviplata += _ko_dom_value;
                                            break;
                                        case "Bold":
                                            item.bold += _ko_dom_value;
                                            break;
                                    }
                                });

                                item.v_domicilio = item.v_domicilio * _ko_dom_all.length;

                                item.subtotal = _ko_dom_all_value - item.v_domicilio;
                                break;
                            default:
                                item.subtotal = "No aplica";
                                break;
                        }
                    }

                    return item;
                });

                setConteo(parseInt(_data.conteo));
                setDataList(_dataList);
            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => history.push("/"));
            } else {
                Alert(_data.msg, "warning");
            }

        }

        setShowLoading(false);
    }

    const BotonDescargar = () => {
        if (dataList.length > 0) {
            return (
                <>
                    {showLoading ?
                        <button className="download-button _flotante"><Icon.Loading />Cargando info...</button>
                        :
                        <button className="download-button _flotante"><DownloadIcon />Descargar Excel</button>
                    }
                </>
            );
        } else {
            return (
                <>
                    {showLoading ?
                        <button className="download-button _flotante"><Icon.Loading />Cargando info...</button>
                        :
                        <button className="download-button _flotante _deshabilitado"><DownloadIcon />Descargar Excel</button>
                    }
                </>
            );
        }
    }

    const HojaDeCalculo = () => {
        switch (parseInt(data_user.nivel)) {
            case 1:
            case 2:
            case 3:
                return <ExcelFile filename="Reporte de entregas" element={<BotonDescargar />}>
                    <ExcelSheet data={dataList} name="Entregas">
                        <ExcelColumn label="Vendedor" value="consignee_name" />
                        <ExcelColumn label="Código" value="codigo" />
                        <ExcelColumn label="Mensajero" value="carrier_name" />
                        <ExcelColumn label="Estado" value={(col) => funciones.getPlaneState(col)} />
                        <ExcelColumn label="Valor producto" value="price" />

                        <ExcelColumn label="Domicilio mensajero" value="v_mensajero" />
                        <ExcelColumn label="Valor domicilio" value="v_domicilio" />

                        <ExcelColumn label="Subtotal mensajero" value="subtotal_mensajero" />
                        <ExcelColumn label="Subtotal" value="subtotal" />

                        <ExcelColumn label="Efectivo" value="efectivo" />
                        <ExcelColumn label="Nequi" value="nequi" />
                        <ExcelColumn label="DaviPlata" value="daviplata" />
                        <ExcelColumn label="Bold" value="bold" />
                        {/* <ExcelColumn label="Ciudad" value="addressee_city" /> */}
                    </ExcelSheet>
                </ExcelFile>;
            case 4:
            case 8:
                return <ExcelFile filename="Reporte de entregas" element={<BotonDescargar />}>
                    <ExcelSheet data={dataList} name="Entregas">
                        <ExcelColumn label="Vendedor" value="consignee_name" />
                        <ExcelColumn label="Código" value="codigo" />
                        <ExcelColumn label="Mensajero" value="carrier_name" />
                        <ExcelColumn label="Estado" value={(col) => funciones.getPlaneState(col)} />
                        <ExcelColumn label="Valor producto" value="price" />

                        <ExcelColumn label="Domicilio m" value="v_mensajero" />
                        <ExcelColumn label="Subtotal m" value="subtotal_mensajero" />

                        <ExcelColumn label="Efectivo" value="efectivo" />
                        <ExcelColumn label="Nequi" value="nequi" />
                        <ExcelColumn label="DaviPlata" value="daviplata" />
                        <ExcelColumn label="Bold" value="bold" />
                    </ExcelSheet>
                </ExcelFile>;
            case 6:
                return <ExcelFile filename="Reporte de entregas" element={<BotonDescargar />}>
                    <ExcelSheet data={dataList} name="Entregas">

                        <ExcelColumn label="Código" value="codigo" />
                        <ExcelColumn label="Mensajero" value="carrier_name" />
                        <ExcelColumn label="Estado" value={(col) => funciones.getPlaneState(col)} />
                        <ExcelColumn label="Valor producto" value="price" />

                        <ExcelColumn label="Valor domicilio" value="v_domicilio" />
                        <ExcelColumn label="Subtotal" value="subtotal" />

                        <ExcelColumn label="Efectivo" value="efectivo" />
                        <ExcelColumn label="Nequi" value="nequi" />
                        <ExcelColumn label="DaviPlata" value="daviplata" />
                        <ExcelColumn label="Bold" value="bold" />
                    </ExcelSheet>
                </ExcelFile>;
            default:
                return <> </>;
        }
    }

    const ResumenVentas = () => {
        let _t_producto = 0;
        let _t_domicilio = 0;
        let _t_mensajero = 0;
        let _t_subtotal = 0;
        let _t_efectivo = 0;
        let _t_nequi = 0;
        let _t_daviplata = 0;
        let _t_bold = 0;
        let _contenido = [];



        if (userToClose) {
            switch (parseInt(userToClose.nivel)) {
                case 4:
                    _contenido = <div className="dashboard__sales-table">
                        {dataList.length < 1 ?
                            <h4>No hay entregas en caja</h4>
                            :
                            <table className="table">
                                <tbody>

                                    <tr className="table_title">
                                        <th></th>
                                        <th>Estado</th>
                                        <th>Código</th>
                                        {!media_movil && <th>Fecha Creado</th>}
                                        {!media_movil && <th>Vendedor</th>}

                                        <th>Producto</th>
                                        <th>Domicilio</th>
                                        <th>Subtotal</th>
                                        <th>Efectivo</th>
                                        <th>Nequi</th>
                                        <th>DaviPlata</th>
                                        <th>Bold</th>
                                    </tr>

                                    {dataList.map((item, index) => {
                                        let fecha_creado = new Date(item.date_create + "T05:00:00");
                                        _t_producto += item.price;
                                        _t_mensajero += item.v_mensajero;
                                        _t_subtotal += item.subtotal_mensajero == "No aplica" ? 0 : item.subtotal_mensajero;
                                        _t_efectivo += parseInt(item.efectivo || 0);
                                        _t_nequi += parseInt(item.nequi || 0);
                                        _t_daviplata += parseInt(item.daviplata || 0);
                                        _t_bold += parseInt(item.bold || 0);

                                        return <tr key={index} className="_to_clic" onClick={resalta}>
                                            <td>{index + 1}</td>
                                            {funciones.getStatus(item)}
                                            <td>{item.codigo}</td>
                                            {!media_movil && <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()}</td>}
                                            {!media_movil && <td>{item.consignee_name}</td>}

                                            <td>{funciones.formatPrice(item.price).format}</td>
                                            <td>{item.v_mensajero ? funciones.formatPrice(item.v_mensajero).format : ""}</td>
                                            <td>{item.subtotal_mensajero ? funciones.formatPrice(item.subtotal_mensajero).format : ""}</td>
                                            <td>{item.efectivo ? funciones.formatPrice(item.efectivo).format : ""}</td>
                                            <td>{item.nequi ? funciones.formatPrice(item.nequi).format : ""}</td>
                                            <td>{item.daviplata ? funciones.formatPrice(item.daviplata).format : ""}</td>
                                            <td>{item.bold ? funciones.formatPrice(item.bold).format : ""}</td>
                                        </tr>;
                                    })}

                                    <tr className="_no_margin">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        {!media_movil && <td></td>}
                                        {!media_movil && <td></td>}
                                        <td><b>{funciones.formatPrice(_t_producto).format}</b></td>
                                        <td><b>{funciones.formatPrice(_t_mensajero).format}</b></td>
                                        <td><b>{funciones.formatPrice(_t_subtotal).format}</b></td>
                                        <td><b>{funciones.formatPrice(_t_efectivo).format}</b></td>
                                        <td><b>{funciones.formatPrice(_t_nequi).format}</b></td>
                                        <td><b>{funciones.formatPrice(_t_daviplata).format}</b></td>
                                        <td><b>{funciones.formatPrice(_t_bold).format}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                    </div>;
                    break;
                case 6:
                    _contenido = <div className="dashboard__sales-table">
                        {dataList.length < 1 ?
                            <h4>No hay entregas en caja</h4>
                            :
                            <table className="table">
                                <tbody>

                                    <tr className="table_title">
                                        <th></th>
                                        <th>Estado</th>
                                        <th>Código</th>
                                        {!media_movil && <th>Fecha Creado</th>}
                                        {!media_movil && <th>Mensajero</th>}

                                        <th>Producto</th>
                                        <th>Domicilio</th>
                                        <th>Subtotal</th>
                                        <th>Efectivo</th>
                                        <th>Nequi</th>
                                        <th>DaviPlata</th>
                                        <th>Bold</th>
                                    </tr>

                                    {dataList.map((item, index) => {
                                        let fecha_creado = new Date(item.date_create + "T05:00:00");
                                        _t_producto += item.price;
                                        _t_domicilio += item.v_domicilio;
                                        _t_subtotal += item.subtotal == "No aplica" ? 0 : item.subtotal;
                                        _t_efectivo += parseInt(item.efectivo || 0);
                                        _t_nequi += parseInt(item.nequi || 0);
                                        _t_daviplata += parseInt(item.daviplata || 0);
                                        _t_bold += parseInt(item.bold || 0);

                                        return <tr key={index} className="_to_clic" onClick={resalta}>
                                            <td>{index + 1}</td>
                                            {funciones.getStatus(item)}
                                            <td>{item.codigo}</td>
                                            {!media_movil && <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()}</td>}
                                            {!media_movil && <td>{item.carrier_name}</td>}

                                            <td>{funciones.formatPrice(item.price).format}</td>
                                            <td>{item.v_domicilio ? funciones.formatPrice(item.v_domicilio).format : ""}</td>
                                            <td>{item.subtotal ? funciones.formatPrice(item.subtotal).format : ""}</td>
                                            <td>{item.efectivo ? funciones.formatPrice(item.efectivo).format : ""}</td>
                                            <td>{item.nequi ? funciones.formatPrice(item.nequi).format : ""}</td>
                                            <td>{item.daviplata ? funciones.formatPrice(item.daviplata).format : ""}</td>
                                            <td>{item.bold ? funciones.formatPrice(item.bold).format : ""}</td>
                                        </tr>;
                                    })}

                                    <tr className="_no_margin">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        {!media_movil && <td></td>}
                                        {!media_movil && <td></td>}
                                        <td><b>{funciones.formatPrice(_t_producto).format}</b></td>
                                        <td><b>{funciones.formatPrice(_t_domicilio).format}</b></td>
                                        <td><b>{funciones.formatPrice(_t_subtotal).format}</b></td>
                                        <td><b>{funciones.formatPrice(_t_efectivo).format}</b></td>
                                        <td><b>{funciones.formatPrice(_t_nequi).format}</b></td>
                                        <td><b>{funciones.formatPrice(_t_daviplata).format}</b></td>
                                        <td><b>{funciones.formatPrice(_t_bold).format}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                    </div>;
                    break;
                default:
                    _contenido = <></>;
                    break;
            }
        }

        return <Col className="col_table">
            <Card style={{ overflow: "hidden" }}>
                <CardBody className="dashboard__card-widget">
                    <div className="card__title">
                        <h5 className="bold-text"><Iconos.Pack className="dashboard__money-icon" /> {conteo} paquetes</h5>
                    </div>

                    <div className="panel__btns">

                        <button
                            className="panel__btn"
                            aria-label="panel__btn"
                            type="button"
                            onClick={handlerRefresh}>
                            <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                        </button>

                    </div>

                    {_contenido}
                </CardBody>
            </Card>
        </Col>;
    }

    React.useEffect(() => {
        getList();
    }, [userToClose, sales]);

    return (
        <div className="content_reportes">
            {dataList.length > 0 ?
                <HojaDeCalculo />
                :
                <BotonDescargar />
            }

            {children}

            {userToClose && <ResumenVentas />}
        </div>
    )
}

export default ReportsExcelAdmin;