import * as React from "react";
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import { useHistory } from 'react-router-dom';
import { Alert } from "shared/Alert";
import api from "services/api";
import funciones from "services/funciones";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const PackageList = ({ data, pos, close }) => {
    const [dataList, setDataList] = React.useState([]);
    const history = useHistory();


    const callPackages = async () => {
        let _data = await api.fetchJson({
            url: "get-all-packages",
            data: { id_carrier: data.id_user, toassign: "toassign" }
        });

        if (_data.response == 1) {

            let _lista = _data.data.map((item, index) => {
                item.index = index;
                item.codigo = item.consecutivo + "-" + item.id_client;
                if (item.carrier == data.id_user) {
                    item.select = true;
                } else {
                    item.select = false;
                }

                return item;
            });
            setDataList(_lista);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const saveAssign = async (_to_assign, _to_clean) => {
        let _data = await api.saveAssignPackages({
            id_carrier: data.id_user,
            list_assign: _to_assign,
            list_clean: _to_clean
        });

        if (_data.response == 1) {
            close(false);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const AllRow = () => {
        let _data = [];

        dataList.map((item, index) => {

            _data.push(
                <p key={item.id}>
                    <input type="checkbox" checked={item.select} onChange={e => handleSelect(e.target.checked, index)} />
                   <i>{item.codigo}</i> {item.pieces} {item.type.toLowerCase()}{item.pieces > 1 ? "s" : ""} {item.length >= 1 && `(${item.length} x ${item.width} x ${item.height} ${item.l_unit})`} - {funciones.getZone({ address: item.addressee_address, city: item.addressee_city })}
                </p>
            );
        });

        if (_data.length < 1) {
            _data.push(<h3 key="01">No hay resultados</h3>);
        }

        return _data;
    }

    const validate = () => {
        let _lista = "";
        let _to_clean = "";

        dataList.map(_pack => {

            if (_pack.select) {

                _lista += _pack.id + ",";
            } else if (_pack.carrier == data.id_user) {
                _to_clean += _pack.id + ","
            }

        });

        if (_lista.length < 1) {
            Alert("Debes agregar al menos un paquete", "warning");
            return;
        }

        saveAssign(_lista, _to_clean);
    }

    const handleSelect = (_check, _index) => {
        let _lista = dataList.filter(item => {

            if (item.id === dataList[_index].id) {
                item.select = _check;
            }

            return item;
        });


        setDataList(_lista);
    }

    const handleClose = () => {
        close(false);
    }

    React.useEffect(() => {
        callPackages();
    }, []);

    return (
        <div className="content_modal">
            <div className="back_form _bkg_white"></div>
            <div className="win_modal" style={pos}>
                <h4><CloseIcon onClick={handleClose} />Paquetes sin asignar </h4>

                <div className="_scroll">

                    <AllRow />
                </div>

                <div className="_in_row">
                    <button type="button" className="btn btn-common width-static" onClick={validate}>Asignar</button>
                </div>
            </div>
        </div>
    );
}

export default PackageList;