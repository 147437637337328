import ArchiveIcon from "mdi-react/ArchiveIcon";
//import api from "./api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-useless-escape: 0 */

class Funciones {

    getPerfil(_nivel) {
        let _perfil;

        /**
         * Niveles de los usuarios 
         * 
         * 1 Super Administrador
         * 2 Administrador
         * 3 Coordinador de zona
         * 4 Mensajero
         * 5 Recepcionista
         * 6 Cliente bts
         * 7 Cliente final
         * 
         */

        switch (parseInt(_nivel)) {
            case 7:
                _perfil = "Cliente final";
                break;
            case 6:
                _perfil = "Cliente bts";
                break;
            case 5:
                _perfil = "Recepcionista";
                break;
            case 4:
                _perfil = "Mensajero";
                break;
            case 3:
                _perfil = "Coordinador";
                break;
            case 2:
                _perfil = "Administrador";
                break;
            case 1:
                _perfil = "Super Administrador";
                break;
            default:
                _perfil = "";
                break;
        }

        return _perfil;
    }

    getPlaneState(item) {
        let _estado = item.estado;
        let _state;

        switch (parseInt(_estado)) {
            case 18:
                _state = "OK+Pagado";
                break;
            case 17:
                if (item.caja_m_a == "0") {
                    _state = "OK+Pagado";
                } else {
                    _state = "Pagado";
                }
                break;
            case 16:
                _state = "Devuelto";
                break;
            case 15:
                _state = "Devolución";
                break;
            case 14:
                _state = "En bodega";
                break;
            case 13:
                _state = "KO+REPROGRAMADO";
                break;
            case 12:
                _state = "KO+DOM+PAGO";
                break;
            case 11:
                _state = "Borrado";
                break;
            case 10:
                _state = "KO+CF";
                break;
            case 9:
                _state = "OK+DV";
                break;
            case 8:
                _state = "KO+DOM";
                break;
            case 7:
                _state = "KO+CANCELADO";
                break;
            case 6:
                _state = "OK";
                break;
            case 5:
                _state = "Por entregar";
                break;
            case 4:
                _state = "Para salir";
                break;
            case 3:
                _state = "En transito";
                break;
            case 2:
                _state = "Recogido";
                break;
            case 1:
                _state = "Por recoger";
                break;
            case 0:
                _state = "Sin asignar";
                break;
            default:
                _state = "Sin estado";
                break;
        }

        return _state;
    }

    getState(item) {
        let _estado = item.estado;
        let _state;

        switch (parseInt(_estado)) {
            case 18:
                _state = <span style={{ backgroundColor: "#329600" }}>OK+Pagado</span>
                break;
            case 17:
                if (item.caja_m_a == "0") {
                    _state = <span style={{ backgroundColor: "#329600" }}>OK+Pagado</span>
                } else {
                    _state = <span style={{ backgroundColor: "#172b44" }}>Pagado</span>
                }
                break;
            case 16:
                _state = <span style={{ backgroundColor: "#172b58" }}>Devuelto</span>
                break;
            case 15:
                _state = <span style={{ backgroundColor: "#172b6c" }}>Devolución</span>
                break;
            case 14:
                _state = <span style={{ backgroundColor: "#172b80" }}>En bodega</span>
                break;
            case 13:
                _state = <span style={{ backgroundColor: "#f55a0d" }}>KO+REPROGRAMADO</span>
                break;
            case 12:
                _state = <span style={{ backgroundColor: "#f5500d" }}>KO+DOM+PAGO</span>
                break;
            case 11:
                _state = <span style={{ backgroundColor: "#ff2525" }}>Borrado</span>
                break;
            case 10:
                _state = <span style={{ backgroundColor: "#f5460d" }}>KO+CF</span>
                break;
            case 9:
                _state = <span style={{ backgroundColor: "#509600" }}>OK+DV</span>
                break;
            case 8:
                _state = <span style={{ backgroundColor: "#f53c0d" }}>KO+DOM</span>
                break;
            case 7:
                _state = <span style={{ backgroundColor: "#f5320d" }}>KO+CANCELADO</span>
                break;
            case 6:
                _state = <span style={{ backgroundColor: "#329600" }}>OK</span>
                break;
            case 5:
                _state = <span style={{ backgroundColor: "#fadc3c" }}>Por entregar</span>
                break;
            case 4:
                _state = <span style={{ backgroundColor: "#172b94" }}>Para salir</span>
                break;
            case 3:
                _state = <span style={{ backgroundColor: "#fae646" }}>En transito</span>
                break;
            case 2:
                _state = <span style={{ backgroundColor: "#faf050" }}>Recogido</span>
                break;
            case 1:
                _state = <span style={{ backgroundColor: "#fafa5a" }}>Por recoger</span>
                break;
            case 0:
                _state = <span style={{ backgroundColor: "#172ba8" }}>Sin asignar</span>
                break;
            default:
                _state = <span style={{ backgroundColor: "#153A61" }}>Sin estado</span>
                break;
        }

        return _state;
    }

    getStatus(item) {
        let _estado = item.estado;
        let status;

        /**
         * 
         * == Orden por colores ==
         * 
         *  0 Sin asignar        #172ba8    Estado inicial
         *  4 Para salir         #172b94
         * 14 En bodega          #172b80
         * 15 Devolución         #172b6c
         * 16 Devuelto           #172b58
         * 17 Pagado             #172b44
         * 18 OK+Pagado          #329600
         * 
         *  1 Por recoger        #fafa5a
         *  2 Recogido           #faf050
         *  3 En transito        #fae646
         *  5 Por entregar       #fadc3c
         * 
         *  6 OK                 #329600    Entregado
         *  9 OK+DV              #509600    Entregado con devolición 
         * 
         * 11 Borrado            #ff2525
         *  7 KO+CANCELADO       #f5320d    Cancelado sin desplazamiento 
         *  8 KO+DOM             #f53c0d    Cancelado con desplazamiento
         * 10 KO+CF              #f5460d    Reprogramado con cambio de franja
         * 12 KO+DOM+PAGO        #f5500d
         * 13 KO+REPROGRAMADO    #f55a0d
         * 
        */

        /**
         * 
         * == Orden por estado
         * 
         *  0 Sin asignar        #172ba8    Estado inicial
         *  1 Por recoger        #fafa5a
         *  2 Recogido           #faf050
         *  3 En transito        #fae646
         *  4 Para salir         #172b94
         *  5 Por entregar       #fadc3c
         *  6 OK                 #329600    Entregado
         *  7 KO+CANCELADO       #f5320d    Cancelado sin desplazamiento 
         *  8 KO+DOM             #f53c0d    Cancelado con desplazamiento
         *  9 OK+DV              #509600    Entregado con devolición 
         * 10 KO+CF              #f5460d    Reprogramado con cambio de franja
         * 11 Borrado            #ff2525
         * 12 KO+DOM+PAGO        #f5500d
         * 13 KO+REPROGRAMADO    #f55a0d
         * 14 En bodega          #172b80
         * 15 Devolución         #172b6c
         * 16 Devuelto           #172b58
         * 17 Pagado             #172b44
         * 18 OK+Pagado          #329600
         * 
         */
        switch (parseInt(_estado)) {
            case 18:
                status = <td><ArchiveIcon color="#329600" /> OK+Pagado</td>;
                break;
            case 17:
                if (item.caja_m_a == "0") {
                    status = <td><ArchiveIcon color="#329600" /> OK+Pagado</td>;
                } else {
                    status = <td><ArchiveIcon color="#172b44" /> Pagado</td>;
                }
                break;
            case 16:
                status = <td><ArchiveIcon color="#172b58" /> Devuelto</td>;
                break;
            case 15:
                status = <td><ArchiveIcon color="#172b6c" /> Devolución</td>;
                break;
            case 14:
                status = <td><ArchiveIcon color="#172b80" /> En bodega</td>;
                break;
            case 13:
                status = <td><ArchiveIcon color="#f55a0d" /> KO+REPROGRAMADO</td>;
                break;
            case 12:
                status = <td><ArchiveIcon color="#f5500d" /> KO+DOM+PAGO</td>;
                break;
            case 11:
                status = <td><ArchiveIcon color="#ff2525" /> Borrado</td>;
                break;
            case 10:
                status = <td><ArchiveIcon color="#f5460d" /> KO+CF</td>;
                break;
            case 9:
                status = <td><ArchiveIcon color="#509600" /> OK+DV</td>;
                break;
            case 8:
                status = <td><ArchiveIcon color="#f53c0d" /> KO+DOM</td>;
                break;
            case 7:
                status = <td><ArchiveIcon color="#f5320d" /> KO+CANCELADO</td>;
                break;
            case 6:
                status = <td><ArchiveIcon color="#329600" /> OK</td>;
                break;
            case 5:
                status = <td><ArchiveIcon color="#fadc3c" /> Por entregar</td>;
                break;
            case 4:
                status = <td><ArchiveIcon color="#172b94" /> Para salir</td>;
                break;
            case 3:
                status = <td><ArchiveIcon color="#fae646" /> En transito</td>;
                break;
            case 2:
                status = <td><ArchiveIcon color="#faf050" /> Recogido</td>;
                break;
            case 1:
                status = <td><ArchiveIcon color="#fafa5a" /> Por recoger</td>;
                break;
            case 0:
                status = <td><ArchiveIcon color="#172ba8" /> Sin asignar</td>;
                break;
            default:
                status = <td><ArchiveIcon color="#153A61" /> Sin estado</td>;
                break;
        }

        return status;
    }

    getMeasure() {
        let units = JSON.parse(localStorage.getItem('measure'));
        let _default = { longitud: "in", masa: "lb", volumen: "ft³" };

        units = units == null ? _default : units;

        return units;
    }

    /**
     * 
     * @param {JSON} _pack todos los prametros del paquete en formato json
     * 
     * @returns {String} string con la dirección, ciudad, zona
     */
    getZone(_pack) {
        let _zone = "";
        let _direccion = this.formateaDireccion(_pack.address.toLowerCase());

        switch (_pack.city.toLowerCase()) {

            case "zipaquira":
            case "san jose":
            case "la neuta":
            case "el canelon":
            case "el tejar":
            case "briceño":
            case "tocancipa":
            case "guasca":
            case "cajica":
            case "chia":
            case "macadamia":
            case "la calera":
            case "patios":
            case "san luis":
                _zone = "NorOriente";
                break;
            case "cota":
            case "el corzo":
            case "la isla":
            case "buena vista":
            case "guadalajara":
            case "villa mery":
                _zone = "NorOccidente";
                break;
            case "batallon 13":
            case "chipaque":
            case "suate":
            case "amorosa":
            case "boqueron":
            case "olarte":
            case "usme pueblo":
                _zone = "SurOriente";
                break;
            case "facatativa":
            case "zipacon":
            case "bojaca":
            case "cartagenita":
            case "madrid":
            case "mosquera":
            case "funza":
                _zone = "Oriente";
                break;
            case "soacha":
            case "fusagasuga":
            case "san antonio":
            case "mesitas":
            case "el charquito":
            case "silvania":
            case "el tambo":
            case "subia":
            case "san raimundo":
            case "granada":
            case "sibate":
            case "la arboleda":
                _zone = "SurOccidente";
                break;
            case "bogotá":
            case "bogota":
                _zone = this.zonificaDireccion(_direccion);
                break;
        }

        return <>{_direccion}, {_pack.city}  <b className="_zone"> {_zone}</b></>;
    }

    validaDireccion(_address) {
        let _dir = /^([a-zA-Z]+\s)+([0-9]{1,})+([a-zA-Z]{0,}\s#\s)+([0-9]{1,})+([a-zA-Z]{0,}\s-\s)+([0-9]{1,})+([a-zA-Z]{0,})|([a-zA-Z]+\s)+([0-9]{1,})+([a-zA-Z]{0,}\s#\s)+([0-9]{1,})+([a-zA-Z]{0,}\s-\s)+([0-9]{1,})+([a-zA-Z]{0,}\s)+([a-zA-Z]{3,})|([a-zA-Z]+\s)+([0-9]{1,})+([a-zA-Z]{0,}\s)+([a-zA-Z]{1,}\s#\s)+([0-9]{1,})+([a-zA-Z]{0,}\s-\s)+([0-9]{1,})+([a-zA-Z]{0,}\s)+(.*)$/;
        _address = _address.trim();

        if (!/^cll/ig.test(_address.toLowerCase()) &
            !/^calle/ig.test(_address.toLowerCase()) &
            !/^cra/ig.test(_address.toLowerCase()) &
            !/^carrera/ig.test(_address.toLowerCase()) &
            !/^av/ig.test(_address.toLowerCase()) &
            !/^avenida/ig.test(_address.toLowerCase()) &
            !/^tv/ig.test(_address.toLowerCase()) &
            !/^transversal/ig.test(_address.toLowerCase()) &
            !/^dg/ig.test(_address.toLowerCase()) &
            !/^diagonal/ig.test(_address.toLowerCase())) {
            return false;
        }

        return _dir.test(_address);
    }

    formateaDireccion(_address) {
        let _cra, _calle, _separa;


        _calle = /cl./ig;
        _address = _address.replaceAll(_calle, "calle");
        _calle = /cl/ig;
        _address = _address.replaceAll(_calle, "calle");

        _calle = /cll./ig;
        _address = _address.replaceAll(_calle, "calle");
        _calle = /cll/ig;
        _address = _address.replaceAll(_calle, "calle");


        _cra = /crra./ig;
        _address = _address.replaceAll(_cra, "carrera");
        _cra = /crr./ig;
        _address = _address.replaceAll(_cra, "carrera");
        _cra = /cra./ig;
        _address = _address.replaceAll(_cra, "carrera");
        _cra = /cr./ig;
        _address = _address.replaceAll(_cra, "carrera");


        _separa = /Nº:/ig;
        _address = _address.replaceAll(_separa, "#");
        _separa = /Nº/ig;
        _address = _address.replaceAll(_separa, "#");

        _separa = /No:/ig;
        _address = _address.replaceAll(_separa, "#");
        _separa = /No /ig;
        _address = _address.replaceAll(_separa, "#");


        return _address;
    }

    zonificaDireccion(_address) {
        let _to_return = "¡?";
        let _re, _dir;
        let _direccion = _address.split("#");


        if (_direccion[0].includes("calle")) {
            _re = /[a-z]/ig;
            _dir = _direccion[0].replaceAll(_re, "");

            if (_address.includes("sur")) {
                _to_return = "Sur";
            } else {
                _to_return = parseInt(_dir) > 13 ? "Nor" : "Sur";
            }
        }


        if (_direccion[0].includes("carrera")) {
            _re = /[a-z]/ig;
            _dir = _direccion[0].replaceAll(_re, "");

            if (_address.includes("sur")) {
                _to_return = "Sur";
            } else {
                _to_return = parseInt(_direccion[1]) > 13 ? "Nor" : "Sur";
            }

            _to_return += parseInt(_dir) > 68 ? "Occidente" : "Oriente";
        } else {
            _to_return += parseInt(_direccion[1]) > 68 ? "Occidente" : "Oriente";
        }


        return _to_return;
    }

    /**
     * Obtiene la zona limpiando la dirección
     * 
     * @param {String} city ciudad de la dirección
     * @param {String} address dirección a limpiar
     * 
     * @returns {JSON} {`zone`, `address`} objeto con la zona y la dirección
     */
    formatZone(city, address) {
        let _zone = "";
        let _direccion = this.formateaDireccion(address.toLowerCase());

        switch (city.toLowerCase()) {

            case "zipaquira":
            case "san jose":
            case "la neuta":
            case "el canelon":
            case "el tejar":
            case "briceño":
            case "tocancipa":
            case "guasca":
            case "cajica":
            case "chia":
            case "macadamia":
            case "la calera":
            case "patios":
            case "san luis":
                _zone = "NorOriente";
                break;
            case "cota":
            case "el corzo":
            case "la isla":
            case "buena vista":
            case "guadalajara":
            case "villa mery":
                _zone = "NorOccidente";
                break;
            case "batallon 13":
            case "chipaque":
            case "suate":
            case "amorosa":
            case "boqueron":
            case "olarte":
            case "usme pueblo":
                _zone = "SurOriente";
                break;
            case "facatativa":
            case "zipacon":
            case "bojaca":
            case "cartagenita":
            case "madrid":
            case "mosquera":
            case "funza":
                _zone = "Oriente";
                break;
            case "soacha":
            case "fusagasuga":
            case "san antonio":
            case "mesitas":
            case "el charquito":
            case "silvania":
            case "el tambo":
            case "subia":
            case "san raimundo":
            case "granada":
            case "sibate":
            case "la arboleda":
                _zone = "SurOccidente";
                break;
            case "bogotá":
            case "bogota":
                _zone = this.zonificaDireccion(_direccion);
                break;
        }

        return { address: _direccion, zone: _zone };
    }

    formatCeros(item) {
        let { code, aux } = item;
        let _codigo = code.toString();
        let _ceros = "";

        if (aux != null) {
            _ceros = aux;
        }

        for (let i = 0; i < (8 - _codigo.length); i++) {
            _ceros += "0";
        }


        return _ceros + _codigo;
    }

    setMeasure(_data) {
        localStorage.setItem('measure', JSON.stringify(_data));
        return _data;
    }

    /**
     * otros
     */
    cleanAccents(str) {
        let toReturn = str.replace(/Á/g, "A")
            .replace(/É/g, "E")
            .replace(/Í/g, "I")
            .replace(/Ó/g, "O")
            .replace(/Ú/g, "U")
            .replace(/á/g, "a")
            .replace(/é/g, "e")
            .replace(/í/g, "i")
            .replace(/ó/g, "o")
            .replace(/ú/g, "u");

        return toReturn.toLowerCase();
    }

    formateaPrecio(amount, decimals = 0) {
        amount += ''; // por si pasan un numero en vez de un string
        amount = parseFloat(amount.replace(/[^0-9\.]/g, '')); // elimino cualquier cosa que no sea numero o punto


        // si no es un numero o es igual a cero retorno el mismo cero
        if (isNaN(amount) || amount === 0)
            return parseFloat(0).toFixed(decimals);

        // si es mayor o menor que cero retorno el valor formateado como numero
        amount = '' + amount.toFixed(decimals);

        var amount_parts = amount.split('.'),
            regexp = /(\d+)(\d{3})/;

        while (regexp.test(amount_parts[0]))
            amount_parts[0] = amount_parts[0].replace(regexp, '$1.$2');

        return amount_parts.join('.');
    }

    /**
     * 
     * @param num Number to format 
     * @returns {JSON} obj {`int`, `format`}
     */
    formatPrice(num) {
        // num = num || 0;

        if (isNaN(num)) {
            return {
                int: num,
                format: num
            };
        } else {
            let _clean = num.toString().replaceAll(".", "");
            let _minus = _clean.substring(0, 1);
            _clean = _minus == "-" ? _clean.substring(1) : _clean;
            _minus = _minus == "-" ? _minus : "";
            let _cant = parseInt(_clean.length / 3);
            let _to_return = "";
            let _rest = _clean;
            let _parts = [];

            for (let i = 0; i < _cant; i++) {
                _parts.push(_rest.slice(-3));
                _rest = _rest.slice(0, -3);
            }

            for (let e = _parts.length - 1; e >= 0; e--) {
                _to_return += "." + _parts[e];
            }

            _to_return = _rest + _to_return;
            _to_return = _to_return.slice(0, 1) == "." ? _to_return.slice(1, _to_return.length) : _to_return;

            return {
                int: _clean,
                format: _minus + _to_return
            };
        }
    }

    getTiempo(unixCreado, unixActual) {
        let timeActual = new Date();
        unixActual = unixActual || Math.round((timeActual.getTime() + ((timeActual.getTimezoneOffset() / 60) * 3600)) / 1000);
        let tiempo = Math.round((unixActual - unixCreado) / 60);
        let toReturn;

        if (tiempo < 2) {
            toReturn = `Hace instantes`;
        } else if (tiempo >= 2 && tiempo < 60) {
            toReturn = `Hace ${Math.round(tiempo)} minutos`;
        } else if (tiempo >= 60 && tiempo < 120) {
            toReturn = `Hace 1 hora`;
        } else if (tiempo >= 120 && tiempo < 1440) {
            toReturn = `Hace ${Math.round(tiempo / 60)} horas`;
        } else if (tiempo >= 1440 && tiempo < 2880) {
            toReturn = `Hace 1 día`;
        } else if (tiempo >= 2880 && tiempo < 10080) {
            toReturn = `Hace ${Math.round(tiempo / 1440)} días`;
        } else if (tiempo >= 10080 && tiempo < 20160) {
            toReturn = `Hace 1 semana`;
        } else if (tiempo >= 20160 && tiempo < 43200) {
            toReturn = `Hace ${Math.round(tiempo / 10080)} semanas`;
        } else if (tiempo >= 43200 && tiempo < 86400) {
            toReturn = `Hace 1 mes`;
        } else if (tiempo >= 86400 && tiempo < 525960) {
            toReturn = `Hace ${Math.round(tiempo / 43200)} meses`;
        } else if (tiempo >= 525960 && tiempo < 1051920) {
            toReturn = `Hace 1 año`;
        } else if (tiempo >= 1051920) {
            toReturn = `Mas de dos años`;
        }

        return toReturn;
    }

    getTimeAgo(time_msg) {
        let ago_time = "hace instantes";
        let time_current = new Date().getTime() / 1000;
        let tiempoPasado = (time_current - time_msg);

        const round = (num) => {
            return Math.round(num);
        }
        const cleanDot = (num) => {
            return num.toFixed(2).split('.')[0];
        }

        let seconds = 60;
        let mins = seconds * 60;

        if (time_msg !== null) {
            if (tiempoPasado >= seconds && tiempoPasado < mins) {
                ago_time = round(tiempoPasado / seconds) > 1 ? "hace " + round(tiempoPasado / seconds) + " minutos" : "hace " + round(tiempoPasado / seconds) + " minuto";
            } else if (tiempoPasado >= 3600 && tiempoPasado < 86400) {
                ago_time = round(tiempoPasado / 3600) > 1 ? "hace " + cleanDot(tiempoPasado / 3600) + " horas" : "hace " + cleanDot(tiempoPasado / 3600) + " hora";
            } else if (tiempoPasado >= 86400 && tiempoPasado < 604800) {
                ago_time = round(tiempoPasado / 86400) > 1 ? "hace " + round(tiempoPasado / 86400) + " días" : "hace " + round(tiempoPasado / 86400) + " día";
            } else if (tiempoPasado >= 604800 && tiempoPasado < 31536000) {
                ago_time = round(tiempoPasado / 604800) > 1 ? "hace " + round(tiempoPasado / 604800) + " semanas" : "hace " + round(tiempoPasado / 604800) + " semana";
            } else if (tiempoPasado > 31536000) {
                ago_time = "mas de un año";
            }
        } else {
            ago_time = null;
        }

        return ago_time;
    }

    getDayMonth(time_msg) {
        let month = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        let day_month = null;

        if (time_msg !== null) {
            let fecha_msg = new Date(time_msg * 1000);
            day_month = <div key={time_msg} className="_date"><h5>{fecha_msg.getDate()} {month[fecha_msg.getMonth()]}</h5></div>;
        }

        return day_month;
    }

    getCompareDates(lista, i) {
        if (i == 0) {
            return lista[i].date;
        } else {
            let fecha_1 = new Date(lista[i].date * 1000);
            fecha_1 = fecha_1.getDate() + " " + fecha_1.getMonth();

            let fecha_2 = new Date(lista[i - 1].date * 1000);
            fecha_2 = fecha_2.getDate() + " " + fecha_2.getMonth();

            if (fecha_1 !== fecha_2) {
                return lista[i].date;
            } else {
                return null;
            }
        }
    }
}

export default new Funciones();