import * as React from "react";
import DeleteIcon from "mdi-react/DeleteOutlineIcon";
import ChatIcon from "mdi-react/ChatOutlineIcon";
import EditIcon from "mdi-react/EditOutlineIcon";
import HistoryIcon from "mdi-react/HistoryIcon";
import OpenIcon from "mdi-react/OpenInNewIcon";
import { Alert, Confirm } from "shared/Alert";
import DeliveryView from "./DeliveryView";
import * as Iconos from "../../../images";
import CourierList from "./CourierList";
import Loading from "shared/Loading";
import History from "shared/History";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuRow = (params) => {
    const { history, data, blue, children, onSel, reload, current } = params;
    const [clases, setClases] = React.useState(blue == 0 ? "table_row bkg_blue" : "table_row");
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const media_movil = window.matchMedia('(max-width: 500px)').matches;
    const [position, setPos] = React.useState({ top: "0", left: "0" });
    const [showLoading, setShowLoading] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);
    const [showMenu, setShowMenu] = React.useState(false);
    const menu = React.useRef(null);


    const goChat = (e) => {
        e.preventDefault();
        e.stopPropagation();

        history.push({
            pathname: "/chat",
            state: {
                data: {
                    guest: {
                        id_user: data_user.nivel == 6 ? data.carrier : data.id_client,
                        nombre: data_user.nivel == 6 ? data.carrier_name : data.nombre_cliente,
                        apellido: data_user.nivel == 6 ? "" : data.apellido_cliente
                    },
                    fromOutside: true
                }
            }
        });
    }

    const goEdit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        history.push({
            pathname: "/editar-paquete",
            state: { data: { ...data } }
        });
    }

    const goOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();

        history.push({
            pathname: "/detalle-paquete",
            state: { data: data }
        });
    }

    const goHistory = (e, _p) => {
        e.preventDefault();
        e.stopPropagation();
        let _width = document.getElementById("root").offsetWidth;
        let _posx = (parseInt(_p.left) + 300) > _width ? _width - 314 : parseInt(_p.left);
        let _pos = {
            top: _p.top,
            left: _posx + "px",
            maxWidth: media_movil ? 300 : 550
        };

        handleModal("history", _pos);
    }

    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm("Seguro de borrar este paquete?", async (response) => {
            if (response) {
                setShowLoading(true);
                let _data = await api.deletePackage({ id_pack: data.id });

                if (_data.response == 1) {
                    reload();
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => history.push("/"));
                } else {
                    Alert(_data.msg, "warning");
                }

                setShowLoading(false);
            }
        });

    }

    const onSelect = (e) => {
        e.preventDefault();
        e.stopPropagation();

        onSel(data);
    }

    const stopClick = (e) => {
        // e.preventDefault(); Se comenta esta linea para que funcione el input[type="file"]
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let _height = window.innerHeight;
        let _width = document.getElementById("root").offsetWidth;
        let _posx = (e.pageX + 220) > _width ? _width - 220 : e.pageX;
        let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;
        let _to_y = _to_border_bottom < 200 ? e.pageY - 200 : e.pageY;


        setPos({ top: _to_y + "px", left: _posx + "px" });
        setShowMenu(true);
    }

    const handleModal = (_option, _pos) => {
        let _window;

        switch (_option) {
            case "history":
                _window = <History
                    data={{ ...data, tipo: 2 }}
                    pos={_pos}
                    close={setShowModal}
                    title="Historial del paquete" />;
                break;
            case "assign_pickup":
                _window = <CourierList
                    data={data}
                    pos={_pos}
                    close={setShowModal}
                    reload={reload} />;
                break;
            case "delivery":
                _window = <DeliveryView
                    data={data}
                    pos={{ ..._pos, top: _pos.top < 70 ? 80 : _pos.top, maxWidth: 230 }}
                    close={setShowModal}
                    reload={reload} />;
                break;
            default:
                _window = null;
                break;
        }

        setShowMenu(false);
        setModalView(_window);
        setShowModal(true);
    }

    const BtnEdit = () => {

        switch (parseInt(data.estado)) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 7:
            case 8:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
                if (data_user.nivel == 1 ||
                    data_user.nivel == 2 ||
                    data_user.nivel == 3 ||
                    data_user.nivel == 5) {
                    return <li onClick={goEdit}><EditIcon /> <span>Editar</span></li>;
                } else {
                    return <></>;
                }
            case 6:
            case 9:
                if (data_user.nivel <= 2) {
                    if (data.caja_m_a == 0) {
                        return <li onClick={goEdit}><EditIcon /> <span>Editar</span></li>;
                    } else {
                        return <li><EditIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Editar</span></li>;
                    }
                } else {
                    return <></>;
                }
            case 15:
            case 16:
            case 17:
                if (data_user.nivel == 1 ||
                    data_user.nivel == 2 ||
                    data_user.nivel == 3 ||
                    data_user.nivel == 5) {
                    return <li><EditIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Editar</span></li>;
                } else {
                    return <></>;
                }
            default:
                return <></>;
        }
    }

    const BtnChat = () => {
        let _btn = <li><ChatIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Abrir chat</span></li>;

        if (data_user.nivel < 4) {
            _btn = <li onClick={goChat}><ChatIcon /> <span>Abrir chat</span></li>;
        }

        if (data_user.nivel == 4 && data.carrier == window.atob(data_user.auth)) {
            _btn = <li onClick={goChat}><ChatIcon /> <span>Abrir chat</span></li>;
        }

        if (data_user.nivel == 6 && data.carrier != null) {
            _btn = <li onClick={goChat}><ChatIcon /> <span>Abrir chat</span></li>;
        }

        return (_btn);
    }

    const BtnAssign = () => {
        let _btn = <li><Iconos.BoxUser color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Cambiar mensajero</span></li>;

        switch (parseInt(data.estado)) {
            case 4:
            case 5:
            case 7:
            case 8:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
                if (data.carrier == null) {
                    _btn = <li onClick={() => handleModal("assign_pickup", position)}><Iconos.BoxUser /> <span>Asignar entrega</span></li>;
                } else {
                    _btn = <li onClick={() => handleModal("assign_pickup", position)}><Iconos.BoxUser /> <span>Cambiar mensajero</span></li>;
                }
                break;
        }

        return (_btn);
    }

    const BtnBodega = () => {
        let _btn = <></>;

        const handleSalida = async (e) => {
            e.preventDefault();
            setShowLoading(true);
            let _data = await api.changeState({
                type: 2,
                id: data.id,
                state: 4,
                nota: "Se le da salida nuevamente "
            });

            if (_data.response == 1) {
                Alert("El paquete fue marcado para salir", "ok", () => reload());
            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => history.push("/"));
            } else {
                Alert(_data.msg, "warning");
            }

            setShowLoading(false);
        }

        const handleDevuelve = async (e) => {
            e.preventDefault();
            setShowLoading(true);
            let _data = await api.changeState({
                type: 2,
                id: data.id,
                state: 15,
                nota: "Se marca para devolver a cliente"
            });

            if (_data.response == 1) {
                Alert("El paquete fue marcado para devolver", "ok", () => reload());
            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => history.push("/"));
            } else {
                Alert(_data.msg, "warning");
            }

            setShowLoading(false);
        }

        switch (parseInt(data.estado)) {
            case 14:
                _btn = <>
                    <li onClick={handleSalida}><Iconos.BoxRefresh /> <span>Vuelve a salir</span></li>
                    <li onClick={handleDevuelve}><Iconos.BoxRemove /> <span>Devolver a cliente</span></li>
                </>;
                break;
            case 15:
                _btn = <li onClick={handleSalida}><Iconos.BoxRefresh /> <span>Vuelve a salir</span></li>;
                break;
        }

        return (_btn);
    }

    const BtnDelivery = () => {
        let _txt = window.atob(data_user.auth) == data.carrier ? "Cambia estado" : "Tomar paquete";
        let _btn = <li><Iconos.PackOk color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>{_txt}</span></li>;

        switch (parseInt(data.estado)) {
            case 4:
            case 5:
            case 7:
            case 8:
            case 10:
            case 11:
            case 12:
            case 13:
                const handleClick = (e) => {
                    let _height = window.innerHeight;
                    let _width = document.getElementById("root").offsetWidth;
                    let _posx = (parseInt(position.left) + 250) > _width ? _width - 260 : parseInt(position.left);
                    let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;
                    let _to_y = _to_border_bottom < 300 ? parseInt(position.top) - 300 : parseInt(position.top);
                    let _pos = {
                        top: _to_y,
                        left: _posx + "px",
                        maxWidth: 230
                    };

                    handleModal("delivery", _pos);
                }

                const handleAsigname = async (e) => {
                    e.preventDefault();
                    setShowLoading(true);
                    let _data = await api.saveAssignPackage({
                        id_carrier: window.atob(data_user.auth),
                        id_pack: data.id
                    });

                    if (_data.response == 1) {
                        Alert("El paquete ha sido asignado a tu lista de entregas", "ok", () => reload());
                    } else if (_data.response === -2) {
                        Alert(_data.msg, "warning", () => history.push("/"));
                    } else {
                        Alert(_data.msg, "warning");
                    }

                    setShowLoading(false);
                }

                if (window.atob(data_user.auth) == data.carrier) {
                    _btn = <li onClick={handleClick}><Iconos.PackOk /> <span>Cambia estado</span></li>;
                } else {
                    _btn = <li onClick={handleAsigname}><Iconos.PackOk /> <span>Tomar paquete</span></li>;
                }
                break;
        }

        return (_btn);
    }

    const BtnDelete = () => {
        let _btn = <li><DeleteIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Borrar</span></li>;

        switch (parseInt(data.estado)) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                if (data_user.nivel < 4) {
                    _btn = <li onClick={goDelete}><DeleteIcon /> <span>Borrar</span></li>;
                }

                if (data_user.nivel == 6 && data.id_client == window.atob(data_user.auth)) {
                    _btn = <li onClick={goDelete}><DeleteIcon /> <span>Borrar</span></li>;
                }
                break;
        }

        return (_btn);
    }

    React.useEffect(() => {
        // document.addEventListener("click", hiddenMenu);

        // return () => {
        //     document.removeEventListener("click", hiddenMenu);
        // }
    }, []);

    React.useEffect(() => {
        current === data.id && setClases(c => c + " bkg_select");
    }, [current]);

    return (
        <tr className={clases}
            onDoubleClick={goOpen}
            onClick={onSelect}
            onContextMenu={handleContextMenu}>

            {children}
            <td className="rowMenu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                {showMenu &&
                    <>
                        <div className="back_form" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu" style={position} ref={menu}>
                            <ul>
                                <li onClick={goOpen}><OpenIcon /> <span><strong>Detalle</strong></span></li>

                                <BtnEdit />
                                <BtnChat />

                                <hr />
                                {data_user.nivel < 4 && <BtnBodega />}
                                {data_user.nivel < 4 && <BtnAssign />}
                                {data_user.nivel == 4 && <BtnDelivery />}
                                {data_user.nivel < 5 && <hr />}

                                <BtnDelete />

                                {data_user.nivel < 4 && <li onClick={(e) => goHistory(e, position)}><HistoryIcon /> <span>Historial</span></li>}

                            </ul>
                            {showLoading && <Loading />}
                        </nav>
                    </>}

                {showModal && modalView}

            </td>
        </tr>
    );

}

export default MenuRow;