import * as React from "react";
import CloseOutlineIcon from "mdi-react/CloseCircleOutlineIcon";
import Information from "mdi-react/InformationOutlineIcon";
import { useHistory } from "react-router-dom";
import funciones from "services/funciones";
import * as Icon from "../../../images";
import { Alert } from "shared/Alert";
import { Input } from "shared/Input";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Entrada = ({ pos, close, reload }) => {
    const [showLoading, setShowLoading] = React.useState(false);
    const [monto, setMonto] = React.useState("");
    const [notes, setNotes] = React.useState("");
    const [error, setError] = React.useState("");
    const [userList, setUserList] = React.useState([]);
    const [userInfoList, setUserInfoList] = React.useState([]);
    const [clientId, setClientId] = React.useState("");
    const [client, setClient] = React.useState("");
    const history = useHistory();

    const getAllClients = async () => {
        setShowLoading(true);
        let _data = await api.getUsers("clients");
        let _users = [];


        if (_data.response == 1) {
            let _list = [];

            setUserInfoList(_data.data);

            _data.data.map(item => {
                _list.push(item.nombre + " " + item.apellido);
            });

            setUserList(_list);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
        return _users;
    }

    const changeClient = (val) => {
        setClient(val);
        setError("");

        let item = userInfoList.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];

        if (typeof item === "object") {
            setClientId(item.id_user);
        } else {
            setClientId("");
        }
    }

    const handleClose = () => {
        close(false);
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _error = false;

        setError("");

        if (clientId.length < 1) {
            setError("¡Debes ingresar un cliente!");
            return;
        }

        if (monto.length < 1) {
            setError("¡Debes ingresar un monto!");
            return;
        }

        if (isNaN(monto)) {
            setError("¡Debes ingresar solo numeros!");
            return;
        }

        if (parseInt(monto) < 1) {
            setError("¡El monto no puede ser cero o menor!");
            return;
        }

        if (notes.length < 1) {
            setError(`¡Debes ingresar una descripción!`);
            return;
        }


        if (_error) {
            console.log("Tiene errores");
        } else {
            guardaCaja();
        }
    }

    const guardaCaja = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "save-caja",
            data: {
                task: "abono",
                destinatario: clientId,
                caja: 2,
                metodo: 1,
                monto: funciones.formatPrice(monto).int,
                concepto: `Depositó para "${client}": \n${notes}`
            }
        });

        if (_data.response == 1) {
            reload();
            close(false);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        getAllClients();
    }, []);

    return (
        <>
            <div className="back_form"></div>
            <form className="win_modal _overflow _delivery" style={pos}>
                <h3 style={{ marginRight: -8, marginBottom: 12 }}><CloseOutlineIcon onClick={handleClose} />Nueva entrada</h3>

                <div className="_in_column">

                    <div>
                        <label className="__titulo">Cliente</label>
                        <Input
                            type="text"
                            list={userList}
                            name="client"
                            placeholder="Nombre del cliente"
                            value={client}
                            onChange={changeClient} />
                    </div>

                    <div>
                        <label className="__titulo">Monto</label>
                        <Input type="tel" name="monto" placeholder="ingresa un valor"
                            value={funciones.formatPrice(monto).format} onChange={val => { setError(""); setMonto(val); }} />
                    </div>

                    <div>
                        <p className="__titulo">Descripción</p>
                        <textarea name="comment" rows="3" placeholder="ingresa una descripción"
                            value={notes} onChange={e => { setError(""); setNotes(e.target.value); }} />
                    </div>

                </div>

                {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

                {showLoading ?
                    <button className="btn btn-common width-static"><Icon.Loading /></button>
                    :
                    <button className="btn btn-common width-static" onClick={validateFields}>Guardar</button>
                }
            </form>
        </>
    );
}

export default Entrada;