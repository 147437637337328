import * as React from "react";
import Information from 'mdi-react/InformationOutlineIcon';
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import { Alert } from "shared/Alert";
import { useHistory } from 'react-router-dom';
import { Input } from "shared/Input";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const CourierList = (params) => {
    const { data, pos, close, reload } = params;
    const [userList, setUserList] = React.useState([]);
    const [courierId, setCourierId] = React.useState("");
    const [courier, setCourier] = React.useState("");
    const [error, setError] = React.useState("");
    const history = useHistory();


    const getAllCourier = async () => {
        let _data = await api.getUsers("carrier");
        let _users = [];

        if (_data.response == 1) {

            setUserList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        return _users;
    }

    const changeCourier = (val) => {
        setCourier(val);
        setError("");

        let item = userList.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];

        if (typeof item === "object") {
            setCourierId(item.id_user);
        } else {
            setCourierId("");
        }
    }

    const handleClose = () => {
        close(false);
    }

    const validateFields = (e) => {
        e.preventDefault();

        setError("");


        if (courierId.length < 1) {
            setError("Debes agregar un mensajero!");
            return;
        }

        saveCourier();
    }

    const saveCourier = async () => {
        let _data = await api.saveAssignOrder({
            id_carrier: courierId,
            id_order: data.id
        });

        if (_data.response == 1) {

            Alert(_data.msg, "warning", () => reload());
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    React.useEffect(() => {
        getAllCourier();
    }, []);

    return (
        <>
            <div className="back_form"></div>
            <div className="win_modal _small" style={pos}>
                <h4><CloseIcon onClick={handleClose} />Lista de mensajeros</h4>

                <div className="_in_column" style={{ padding: "0 12px" }}>
                    <p className="_info">¡Se mostrarán sugerencias de los mensajeros a medida que escribas el nombre!</p>
                    <Input
                        type="text"
                        list={userList.map(({ nombre, apellido }) => (nombre + " " + apellido))}
                        name="courier"
                        placeholder="Nombre del mensajero"
                        value={courier}
                        onChange={changeCourier} />
                </div>

                {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}


                <button className="btn btn-common width-static" onClick={validateFields}>Asignar</button>
            </div>
        </>
    );
}

export default CourierList;