import * as React from "react";
import { useBarcode } from 'react-barcodes';

/* eslint react-hooks/exhaustive-deps: 0 */

export const GroupCode = (params) => {
    const { children, code, logo = "matrix(0.0, -0.010, -0.010, 0.0, 44, 116)", bars = "matrix(0, -0.36, 0.36, 0, 0, 100)" } = params;
    const outputRef = React.useRef();
    const { inputRef } = useBarcode({
        value: code,
        options: {
            height: 40,
            format: 'CODE128',
            fontSize: 26,
            fontOptions: 'bold',
            //lineColor: '#646777',
            background: '#ffffff00',
        }
    });

    React.useEffect(() => {
        let _code_b = inputRef.current.lastChild;
        _code_b.attributes.transform.value = bars;

        inputRef.current.remove();
        outputRef.current.appendChild(_code_b);
    }, [inputRef]);

    return (
        <>
            <g ref={outputRef}>
                <g fill="rgb(0,0,0)" stroke="none" transform={logo}>
                    <path d="M 840,415 V 280 h 49 c 111,0 163,57 111,123 -16,20 -18,31 -10,51 22,58 -20,96 -106,96 h -44 z m 98,59 c 3,-22 -21,-39 -39,-28 -11,6 -12,38 -2,48 13,13 38,1 41,-20 z m 18,-99 c 9,-21 -14,-45 -42,-45 -20,0 -24,5 -24,30 0,27 3,30 30,30 18,0 33,-6 36,-15 z" />
                    <path d="m 1087,543 c -4,-3 -7,-14 -7,-24 0,-13 9,-19 33,-21 l 32,-3 3,-108 3,-108 27,3 27,3 3,108 3,107 h 29 c 25,0 30,4 30,25 0,25 -1,25 -88,25 -49,0 -92,-3 -95,-7 z" />
                    <path d="m 1365,526 c -42,-46 -24,-97 46,-131 55,-26 41,-72 -18,-58 -12,3 -28,10 -34,15 -11,8 -21,0 -38,-30 -5,-8 4,-17 28,-27 70,-29 136,-13 157,38 17,41 -3,75 -64,106 -61,31 -60,56 1,56 30,0 46,5 53,17 24,42 -92,54 -131,14 z" />
                    <path d="m 1713,544 c -5,-4 -8,-16 -8,-27 0,-20 5,-20 182,-14 99,4 207,7 240,7 45,0 58,3 58,15 0,13 -27,15 -182,15 -101,0 -205,3 -233,6 -27,3 -53,3 -57,-2 z" />
                    <path d="m 150,530 -155,-5 115,-7 c 63,-4 212,-9 330,-10 198,-3 215,-2 218,15 3,16 -10,17 -175,15 -98,-1 -248,-5 -333,-8 z" />
                    <path d="m 122,383 c -162,-5 -161,-23 1,-23 68,0 226,-3 350,-7 l 227,-6 v 21 c 0,22 0,22 -227,20 -126,-1 -283,-4 -351,-5 z" />
                    <path d="m 1625,370 v -23 l 278,7 c 152,4 279,10 281,12 15,14 -40,19 -284,22 l -275,4 z" />
                    <path d="M 122,233 C 55,231 0,226 0,221 c 0,-13 119,-18 443,-20 282,-2 287,-1 287,19 0,20 -5,20 -242,18 -134,-1 -298,-4 -366,-5 z" />
                    <path d="m 1605,219 v -22 l 287,7 c 265,7 304,9 292,20 -2,2 -133,7 -291,10 l -288,7 z" />
                </g>
                {children}
            </g>
            <svg ref={inputRef} />
        </>
    );
}

const BarCode = ({ code }) => {
    const { inputRef } = useBarcode({
        value: code,
        options: {
            height: 40,
            format: 'CODE128',
            fontSize: 26,
            fontOptions: 'bold',
            //lineColor: '#646777',
            background: '#ffffff00',
        }
    });

    return (
        <div className="_bar_code">
            <svg ref={inputRef} />
        </div>
    );
}

export default BarCode;