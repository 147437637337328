import * as React from "react";
import { Alert, alerta, alertaRemove, alertDown, Confirm } from "shared/Alert";
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import EditContainer from "./EditContainer";
import funciones from "services/funciones";
import DatePicker from "react-datepicker";
import NewContainer from "./NewContainer";
import useTitle from "hooks/useTitle";
import Loading from "shared/Loading";
import { Field } from "shared/Input";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const EditReception = ({ history, location }) => {
    const [data, setData] = React.useState([]);
    const [userList, setUserList] = React.useState([{ id_user: 0, nombre: "Cargando...", apellido: "" }]);
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [fechaIngreso, setFechaIngreso] = React.useState(new Date());
    const [addressConsignee, setAddressConsignee] = React.useState("");
    const [collectPrice, setCollectPrice] = React.useState("");
    const [showLoading, setShowLoading] = React.useState(true);
    const [allPaquages, setAllPaquages] = React.useState([]);
    const [consigneeId, setConsigneeId] = React.useState("");
    const [consignee, setConsignee] = React.useState("");
    const [valDomc, setValDomc] = React.useState("8000");
    const [collect, setCollect] = React.useState(false);
    const [packages, setPackages] = React.useState({});
    const [origin] = React.useState(1);
    const [notes, setNotes] = React.useState("");
    const { setTitulo } = useTitle();
    const ref_form = React.useRef();


    const getPackagesList = async (_id) => {
        let _data = await api.getPackages(_id);
        let _lista = await getAllList();

        if (_data.response == 1) {
            configPackages(_data.data, _lista);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getAllList = async () => {
        let _data = await api.getUsers("duo");
        let _users = [];


        if (_data.response == 1) {

            _data.data.map(item => {
                item.nivel > "2" && _users.push(item);
            });

            setUserList(_users);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        return _users;
    }

    const configPackages = (_data, _lista) => {
        let _pack = {};
        let _allPack = [];
        let _index;

        _data.map((item, index) => {
            _index = index + 1;
            item.index = _index;
            item.estado_real = item.estado;
            item.addressee = item.addressee ?? "";
            item.recibiendo = data.comentario !== null;
            item.estado = data.comentario == null ? item.estado : 4;
            item.valorp = item.price;
            item.valord = item.v_domicilio;
            item.valorm = item.v_mensajero;

            _pack = { ..._pack, [item.index]: item };
            _allPack.push(<ContainerEdit key={item.index} pack={item} lista={_lista} />);
        });

        setPackages(_pack);
        setAllPaquages(_allPack);
    }

    const updatePackage = (pack, index) => {
        setPackages(p => ({ ...p, [index]: pack }))
    }

    const format = (_num) => {
        return _num < 10 ? "0" + _num : _num;
    }

    const saveOrder = async () => {
        setShowLoading(true);

        let _info = {
            order: data.id,
            estado: data.comentario == null ? data.estado : 4,
            date_ingress: fechaIngreso.getFullYear() + "-" + format(fechaIngreso.getMonth() + 1) + "-" + format(fechaIngreso.getDate()),
            origin: origin,
            consignee: consigneeId,
            comentario: data.comentario ?? "Se modifica orden de recepción",
            notes: notes,
            packages: packages
        };

        let _data = await api.editReceptionOrder(_info);

        if (_data.response == 1) {
            Alert(data.comentario == null ? _data.msg : "Recibido correctamente", "ok", () => history.goBack());
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _is_empty = false;
        let _message = "";

        alertaRemove(ref_form.current.origen);
        alertaRemove(ref_form.current.consignee);
        alertaRemove(ref_form.current.other);

        if (origin == 0) {
            alerta(ref_form.current.origen, "Debes ingresar el origen del paquete!");
            ref_form.current.origen.focus();
            window.scrollTo({
                top: 60,
                behavior: 'smooth'
            });
            return;
        }

        if (consignee.length < 1) {
            alerta(ref_form.current.consignee, "Debes ingresar un Cliente bts!");
            ref_form.current.consignee.focus();
            window.scrollTo({
                top: 190,
                behavior: 'smooth'
            });
            return;
        }

        if (consigneeId.length < 1) {
            alerta(ref_form.current.consignee, "Este usuario no se encuentra registrado! \nAgregalo desde el icono ( + )");
            ref_form.current.consignee.focus();
            window.scrollTo({
                top: 250,
                behavior: 'smooth'
            });
            return;
        }

        if (typeof packages !== "object" || Object.entries(packages).length < 1) {
            alertDown(ref_form.current.other, "Debes agregar almenos un paquete!");
            return;
        }

        for (let i in packages) {

            if (packages[i]["codigo"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar un "código" para cada paquete`;
                break
            }

            if (packages[i]["pieces"] < 1) {
                _is_empty = true;
                _message = `La cantidad de "piezas" no puede ser menor a uno!`;
                break
            }

            if (packages[i]["valorp"].length < 1 || parseInt(packages[i]["valorp"]) < 0) {
                _is_empty = true;
                _message = `Debes ingresar el "Valor del producto" para cada paquete`;
                break
            }

            if (packages[i]["valord"].length < 1 || parseInt(packages[i]["valord"]) < 1) {
                _is_empty = true;
                _message = `Debes ingresar el "Valor del domicilio" para cada paquete`;
                break
            }

            if (packages[i]["valorm"].length < 1 || parseInt(packages[i]["valorm"]) < 1) {
                _is_empty = true;
                _message = `Debes ingresar el "Valor del mensajero" para cada paquete`;
                break
            }

            if (packages[i]["attachments"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar almenos una imagen para cada paquete`;
                break
            }
        }

        if (_is_empty) {
            Alert(_message);
            return;
        }

        saveOrder();
    }

    const addPackage = (e) => {
        e.preventDefault();

        if (consigneeId !== "") {

            let _index = allPaquages.length + 1;
            let _pack = {
                "index": _index,
                "id": 0,
                "type": "N/A",
                "pieces": 1,
                "codigo": "",
                "valorp": "0",
                "valord": valDomc,
                "valorm": "5000",
                "descripcion": "",
                "attachments": "",
                "estado": 4,
            };

            setPackages({ ...packages, [_index]: _pack });

            alertaRemove(ref_form.current.other);

            setAllPaquages([...allPaquages, <Container key={_index} pack={_pack} />]);
        }
    }

    const removePackage = (_index, _id) => {
        let _pack_list = [];
        let _action = () => {
            setPackages(p => {
                let _new_list;

                for (let i in p) {
                    if (p[i].index !== _index) {
                        _new_list = { ..._new_list, [i]: p[i] };
                        _pack_list.push(<ContainerEdit key={p[i].index} pack={p[i]} />);
                    }
                }

                return _new_list;
            });

            setAllPaquages(_pack_list);
        }

        if (_id != 0) {
            Confirm("Seguro de borrar este paquete", (response) => {
                if (response) {
                    _action();
                }
            });
        } else {
            _action();
        }
    }

    const Container = ({ pack }) => {

        return (
            <NewContainer
                item={pack}
                list={userList}
                remove={removePackage}
                update={item => setPackages(p => ({ ...p, [pack.index]: item }))} />
        );
    }

    const ContainerEdit = ({ pack, lista }) => {

        return (
            <EditContainer
                item={pack}
                list={lista}
                remove={removePackage}
                update={(p, i) => updatePackage(p, i)} />
        );
    }

    const addConsignee = async (val) => {
        setConsignee(val);
        alertaRemove(ref_form.current.consignee);

        let lista = await getAllList();

        let item = lista.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];

        if (typeof item === "object") {
            setAddressConsignee(item.address);
            setConsigneeId(item.id_user);
            setValDomc(item.domicilio);
        } else {
            setAddressConsignee("");
            setConsigneeId("");
            setValDomc("8000");
        }
    }

    const changeConsignee = (val) => {
        setConsignee(val);
        alertaRemove(ref_form.current.consignee);

        let item = userList.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];

        if (typeof item === "object") {
            setAddressConsignee(item.address);
            setConsigneeId(item.id_user);
            setValDomc(item.domicilio);
        } else {
            setAddressConsignee("");
            setConsigneeId("");
            setValDomc("8000");
        }
    }

    React.useEffect(() => {
        if (data.length > 0) {
            getPackagesList(data.id);

            // setOrigin(data.origin);
            setValDomc(data.consignee_domicilio == null ? "8000" : data.consignee_domicilio);
            setConsignee(data.consignee_name == null ? "" : data.consignee_name);
            setConsigneeId(data.consignee == null ? "" : data.consignee);
            setAddressConsignee(data.consignee_address == null ? "" : data.consignee_address);

            setNotes(data.notes == null ? "" : data.notes);

            setFechaIngreso(new Date(data.date_received + "T05:00:00"));

            if (data.collect > 1) {
                setCollect(true);
                setCollectPrice(funciones.formatPrice(data.collect).format);
            }
        }
    }, [data]);

    React.useEffect(() => {
        if (data_user.nivel == 1 ||
            data_user.nivel == 2 ||
            data_user.nivel == 3 ||
            data_user.nivel == 5) {
            if (location.state) {
                let _data = location.state.data;
                _data.length = 1;

                setTitulo(_data.comentario == null ? "Editar orden de recepción " + _data.consecutivo : "Recibir en almacén orden " + _data.consecutivo);

                setData(_data);
                setFechaIngreso(new Date(_data.date_received + "T01:00:00"));
            }
        } else {
            history.push("/home");
        }
    }, [location.state]);

    return (
        <form className="pickup" ref={ref_form}>
            <h3 className="_title">{data.comentario == null ? "Editar orden de recepción" : "Recibir orden en almacén"}</h3>

            <div className="pickup_container" style={{ marginBottom: 28 }}>
                <h5>Número: {data.consecutivo}</h5>

                {/* <fieldset>

                    <div className="pickup_column static">

                        <label>Origen del paquete</label>
                        <div className="input">

                            <select name="origen" value={origin} onChange={({ target: { value } }) => { setOrigin(value); alertaRemove(ref_form.current.origen); }} >
                                <option value="0">Ingresa origen...</option>
                                <option value="1">Cliente</option>
                                <option value="2">Mercadoflex</option>
                                <option value="3">Mensajero</option>
                            </select>
                        </div>

                    </div>

                </fieldset> */}

                <div className="pickup_row">

                    <div className="pickup_column static">

                        <div className="pickup_column m_less">
                            <label style={{ height: 24 }}>Fecha de Ingreso</label>
                            <div className="field_icon">
                                <DatePicker dateFormat="yyyy/MM/dd" name="pickup_date" selected={fechaIngreso} onChange={(date) => setFechaIngreso(date)} />
                                <CalendarMonthIcon />
                            </div>
                        </div>


                        <div className="pickup_column m_less">
                            <label style={{ marginTop: 12 }}>Nota</label>
                            <textarea name="notes" rows="2" placeholder="Ingresa una observación"
                                value={notes} onChange={({ target: { value } }) => setNotes(value)} />
                        </div>

                    </div>

                    <div className="pickup_column static">
                        <Field
                            rol="6"
                            name="consignee"
                            titulo="Cliente bts"
                            list={userList}
                            placeholder="Ingresa Cliente bts"
                            value={consignee}
                            onChange={changeConsignee}
                            onAdd={addConsignee}
                        />

                        <div className="pickup_column m_less">

                            <label style={{ marginTop: 12 }}>Dirección</label>
                            <textarea name="address_delivery_location" rows="2" placeholder="Selecciona un Cliente bts para agregar su dirección."
                                value={addressConsignee} onChange={({ target: { value } }) => setAddressConsignee(value)} readOnly={true} />
                        </div>
                    </div>

                </div>

                {collect && <h5 className="_collect">Esta orden tiene un cobro de recolección de ${collectPrice}</h5>}
                {allPaquages}
            </div>

            <div className="boton_agregar">
                <div className="for_alert">
                    <button className={consigneeId !== "" ? "btn btn-common" : "btn btn-common _deshabilitado"}
                        type="button" name="other"
                        onClick={addPackage}>
                        <PlusCircleOutlineIcon /> Agregar paquete
                    </button>
                </div>


                <button type="button" className="btn btn-common width-static" onClick={validateFields}>Guardar</button>
                <button type="button" className="btn btn-common width-static" onClick={() => history.goBack()}>Cancelar</button>

            </div>

            {showLoading && <Loading />}
        </form >
    );
}

export default EditReception;