import * as React from 'react';
import { useTranslation } from 'react-i18next';
import MailIcon from 'mdi-react/MailOutlineIcon';
import api from "services/api";
import { Alert, alerta, alertaRemove } from "shared/Alert";

/* eslint no-useless-escape: 0 */

const FortgotForm = ({ history }) => {
  const { t } = useTranslation('common');
  const [email, setEmail] = React.useState(null);
  const inputMail = React.useRef(null);
  


  const validateFormatEmail = () => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regex.test(String(email).toLowerCase())) {
      alerta(inputMail, t("msg.mail_format_error"));

      return false;
    } else {
      return true;
    }
  }

  const validaMail = () => {
    alertaRemove(inputMail);

    if (email == null || email.length < 1) {
      alerta(inputMail, t("msg.user_require"));

      return false;
    } else {
      return true;
    }
  }

  const validate = async () => {
    if (validaMail() && validateFormatEmail()) {
      let _data = await api.resetPassword(email);

      if (_data.response == "1") {
        Alert(_data.msg, "ok", () => history.goBack());
      } else {
        Alert(_data.msg, "warning");
      }
    }
  }

  return (
    <form className="form">

      <div className="form__form-group">
        <span className="form__form-group-label">{t('form_login.email')}</span>
        <div className="form__form-group-field input_radius">
          <div className="form__form-group-icon">
            <MailIcon />
          </div>
          <input type="email" name="email" ref={inputMail} placeholder={t('form_login.email')} className="radius_right"
            onChange={({ target: { value } }) => setEmail(value)} />
        </div>
      </div>


      <button type="button" className="btn btn-primary account__btn account__btn-login" onClick={validate}>{t('btn.send')}</button>
    </form>
  );
};

export default FortgotForm;
