import * as React from "react";
import ReportsExcelHistory from "./components/ReportsExcelHistory";
import ResumenCierreAdmin from "./components/ResumenCierreAdmin";
import { Container, Row } from "reactstrap";
import useTitle from "hooks/useTitle";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-extend-native: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

Date.prototype.getWeekNumber = function () {
  var d = new Date(+this);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));

  return Math.ceil((((d - new Date(d.getFullYear(), 0, 1)) / 8.64e7) + 1) / 7);
};

const format = (_num) => {
  return _num < 10 ? "0" + _num : _num;
}

const ReportHistory = (params) => {
  const { history } = params;
  const { setTitulo } = useTitle();
  const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
  const [resumenPago, setResumenPago] = React.useState([]);
  const [userToClose, setUserToClose] = React.useState();
  const fecha_creado = new Date(params.location.state.propietario.fecha);
  const fecha = `${format(fecha_creado.getHours())}:${format(fecha_creado.getMinutes())} el ${format(fecha_creado.getDate())} de ${meses[fecha_creado.getMonth()]} del ${fecha_creado.getFullYear()}`;


  const armaMensajero = (_datos) => {
    // 304 378 3429
    let _sales = [
      {
        id: 0,
        title: 'Totales',
        abonos: _datos.valor_abono || 0,
        entregas: _datos.valor_total || 0,
        recogidas: _datos.valor_recogidas || 0,
        v_domicilio: _datos.domicilios || 0,
        v_mensajero: _datos.domicilios || 0,
        v_mensajero_r: _datos.domicilios_recogidas || 0,
        subtolal: _datos.valor_subtotal || 0,
        efectivo: _datos.efectivo || 0,
        transferencia: _datos.transferencia || 0,
        domicilio_ko: _datos.domicilios_ko || 0,
      },
    ];

    setResumenPago(_sales);
  }

  const armaCliente = (_datos) => {
    // 304 378 3429
    let _sales = [
      {
        id: 0,
        title: 'Totales',
        abonos: _datos.valor_abono || 0,
        entregas: _datos.valor_total || 0,
        recogidas: _datos.valor_recogidas || 0,
        v_domicilio: _datos.domicilios || 0,
        v_domicilio_r: _datos.domicilios_recogidas || 0,
        v_mensajero: 0,
        subtolal: _datos.valor_subtotal || 0,
        efectivo: _datos.efectivo || 0,
        transferencia: _datos.transferencia || 0,
        domicilio_ko: _datos.domicilios_ko || 0,
      },
    ];

    setResumenPago(_sales);
  }

  const getSales = async () => {
    if (userToClose && userToClose.fecha.length > 1) {

      let _data = await api.fetchJson({
        url: "get-history-cortes",
        data: {
          task: "cortes",
          fecha: userToClose.fecha,
          id_user: userToClose.id_user
        }
      });

      if (_data.response == 1) {

        if (userToClose.nivel == 4) {
          armaMensajero(_data.data[0]);
        }

        if (userToClose.nivel == 6) {
          armaCliente(_data.data[0]);
        }

      } else if (_data.response === -2) {
        Alert(_data.msg, "warning", () => history.push("/"));
      } else {
        Alert(_data.msg, "warning");
      }
    }
  }

  React.useEffect(() => {
    if (data_user.nivel == 1 ||
      data_user.nivel == 2 ||
      data_user.nivel == 3 ||
      data_user.nivel == 4 ||
      data_user.nivel == 6) {
      setTitulo("Historico de corte");
    } else {
      history.push("/home");
    }
  }, []);

  React.useEffect(() => {
    if (userToClose) {
      getSales();
    }
  }, [userToClose]);

  React.useEffect(() => {
    if (params.location.state && params.location.state.propietario) {
      setUserToClose(params.location.state.propietario);
    }
  }, [params.location.state]);

  return (
    <Container className="dashboard">
      <Row>
        <h3 className="page-title">Historico de corte {data_user.nivel < 4 && <><br /><i>{params.location.state.propietario.nombre}</i></>} <span>{fecha}</span></h3>

        <ReportsExcelHistory userToClose={userToClose}>

          <ResumenCierreAdmin
            titulo="Total entregado"
            userToClose={userToClose}
            data={resumenPago}
            onRefresh={() => getSales()} />

        </ReportsExcelHistory>
      </Row>

    </Container>
  );
};

export default ReportHistory;
