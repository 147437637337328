import * as React from "react";
import MenuRightIcon from "mdi-react/MenuRightIcon";
import funciones from "services/funciones";
import * as Rdom from "react-router-dom";
import { Link } from "react-router-dom";
import useTitle from "hooks/useTitle";
import BarCode from "shared/BarCode";
import { Alert } from "shared/Alert";
import Loading from "shared/Loading";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

const ReceptionOrder = ({ history, location }) => {
    const params = Rdom.useParams();
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [fecha_recibido, setFechaRecibido] = React.useState(new Date());
    const media_movil = window.matchMedia('(max-width: 500px)').matches;
    const [mensaje, setMensaje] = React.useState("Cargando...");
    const [showLoading, setShowLoading] = React.useState(true);
    const [collectPrice, setCollectPrice] = React.useState(0);
    const [collect, setCollect] = React.useState(false);
    const [packages, setPackages] = React.useState([]);
    const [data, setData] = React.useState();
    const { setTitulo } = useTitle();


    const getPackagesList = async () => {
        let _list = [];
        let _data = await api.getPackages(data.id);

        if (_data.response == 1) {

            _data.data.map(item => {
                _list.push(
                    <tr key={item.id}>
                        <td>
                            <p>{item.pieces}</p>
                        </td>
                        <td>
                            <p><Link to={"/detalle-paquete/" + item.codigo}>{item.codigo}</Link></p>
                        </td>
                        {/* <td>
                            <p>{item.length} x {item.width} x {item.height} {data.l_unit}</p>
                        </td> */}
                        <td>
                            <p>{item.descripcion}</p>
                        </td>
                        <td>
                            {!media_movil ?
                                <p>{item.price > 0 ? "$" + funciones.formateaPrecio(item.price) : "..."}</p>
                                :
                                <>
                                    <p>
                                        Producto: <b>{item.price > 0 ? "$" + funciones.formateaPrecio(item.price) : "..."}</b>
                                        {(data_user.nivel == 6 || data_user.nivel < 4) && <><br />domicilio: <b>{item.v_domicilio > 0 ? "$" + funciones.formateaPrecio(item.v_domicilio) : "..."}</b></>}
                                        {data_user.nivel <= 4 && <><br />mensajero: <b>{item.v_mensajero > 0 ? "$" + funciones.formateaPrecio(item.v_mensajero) : "..."}</b></>}
                                    </p>
                                    <hr />
                                </>
                            }
                        </td>
                        {!media_movil && <>
                            {(data_user.nivel == 6 || data_user.nivel < 4) && <td>
                                <p>{item.v_domicilio > 0 ? "$" + funciones.formateaPrecio(item.v_domicilio) : "..."}</p>
                            </td>}
                            {data_user.nivel <= 4 && <td>
                                <p>{item.v_mensajero > 0 ? "$" + funciones.formateaPrecio(item.v_mensajero) : "..."}</p>
                            </td>}
                        </>}
                    </tr>
                );
            });

            if (_data.data.length < 6) {
                let _loop = (6 - _data.data.length);

                for (let i = 0; i < _loop; i++) {
                    _list.push(
                        <tr key={"le_" + i}>
                            <td>
                                <p>&nbsp;</p>
                            </td>
                            <td>
                                <p></p>
                            </td>
                            <td>
                                <p></p>
                            </td>
                            <td>
                                <p></p>
                            </td>
                            {!media_movil && <>
                                {(data_user.nivel == 6 || data_user.nivel < 4) && <td>
                                    <p></p>
                                </td>}
                                {data_user.nivel <= 4 && <td>
                                    <p></p>
                                </td>}
                            </>}
                        </tr>
                    );
                }
            }

            setPackages(_list);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getData = async (_codebar) => {
        setShowLoading(true);
        let toSend = _codebar.split("-");

        let _data = await api.fetchJson({
            url: "get-orders",
            data: {
                type: "por_codigo",
                consignee: toSend[0],
                consecutive: toSend[1]
            }
        });

        if (_data.response == 1) {
            if (_data.data.length > 0) {
                let _datos = _data.data[0];

                setData(_datos);
                setFechaRecibido(new Date(_datos.date_received + "T05:00:00"));

                if (parseInt(_datos.collect) > 1) {
                    setCollect(true);
                    // setCollectPrice(isNaN(parseInt(_datos.collect)) ? 0 : parseInt(_datos.collect));
                    setCollectPrice(funciones.formatPrice(_datos.collect).format);
                }
            } else {
                setData();
                setMensaje(`No se encontró ningún paquete asignado al código "${_codebar}"`);
            }
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        if (data) {
            getPackagesList();
        }
    }, [data]);

    React.useEffect(() => {
        if (params.codigo) {
            setTitulo("Orden de servicio " + params.codigo);
            getData(params.codigo);
        } else {
            if (location.state) {
                let _data = location.state.data;
                setTitulo("Orden de servicio " + _data.consecutivo);
                getData(_data.consecutivo);
            } else {
                history.push("/ordenes-de-recepcion");
            }
        }
    }, [params.codigo, location.pathname]);

    return (<div className="main_container">

        {data ?
            <>
                <div className="t_header">

                    <div className="_columna" >
                        <h3>Orden de servicio</h3>
                        <h5>Fecha de recepción: {fecha_recibido.getDate()} de {meses[fecha_recibido.getMonth()]} del {fecha_recibido.getFullYear()}</h5>

                        <h5 className="estado">Estado: {funciones.getState(data)}</h5>
                    </div>

                    <div className="_columna">
                        {/* <BarCode code={"R-" + data.consignee_city.toUpperCase().substring(0, 3) + data.consecutivo} /> */}
                        <BarCode code={"OS-" + data.consecutivo} />
                    </div>
                </div>

                <h4 style={{ padding: 0 }}>
                    <span>Información del cliente bts</span>
                    <span>Nota</span>
                </h4>

                <div className="_tabla">

                    <div className="columna">
                        <div className="cont_info">

                            <p><b>{data.consignee_name}</b></p>
                            <p>Direción: {media_movil && <br />} <b>{data.consignee_address}, {data.consignee_city}</b></p>
                            {(data_user.nivel == 6 || data_user.nivel < 4) && <p>Teléfono: {media_movil && <br />} <b>{data.consignee_phone}</b></p>}

                            {collect && <h5 className="_collect" style={{ marginLeft: 12, marginTop: 18 }}>Esta orden tiene un cobro de recolección de ${collectPrice}</h5>}

                        </div>
                    </div>

                    <div className="columna">
                        <div className="cont_info">

                            <p className="notas">{data.notes}</p>

                        </div>
                    </div>

                </div>

                <table className="t_resumen">
                    <tbody>

                        <tr>
                            <th>
                                <p className="_th">Piezas</p>
                            </th>
                            <th>
                                <p className="_th">Paquete</p>
                            </th>
                            {/* <th>
                            <p className="_th">Dimensiones</p>
                        </th> */}
                            <th>
                                <p className="_th">Notas</p>
                            </th>
                            <th>
                                <p className="_th">{!media_movil ? "V. Producto" : "Valores"}</p>
                            </th>
                            {!media_movil && <>
                                {(data_user.nivel == 6 || data_user.nivel < 4) && <th>
                                    <p className="_th">V. domicilio</p>
                                </th>}
                                {data_user.nivel <= 4 && <th>
                                    <p className="_th">V. mensajero</p>
                                </th>}
                            </>}
                        </tr>

                        {packages}

                    </tbody>
                </table>

                <div className="t_footer">
                    <div className="observaciones _padding_right_8">
                        <p>Firma: <span>______________________________________________________ </span>
                            <strong>TOTAL < MenuRightIcon /></strong>
                        </p>
                    </div>

                    <div className="peso">

                        <div className="colunmas" >
                            <div className="_th">Piezas</div>
                            <p>{data.piezas}</p>
                        </div>

                        {/* <div className="colunmas" >
                            <div className="_th">Peso</div>
                            <p>{data.weight} {data.m_unit}</p>
                        </div> */}

                        {/* <div className="colunmas" >
                            <div className="_th">Volumen</div>
                            <p>{data.volume} {data.v_unit}</p>
                        </div> */}

                        <div className="colunmas" >
                            <div className="_th">Valor total</div>
                            <p>{data.v_total > 0 ? "$" + funciones.formateaPrecio(data.v_total) : "..."}</p>
                        </div>

                    </div>

                </div>
            </>
            :
            <h2 style={{ textAlign: "center", paddingTop: 64, paddingBottom: 82 }}>{mensaje}</h2>
        }
        {showLoading && <Loading />}
    </div >
    );
}

export default ReceptionOrder;