import * as React from "react";
import ReportsExcelAdmin from "./components/ReportsExcelAdmin";
import ResumenCierreAdmin from "./components/ResumenCierreAdmin";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import RecogidasAdmin from "./components/RecogidasAdmin";
import CerrarCajaIcon from "mdi-react/CashRegisterIcon";
import EntregasAdmin from "./components/EntregasAdmin";
import CierreCaja from "./components/CierreCaja";
import { SearchInReport } from "shared/Input";
import { Container, Row } from "reactstrap";
import funciones from "services/funciones";
import DatePicker from "react-datepicker";
import Cortes from "./components/Cortes";
import useTitle from "hooks/useTitle";
import * as Icon from "../../images";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-extend-native: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

Date.prototype.getWeekNumber = function () {
  var d = new Date(+this);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));

  return Math.ceil((((d - new Date(d.getFullYear(), 0, 1)) / 8.64e7) + 1) / 7);
};

const format = (_num) => {
  return _num < 10 ? "0" + _num : _num;
}

const ReportRecepcion = (params) => {
  const { history } = params;
  const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
  const [listCountDelivery, setListCountDelivery] = React.useState([]);
  const [showLoading, setShowLoading] = React.useState(true);
  const [listPickups, setListPickups] = React.useState([]);
  const [resumenPago, setResumenPago] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const [userNameList, setUserNameList] = React.useState([]);
  const [userToClose, setUserToClose] = React.useState();
  const [showModal, setShowModal] = React.useState(false);
  const [modalView, setModalView] = React.useState(null);
  const [criterio, setCriterio] = React.useState("");
  const { setTitulo } = useTitle();

  const [dates, setDates] = React.useState("10");
  const [fechaDesde, setFechaDesde] = React.useState(new Date());
  const [fechaHasta, setFechaHasta] = React.useState(new Date());
  const [objDates, setObjDates] = React.useState({
    dates: dates,
    desde: fechaDesde.getFullYear() + "-" + format(fechaDesde.getMonth() + 1) + "-" + format(fechaDesde.getDate()),
    hasta: fechaHasta.getFullYear() + "-" + format(fechaHasta.getMonth() + 1) + "-" + format(fechaHasta.getDate()),
  });

  const handleModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let _height = window.innerHeight;
    let _width = document.getElementById("root").offsetWidth;
    let _posx = (e.pageX + 280) > _width ? _width - 280 : e.pageX;
    let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;
    let _to_y = _to_border_bottom < 315 ? e.pageY - 150 : e.pageY - 80;
    let _pos = { top: _to_y + "px", left: _posx + "px", width: 220, maxWidth: 220 };

    setModalView(<CierreCaja pos={_pos} close={setShowModal} reload={getSales} data={{ ...userToClose, ...resumenPago[0] }} />);
    setShowModal(true);
  }

  const handleSelectUser = (_nombre) => {
    setCriterio(_nombre);

    let _item = userList.filter((item) => item.nombre + " " + item.apellido === _nombre);

    if (_item.length >= 1) {
      setUserToClose(_item[0]);
    }
  }

  const handlePasedUser = (_lista_usuarios, _nombre) => {
    setCriterio(_nombre);

    let _item = _lista_usuarios.filter((item) => item.nombre + " " + item.apellido === _nombre);

    if (_item.length >= 1) {
      setUserToClose(_item[0]);
    }
  }

  const getUserList = async () => {
    setShowLoading(true);
    let _data = await api.getUsers("carrier");
    let _users = [];

    if (_data.response == 1) {

      _data.data.map(item => {
        item.nivel != "1" && _users.push(item.nombre + " " + item.apellido);
      });

      setUserList(_data.data);
      setUserNameList(_users);

      if (params.location.state && params.location.state.nombre) {
        handlePasedUser(_data.data, params.location.state.nombre);
      } else {
        setShowLoading(false);
      }
    } else if (_data.response === -2) {
      Alert(_data.msg, "warning", () => history.push("/"));
      setShowLoading(false);
    } else {
      Alert(_data.msg, "warning");
      setShowLoading(false);
    }

    return _users;
  }

  const getPickups = async () => {
    let _nivel = "user";
    if (userToClose && userToClose.id_user) {

      switch (parseInt(data_user.nivel)) {
        case 1:
        case 2:
        case 3:
          _nivel = "admin";
          break;
        case 4:
          _nivel = "carrier";
          break;
        case 8:
          _nivel = "recepcion";
          break;
        case 6:
          _nivel = "cliente";
          break;
        default:
          _nivel = "user";
          break;
      }

      let _data = await api.fetchJson({
        url: "reportes",
        data: {
          task: "recogidas",
          nivel: _nivel,
          user_close: userToClose ? userToClose.id_user : "",
          nivel_close: userToClose ? userToClose.nivel : ""
        }
      });

      if (_data.response == 1) {
        _data.data.map(item => {
          item.collect = parseInt(item.collect_product) || 0;
          return item;
        });

        setListPickups(_data.data);
      } else if (_data.response === -2) {
        Alert(_data.msg, "warning", () => history.push("/"));
      } else {
        Alert(_data.msg, "warning");
      }
    }
  }

  const getCountDeliverys = async () => {
    let _nivel = "user";

    if (userToClose && userToClose.id_user) {

      switch (parseInt(data_user.nivel)) {
        case 1:
        case 2:
        case 3:
          _nivel = "admin";
          break;
        case 4:
          _nivel = "carrier";
          break;
        case 8:
          _nivel = "recepcion";
          break;
        case 6:
          _nivel = "cliente";
          break;
        default:
          _nivel = "user";
          break;
      }

      let _data = await api.fetchJson({
        url: "reportes",
        data: {
          task: "cuentaentregas",
          nivel: _nivel,
          user_close: userToClose ? userToClose.id_user : "",
          nivel_close: userToClose ? userToClose.nivel : ""
        }
      });

      if (_data.response == 1) {
        let _delivery = _data.data.map(item => {
          item.counter = parseInt(item.counter) || 0;
          return item;
        });

        setListCountDelivery(_delivery);
      } else if (_data.response === -2) {
        Alert(_data.msg, "warning", () => history.push("/"));
      } else {
        Alert(_data.msg, "warning");
      }
    }
  }

  const armaMensajero = (_entregas, _recogidas, _abonos) => {
    let _sales = [
      {
        id: 0,
        title: 'Totales',
        abonos: 0,
        entregas: 0,
        recogidas: 0,
        v_domicilio: 0,
        v_mensajero: 0,
        v_mensajero_r: 0,
        subtolal: 0,
        pendiente: 0,
        efectivo: 0,
        transferencia: 0,
        domicilio_ko: 0,
      },
    ];

    _entregas.map(item => {

      if (item.estado == 6 || item.estado == 9) {
        _sales[0].entregas += parseInt(item.price);

        _sales[0].subtolal += parseInt(item.price);
        _sales[0].v_mensajero += parseInt(item.v_mensajero);

        _sales[0].efectivo += parseInt(item.efectivo || 0);
        _sales[0].transferencia += parseInt(item.nequi || 0) + parseInt(item.daviplata || 0) + parseInt(item.bold || 0);
      } else if (item.estado == 8) {
        _sales[0].v_mensajero += parseInt(item.v_mensajero);
      } else {
        if (item.estado == 12) {
          let _ko_obj = item.domicilio_ko ? item.domicilio_ko.split(",").pop() : [];
          let _ko_dom_value = _ko_obj ? parseInt(_ko_obj.split(":")[1]) : 0;
          let _ko_dom_name = _ko_obj ? _ko_obj.split(":")[0] : "";

          _sales[0].domicilio_ko += _ko_dom_value;
          _sales[0].subtolal += _ko_dom_value;
          _sales[0].v_mensajero += parseInt(item.v_mensajero);

          if (_ko_dom_name.length > 0) {
            if (_ko_dom_name == "Efectivo") {
              _sales[0].efectivo += _ko_dom_value;
            } else {
              _sales[0].transferencia += _ko_dom_value;
            }
          }

        } else {
          _sales[0].pendiente += parseInt(item.price);
        }
      }

    });


    _recogidas.map(item => {
      _sales[0].recogidas += parseInt(item.collect_product || 0);
      _sales[0].v_mensajero_r += parseInt(item.collect_carrier || 0);

      _sales[0].subtolal -= parseInt(item.collect_product || 0);
      _sales[0].subtolal -= parseInt(item.collect_carrier || 0);
    });

    _sales[0].efectivo -= _sales[0].v_mensajero;
    _sales[0].subtolal -= _sales[0].v_mensajero;
    _sales[0].abonos = parseInt(_abonos.monto || 0);
    _sales[0].subtolal -= _sales[0].abonos;
    _sales[0].efectivo -= _sales[0].abonos;

    setResumenPago(_sales);
  }

  const armaCliente = (_entregas, _recogidas, _abonos) => {
    // 304 378 3429
    let _sales = [
      {
        id: 0,
        title: 'Totales',
        abonos: 0,
        entregas: 0,
        recogidas: 0,
        v_domicilio: 0,
        v_domicilio_r: 0,
        v_mensajero: 0,
        subtolal: 0,
        pendiente: 0,
        efectivo: 0,
        transferencia: 0,
        domicilio_ko: 0,
      },
    ];

    _entregas.map(item => {

      if (item.estado == 6 || item.estado == 9) {
        _sales[0].entregas += parseInt(item.price);

        _sales[0].subtolal += parseInt(item.price);
        _sales[0].v_domicilio += parseInt(item.v_domicilio);

        _sales[0].efectivo += parseInt(item.efectivo || 0);
        _sales[0].transferencia += parseInt(item.nequi || 0) + parseInt(item.daviplata || 0) + parseInt(item.bold || 0);
      } else {
        if (item.estado == 15) {
          let _ko_dom_all = item.domicilio_ko ? item.domicilio_ko.split(",") : [];

          _sales[0].v_domicilio += parseInt(item.v_domicilio);

          _ko_dom_all.map(_ko_obj => {
            let _ko_dom_value = _ko_obj ? parseInt(_ko_obj.split(":")[1]) : 0;
            let _ko_dom_name = _ko_obj ? _ko_obj.split(":")[0] : "";

            _sales[0].domicilio_ko += _ko_dom_value;
            _sales[0].subtolal += _ko_dom_value;

            if (_ko_dom_name.length > 0) {
              if (_ko_dom_name == "Efectivo") {
                _sales[0].efectivo += _ko_dom_value;
              } else {
                _sales[0].transferencia += _ko_dom_value;
              }
            }

          });
        } else {
          _sales[0].pendiente += parseInt(item.price);
        }
      }

    });

    _recogidas.map(item => {
      _sales[0].recogidas += parseInt(item.collect_product || 0);
      _sales[0].v_domicilio_r += parseInt(item.collect_domic || 0);

      _sales[0].subtolal -= parseInt(item.collect_product || 0);
      _sales[0].subtolal -= parseInt(item.collect_domic || 0);
    });

    // _sales[0].efectivo -= _sales[0].v_domicilio;
    _sales[0].subtolal -= _sales[0].v_domicilio;
    _sales[0].abonos = parseInt(_abonos.monto || 0);
    _sales[0].subtolal += _sales[0].abonos;

    setResumenPago(_sales);
  }

  const getSales = async () => {
    if (userToClose && userToClose.id_user) {
      setShowLoading(true);
      let _nivel = "user";

      switch (parseInt(data_user.nivel)) {
        case 1:
        case 2:
        case 3:
          _nivel = "admin";
          break;
        case 4:
          _nivel = "carrier";
          break;
        case 8:
          _nivel = "recepcion";
          break;
        case 6:
          _nivel = "cliente";
          break;
        default:
          _nivel = "user";
          break;
      }

      let _data = await api.fetchJson({
        url: "reportes",
        data: {
          task: "ingresos",
          nivel: _nivel,
          user_close: userToClose ? userToClose.id_user : "",
          nivel_close: userToClose ? userToClose.nivel : ""
        }
      });

      let _data_pickup = await api.fetchJson({
        url: "reportes",
        data: {
          task: "pagos_recogidas",
          nivel: _nivel,
          user_close: userToClose ? userToClose.id_user : "",
          nivel_close: userToClose ? userToClose.nivel : ""
        }
      });

      let _data_abonos = await api.fetchJson({
        url: "reportes",
        data: {
          task: "abonos",
          nivel: _nivel,
          user_close: userToClose ? userToClose.id_user : "",
          nivel_close: userToClose ? userToClose.nivel : ""
        }
      });

      if (_data.response == 1) {

        if (userToClose.nivel == 4) {
          armaMensajero(_data.data, _data_pickup.data, _data_abonos.data[0]);
        }

        if (userToClose.nivel == 6) {
          armaCliente(_data.data, _data_pickup.data, _data_abonos.data[0]);
        }

      } else if (_data.response === -2) {
        Alert(_data.msg, "warning", () => history.push("/"));
      } else {
        Alert(_data.msg, "warning");
      }
    }

    setShowLoading(false);
  }

  const cerrarCaja = async (e) => {
    e.preventDefault();

    if (userToClose && userToClose.id_user) {
      setShowLoading(true);
      let _nivel = "user";

      switch (parseInt(data_user.nivel)) {
        case 1:
        case 2:
        case 3:
          _nivel = "admin";
          break;
        case 4:
          _nivel = "carrier";
          break;
        case 8:
          _nivel = "recepcion";
          break;
        case 6:
          _nivel = "cliente";
          break;
        default:
          _nivel = "user";
          break;
      }

      let _data = await api.fetchJson({
        url: "cerrar-caja",
        data: {
          task: _nivel,
          user_close: userToClose ? userToClose.id_user : "",
          nivel_close: userToClose ? userToClose.nivel : ""
        }
      });

      if (_data.response == 1) {
        let _no_have = userToClose.nivel == 4 ? "¡No devolverá paquetes!" : "¡No tiene devociones!";
        let _entregada = _data.data.cantidad_entregada > 0 ? `${_data.data.cantidad_entregada} entrega${_data.data.cantidad_entregada < 2 ? "" : "s"} = $${funciones.formatPrice(_data.data.valor_entregado).format}` : "¡No tiene entregas!";
        let _devuelto = _data.data.candidad_devuelta > 0 ? `Devolverá ${_data.data.candidad_devuelta} paquete${_data.data.candidad_devuelta < 2 ? "" : "s"} a bodega` : _no_have;
        let _efectivo = _data.data.efectivo > 0 ? `Efectivo = $${funciones.formatPrice(_data.data.efectivo).format}` : "¡Sin pagos en efectivo!";
        let _devoluciones = _data.data.cantidad_ko_dv > 0 ? _data.data.ko_dv : "¡Sin entregas KO+DV!";
        let _transferencias = _data.data.transferencia > 0 ? `Transferencias = $${funciones.formatPrice(_data.data.transferencia).format}` : "¡Sin transferencias!";
        let _mensaje;
        if (userToClose.nivel == 4) {
          _mensaje = `Caja recibida con: \n\n${_entregada} \n${_efectivo} \n${_transferencias} \n${_devoluciones} \n${_devuelto} \n\nReabierta correctamente.`;
        } else {
          _mensaje = `Corte realizado con: \n\n${_entregada} \n${_devuelto}`;
        }

        Alert(_mensaje, "ok", () => {
          getSales();
          getPickups();
          getCountDeliverys();
        });

      } else if (_data.response === -2) {
        Alert(_data.msg, "warning", () => history.push("/"));
      } else {
        Alert(_data.msg, "warning");
      }

      setShowLoading(false);
    }
  }

  const calcWeek = (_fecha) => {
    let _restantes = 6 - _fecha.getDay();
    let _min_left = (60 - _fecha.getMinutes()) * 60000;
    let _hours_left = ((24 - _fecha.getHours()) * 3600000) - _min_left;
    let _time = _fecha.getTime() + (_restantes * 86400000) + _hours_left;

    let _fecha_d = new Date(_time - ((7 * 86400000) - 2400000));
    let _fecha_h = new Date(_time);

    return {
      desde: _fecha_d.getFullYear() + "-" + format(_fecha_d.getMonth() + 1) + "-" + format(_fecha_d.getDate()),
      hasta: _fecha_h.getFullYear() + "-" + format(_fecha_h.getMonth() + 1) + "-" + format(_fecha_h.getDate()),
    }
  }

  const calcNextWeek = (_fecha = new Date()) => {
    let _restantes = 7 - _fecha.getDay();
    let _time = _fecha.getTime() + (_restantes * 86400000);
    let _fecha_d = new Date(_time);
    let _fecha_h = new Date(_time + (6 * 86400000));

    return {
      desde: _fecha_d.getFullYear() + "-" + format(_fecha_d.getMonth() + 1) + "-" + format(_fecha_d.getDate()),
      hasta: _fecha_h.getFullYear() + "-" + format(_fecha_h.getMonth() + 1) + "-" + format(_fecha_h.getDate()),
    }
  }

  const calcAllDates = (_fecha = new Date()) => {
    let _fecha_d = new Date(0);
    let _fecha_h = new Date((31556926 * 300) * 1000);

    return {
      desde: _fecha_d.getFullYear() + "-" + format(_fecha_d.getMonth() + 1) + "-" + format(_fecha_d.getDate()),
      hasta: _fecha_h.getFullYear() + "-" + format(_fecha_h.getMonth() + 1) + "-" + format(_fecha_h.getDate()),
    }
  }

  const BtnClose = () => {

    if (userToClose) {

      if (showLoading) {
        return <button className="close-button _flotante"><Icon.Loading />Cargando...</button>;
      } else {
        return <>
          {userToClose.nivel == 6 ?
            <button className="close-button _flotante" onClick={handleModal}><CerrarCajaIcon />Corte de caja</button>
            :
            <button className="close-button _flotante" onClick={cerrarCaja}><CerrarCajaIcon />Recibir caja</button>
          }
        </>;
      }

    } else {
      return <button className="close-button _flotante _deshabilitado"><CerrarCajaIcon />{(userToClose && userToClose.nivel == 6) ? "Corte de caja" : "Recibir caja"}</button>;
    }

  }

  React.useEffect(() => {
    if (data_user.nivel == 8) {
      setTitulo("Reportes de recogida y entrega");
      getUserList();
    } else {
      history.push("/home");
    }
  }, []);

  React.useEffect(() => {
    if (userToClose && userToClose.id_user) {
      getSales();
      getCountDeliverys();
      getPickups();
    }
  }, [userToClose]);

  React.useEffect(() => {
    let _fecha = new Date();
    let _desde, _hasta, _new_date, _obj_dates;

    switch (parseInt(dates)) {
      case 10:
        _new_date = calcAllDates(_fecha);

        _desde = _new_date.desde;
        _hasta = _new_date.hasta;
        break;
      case 9:
        _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 2)}-01`;
        _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 2)}-30`;
        break;
      case 8:
        _new_date = calcNextWeek(_fecha);

        _desde = _new_date.desde;
        _hasta = _new_date.hasta;
        break;
      case 7:
        _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() - 5)}-01`;
        _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth())}-30`;
        break;
      case 6:
        _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() - 2)}-01`;
        _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth())}-30`;
        break;
      case 5:
        _desde = `${_fecha.getFullYear()}-01-01`;
        _hasta = `${_fecha.getFullYear()}-12-30`;
        break;
      case 4:
        _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 1)}-01`;
        _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 1)}-30`;
        break;
      case 3:
        _new_date = calcWeek(_fecha);

        _desde = _new_date.desde;
        _hasta = _new_date.hasta;
        break;
      case 2:
        _desde = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate() - 1);
        _hasta = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate() - 1);
        break;
      case 1:
        _desde = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate());
        _hasta = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate());
        break;
    }

    if (parseInt(dates) !== 11) {
      _obj_dates = {
        dates: dates,
        desde: _desde,
        hasta: _hasta,
      };

      setObjDates(_obj_dates);
    }
  }, [dates]);

  React.useEffect(() => {
    if (parseInt(dates) === 11) {
      let _obj_dates = {
        dates: dates,
        desde: fechaDesde.getFullYear() + "-" + format(fechaDesde.getMonth() + 1) + "-" + format(fechaDesde.getDate()),
        hasta: fechaHasta.getFullYear() + "-" + format(fechaHasta.getMonth() + 1) + "-" + format(fechaHasta.getDate()),
      };

      setObjDates(_obj_dates);
    }
  }, [fechaDesde, fechaHasta]);

  return (
    <Container className="dashboard">
      <Row>
        <h3 className="page-title">Reportes de recogidas y entregas</h3>

        <SearchInReport
          name="Buscar"
          placeholder="Buscar vendedor o mensajero"
          value={criterio}
          list={userNameList}
          onChange={handleSelectUser} />

        <ReportsExcelAdmin fechas={objDates} userToClose={userToClose} sales={resumenPago}>
          <BtnClose />

          <Row className="content_select">
            <select name="dates" defaultValue={dates} onChange={e => setDates(e.target.value)}>

              <option value="1">Hoy</option>
              <option value="2">Ayer</option>
              <option value="3">Esta semana</option>
              <option value="4">Este mes</option>
              <option value="6">Últimos tres meses</option>
              <option value="7">Últimos seis meses</option>
              <option value="5">Este año</option>
              {/* <option value="8">Siguiente semana</option> */}
              {/* <option value="9">Siguiente mes</option> */}
              <option value="10">Todos</option>
              <option value="11">Personalizado</option>

            </select>

            {dates === "11" &&
              <div className="cont_calendar">

                <div className="calendar">
                  <DatePicker dateFormat="yyyy/MM/dd" name="desde" selected={fechaDesde} onChange={(date) => setFechaDesde(date)} />
                  <CalendarMonthIcon />
                </div>

                <div className="calendar">
                  <DatePicker dateFormat="yyyy/MM/dd" name="hasta" selected={fechaHasta} onChange={(date) => setFechaHasta(date)} />
                  <CalendarMonthIcon />
                </div>

              </div>}
          </Row>

          {userToClose && <ResumenCierreAdmin
            titulo="Total a entregar"
            userToClose={userToClose}
            data={resumenPago}
            onRefresh={() => getSales()} />}

        </ReportsExcelAdmin>
      </Row>

      <Row>
        <EntregasAdmin userToClose={userToClose} list={listCountDelivery} onRefresh={() => getCountDeliverys()} />
        <RecogidasAdmin userToClose={userToClose} list={listPickups} onRefresh={() => getPickups()} />
      </Row>

      <Row>
        <Cortes data_user={userToClose} sales={resumenPago} />
      </Row>

      {showModal && modalView}

    </Container>
  )
}

export default ReportRecepcion;
