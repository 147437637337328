import * as React from "react";
import ReactDOM from 'react-dom';
import CloseIcon from 'mdi-react/CloseCircleIcon';
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";


const ImgComponent = (params) => {
    const data = params[0];
    const imgRef = React.useRef(null);

    const onDismiss = (e) => {
        e.preventDefault();

        ReactDOM.unmountComponentAtNode(document.getElementById("modal"));
    }

    const onUpdate = React.useCallback(({ x, y, scale }) => {
        const { current: img } = imgRef;

        if (img) {
            const value = make3dTransformValue({ x, y, scale });

            img.style.setProperty("transform", value);
        }
    }, []);


    return (
        <div className="modal_img">
            <div className="modal_bkg-blur"></div>
            <QuickPinchZoom onUpdate={onUpdate}>
                <img src={process.env.REACT_APP_SERVER + data.preview} alt={data.name} ref={imgRef} />
            </QuickPinchZoom>
            <CloseIcon onClick={onDismiss} />
        </div>
    );
}

const VisorImg = (params) => {
    let _modal = document.getElementById("modal");

    ReactDOM.render(
        <ImgComponent {...params} />,
        _modal,
    );
}

export default VisorImg;