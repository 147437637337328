import * as React from "react";
import ImageMinusIcon from "mdi-react/ImageMinusIcon";
import ImagePlusIcon from "mdi-react/ImagePlusIcon";
import { Card, CardBody, Col } from "reactstrap";
import LoadingIcon from "mdi-react/LoadingIcon";
import ClipIcon from "mdi-react/PaperclipIcon";
import { uploadFile } from "services/Uploader";
import { alerta, alertaRemove } from "./Alert";
import VisorImg from "shared/VisorImg";
import DropZoneField from "./DropZone";
import log from "services/logs";
import resize from "./Resize";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const FileUpload = ({ codigo, onChange, attach }) => {
  const [files, setFiles] = React.useState([]);
  const [filesToUpload, setFilesToUpload] = React.useState([]);
  const [currentProgress, setCurrentProgress] = React.useState({ value: 0, file: "" });
  const [loading, setLoading] = React.useState(false);
  const alertRef = React.useRef(null);


  const validaEnvio = () => {
    let allFiles = [];
    let _urls = "";
    alertaRemove(alertRef.current);

    if (filesToUpload.length < 1) {
      alerta(alertRef.current, "No hay archivos para subir! ");
    } else {
      setLoading(true);

      filesToUpload.forEach( async file => {
        const data = new FormData();
        const imgName = file.nameinserver;

        setCurrentProgress({
          file: imgName,
          value: Math.round(1)
        });

        resize(file, async image => {

          if (image !== null) {
            data.append('image', image);
            data.append('nombre', imgName.trim());
            data.append('destino', "packs");
          }

          await uploadFile(data).then(response => {

            allFiles.push(response);

            log.d("archivo subido", file.name);

            if (allFiles.length == filesToUpload.length) {

              setFiles(allFiles);

              allFiles.forEach(f => {
                log.d("nameinserver", f.nameinserver);
                _urls += f.nameinserver + "|" + f.url + ",";
              });

              setCurrentProgress({
                file: imgName,
                value: Math.round(100)
              });

              onChange(_urls.slice(0, -1));
              setFilesToUpload([]);
              setLoading(false);

              if (typeof android !== 'undefined') {

                setTimeout(() => {
                  // eslint-disable-next-line no-undef
                  android.eliminaArchivo(file.name);
                }, 3000);
              }
            }

          }).catch(error => {
            alerta(alertRef.current, error);
            setLoading(false);
          });
        });
      });
    }
  }

  const handleDeleteFile = (file) => {
    if (file) {

      if (file.isUpdate) {

        files.forEach((f, i) => {
          let newFiles = files.filter(f => f.nameinserver != file.nameinserver);
          setFiles(newFiles);
        });

      } else {
        let fileName = file.nameinserver;
        let newFiles = files.filter(f => f.nameinserver != fileName);
        let _urls = "";

        log.i("File deleted", fileName);

        setFiles(newFiles);

        newFiles.forEach(f => {
          _urls += f.url + ",";
        });

        onChange(_urls.slice(0, -1));
      }
    }
  }

  React.useEffect(() => {
    if (attach) {
      setFiles(attach);
    }
  }, [attach]);

  React.useEffect(() => {
    if (filesToUpload.length > 0) {
      validaEnvio();
      // setFiles(filesToUpload);
    }
  }, [filesToUpload]);

  return (
    <Col style={{ position: "relative" }}>
      <Card className="card--not-full-height">
        <CardBody className="width_static">
          <div className="form">
            {loading ?
              <div className="dropzone dropzone--single">
                <div className="dropzone__input dropzone__loading">
                  <i className="progress">{currentProgress.value}%</i>
                  <i className="file">{currentProgress.file}</i>
                  <LoadingIcon className="on_refresh" />
                </div>
              </div>
              :
              <DropZoneField
                codigo={codigo}
                onChange={setFilesToUpload} onDelete={handleDeleteFile}
                files={files} />
            }

          </div>
        </CardBody>
      </Card>
      <div className="dropzone__alert">
        <input type="hidden" ref={alertRef} />
      </div>
    </Col>
  );
}

export default FileUpload;

export const FileUploadImg = ({ img, codigo, loading, onStart, onUpload }) => {

  const handleSelectFile = async (e) => {

    if (e.target.files.length > 0) {
      const data = new FormData();
      const file = e.target.files[0];
      let imgName = file.name;
      let _ext = imgName.split(".");
      _ext = _ext[_ext.length - 1];
      imgName = `EVIDENCE_${codigo}_${(new Date().getTime() / 1000).toFixed(0)}.${_ext}`;

      onStart();

      resize(file, async image => {

        if (image !== null) {
          data.append('image', image);
          data.append('nombre', imgName.trim());
          data.append('destino', "packs");
        }

        await uploadFile(data).then(imagen => {
          // log.i("url", imagen);
          onUpload({ url: imagen.url, name: imgName, realname: imagen.name });
        }).catch(error => {
          log.e("Error: ", error);
        });
      });
    }
  }

  return (
    <>
      {img ?
        <>
          <i className="name_img">{img.realname.length > 25 ? img.realname.substring(0, 12) + "..." + img.realname.substring(img.realname.length - 10) : img.realname}</i>
          <label className="label_icon">{loading ? <LoadingIcon className="on_refresh" /> : <ImageMinusIcon onClick={() => onUpload(null)} />}</label>
        </>
        :
        <>
          <label htmlFor="file_img" className="label_icon">{loading ? <LoadingIcon className="on_refresh" /> : <ImagePlusIcon />}</label>
          <input type="file" name="file_img" id="file_img" onChange={handleSelectFile} />
        </>
      }
    </>
  );
}

export const FileUploadClip = ({ loading, onStart, onUpload }) => {

  const handleSelectFile = async (e) => {
    if (e.target.files.length > 0) {
      const data = new FormData();
      const file = e.target.files[0];
      const imgName = file.name;

      onStart();

      resize(file, async image => {

        if (image !== null) {
          data.append('image', image);
          data.append('nombre', imgName.trim());
          data.append('destino', "chat");
        }

        await uploadFile(data).then(imagen => {
          // log.i("url", imagen.url);
          onUpload({ url: imagen.url, name: imgName });
        }).catch(error => {
          log.e("Error: ", error);
        });
      });
    }
  }

  return (
    <>
      <label htmlFor="file_clip">{loading ? <LoadingIcon className="on_refresh" /> : <ClipIcon />}</label>
      <input type="file" name="file_clip" id="file_clip" onChange={handleSelectFile} />
    </>
  );
}

export const ViewImage = ({ data }) => {
  const [pathImg, setPathImg] = React.useState(data.imagen_url);

  const handleZoom = (e) => {
    e.preventDefault();

    let _new_file = new File([data.imagen_url], data.imagen, { type: "image/" + data.imagen.split('.').pop() });

    _new_file = Object.assign(_new_file, { preview: data.imagen_url });

    VisorImg([_new_file]);
  }

  React.useEffect(() => {
    if (data.imagen_url == null && data.imagen != null) {

      log.i("url", data.imagen_url);
      setPathImg(data.imagen_url);
    }
  }, [data]);

  return (
    <img
      src={pathImg}
      alt="imagen enviada"
      onClick={handleZoom} />
  );
}