import * as React from "react";
import CloseOutlineIcon from "mdi-react/CloseCircleOutlineIcon";
import Information from 'mdi-react/InformationOutlineIcon';
import { useHistory } from 'react-router-dom';
import useFilter from "hooks/useFilter";
import * as Icon from "../../images";
import { Alert } from "./Alert";
import { Input } from "./Input";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Filter = (params) => {
    const { pos, close, call } = params;
    const [userList, setUserList] = React.useState([{ id_user: 0, nombre: "Cargando..." }]);
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [showLoading, setShowLoading] = React.useState(true);
    const [courierList, setCourierList] = React.useState([]);
    const [consigneeId, setConsigneeId] = React.useState("");
    const [clientList, setClientList] = React.useState([]);
    const [courierId, setCourierId] = React.useState("");
    const [consignee, setConsignee] = React.useState("");
    const [statusId, setStatusId] = React.useState("0");
    const [courier, setCourier] = React.useState("");
    const [status, setStatus] = React.useState("");
    const [error, setError] = React.useState("");

    const history = useHistory();
    const form_ref = React.useRef();
    const { opFilter, setOpFilter } = useFilter();
    const _estados = ["Todos", "Para salir", "Por entregar", "OK", "OK+DV", "KO+CANCELADO", "KO+DOM", "KO+CF", "KO+DOM+PAGO", "KO+REPROGRAMADO", "En bodega", "Devolución", "Devuelto", "Pagado"];
    const _all_states = [
        { int: "0", nombre: "Sin asignar" },
        { int: "1", nombre: "Por recoger" },
        { int: "2", nombre: "Recogido" },
        { int: "3", nombre: "En transito" },
        { int: "4", nombre: "Para salir" },
        { int: "5", nombre: "Por entregar" },
        { int: "6", nombre: "OK" },
        { int: "7", nombre: "KO+CANCELADO" },
        { int: "8", nombre: "KO+DOM" },
        { int: "9", nombre: "OK+DV" },
        { int: "10", nombre: "KO+CF" },
        { int: "11", nombre: "Borrado" },
        { int: "12", nombre: "KO+DOM+PAGO" },
        { int: "13", nombre: "KO+REPROGRAMADO" },
        { int: "14", nombre: "En bodega" },
        { int: "15", nombre: "Devolución" },
        { int: "16", nombre: "Devuelto" },
        { int: "17", nombre: "Pagado" }
    ];


    const handleClose = (e) => {
        e.preventDefault();
        close(false);
    }

    const clearFields = (e) => {
        e.preventDefault();

        setError("");

        setOpFilter({
            client: "",
            carrier: "",
            status: ""
        });

        call({
            search: "1",
            estado: "",
            consignee: "",
            carrier: ""
        });

        close(false);
    }

    const validateFields = (e) => {
        e.preventDefault();

        setError("");

        if (status.length < 1 && courierId.length < 1 && consigneeId.length < 1) {
            setError("Debe agregar al menos una opción");
            return;
        }

        setOpFilter({
            client: consigneeId,
            carrier: courierId,
            status: statusId
        });

        call({
            search: "1",
            estado: statusId,
            consignee: consigneeId,
            carrier: courierId,
        });
        close(false);
    }

    const setValues = (_tipo, _val) => {
        let item = userList.filter(({ id_user }) => id_user === _val);
        item = item[0];

        switch (_tipo) {
            case "courier":
                setCourierId(_val);

                if (typeof item === "object") {
                    setCourier(item.nombre + " " + item.apellido);
                } else {
                    setCourier("");
                }
                break;
            case "consignee":
                setConsigneeId(_val);

                if (typeof item === "object") {
                    setConsignee(item.nombre + " " + item.apellido);
                } else {
                    setConsignee("");
                }
                break;
            case "status":
                let _state_name = "";

                _all_states.map(obj => {
                    if (_val === obj.int) {
                        _state_name = obj.nombre;
                    }
                });

                setStatus(_state_name);
                setStatusId(_val);

                break;
        }

    }

    const changeUser = (_tipo, _val) => {

        let item = userList.filter(({ nombre, apellido }) => nombre + " " + apellido === _val);
        item = item[0];


        switch (_tipo) {
            case "courier":
                setCourier(_val);

                if (typeof item === "object") {
                    setCourierId(item.id_user);
                } else {
                    setCourierId("");
                }
                break;
            case "consignee":
                setConsignee(_val);

                if (typeof item === "object") {
                    setConsigneeId(item.id_user);
                } else {
                    setConsigneeId("");
                }
                break;
            case "status":
                let _state_int = "0";

                _all_states.map(obj => {
                    if (_val === obj.nombre) {
                        _state_int = obj.int;
                    }
                });

                // for (let i in _estados) {
                //     if (_val === _estados[i]) {
                //         _state_int = i;
                //     }
                // }

                setStatus(_val);
                setStatusId(_state_int);

                break;
        }

    }

    const getAllList = async () => {
        setShowLoading(true);
        let _data = await api.getUsers(-1);
        let _users = [];
        let _user_list = [];
        let _courier_list = [];


        if (_data.response == 1) {

            _data.data.map(item => {
                let _nivel = parseInt(item.nivel);

                if (_nivel > 2) {
                    _users.push(item);

                    if (_nivel == 6) {
                        _user_list.push(item.nombre + " " + item.apellido);
                    }

                    if (_nivel == 4) {
                        _courier_list.push(item.nombre + " " + item.apellido);
                    }
                }
            });

            setUserList(_users);
            setClientList(_user_list);
            setCourierList(_courier_list);

        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);

        return _users;
    }

    React.useEffect(() => {
        if (userList.length > 1) {
            setValues("consignee", opFilter.client);
            setValues("courier", opFilter.carrier);
        }

        setValues("status", opFilter.status);

    }, [userList]);

    React.useEffect(() => {

        getAllList();

    }, []);

    return (
        <>
            <div className="back_form"></div>
            <div className="win_modal _overflow" style={pos}>
                <h4><CloseOutlineIcon onClick={handleClose} /> Filtro Personalizado</h4>

                <form ref={form_ref}>

                    <div className="_in_column">

                        {data_user.nivel < 6 && <div className="_in_column">
                            <label className="__titulo">Cliente bts</label>
                            <Input type="text" name="consignee" value={consignee} list={clientList}
                                onChange={(val) => changeUser("consignee", val)} />
                        </div>}

                        {params.onliClient && <>
                            {data_user.nivel < 4 && <div className="_in_column">
                                <label className="__titulo">Mensajero</label>
                                <Input type="text" name="courier" value={courier} list={courierList}
                                    onChange={(val) => changeUser("courier", val)} />
                            </div>}

                            <div className="_in_column">
                                <label className="__titulo">Estado</label>
                                <Input type="text" name="status" value={status} list={_estados} placeholder="Todos"
                                    onChange={(val) => changeUser("status", val)} />
                            </div>
                        </>}
                    </div>

                </form>
                {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

                <div className="cont_botones">

                    {showLoading ?
                        <>
                            <button className="btn btn-common width-static _all"><Icon.Loading /></button>
                            <button className="btn btn-common width-static"><Icon.Loading /></button>
                        </>
                        :
                        <>
                            {(opFilter.client == "" &&
                                opFilter.carrier == "" &&
                                opFilter.status == "") ?
                                <button className="btn btn-common width-static _all _des">Todos</button>
                                :
                                <button className="btn btn-common width-static _all" onClick={clearFields}>Todos</button>
                            }
                            <button className="btn btn-common width-static" onClick={validateFields}>Filtrar</button>
                        </>
                    }

                </div>
            </div>
        </>
    );
}

export default Filter;