import * as React from "react";
import AccountGroupIcon from "mdi-react/AccountGroupIcon";
import ReporIcon from "mdi-react/ChartAreasplineIcon";
import ChatIcon from "mdi-react/ChatProcessingIcon";
import CajaIcon from "mdi-react/CashRegisterIcon";
import BarcodeIcon from "mdi-react/BarcodeIcon";
import ArchiveIcon from "mdi-react/ArchiveIcon";
import * as Iconos from "../../images";
import useTitle from "hooks/useTitle";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Home = ({ history }) => {
    const { setTitulo } = useTitle();
    const [data_user, setDataUser] = React.useState({ nivel: 7 });


    const getStatus = async () => {
        let _data = await api.getSession();

        if (_data.response == 1) {
            let _data_user = localStorage.getItem("usuario");
            if (_data_user == null) {
                history.push("/");
            } else {
                setDataUser(JSON.parse(_data_user));
            }
            return;
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setDataUser({ nivel: 7 });
    }

    React.useEffect(() => {
        setTitulo("Best Transport Service", false);
        getStatus();
    }, []);

    return (
        <div className="home">

            <div className="home_title" >

                <div className="title">
                    <h2>Inicio</h2>
                    <p></p>
                </div>

                <div className="trm">

                </div>

            </div>

            {/* <div className="search_bar">

                <input type="text" placeholder="Buscar" />
                <div className="lupa"><MagnifyIcon /> </div>

            </div> */}

            {data_user.nivel <= 4 && <>
                <div className="option_up">

                    <div className="_btn_up" onClick={() => history.push("/ordenes-de-recogida")}>
                        <div className="icono"><Iconos.BoxMarker /></div>
                        <h4>Recogidas</h4>
                    </div>

                    {data_user.nivel < 4 && <div className="_btn_up" onClick={() => history.push("/ordenes-de-recepcion")}>
                        <div className="icono"><Iconos.BoxReception /></div>
                        <h4>Recepción</h4>
                    </div>}

                    <div className="_btn_up" onClick={() => history.push("/todos-los-paquetes")}>
                        <div className="icono"><ArchiveIcon /></div>
                        <h4>Entregas</h4>
                    </div>
                </div>

                <hr />

                {data_user.nivel < 4 && <div className="option_up _compleneto">

                    <div className="_btn_up" onClick={() => history.push("/nueva-orden-de-recogida")}>
                        <div className="icono"><Iconos.BoxAddOutLine /></div>
                        <h4>Nueva Recogida</h4>
                    </div>

                    <div className="_btn_up" onClick={() => history.push("/nueva-orden-de-servicio")}>
                        <div className="icono"><Iconos.BoxAdd /></div>
                        <h4>Nueva Recepción</h4>
                    </div>

                </div>}
            </>}

            {data_user.nivel == 5 && <>
                <div className="option_up">

                    <div className="_btn_up" onClick={() => history.push("/ordenes-de-recepcion")}>
                        <div className="icono"><Iconos.BoxReception /></div>
                        <h4>Recepción</h4>
                    </div>

                    <div className="_btn_up" onClick={() => history.push("/todos-los-paquetes")}>
                        <div className="icono"><ArchiveIcon /></div>
                        <h4>Entregas</h4>
                    </div>

                </div>

                <hr />
            </>}

            {data_user.nivel == 6 && <div className="option_up">

                <div className="_btn_up" onClick={() => history.push("/mis-ordenes")}>
                    <div className="icono"><Iconos.BoxMarker /></div>
                    <h4>Recogidas</h4>
                </div>

                <div className="_btn_up" onClick={() => history.push("/mis-paquetes")}>
                    <div className="icono"><ArchiveIcon /></div>
                    <h4>Entregas</h4>
                </div>
            </div>}


            <div className="option_down">

                {data_user.nivel < 4 &&
                    <>
                        <div className="_btn_down" onClick={() => history.push({ pathname: "/clientes", state: { rol: 6 } })}>
                            <div className="icono _large"><AccountGroupIcon /></div>
                            <h4>Clientes</h4>
                        </div>

                        <div className="_btn_down" onClick={() => history.push("/mensajeros")}>
                            <div className="icono _large"><Iconos.DeliveryMan /></div>
                            <h4>Mensajeros</h4>
                        </div>
                    </>}

                {data_user.nivel == 4 &&
                    <>
                        <div className="_btn_down" onClick={() => history.push("/reportes")}>
                            <div className="icono"><CajaIcon /></div>
                            <h4>Caja</h4>
                        </div>

                        <div className="_btn_down" onClick={() => history.push("/chat")}>
                            <div className="icono"><ChatIcon /></div>
                            <h4>Chat</h4>
                        </div>
                    </>}

                {data_user.nivel == 5 &&
                    <>
                        <div className="_btn_down" onClick={() => history.push("/nueva-orden-de-recogida")}>
                            <div className="icono"><Iconos.BoxAddOutLine /></div>
                            <h4>Nueva Recogida</h4>
                        </div>

                        <div className="_btn_down" onClick={() => history.push("/nueva-orden-de-servicio")}>
                            <div className="icono"><Iconos.BoxAdd /></div>
                            <h4>Nueva Recepción</h4>
                        </div>
                    </>}

                {data_user.nivel == 6 &&
                    <>
                        <div className="_btn_down" onClick={() => history.push("/todos-mis-paquetes")}>
                            <div className="icono"><Iconos.BoxHistory /></div>
                            <h4>Historial</h4>
                        </div>

                        <div className="_btn_down" onClick={() => history.push("/reportes")}>
                            <div className="icono"><CajaIcon /></div>
                            <h4>Caja</h4>
                        </div>

                        <div className="_btn_down" onClick={() => history.push("/chat")}>
                            <div className="icono"><ChatIcon /></div>
                            <h4>Chat</h4>
                        </div>
                    </>}

                {data_user.nivel == 8 &&
                    <>
                        <div className="_btn_down" onClick={() => history.push("/reportes-mensajeros")}>
                            <div className="icono"><ReporIcon /></div>
                            <h4>Reportes</h4>
                        </div>

                        <div className="_btn_down" onClick={() => history.push("/caja-mensajeros")}>
                            <div className="icono"><CajaIcon /></div>
                            <h4>Caja</h4>
                        </div>
                    </>}

            </div>

            {data_user.nivel < 4 &&
                <div className="option_down">
                    <div className="_btn_down" onClick={() => history.push("/generar-codigos")}>
                        <div className="icono _large"><BarcodeIcon /></div>
                        <h4>Generar Códigos</h4>
                    </div>

                </div>}

        </div>
    );
}

export default Home;