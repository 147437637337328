import * as React from "react";
import CloseOutlineIcon from "mdi-react/CloseCircleOutlineIcon";
import Information from "mdi-react/InformationOutlineIcon";
import { useHistory } from "react-router-dom";
import funciones from "services/funciones";
import * as Icon from "../../../images";
import { Alert } from "shared/Alert";
import api from "services/api";
import { Input } from "shared/Input";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const AbonoCaja = ({ pos, close, reload }) => {
    const history = useHistory();
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [showLoading, setShowLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [monto, setMonto] = React.useState("");
    const [notes, setNotes] = React.useState("");

    console.log('data_user', data_user);
    

    const handleClose = () => {
        reload();
        close(false);
    }

    const entregarCaja = async () => {
        setError("");
        
        if (monto.length < 1 || parseInt(monto) < 1 || isNaN(monto)) {
            setError("Debes ingerar un monto valido");
            return false;
        }

        if (notes.length < 1) {
            setError(`¡Debes ingresar una nota!`);
            return;
        }

        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "save-caja",
            data: {
                task: "abono_caja",
                monto: parseInt(monto.trim()),
                concepto: `"${data_user.nombre}" abono a caja: \n${notes}`
            }
        });

        if (_data.response == 1) {

        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            setError(_data.msg);
        }

        setShowLoading(false);
    }

    const BtnAceptar = () => {
        if (showLoading) {
            return <button className="btn btn-common width-static"><Icon.Loading /></button>;
        } else {
            return <button className="btn btn-common width-static" onClick={entregarCaja}>Abonar</button>;
        }
    }

    return (
        <>
            <div className="back_form"></div>
            <div className="win_modal _overflow _delivery" style={pos}>
                <h3 style={{ marginRight: -8, marginBottom: 8 }}><CloseOutlineIcon onClick={() => close(false)} />Abon a caja</h3>

                <div className="_in_column">

                    <div>
                        <label className="__titulo">Monto</label>
                        <Input type="tel" name="monto" placeholder="ingresa un valor"
                            value={funciones.formatPrice(monto).format} onChange={val => { setError(""); setMonto(val); }} />
                    </div>

                    <div>
                        <p className="__titulo">Nota</p>
                        <textarea name="comment" rows="3" placeholder="ingresa una nota"
                            value={notes} onChange={e => { setError(""); setNotes(e.target.value); }} />
                    </div>

                </div>

                {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

                <BtnAceptar />
            </div>
        </>
    );
}

export default AbonoCaja;