import * as React from "react";
import SugerenciasChat from './components/SugerenciasChat';
import useTitle from "hooks/useTitle";
import VistaChat from './components/VistaChat';
import { SearchChat } from "shared/Input";
import { Alert } from "shared/Alert";
import log from "services/logs";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Chat = (params) => {
    const { history, location } = params;
    const data = location.state ? location.state.data : null;
    const [media_movil] = React.useState(window.matchMedia('(max-width: 576px)').matches);
    const [showSugestions, setShowSugestions] = React.useState(data != null ? data.showSugestions : true);
    const [guest, setGuest] = React.useState(data != null ? data.guest : null);
    const [userList, setUserList] = React.useState([]);
    const [showChat, setShowChat] = React.useState(typeof android !== "undefined" ? false : !media_movil);
    const [criterio, setCriterio] = React.useState("");
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const { setTitulo } = useTitle();


    const ListaVacia = () => {
        return (
            <div className="sugerencia">
                <p className="nota">No hay sugerencias de chat</p>
            </div>
        );
    }

    const getUserList = async () => {
        let _data = await api.fetchJson({
            url: "/chats",
            data: {
                task: "mischats",
                mi_id: window.atob(data_user.auth),
            }
        });

        if (_data.response == 1) {
            let _list = _data.data.filter(x => x.id_user != window.atob(data_user.auth));

            setUserList(_list);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const searchChater = async (_val) => {
        setCriterio(_val);

        if (_val.length > 0) {

            let _data = await api.fetchJson({
                url: "/chats",
                data: {
                    task: "chaters",
                    mi_id: window.atob(data_user.auth),
                    criterio: _val
                }
            });

            if (_data.response == 1) {
                let _list = _data.data.filter(x => x.id_user != window.atob(data_user.auth));

                setUserList(_list);
            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => history.push("/"));
            } else {
                Alert(_data.msg, "warning");
            }
        } else {
            getUserList();
        }
    }

    const handleOpen = (_guest) => {
        setGuest(_guest);

        if (typeof android !== "undefined" || media_movil) {
            if (!showChat) {
                history.push({
                    pathname: "/chat",
                    state: {
                        data: {
                            guest: _guest,
                            showSugestions: false
                        }
                    }
                });
            }
        }
    }

    const handleViews = () => {
        if (data != null && data.fromOutside) {
            setGuest(data.guest);
            console.log("data", data);
        }

        if (typeof android !== "undefined" || media_movil) {
            log.i("es movil");

            if (data != null) {
                log.d("mostrando chat");
                setShowSugestions(data.showSugestions);
                setShowChat(true);
            } else {
                log.d("mostrando sugerencias");
                setShowSugestions(true);
                setShowChat(false);
                getUserList();
            }
        } else {
            log.i("no es movil");
            setShowSugestions(true);
            setShowChat(true);
            getUserList();
        }
    }

    React.useEffect(() => {
        if (data_user.nivel == 1 ||
            data_user.nivel == 2 ||
            data_user.nivel == 3 ||
            data_user.nivel == 4 ||
            data_user.nivel == 6) {
            setTitulo("Chat");

            if (typeof android !== "undefined" || media_movil) {

                return history.listen(location => {
                    if (history.action === 'POP') {
                        log.w("history", location.pathname);

                        if (location.pathname === "/chat") {
                            // handleViews();
                        }
                    }
                });
            }
        } else {
            history.push("/home");
        }
    }, []);

    React.useEffect(() => {
        handleViews();
    }, [data]);

    return (
        <div className="cont_chat">

            {showSugestions && <div className="chat_lateral" style={{ width: media_movil ? "100%" : 300 }}>

                <div className="buscar_chat">
                    <SearchChat type="text"
                        name="Buscar"
                        placeholder="Buscar"
                        value={criterio}
                        onChange={searchChater} />
                </div>
                {userList.length > 0 ?
                    <SugerenciasChat userList={userList} openChat={handleOpen} />
                    :
                    <ListaVacia />
                }
            </div>}

            {showChat && <VistaChat guest={guest} />}
        </div>
    );
}

export default Chat;