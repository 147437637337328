import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Route, Switch } from "react-router-dom";
import { TitleProvider } from "./Contexts";
import "rc-time-picker/assets/index.css";
import Layout from "../view/Layout";
import classNames from "classnames";

import Home from "../view/Home";

import LogIn from "../view/LogIn";
import SignUp from "../view/SignUp";
import ForgotPassword from "../view/ForgotPassword";

import MyPickUps from "../view/MyPickUps";
import MyNewPickUp from "../view/MyPickUps/components/NewPickUp";
import EditMyPickUp from "../view/MyPickUps/components/EditPickUp";

import PickUpList from "../view/PickUp";
import NewPickUp from "../view/PickUp/components/NewPickUp";
import EditPickUp from "../view/PickUp/components/EditPickUp";
import PickUpOrder from "../view/PickUp/components/PickUpOrder";

import ReceptionList from "../view/Reception";
import NewReception from "../view/Reception/components/NewReception";
import EditReception from "../view/Reception/components/EditReception";
import Reception from "../view/Reception/components/ReceptionOrder";

import CommodityList from "../view/CommodityList";
import MisPaquetes from "../view/CommodityList/MisPaquetes";
import TodosMisPaquetes from "../view/CommodityList/TodosMisPaquetes";
import Detalle from "../view/CommodityList/components/Detalle";
import EditPackage from "../view/Reception/components/EditPackage";

import Mensajeros from "../view/Mensajeros";
import Usuarios from "../view/Usuarios";
import Clientes from "../view/Clientes";

import Chat from "../view/Chat";
import VistaChat from "../view/Chat/components/VistaChat";

import Reports from "../view/Reports";
import ReportAdmin from "../view/Reports/ReportAdmin";
import ReportHistory from "../view/Reports/ReportHistory";
import ReportRecepcion from "../view/Reports/ReportRecepcion";

import CodeGenerator from "../view/CodeGenerator";

import CajaMensajeros from "../view/CajaMensajeros";
import CajaClientes from "../view/CajaClientes";
import CajaBts from "../view/CajaBts";


const Pages = () => (
  <Switch>
    <Route path="/home" component={Home} />

    <Route path="/caja-mensajeros" component={CajaMensajeros} />
    <Route path="/caja-clientes" component={CajaClientes} />
    <Route path="/caja-bts" component={CajaBts} />

    <Route path="/mis-ordenes" component={MyPickUps} />
    <Route path="/mis-paquetes" component={MisPaquetes} />
    <Route path="/todos-mis-paquetes" component={TodosMisPaquetes} />

    <Route path="/ordenes-de-recogida" component={PickUpList} />
    <Route path="/ordenes-de-recepcion" component={ReceptionList} />

    <Route exact path="/orden-de-recogida" component={PickUpOrder} />
    <Route path="/orden-de-recogida/:codigo" component={PickUpOrder} />

    <Route exact path="/orden-de-servicio" component={Reception} />
    <Route path="/orden-de-servicio/:codigo" component={Reception} />

    <Route path="/todos-los-paquetes" component={CommodityList} />
    <Route exact path="/detalle-paquete" component={Detalle} />
    <Route path="/detalle-paquete/:codebar" component={Detalle} />

    <Route path="/administradores" component={Usuarios} />
    <Route path="/coordinadores" component={Usuarios} />
    <Route path="/mensajeros" component={Mensajeros} />
    <Route path="/recepcionistas" component={Usuarios} />
    <Route path="/cajeros" component={Usuarios} />
    <Route path="/clientes" component={Clientes} />

    <Route path="/reportes" component={Reports} />
    <Route path="/reportes-admin" component={ReportAdmin} />
    <Route path="/report-history" component={ReportHistory} />
    <Route path="/reportes-mensajeros" component={ReportRecepcion} />

    <Route path="/chat" component={Chat} />
    <Route path="/chating" component={VistaChat} />

    <Route path="/nueva-orden" component={MyNewPickUp} />
    <Route path="/nueva-orden-de-recogida" component={NewPickUp} />
    <Route path="/nueva-orden-de-servicio" component={NewReception} />

    <Route path="/editar-mi-orden" component={EditMyPickUp} />
    <Route path="/editar-orden-de-recogida" component={EditPickUp} />
    <Route path="/editar-orden-de-servicio" component={EditReception} />
    <Route path="/editar-paquete" component={EditPackage} />

    <Route path="/generar-codigos" component={CodeGenerator} />
  </Switch>
);

const WrappedRoutes = (params) => {
  return (
    <div>
      <TitleProvider>
        <Layout {...params} />
        <div className="container__wrap">
          <Pages />
        </div>
      </TitleProvider>
    </div>
  );
}

const Router = () => {
  const [currentTheme, setCurrentTheme] = React.useState("theme-light");
  const themeClass = classNames(currentTheme);

  return (
    <div className={themeClass}>
      <div className="wrapper">
        <main id="main">
          <Switch>
            <Route exact path="/" render={props => <LogIn {...props} changeTheme={(_theme) => setCurrentTheme(_theme)} />} />
            <Route exact path="/sign-up" render={props => <SignUp {...props} changeTheme={(_theme) => setCurrentTheme(_theme)} />} />
            <Route path="/" render={props => <WrappedRoutes changeTheme={(_theme) => setCurrentTheme(_theme)} />} />
            <Route exact path="/forgot-password" render={props => <ForgotPassword {...props} />} />
          </Switch>
          <div id="modal"></div>
        </main>
      </div>
    </div>
  );
}

export default Router;
