import * as React from 'react';
import Information from 'mdi-react/InformationOutlineIcon';
import CloseIcon from 'mdi-react/CloseCircleOutlineIcon';
import { useHistory } from 'react-router-dom';
import EyeOffIcon from "mdi-react/EyeOffIcon";
import EyeIcon from "mdi-react/EyeIcon";
import { Alert } from "shared/Alert";
import { Input } from 'shared/Input';
import CryptoJS from "crypto-js";
import api from "services/api";
import funciones from 'services/funciones';

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const media_movil = window.matchMedia('(max-width: 500px)').matches;

const FormEdit = ({ position, toggle, item }) => {
  const [nombre, setNombre] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [phone2, setPhone2] = React.useState("");
  const [idtipe, setIdtipe] = React.useState("Cédula de Ciudadanía");
  const [identification, setIdentification] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPass] = React.useState("");
  const [city, setCity] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [error, setError] = React.useState("");
  const [zona, setZona] = React.useState(0);
  const [role, setRole] = React.useState(item.nivel);
  const [cityList, setCityList] = React.useState([]);
  const [showPass, setShowPass] = React.useState(false);

  const ref_name = React.useRef();
  const ref_lastname = React.useRef();
  const ref_identification = React.useRef();
  const ref_phone = React.useRef();
  const ref_email = React.useRef();
  const ref_address = React.useRef();
  const ref_password = React.useRef();

  const history = useHistory();

  const getCityList = async () => {
    let _data = await api.getUsers("city");

    if (_data.response == 1) {

      setCityList(_data.data.map(item => (item.city)));
    } else if (_data.response === -2) {
      Alert(_data.msg, "warning", () => history.push("/"));
    } else {
      setError(_data.msg);
    }
  }

  const saveData = async () => {
    setError("");
    let _pass = password.length > 1 ? CryptoJS.SHA256(password).toString() : null;
    let _info = {
      id: item.id_user,
      nombre: nombre,
      lastname: lastname,
      idtipe: idtipe,
      identification: identification,
      phone: phone,
      phone_family: phone2,
      email: email,
      city: city,
      address: address,
      password: _pass,
      reset_pass: password.length > 1 ? "reset" : "",
      rol: role,
      zona: zona
    };

    let _data = await api.saveAgent(_info);

    if (_data.response == 1) {
      //change(nombre + " " + lastname);
      toggle();
    } else if (_data.response === -2) {
      Alert(_data.msg, "warning", () => history.push("/"));
    } else {
      setError(_data.msg);
    }
  }

  const validateFields = (e) => {
    e.preventDefault();
    let _form = {
      name: ref_name.current,
      lastname: ref_lastname.current,
      identification: ref_identification.current,
      phone: ref_phone.current,
      email: ref_email.current,
      address: ref_address.current,
      password: ref_password.current,
    };


    if (role == "0") {
      setError("Debes asignar un rol");
      return;
    }

    if (nombre.length < 1) {
      setError("El nombre no puede estar vacío");
      _form.name.focus();
      return;
    }

    if (lastname.length < 1) {
      setError("El apellido no puede estar vacío");
      _form.lastname.focus();
      return;
    }

    if (identification.length < 1) {
      setError("El numero del documento no puede estar vacío");
      _form.identification.focus();
      return;
    }

    if (phone.length < 1) {
      setError("El teléfono no puede estar vacío");
      _form.phone.focus();
      return;
    }

    if (email.length < 1) {
      setError("El correo no puede estar vacío");
      _form.email.focus();
      return;
    }

    if (city.length < 1) {
      setError("La ciudad no puede estar vacía");
      return;
    }

    if (address.length < 1) {
      setError("La direccón no puede estar vacía");
      _form.address.focus();
      return;
    }

    if (!funciones.validaDireccion(address)) {
      setError("El formato de la direccón no es válido \nEjemplos: \nCra 94 # 69a - 42 \nCarrera 94a # 69 - 42 sur");
      _form.address.focus();
      return;
    }

    if (zona == 0) {
      setError("Debes seleccionar la zona designada al mensajero.");
      return;
    }

    saveData();
  }

  React.useEffect(() => {
    getCityList();
  }, []);

  React.useEffect(() => {
    setRole(item.nivel);

    setNombre(item.nombre || "");
    setLastname(item.apellido || "");
    setPhone(item.telefono || "");
    setPhone2(item.telefono_familia || "");
    setIdtipe(item.typo_doc || "");
    setIdentification(item.documento || "");
    setEmail(item.correo || "");
    setCity(item.city || "");
    setAddress(item.address || "");
    setZona(item.zona || 0);

  }, [item]);

  return (
    <>
      <div className="back_form" onClick={toggle}></div>
      <div className="new_agent" style={position}>
        <h3>Editar Mensajero<CloseIcon onClick={toggle} /></h3>

        <input type="email" name="fake_email" style={{ display: "none" }} />
        {media_movil ?
          <div className="_in_column">

            <label>Nombre</label>
            <input type="text" name="name" value={nombre} ref={ref_name}
              onChange={({ target: { value } }) => setNombre(value)} />

            <label>Apellidos</label>
            <input type="text" name="lastname" value={lastname} ref={ref_lastname}
              onChange={({ target: { value } }) => setLastname(value)} />


            <label>Tipo de identificación</label>
            <select name="role" value={idtipe} onChange={({ target: { value } }) => setIdtipe(value)}>
              <option>Cédula de Ciudadanía</option>
              <option>Cédula de extranjería</option>
              <option>Pasapote</option>
              <option>NIT</option>
            </select>

            <label>Número de identificación</label>
            <input type="text" name="identification" value={identification} maxLength="12" ref={ref_identification}
              onChange={({ target: { value } }) => setIdentification(value)} />


            <label>Teléfono</label>
            <input type="text" name="phone" value={phone} maxLength="15" ref={ref_phone}
              onChange={({ target: { value } }) => setPhone(value)} />


            <label>Correo</label>
            <input type="email" name="email" value={email} ref={ref_email}
              onChange={({ target: { value } }) => setEmail(value)} />

            <label>Contraseña</label>
            <div className="_toglle_pass">
              <input type={showPass ? "text" : "password"} name="password" value={password}
                onChange={({ target: { value } }) => setPass(value)} />
              {showPass ? <EyeOffIcon onClick={() => setShowPass(false)} /> : <EyeIcon onClick={() => setShowPass(true)} />}
            </div>


            <label>Ciudad</label>
            <Input type="text" name="city" value={city} list={cityList}
              onChange={setCity} />

            <label>Dirección</label>
            <textarea name="address" rows="2" value={address} placeholder="Ingresa Dirección" ref={ref_address}
              onChange={({ target: { value } }) => setAddress(value)} />

            <label>Zonificación</label>
            <select name="zona" value={zona} onChange={({ target: { value } }) => setZona(value)}>
              <option value="0">Sellecciona...</option>
              <option value="1">Norte</option>
              <option value="2">Sur</option>
              <option value="3">Oriente</option>
              <option value="4">Occidente</option>
              <option value="5">NorOriente</option>
              <option value="6">NorOccidente</option>
              <option value="7">SurOriente</option>
              <option value="8">SurOccidente</option>

            </select>

          </div>
          :
          <div className="_in_row">

            <div className="_in_column">

              <label>Nombre</label>
              <input type="text" name="name" value={nombre} ref={ref_name}
                onChange={({ target: { value } }) => setNombre(value)} />

              <label>Apellidos</label>
              <input type="text" name="lastname" value={lastname} ref={ref_lastname}
                onChange={({ target: { value } }) => setLastname(value)} />


              <label>Teléfono</label>
              <input type="text" name="phone" value={phone} maxLength="15" ref={ref_phone}
                onChange={({ target: { value } }) => setPhone(value)} />

              <label>Teléfono de contacto</label>
              <input type="text" name="phone2" value={phone2} maxLength="15"
                onChange={({ target: { value } }) => setPhone2(value)} />


              <label>Correo</label>
              <input type="email" name="email" value={email} ref={ref_email}
                onChange={({ target: { value } }) => setEmail(value)} />

              <label>Contraseña</label>
              <div className="_toglle_pass">
                <input type={showPass ? "text" : "password"} name="password" value={password}
                  onChange={({ target: { value } }) => setPass(value)} />
                {showPass ? <EyeOffIcon onClick={() => setShowPass(false)} /> : <EyeIcon onClick={() => setShowPass(true)} />}
              </div>


            </div>

            <div className="_in_column">

              <label>Tipo de identificación</label>
              <select name="role" value={idtipe} onChange={({ target: { value } }) => setIdtipe(value)}>
                <option>Cédula de Ciudadanía</option>
                <option>Cédula de extranjería</option>
                <option>Pasapote</option>
                <option>NIT</option>
              </select>

              <label>Número de identificación</label>
              <input type="text" name="identification" value={identification} maxLength="12" ref={ref_identification}
                onChange={({ target: { value } }) => setIdentification(value)} />

              <label>Ciudad</label>
              <Input type="text" name="city" value={city} list={cityList}
                onChange={setCity} />

              <label>Dirección</label>
              <textarea name="address" rows="2" value={address} placeholder="Ingresa Dirección" ref={ref_address}
                onChange={({ target: { value } }) => setAddress(value)} />


              <label>Zonificación</label>
              <select name="zona" value={zona} onChange={({ target: { value } }) => setZona(value)}>
                <option value="0">Sellecciona...</option>
                <option value="1">Norte</option>
                <option value="2">Sur</option>
                <option value="3">Oriente</option>
                <option value="4">Occidente</option>
                <option value="5">NorOriente</option>
                <option value="6">NorOccidente</option>
                <option value="7">SurOriente</option>
                <option value="8">SurOccidente</option>

              </select>
            </div>

          </div>
        }

        {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

        <div className="_in_row">
          <button className="btn btn-common width-static" onClick={validateFields}>Guardar</button>
          <button className="btn btn-common width-static" onClick={toggle}>Cancelar</button>
        </div>

      </div>
    </>
  );
}

const FormNew = ({ position, toggle, rol }) => {
  const [nombre, setNombre] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [phone2, setPhone2] = React.useState("");
  const [idtipe, setIdtipe] = React.useState("Cédula de Ciudadanía");
  const [identification, setIdentification] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPass] = React.useState("");
  const [city, setCity] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [error, setError] = React.useState("");
  const [zona, setZona] = React.useState(0);
  const [role, setRole] = React.useState(rol);
  const [cityList, setCityList] = React.useState([]);
  const [showPass, setShowPass] = React.useState(false);

  const ref_name = React.useRef();
  const ref_lastname = React.useRef();
  const ref_identification = React.useRef();
  const ref_phone = React.useRef();
  const ref_email = React.useRef();
  const ref_address = React.useRef();
  const ref_password = React.useRef();

  const history = useHistory();

  const getCityList = async () => {
    let _data = await api.getUsers("city");

    if (_data.response == 1) {

      setCityList(_data.data.map(item => (item.city)));
    } else if (_data.response === -2) {
      Alert(_data.msg, "warning", () => history.push("/"));
    } else {
      setError(_data.msg);
    }
  }

  const saveData = async () => {
    setError("");
    let _pass = password.length > 1 ? CryptoJS.SHA256(password).toString() : null;
    let _info = {
      id: 0,
      nombre: nombre,
      lastname: lastname,
      idtipe: idtipe,
      identification: identification,
      phone: phone,
      phone_family: phone2,
      email: email,
      city: city,
      address: address,
      password: _pass,
      rol: role,
      zona: zona
    };

    let _data = await api.saveAgent(_info);

    if (_data.response == 1) {
      toggle();
    } else if (_data.response === -2) {
      Alert(_data.msg, "warning", () => history.push("/"));
    } else {
      setError(_data.msg);
    }
  }

  const validateFields = (e) => {
    e.preventDefault();
    let _form = {
      name: ref_name.current,
      lastname: ref_lastname.current,
      identification: ref_identification.current,
      phone: ref_phone.current,
      email: ref_email.current,
      address: ref_address.current,
      password: ref_password.current,
    };


    if (role == "0") {
      setError("Debes asignar un rol");
      return;
    }

    if (nombre.length < 1) {
      setError("El nombre no puede estar vacío");
      _form.name.focus();
      return;
    }

    if (lastname.length < 1) {
      setError("El apellido no puede estar vacío");
      _form.lastname.focus();
      return;
    }

    if (identification.length < 1) {
      setError("El numero del documento no puede estar vacío");
      _form.identification.focus();
      return;
    }

    if (phone.length < 1) {
      setError("El teléfono no puede estar vacío");
      _form.phone.focus();
      return;
    }

    if (email.length < 1) {
      setError("El correo no puede estar vacío");
      _form.email.focus();
      return;
    }

    if (city.length < 1) {
      setError("La ciudad no puede estar vacía");
      return;
    }

    if (address.length < 1) {
      setError("La direccón no puede estar vacía");
      _form.address.focus();
      return;
    }

    if (!funciones.validaDireccion(address)) {
      setError("El formato de la direccón no es válido \nEjemplos: \nCra 94 # 69a - 42 \nCarrera 94a # 69 - 42 sur");
      _form.address.focus();
      return;
    }

    if (password.length < 1) {
      setError("Debes ingresar una clave para el mensajero");
      _form.password.focus();
      return;
    }

    if (zona == 0) {
      setError("Debes seleccionar la zona designada al mensajero.");
      return;
    }

    saveData();
  }

  React.useEffect(() => {
    getCityList();
  }, []);

  React.useEffect(() => {
    setRole(rol);
  }, [rol]);

  return (
    <>
      <div className="back_form" onClick={toggle}></div>
      <div className="new_agent" style={position}>
        <h3>Nuevo Mensajero <CloseIcon onClick={toggle} /></h3>

        <input type="email" name="fake_email" style={{ display: "none" }} />
        {media_movil ?
          <div className="_in_column">

            <label>Nombre</label>
            <input type="text" name="name" value={nombre} ref={ref_name}
              onChange={({ target: { value } }) => setNombre(value)} />

            <label>Apellidos</label>
            <input type="text" name="lastname" value={lastname} ref={ref_lastname}
              onChange={({ target: { value } }) => setLastname(value)} />


            <label>Tipo de identificación</label>
            <select name="role" value={idtipe} onChange={({ target: { value } }) => setIdtipe(value)}>
              <option>Cédula de Ciudadanía</option>
              <option>Cédula de extranjería</option>
              <option>Pasapote</option>
              <option>NIT</option>
            </select>

            <label>Número de identificación</label>
            <input type="text" name="identification" value={identification} maxLength="12" ref={ref_identification}
              onChange={({ target: { value } }) => setIdentification(value)} />


            <label>Teléfono</label>
            <input type="text" name="phone" value={phone} maxLength="15" ref={ref_phone}
              onChange={({ target: { value } }) => setPhone(value)} />


            <label>Correo</label>
            <input type="email" name="email" value={email} ref={ref_email}
              onChange={({ target: { value } }) => setEmail(value)} />

            <label>Contraseña</label>
            <div className="_toglle_pass">
              <input type={showPass ? "text" : "password"} name="password" value={password} ref={ref_password}
                onChange={({ target: { value } }) => setPass(value)} />
              {showPass ? <EyeOffIcon onClick={() => setShowPass(false)} /> : <EyeIcon onClick={() => setShowPass(true)} />}
            </div>


            <label>Ciudad</label>
            <Input type="text" name="city" value={city} list={cityList}
              onChange={setCity} />

            <label>Dirección</label>
            <textarea name="address" rows="2" value={address} placeholder="Ingresa Dirección" ref={ref_address}
              onChange={({ target: { value } }) => setAddress(value)} />

            <label>Zonificación</label>
            <select name="zona" value={zona} onChange={({ target: { value } }) => setZona(value)}>
              <option value="0">Sellecciona...</option>
              <option value="1">Norte</option>
              <option value="2">Sur</option>
              <option value="3">Oriente</option>
              <option value="4">Occidente</option>
              <option value="5">NorOriente</option>
              <option value="6">NorOccidente</option>
              <option value="7">SurOriente</option>
              <option value="8">SurOccidente</option>

            </select>

          </div>
          :
          <div className="_in_row">

            <div className="_in_column">

              <label>Nombre</label>
              <input type="text" name="name" value={nombre} ref={ref_name}
                onChange={({ target: { value } }) => setNombre(value)} />

              <label>Apellidos</label>
              <input type="text" name="lastname" value={lastname} ref={ref_lastname}
                onChange={({ target: { value } }) => setLastname(value)} />


              <label>Teléfono</label>
              <input type="text" name="phone" value={phone} maxLength="15" ref={ref_phone}
                onChange={({ target: { value } }) => setPhone(value)} />

              <label>Teléfono de contacto</label>
              <input type="text" name="phone2" value={phone2} maxLength="15"
                onChange={({ target: { value } }) => setPhone2(value)} />


              <label>Correo</label>
              <input type="email" name="email" value={email} ref={ref_email}
                onChange={({ target: { value } }) => setEmail(value)} />

              <label>Contraseña</label>
              <div className="_toglle_pass">
                <input type={showPass ? "text" : "password"} name="password" value={password} ref={ref_password}
                  onChange={({ target: { value } }) => setPass(value)} />
                {showPass ? <EyeOffIcon onClick={() => setShowPass(false)} /> : <EyeIcon onClick={() => setShowPass(true)} />}
              </div>


            </div>

            <div className="_in_column">

              <label>Tipo de identificación</label>
              <select name="role" value={idtipe} onChange={({ target: { value } }) => setIdtipe(value)}>
                <option>Cédula de Ciudadanía</option>
                <option>Cédula de extranjería</option>
                <option>Pasapote</option>
                <option>NIT</option>
              </select>

              <label>Número de identificación</label>
              <input type="text" name="identification" value={identification} maxLength="12" ref={ref_identification}
                onChange={({ target: { value } }) => setIdentification(value)} />

              <label>Ciudad</label>
              <Input type="text" name="city" value={city} list={cityList}
                onChange={setCity} />

              <label>Dirección</label>
              <textarea name="address" rows="2" value={address} placeholder="Ingresa Dirección" ref={ref_address}
                onChange={({ target: { value } }) => setAddress(value)} />


              <label>Zonificación</label>
              <select name="zona" value={zona} onChange={({ target: { value } }) => setZona(value)}>
                <option value="0">Sellecciona...</option>
                <option value="1">Norte</option>
                <option value="2">Sur</option>
                <option value="3">Oriente</option>
                <option value="4">Occidente</option>
                <option value="5">NorOriente</option>
                <option value="6">NorOccidente</option>
                <option value="7">SurOriente</option>
                <option value="8">SurOccidente</option>

              </select>
            </div>

          </div>
        }

        {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

        <div className="_in_row">
          <button className="btn btn-common width-static" onClick={validateFields}>Guardar</button>
          <button className="btn btn-common width-static" onClick={toggle}>Cancelar</button>
        </div>

      </div>
    </>
  );
}

/**
 * 1 - Super Administrador
 * 2 - Administrador
 * 3 - Coordinador de zona
 * 4 - Mensajero
 * 5 - Recepcionista
 * 6 - Cliente
 */
const EditAgent = ({ data, pos, close }) => {

  return (
    <FormEdit
      position={pos}
      toggle={() => close(false)}
      item={data} />
  );
}

/**
 * 1 - Super Administrador
 * 2 - Administrador
 * 3 - Coordinador de zona
 * 4 - Mensajero
 * 5 - Recepcionista
 * 6 - Cliente
 */
const NewAgent = ({ data, pos, close }) => {

  return (
    <FormNew
      position={pos}
      toggle={() => close(false)}
      rol={4} />
  );
}


export { NewAgent, EditAgent };
