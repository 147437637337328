import * as React from "react";
import ReactDOM from "react-dom";
import InfoCircleIcon from "mdi-react/InformationOutlineIcon";
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import InfoIcon from "mdi-react/InformationVariantIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import OkIcon from "mdi-react/CheckBoldIcon";
import AlerIcon from "mdi-react/WarningIcon";

/* eslint jsx-a11y/anchor-is-valid: 0 */

const SearchComponent = ({ action }) => {
  const [codigo, setCodigo] = React.useState("");
  const [error, setError] = React.useState("");

  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById("modal"));
  }

  const onDismiss = (e) => {
    e.preventDefault();
    setError("");

    if (codigo.length < 1) {
      setError("¡No hay contenido para buscar!");
    } else {
      action(codigo);
      ReactDOM.unmountComponentAtNode(document.getElementById("modal"));
    }
  }

  return (
    <div className="alert__content search_flotante">
      <h4><CloseIcon onClick={handleClose} />Ingresa el código a buscar</h4>

      <div className="cont_search">
        <input type="text" placeholder="Buscar código" value={codigo}
          onChange={e => { setError(""); setCodigo(e.target.value); }} />
        <MagnifyIcon />
      </div>

      {error.length > 1 && <i className="_error">{error}</i>}
      <a className="close" onClick={onDismiss}>Buscar</a>
    </div>
  );
}

const AlertComponent = ({ msg, icono, action }) => {
  let Icon;

  const onDismiss = () => {
    action();
    document.body.style.overflow = "auto";
    ReactDOM.unmountComponentAtNode(document.getElementById("modal"));
  }

  switch (icono) {
    case "alert":
    case "warning":
      Icon = <AlerIcon />;
      break;
    case "ok":
      Icon = <OkIcon />;
      break;
    case "infoc":
      Icon = <InfoCircleIcon />;
      break;
    case "info":
    default:
      Icon = <InfoIcon />;
      break;
  }

  React.useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  return (
    <div className="alert__content">
      <div className="alert__icon">{Icon}</div>
      <div className="to_scroll">
        <p>{msg}</p>
      </div>
      <a className="close" onClick={onDismiss}>Aceptar</a>
    </div>
  );
}

const ConfirmComponent = ({ msg, action }) => {
  let Icon = <AlerIcon />;

  const onDismiss = (e, _response) => {
    e.preventDefault();
    e.stopPropagation();

    action(_response);
    ReactDOM.unmountComponentAtNode(document.getElementById("modal"));
  }

  return (
    <div className="alert__content">
      <div className="alert__icon">{Icon}</div>
      <p>{msg}</p>
      <div className="in_row">
        <a className="close" onClick={e => onDismiss(e, true)}>Aceptar</a>
        <a className="close" onClick={e => onDismiss(e, false)}>Cancelar</a>
      </div>
    </div>
  );
}

const ResumenComponent = ({ titulo, component, action }) => {

  const onDismiss = () => {
    action();
    document.body.style.overflow = "auto";
    ReactDOM.unmountComponentAtNode(document.getElementById("modal"));
  }

  React.useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  return (
    <div className="alert__content _resumen">
      <h4>{titulo}</h4>
      <div className="to_scroll">
        {component}
      </div>
      <a className="close" onClick={onDismiss}>Aceptar</a>
    </div>
  );
}

const ResumenEntrega = (_titulo, _component, action = () => console.log("No action defined")) => {
  let _modal = document.getElementById("modal");

  ReactDOM.render(
    <div className="alert">
      <ResumenComponent titulo={_titulo} component={_component} action={action} />
    </div>,
    _modal,
  );
}

const Alert = (_msg, _icono = "info", action = () => console.log("No action defined")) => {
  let _modal = document.getElementById("modal");

  ReactDOM.render(
    <div className="alert">
      <AlertComponent msg={_msg} icono={_icono} action={action} />
    </div>,
    _modal,
  );
}

const Search = (action = () => console.log("No action defined")) => {
  let _modal = document.getElementById("modal");

  ReactDOM.render(
    <div className="alert">
      <SearchComponent action={action} />
    </div>,
    _modal,
  );
}

const Confirm = (_msg, action = () => console.log("No action defined")) => {
  let _modal = document.getElementById("modal");

  ReactDOM.render(
    <div className="alert">
      <ConfirmComponent msg={_msg} action={action} />
    </div>,
    _modal,
  );
}

const alertArea = (_current_node, _msg) => {
  let _new_node = document.createElement("span");
  let _add_alert = true;

  if (_current_node.classList.contains("alert__resalta_input")) {
    _add_alert = false;
  }

  if (_add_alert) {
    _current_node.classList.add("alert__resalta_input");
    _new_node.className = "alert__input _up _area";
    _new_node.innerText = _msg;

    _current_node.insertAdjacentElement("afterend", _new_node);
  }
}

const alerta = (_current_node, _msg) => {
  let _new_node = document.createElement("span");
  let _add_alert = true;

  if (_current_node.classList.contains("alert__resalta_input")) {
    _add_alert = false;
  }

  if (_add_alert) {
    _current_node.classList.add("alert__resalta_input");
    _new_node.className = "alert__input _up";
    _new_node.innerText = _msg;

    _current_node.insertAdjacentElement("afterend", _new_node);
  }
}

const alertDown = (_current_node, _msg) => {
  let _new_node = document.createElement("span");
  let _add_alert = true;

  if (_current_node.classList.contains("alert__resalta_input")) {
    _add_alert = false;
  }

  if (_add_alert) {
    _current_node.classList.add("alert__resalta_input");
    _new_node.className = "alert__input _down";
    _new_node.innerText = _msg;

    _current_node.insertAdjacentElement("afterend", _new_node);
  }
}

const alertaRemove = (_current_node) => {
  let _alerta = _current_node == null ? null : _current_node.nextSibling;

  if (_alerta != null) {
    _current_node.classList.remove("alert__resalta_input");

    if (_alerta.className === "alert__input _up"
      || _alerta.className === "alert__input _down"
      || _alerta.className === "alert__input _up _area") {
      _alerta.parentNode.removeChild(_alerta);
    }
  }
}

export { Alert, Confirm, Search, alerta, alertArea, alertDown, alertaRemove, ResumenEntrega };
