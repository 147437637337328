import * as React from "react";
import CloseIcon from "mdi-react/CloseCircleIcon";
import EditIcon from "mdi-react/EditOutlineIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import funciones from "services/funciones";
import PlusIcon from "mdi-react/PlusIcon";
import EditAgent from "./EditAgent";
import NewAgent from "./NewAgent";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Suggesting = React.forwardRef((params, ref) => {
    const { list, onSelect, currentIndex, onTop = { height: 28, top: false } } = params;
    let _styles = onTop.top ? { bottom: onTop.height } : { top: onTop.height };
    const [options, setOptions] = React.useState([]);


    React.useEffect(() => {
        let _options = [];

        list.map((item, index) => {
            _options.push(
                <li key={index}
                    className={currentIndex == index ? "_selected" : ""}
                    onClick={() => onSelect({ ...item, index: index })}>
                    <a>{item.text}</a>
                </li>
            );
        });

        if (_options.length < 1) {
            _options.push(<li key={0}><a>No hay resultados.</a></li>);
        }

        setOptions(_options);

    }, [list, currentIndex]);

    return (
        <ul id="float_list" ref={ref}
            className={onTop.top ? "_float_list _on_top" : "_float_list"}
            style={_styles}>
            {options}
        </ul>
    );
});

export const Input = (params) => {
    const {
        onChange,
        name,
        type = "text",
        list = false,
        value,
        placeholder = ""
    } = params;
    const [suggestionIndex, setSuggestionIndex] = React.useState(-1);
    const [onTop, setOnTop] = React.useState({ height: 28, top: false });
    const [show, setShow] = React.useState(false);
    const [isFirst, setIsFirst] = React.useState(true);
    const [lista, setLista] = React.useState([]);
    const listRef = React.useRef();

    const buildUserList = () => {
        let _list = [];

        list.map(item => {
            _list.push({ text: item });
        });

        setLista(_list);
        setIsFirst(false);
    }

    const handlerCriteio = (_crit) => {
        onChange(_crit);

        if (list !== false) {
            let _list = [];

            if (!show) {
                setShow(true);
            }

            if (_crit.length > 0) {

                // if (_crit.length < value.length) {
                setSuggestionIndex(0);
                list.map(item => {
                    let _new_item = { text: item };
                    if (_new_item.text.toLowerCase().includes(_crit.toLowerCase())) {
                        _list.push(_new_item);
                    }
                });
                // } else {
                //     _list = lista.filter(item => {
                //         return item.text.toLowerCase().includes(_crit.toLowerCase());
                //     });
                // }

            } else {
                list.map(item => {
                    _list.push({ text: item });
                });
            }

            setLista(_list);
        }
    }

    const handleSelect = (data) => {

        setSuggestionIndex(data.index);
        setShow(false);
        onChange(data.text);
    }

    const handlerFocus = (e) => {
        let _height = window.innerHeight;
        let _height_input = e.target.getBoundingClientRect().height;
        let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;

        setOnTop({
            height: _height_input,
            top: _to_border_bottom < 150
        });

        setSuggestionIndex(0);

        if (lista.length > 0) {
            setShow(true);
        } else {
            setShow(false);
        }
    }

    const handlerBlur = (e) => {
        setTimeout(() => {
            setShow(false);
        }, 200);
    }

    const handleKey = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (listRef.current) {
            let _caja = listRef.current.offsetHeight;
            let _scroll = listRef.current.scrollHeight;
            let _length = lista.length;
            let _trozo = _scroll / _length;

            if (e.key === "Enter") {
                setShow(false);
                onChange(lista[suggestionIndex].text);
            }

            if (e.key === "ArrowUp") {
                setSuggestionIndex(val => {
                    let _unit = val < 1 ? (_length - 1) : val - 1;
                    let _uni_height = _trozo * _unit;

                    if (_scroll > _caja) {
                        let _desplaza = _uni_height > 0 ? _uni_height : 0;

                        listRef.current.scrollTo({
                            top: _desplaza,
                            left: 0
                        });
                    }

                    return _unit;
                });
            }

            if (e.key === "ArrowDown") {
                setSuggestionIndex(val => {
                    let _unit = val < (_length - 1) ? val + 1 : 0;
                    let _uni_height = _trozo * _unit;

                    if (_scroll > _caja) {

                        listRef.current.scrollTo({
                            top: _uni_height,
                            left: 0
                        });
                    }

                    return _unit;
                });
            }
        }
    }

    React.useEffect(() => {
        if (list !== false && isFirst) {
            buildUserList();
        }
    }, [list]);

    return (
        <div className="input" onBlur={handlerBlur}>
            <input
                name={name}
                type={type}
                value={value}
                autoComplete="off"
                placeholder={placeholder}
                onFocus={handlerFocus}
                onKeyUp={handleKey}
                onChange={({ target: { value } }) => handlerCriteio(value)} />
            {value.length > 0 && <CloseIcon onClick={() => handlerCriteio("")} />}

            {(list !== false && show) && <Suggesting
                list={lista}
                ref={listRef}
                onTop={onTop}
                currentIndex={suggestionIndex}
                onSelect={handleSelect} />}
        </div>
    );
}

export const SearchInReport = (params) => {
    const {
        onChange,
        name,
        type = "text",
        list = false,
        value,
        placeholder = ""
    } = params;
    const [suggestionIndex, setSuggestionIndex] = React.useState(-1);
    const [onTop, setOnTop] = React.useState({ height: 28, top: false });
    const [show, setShow] = React.useState(false);
    const [isFirst, setIsFirst] = React.useState(true);
    const [lista, setLista] = React.useState([]);
    const listRef = React.useRef();


    const buildUserList = () => {
        let _list = [];

        list.map(item => {
            _list.push({ text: item });
        });

        setLista(_list);
        setIsFirst(false);
    }

    const handlerCriteio = (_crit) => {
        onChange(_crit);

        if (list !== false) {
            let _list = [];

            if (!show) {
                setShow(true);
            }

            if (_crit.length > 0) {

                // if (_crit.length < value.length) {
                setSuggestionIndex(0);
                list.map(item => {
                    let _new_item = { text: item };
                    if (_new_item.text.toLowerCase().includes(_crit.toLowerCase())) {
                        _list.push(_new_item);
                    }
                });
                // } else {
                //     _list = lista.filter(item => {
                //         return item.text.toLowerCase().includes(_crit.toLowerCase());
                //     });
                // }

            } else {
                list.map(item => {
                    _list.push({ text: item });
                });
            }

            setLista(_list);
        }
    }

    const handleSelect = (data) => {

        setSuggestionIndex(data.index);
        setShow(false);
        onChange(data.text);
    }

    const handlerFocus = (e) => {
        let _height = window.innerHeight;
        let _height_input = e.target.getBoundingClientRect().height;
        let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;

        setOnTop({
            height: _height_input,
            top: _to_border_bottom < 150
        });

        setSuggestionIndex(0);

        if (lista.length > 0) {
            setShow(true);
        } else {
            setShow(false);
        }
    }

    const handlerBlur = (e) => {
        setTimeout(() => {
            setShow(false);
        }, 200);
    }

    const handleKey = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (listRef.current) {
            let _caja = listRef.current.offsetHeight;
            let _scroll = listRef.current.scrollHeight;
            let _length = lista.length;
            let _trozo = _scroll / _length;

            if (e.key === "Enter") {
                setShow(false);
                onChange(lista[suggestionIndex].text);
            }

            if (e.key === "ArrowUp") {

                setSuggestionIndex(val => {
                    let _unit = val < 1 ? (_length - 1) : val - 1;
                    let _uni_height = _trozo * _unit;

                    if (_scroll > _caja) {
                        let _desplaza = _uni_height > 0 ? _uni_height : 0;

                        listRef.current.scrollTo({
                            top: _desplaza,
                            left: 0
                        });
                    }

                    return _unit;
                });
            }

            if (e.key === "ArrowDown") {

                setSuggestionIndex(val => {
                    let _unit = val < (_length - 1) ? val + 1 : 0;
                    let _uni_height = _trozo * _unit;

                    if (_scroll > _caja) {

                        listRef.current.scrollTo({
                            top: _uni_height,
                            left: 0
                        });
                    }

                    return _unit;
                });
            }
        }
    }

    React.useEffect(() => {
        if (list !== false && isFirst) {
            buildUserList();
        }
    }, [list]);

    return (
        <div className="search_bar" onBlur={handlerBlur}>
            <input
                name={name}
                type={type}
                value={value}
                autoComplete="off"
                placeholder={placeholder}
                onFocus={handlerFocus}
                onKeyUp={handleKey}
                onChange={({ target: { value } }) => handlerCriteio(value)} />
            {value.length > 0 ? <CloseIcon className="icon_close" onClick={() => handlerCriteio("")} /> : <MagnifyIcon />}

            {(list !== false && show) && <Suggesting
                list={lista}
                ref={listRef}
                onTop={onTop}
                currentIndex={suggestionIndex}
                onSelect={handleSelect} />}
        </div>
    );
}

export const SearchChat = (params) => {
    const {
        type = "text",
        name,
        value,
        placeholder = "",
        onChange
    } = params;

    return (
        <div className="input">
            <input
                type={type}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={({ target: { value } }) => onChange(value)} />
            {value.length > 0 ? <CloseIcon className="icon-padding" onClick={() => onChange("")} /> : <MagnifyIcon />}
        </div>
    );
}

export const SearchUsers = (params) => {
    const {
        onChange,
        name,
        type = "text",
        value,
        placeholder = ""
    } = params;
    const [cliterio, setCriterio] = React.useState(value || "");

    const handleKey = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.key === "Enter") {
            onChange(e.target.value);
        }
    }

    return (
        <div className="input">
            <input
                name={name}
                type={type}
                value={cliterio}
                placeholder={placeholder}
                onKeyUp={handleKey}
                onChange={e => setCriterio(e.target.value)} />
            {cliterio.length > 0 ? <CloseIcon onClick={() => setCriterio("")} /> : <MagnifyIcon />}
        </div>
    );
}

export const SearchUsersInList = (params) => {
    const {
        onChange,
        name,
        type = "text",
        list = false,
        value,
        placeholder = ""
    } = params;
    const [suggestionIndex, setSuggestionIndex] = React.useState(-1);
    const [onTop, setOnTop] = React.useState({ height: 28, top: false });
    const [show, setShow] = React.useState(false);
    const [isFirst, setIsFirst] = React.useState(true);
    const [lista, setLista] = React.useState([]);
    const listRef = React.useRef();

    const buildUserList = () => {
        let _list = [];

        list.map(item => {
            _list.push({ text: item });
        });

        setLista(_list);
        setIsFirst(false);
    }

    const handlerCriteio = (_crit) => {
        onChange(_crit);

        if (list !== false) {
            let _list = [];

            if (!show) {
                setShow(true);
            }

            if (_crit.length > 0) {

                // if (_crit.length < value.length) {
                setSuggestionIndex(0);
                list.map(item => {
                    let _new_item = { text: item };
                    if (_new_item.text.toLowerCase().includes(_crit.toLowerCase())) {
                        _list.push(_new_item);
                    }
                });
                // } else {
                //     _list = lista.filter(item => {
                //         return item.text.toLowerCase().includes(_crit.toLowerCase());
                //     });
                // }

            } else {
                list.map(item => {
                    _list.push({ text: item });
                });
            }

            setLista(_list);
        }
    }

    const handleSelect = (data) => {

        setSuggestionIndex(data.index);
        setShow(false);
        onChange(data.text);
    }

    const handlerFocus = (e) => {
        let _height = window.innerHeight;
        let _height_input = e.target.getBoundingClientRect().height;
        let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;

        setOnTop({
            height: _height_input,
            top: _to_border_bottom < 150
        });

        setSuggestionIndex(0);

        if (lista.length > 0) {
            setShow(true);
        } else {
            setShow(false);
        }
    }

    const handlerBlur = (e) => {
        setTimeout(() => {
            setShow(false);
        }, 200);
    }

    const handleKey = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (listRef.current) {
            let _caja = listRef.current.offsetHeight;
            let _scroll = listRef.current.scrollHeight;
            let _length = lista.length;
            let _trozo = _scroll / _length;

            if (e.key === "Enter") {
                setShow(false);
                onChange(lista[suggestionIndex].text);
            }

            if (e.key === "ArrowUp") {
                setSuggestionIndex(val => {
                    let _unit = val < 1 ? (_length - 1) : val - 1;
                    let _uni_height = _trozo * _unit;

                    if (_scroll > _caja) {
                        let _desplaza = _uni_height > 0 ? _uni_height : 0;

                        listRef.current.scrollTo({
                            top: _desplaza,
                            left: 0
                        });
                    }

                    return _unit;
                });
            }

            if (e.key === "ArrowDown") {
                setSuggestionIndex(val => {
                    let _unit = val < (_length - 1) ? val + 1 : 0;
                    let _uni_height = _trozo * _unit;

                    if (_scroll > _caja) {

                        listRef.current.scrollTo({
                            top: _uni_height,
                            left: 0
                        });
                    }

                    return _unit;
                });
            }
        }
    }

    React.useEffect(() => {
        if (list !== false && isFirst) {
            buildUserList();
        }
    }, [list]);

    return (
        <div className="input" onBlur={handlerBlur}>
            <input
                name={name}
                type={type}
                value={value}
                autoComplete="off"
                placeholder={placeholder}
                onFocus={handlerFocus}
                onKeyUp={handleKey}
                onChange={({ target: { value } }) => handlerCriteio(value)} />
            {value.length > 0 ? <CloseIcon onClick={() => handlerCriteio("")} /> : <MagnifyIcon />}

            {(list !== false && show) && <Suggesting
                list={lista}
                ref={listRef}
                onTop={onTop}
                currentIndex={suggestionIndex}
                onSelect={handleSelect} />}
        </div>
    );
}

export const InputNum = (params) => {
    const {
        name,
        type = "tel",
        list = "",
        value,
        placeholder = "",
        onChange
    } = params;

    const handleChange = (_obj) => {
        // console.log("num: ", _obj);
        // console.log("val: " + _obj.format, !isNaN(_obj.int));
        if (!isNaN(_obj.int)) {
            onChange(_obj.format);
        }
    }

    return (
        <div className="input">
            <input
                type={type}
                list={list}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={({ target: { value } }) => handleChange(funciones.formatPrice(value))} />
            {value.length >= 1 && <CloseIcon onClick={() => onChange("")} />}
        </div>
    );
}

export const InputL = (params) => {
    const {
        children,
        onChange,
        name,
        type = "text",
        list = "",
        value,
        placeholder = "",
        loc = "all"
    } = params;

    return (
        <div className="input">
            <input
                type={type}
                list={list}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={({ target: { value } }) => onChange(value, loc)} />
            {children}

            {value.length > 1 && <CloseIcon onClick={() => onChange("", loc)} />}
        </div>
    );
}

export const Label = (params) => {
    const { texto, rol, data, cambio, userList } = params;


    const Agregar = (param) => {
        return (
            <NewAgent {...param}>
                <PlusIcon />
            </NewAgent>
        );
    }

    const Editar = (params) => {
        let item = userList.filter(({ nombre, apellido }) => nombre + " " + apellido === params.dato);

        return (
            <EditAgent item={item[0]} change={params.change}>
                {params.dato.length < 1 ?
                    <EditIcon color="#c5c5c5" />
                    :
                    <EditIcon />}
            </EditAgent>
        );
    }

    return (<label>{texto}<Editar dato={data} change={cambio} rol={rol} /><Agregar change={cambio} rol={rol} /></label>);
}

export const Field = (params) => {
    const {
        titulo,
        rol,
        onAdd,
        onChange,
        name,
        type = "text",
        list = [],
        value = "",
        placeholder = ""
    } = params;
    const [suggestionIndex, setSuggestionIndex] = React.useState(-1);
    const [onTop, setOnTop] = React.useState({ height: 28, top: false });
    const [show, setShow] = React.useState(false);
    const [lista, setLista] = React.useState([]);
    const listRef = React.useRef();


    const Agregar = (param) => {
        return (
            <NewAgent {...param}>
                <PlusIcon />
            </NewAgent>
        );
    }

    const Editar = (params) => {
        let item = list.filter(({ nombre, apellido }) => nombre + " " + apellido === params.dato);

        return (
            <EditAgent item={item[0]} change={params.change}>
                {params.dato.length < 1 ?
                    <EditIcon color="#c5c5c5" />
                    :
                    <EditIcon />}
            </EditAgent>
        );
    }

    const buildUserList = () => {
        let _list = [];

        list.map(item => {
            if (item.nivel == rol) {
                _list.push({ ...item, text: item.nombre + " " + item.apellido });
            }
        });

        setLista(_list);
    }

    const handlerCriteio = (_crit) => {
        let _list = [];
        onChange(_crit);

        if (!show) {
            setShow(true);
        }

        if (_crit.length > 0) {
            if (_crit.length < value.length) {
                setSuggestionIndex(0);
                _list = list.filter(item => {
                    if (item.nivel == rol) {
                        item.text = item.nombre + " " + item.apellido;
                        return item.text.toLowerCase().includes(_crit.toLowerCase());
                    }
                });
            } else {
                _list = lista.filter(item => {
                    return item.text.toLowerCase().includes(_crit.toLowerCase());
                });
            }
        } else {
            list.map(item => {
                if (item.nivel == rol) {
                    _list.push({ ...item, text: item.nombre + " " + item.apellido });
                }
            });
        }

        setLista(_list);
    }

    const handleSelect = (data) => {

        setSuggestionIndex(data.index);
        setShow(false);
        onChange(data.text);
    }

    const handlerFocus = (e) => {
        let _height = window.innerHeight;
        let _height_input = e.target.getBoundingClientRect().height;
        let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;

        setOnTop({
            height: _height_input,
            top: _to_border_bottom < 150
        });

        setSuggestionIndex(0);

        if (lista.length > 0) {
            setShow(true);
        } else {
            setShow(false);
        }
    }

    const handlerBlur = (e) => {
        setTimeout(() => {
            setShow(false);
        }, 200);
    }

    const handleKey = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (listRef.current) {
            let _caja = listRef.current.offsetHeight;
            let _scroll = listRef.current.scrollHeight;
            let _length = lista.length;
            let _trozo = _scroll / _length;

            if (e.key === "Enter") {
                setShow(false);
                onChange(lista[suggestionIndex].text);
            }

            if (e.key === "ArrowUp") {
                setSuggestionIndex(val => {
                    let _unit = val < 1 ? (_length - 1) : val - 1;
                    let _uni_height = _trozo * _unit;

                    if (_scroll > _caja) {
                        let _desplaza = _uni_height > 0 ? _uni_height : 0;

                        listRef.current.scrollTo({
                            top: _desplaza,
                            left: 0
                        });
                    }

                    return _unit;
                });
            }

            if (e.key === "ArrowDown") {
                setSuggestionIndex(val => {
                    let _unit = val < (_length - 1) ? val + 1 : 0;
                    let _uni_height = _trozo * _unit;

                    if (_scroll > _caja) {

                        listRef.current.scrollTo({
                            top: _uni_height,
                            left: 0
                        });
                    }

                    return _unit;
                });
            }
        }
    }

    React.useEffect(() => {
        buildUserList();
    }, [list]);

    return (
        <>
            <label>{titulo}<Editar dato={value} change={onAdd} rol={rol} /><Agregar change={onAdd} rol={rol} /></label>
            <div className="input" onBlur={handlerBlur}>
                <input
                    name={name}
                    type={type}
                    value={value}
                    autoComplete="off"
                    placeholder={placeholder}
                    onFocus={handlerFocus}
                    onKeyUp={handleKey}
                    onChange={({ target: { value } }) => handlerCriteio(value)} />
                {value.length > 0 && <CloseIcon onClick={() => handlerCriteio("")} />}

                {show && <Suggesting
                    list={lista}
                    ref={listRef}
                    onTop={onTop}
                    currentIndex={suggestionIndex}
                    onSelect={handleSelect} />}
            </div>
        </>
    );
}

export const FieldDuo = (params) => {
    const {
        titulo,
        rol,
        onAdd,
        onChange,
        name,
        type = "text",
        list = [],
        value = "",
        placeholder = ""
    } = params;
    const [suggestionIndex, setSuggestionIndex] = React.useState(-1);
    const [onTop, setOnTop] = React.useState({ height: 28, top: false });
    const [show, setShow] = React.useState(false);
    const [lista, setLista] = React.useState([]);
    const listRef = React.useRef();


    const Agregar = (param) => {
        return (
            <NewAgent {...param}>
                <PlusIcon />
            </NewAgent>
        );
    }

    const Editar = (params) => {
        let item = list.filter(({ nombre, apellido }) => nombre + " " + apellido === params.dato);

        return (
            <EditAgent item={item[0]} change={params.change}>
                {params.dato.length < 1 ?
                    <EditIcon color="#c5c5c5" />
                    :
                    <EditIcon />}
            </EditAgent>
        );
    }

    const buildUserList = () => {
        let _list = [];

        list.map(item => {
            if (item.nivel == rol) {
                _list.push({ text: item.nombre + " " + item.apellido });
            }
        });

        setLista(_list);
    }

    const handlerCriteio = (_crit) => {
        let _list = [];
        onChange(_crit);

        if (!show) {
            setShow(true);
        }

        if (_crit.length > 0) {
            if (_crit.length < value.length) {
                setSuggestionIndex(0);
                _list = list.filter(item => {
                    if (item.nivel == rol) {
                        item.text = item.nombre + " " + item.apellido;
                        return item.text.toLowerCase().includes(_crit.toLowerCase());
                    }
                });
            } else {
                _list = lista.filter(item => {
                    return item.text.toLowerCase().includes(_crit.toLowerCase());
                });
            }
        } else {
            list.map(item => {
                if (item.nivel == rol) {
                    _list.push({ ...item, text: item.nombre + " " + item.apellido });
                }
            });
        }

        setLista(_list);
    }

    const handleSelect = (data) => {

        setSuggestionIndex(data.index);
        setShow(false);
        onChange(data.text);
    }

    const handlerFocus = (e) => {
        let _height = window.innerHeight;
        let _height_input = e.target.getBoundingClientRect().height;
        let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;

        setOnTop({
            height: _height_input,
            top: _to_border_bottom < 150
        });

        setSuggestionIndex(0);

        if (lista.length > 0) {
            setShow(true);
        } else {
            setShow(false);
        }
    }

    const handlerBlur = (e) => {
        setTimeout(() => {
            setShow(false);
        }, 200);
    }

    const handleKey = (e) => {
        if (listRef.current) {
            let _caja = listRef.current.offsetHeight;
            let _scroll = listRef.current.scrollHeight;
            let _length = lista.length;
            let _trozo = _scroll / _length;

            if (e.key === "Enter") {
                setShow(false);
                onChange(lista[suggestionIndex].text);
            }

            if (e.key === "ArrowUp") {
                setSuggestionIndex(val => {
                    let _unit = val < 1 ? (_length - 1) : val - 1;
                    let _uni_height = _trozo * _unit;

                    if (_scroll > _caja) {
                        let _desplaza = _uni_height > 0 ? _uni_height : 0;

                        listRef.current.scrollTo({
                            top: _desplaza,
                            left: 0
                        });
                    }

                    return _unit;
                });
            }

            if (e.key === "ArrowDown") {
                setSuggestionIndex(val => {
                    let _unit = val < (_length - 1) ? val + 1 : 0;
                    let _uni_height = _trozo * _unit;

                    if (_scroll > _caja) {

                        listRef.current.scrollTo({
                            top: _uni_height,
                            left: 0
                        });
                    }

                    return _unit;
                });
            }
        }
    }

    React.useEffect(() => {
        buildUserList();
    }, [list]);

    return (
        <>
            <label>{titulo}<Editar dato={value} change={onAdd} rol={rol} /><Agregar change={onAdd} rol={rol} /></label>
            <div className="input" onBlur={handlerBlur}>
                <input
                    name={name}
                    type={type}
                    value={value}
                    autoComplete="off"
                    placeholder={placeholder}
                    onFocus={handlerFocus}
                    onKeyUp={handleKey}
                    onChange={({ target: { value } }) => handlerCriteio(value)} />
                {value.length > 0 && <CloseIcon onClick={() => handlerCriteio("")} />}

                {show && <Suggesting
                    list={lista}
                    ref={listRef}
                    onTop={onTop}
                    currentIndex={suggestionIndex}
                    onSelect={handleSelect} />}
            </div>
        </>
    );
}