import * as React from "react";
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import { useHistory } from 'react-router-dom';
import * as Icon from "../../../images";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const OrderList = ({ data, pos, close }) => {
    const [txtMensaje, setTxtMensaje] = React.useState("Cargando...");
    const [showLoading, setShowLoading] = React.useState(true);
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);
    const history = useHistory();


    const callOrdersLazy = async () => {
        setShowLoading(true);

        let _data = await api.getPickUpList({
            type: 2,
            carrier: data.id_user,
            offset: dataList.length
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map((item, index) => {
                item.index = index;

                if (item.carrier == data.id_user) {
                    item.select = true;
                } else {
                    item.select = false;
                }

                _packs.push(item);
            });

            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_packs);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setTxtMensaje("No hay resultados");
        setShowLoading(false);
    }

    const callOrders = async () => {
        let _data = await api.getPickUpList({
            type: 2,
            carrier: data.id_user,
            offset: 0
        });

        if (_data.response == 1) {

            let _lista = _data.data.map((item, index) => {
                item.index = index;
                if (item.carrier == data.id_user) {
                    item.select = true;
                } else {
                    item.select = false;
                }

                return item;
            });
            setDataList(_lista);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setTxtMensaje("No hay resultados");
        setShowLoading(false);
    }

    const saveAssign = async (_to_assign, _to_clean) => {
        setShowLoading(true);
        let _data = await api.saveAssignOrders({
            id_carrier: data.id_user,
            list_assign: _to_assign,
            list_clean: _to_clean
        });

        if (_data.response == 1) {
            close(false);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const AllRow = () => {
        let _data = [];

        dataList.map((item, index) => {

            _data.push(
                <div key={item.id}>
                    <p style={{ marginTop: 6, marginBottom: 8 }}>
                        <input type="checkbox" checked={item.select} onChange={e => handleSelect(e.target.checked, index)} />
                        <i>{item.consecutivo}</i> <b>{item.consignee_name}</b>
                    </p>
                    {/* <p style={{ marginLeft: 22, marginTop: 0, marginBottom: 12 }}>{item.pickup_address}</p> */}
                </div>
            );
        });

        if (_data.length < 1) {
            return <h3 key="01">{txtMensaje}</h3>;
        } else {
            return <>
                {_data}

                {showLoading ?
                    <button className="btn_lazy_load"><Icon.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn_lazy_load" onClick={() => callOrdersLazy()}>Cargar mas</button>
                            :
                            <h4 className="h4_lazy_load">¡No hay mas ordenes!</h4>
                        }
                    </>
                }
            </>;
        }
    }

    const validate = () => {
        let _lista = "";
        let _to_clean = "";

        dataList.map(_pack => {

            if (_pack.select) {

                _lista += _pack.id + ",";
            } else if (_pack.carrier == data.id_user) {
                _to_clean += _pack.id + ","
            }

        });

        if (_lista.length < 1) {
            Alert("Debes agregar al menos un paquete", "warning");
            return;
        }

        saveAssign(_lista, _to_clean);
    }

    const handleSelect = (_check, _index) => {
        let _lista = dataList.filter(item => {

            if (item.id === dataList[_index].id) {
                item.select = _check;
            }

            return item;
        });


        setDataList(_lista);
    }

    const handleClose = () => {
        close(false);
    }

    React.useEffect(() => {
        callOrders();
    }, []);

    return (
        <div className="content_modal">
            <div className="back_form _bkg_white"></div>
            <div className="win_modal" style={pos}>
                <h4><CloseIcon onClick={handleClose} />Recogidas sin asignar </h4>

                <div className="_scroll">

                    <AllRow />
                </div>
                <hr className="_line" />

                <div className="_in_row">
                    {showLoading ?
                        <button type="button" className="btn btn-common width-static"><Icon.Loading /></button>
                        :
                        <button type="button" className="btn btn-common width-static" onClick={validate}>Asignar</button>
                    }
                </div>
            </div>
        </div>
    );
}

export default OrderList;