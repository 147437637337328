import * as React from "react";
import CloseOutlineIcon from "mdi-react/CloseCircleOutlineIcon";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import Information from 'mdi-react/InformationOutlineIcon';
import { useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import useFilter from "hooks/useFilter";
import * as Icon from "../../../images";
import { Alert } from "shared/Alert";
import { Input } from "shared/Input";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const FilterConcept = (params) => {
    const { pos, close, call } = params;
    const [showLoading, setShowLoading] = React.useState(false);
    const [criterio, setCriterio] = React.useState("");
    const [error, setError] = React.useState("");
    const form_ref = React.useRef();

    const format = (_num) => {
        return _num < 10 ? "0" + _num : _num;
    }

    const handleClose = (e) => {
        e.preventDefault();
        close(false);
    }

    const validateFields = (e) => {
        e.preventDefault();

        setError("");

        if (criterio.length < 1) {
            setError("¡Debes ingresar una Debes ingresar un criterio de búsqueda, puede ser el nombre de un mensajero o un método de pago!");
            return;
        }
        setShowLoading(true);

        call({
            task: "fil_concepto",
            criterio: criterio.trim()
        });
    }


    return (
        <>
            <div className="back_form"></div>
            <div className="win_modal _overflow" style={pos}>
                <h4><CloseOutlineIcon onClick={handleClose} /> Filtro por concepto</h4>

                <form ref={form_ref}>

                    <div className="_in_column">

                        <div className="_in_column">
                            <label className="__titulo">Concepto</label>
                            <input type="text" name="criterio" placeholder="Ejem: nequi o nombre mensajero"
                                value={criterio} onChange={e => { setError(""); setCriterio(e.target.value); }} />
                        </div>

                    </div>

                </form>
                {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

                {showLoading ?
                    <button className="btn btn-common width-static"><Icon.Loading /></button>
                    :
                    <button className="btn btn-common width-static" onClick={validateFields}>Filtrar</button>
                }
            </div>
        </>
    )
}

export default FilterConcept;