import * as React from "react";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import DeleteEmptyIcon from "mdi-react/DeleteEmptyIcon";
import BarcodeScanIcon from "mdi-react/BarcodeScanIcon";
import FilterMenuIcon from "mdi-react/FilterMenuIcon"
import PlusThickIcon from "mdi-react/PlusThickIcon";
import ReloadIcon from "mdi-react/RotateRightIcon";
import PencilIcon from "mdi-react/PencilIcon";
import DatePicker from "react-datepicker";
import { Alert, Confirm } from "./Alert";
import useFilter from "hooks/useFilter";
import * as Iconos from "../../images";
import Loading from "shared/Loading";
import api from "services/api";
import Filter from "./Filter";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const ToolBar = (params) => {
    const { history, data, addOpt = true, onliClient = true } = params;
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [dates, setDates] = React.useState(localStorage.getItem('dates') || 1);
    const [fechaDesde, setFechaDesde] = React.useState(new Date());
    const [fechaHasta, setFechaHasta] = React.useState(new Date());
    const [pos, setPos] = React.useState({ top: "0", left: "0" });
    const [showLoading, setShowLoading] = React.useState(false);
    const [showFilter, setShowFilter] = React.useState(false);
    const [objDates, setObjDates] = React.useState();
    const { opFilter } = useFilter();


    const goEdit = (e) => {
        e.preventDefault()
        e.stopPropagation();

        if (params.estado == 1) {
            history.push({
                pathname: params.editar,
                state: { data: data }
            });
        } else if (params.estado == 4) {
            history.push({
                pathname: params.editar,
                state: { data: { ...data, date_received: data.date_received == null ? data.date_creation : data.date_received } }
            });
        }

    }

    const goDelete = (e) => {

        Confirm(params.borrar.msg, async (response) => {
            if (response) {
                setShowLoading(true);

                let _data = await api.deleteOrder({
                    id_order: data.id,
                    tipo: params.borrar.tipo
                });

                if (_data.response == 1) {
                    params.reload(objDates);
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => history.push("/"));
                } else {
                    Alert(_data.msg, "warning");
                }

                setShowLoading(false);
            }
        });

    }

    const handleOpenFilter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let _width = document.getElementById("root").offsetWidth;
        let _posx = (e.pageX + 220) > _width ? _width - 250 : e.pageX;

        setPos({ top: "24px", left: _posx + "px", });
        setShowFilter(true);
    }

    const asignaPaquete = async (codigo) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "save-assign-package-code",
            data: {
                codigo: codigo,
                mensajero: window.atob(data_user.auth)
            }
        });

        if (_data.response == 1) {
            Confirm(`El paquete ha sido asignado correctamente a tu lista de entregas. \n\n¿Te quieres asignar otro?`, response => {
                if (response) {
                    callScanner();
                } else {
                    params.reload();
                }
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const asignaRecogida = async (codigo) => {
        setShowLoading(true);

        let toSend = codigo.split("-");
        let _data = await api.fetchJson({
            url: "save-assign-order-code",
            data: {
                mensajero: window.atob(data_user.auth),
                consignee: toSend[0],
                consecutive: toSend[1]
            }
        });

        if (_data.response == 1) {
            Confirm(`La recogida ha sido asignada correctamente a tu lista. \n\n¿Te quieres asignar otra?`, response => {
                if (response) {
                    callScanner();
                } else {
                    params.reload();
                }
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const callScanner = () => {
        if (typeof android !== "undefined") {

            // eslint-disable-next-line no-undef
            scanner.response = (codigo) => {
                if (codigo.substring(0, 3) === "OS-") {
                    console.log("Es una orden de servicio");
                    Alert("¡No se puede asignar una orden de servicio!", "warning");
                } else if (codigo.substring(0, 3) === "OR-") {
                    asignaRecogida(codigo.substring(3));
                } else {
                    asignaPaquete(codigo);
                }
            };

            // eslint-disable-next-line no-undef
            scanner.error = (format) => Alert(`Error de lectura: \nEl formato del código es "${format}" y no es compatible con esta función.`, "warning");

            // eslint-disable-next-line no-undef
            android.scannerQR();
        }
    }

    const handleScanner = (e) => {
        e.preventDefault();
        e.stopPropagation();

        callScanner();
    }

    // eslint-disable-next-line
    const BtnEdit = () => {
        let _btn;

        if (data.id == 0) {
            _btn = data_user.nivel == 4 ? <Iconos.PackAdd color="#808486" /> : <PencilIcon color="#808486" />;
        } else {
            _btn = data_user.nivel == 4 ? <Iconos.PackAdd onClick={goEdit} /> : <PencilIcon onClick={goEdit} />;
        }
        return (_btn);
    }

    // eslint-disable-next-line
    const BtnDelete = () => {
        let _btn;

        if (data.id == 0) {
            _btn = <DeleteEmptyIcon color="#808486" />;
        } else {
            _btn = <DeleteEmptyIcon onClick={goDelete} />;
        }
        return (_btn);
    }

    const format = (_num) => {
        return _num < 10 ? "0" + _num : _num;
    }

    const calcWeek = (_fecha) => {
        let _restantes = 6 - _fecha.getDay();
        let _min_left = (60 - _fecha.getMinutes()) * 60000;
        let _hours_left = ((24 - _fecha.getHours()) * 3600000) - _min_left;
        let _time = _fecha.getTime() + (_restantes * 86400000) + _hours_left;

        let _fecha_d = new Date(_time - ((7 * 86400000) - 2400000));
        let _fecha_h = new Date(_time);

        return {
            desde: _fecha_d.getFullYear() + "-" + format(_fecha_d.getMonth() + 1) + "-" + format(_fecha_d.getDate()),
            hasta: _fecha_h.getFullYear() + "-" + format(_fecha_h.getMonth() + 1) + "-" + format(_fecha_h.getDate()),
        }
    }

    const calcNextWeek = (_fecha = new Date()) => {
        let _restantes = 7 - _fecha.getDay();
        let _time = _fecha.getTime() + (_restantes * 86400000);
        let _fecha_d = new Date(_time);
        let _fecha_h = new Date(_time + (6 * 86400000));

        return {
            desde: _fecha_d.getFullYear() + "-" + format(_fecha_d.getMonth() + 1) + "-" + format(_fecha_d.getDate()),
            hasta: _fecha_h.getFullYear() + "-" + format(_fecha_h.getMonth() + 1) + "-" + format(_fecha_h.getDate()),
        }
    }

    const calcAllDates = (_fecha = new Date()) => {
        let _fecha_d = new Date(0);
        let _fecha_h = new Date((31556926 * 300) * 1000);

        return {
            desde: _fecha_d.getFullYear() + "-" + format(_fecha_d.getMonth() + 1) + "-" + format(_fecha_d.getDate()),
            hasta: _fecha_h.getFullYear() + "-" + format(_fecha_h.getMonth() + 1) + "-" + format(_fecha_h.getDate()),
        }
    }

    const callFilter = (_opt) => {
        let _fecha = new Date();
        let _desde, _hasta, _new_date;
        let _opciones = {}

        switch (dates) {
            case "10":
                _new_date = calcAllDates(_fecha);

                _desde = _new_date.desde;
                _hasta = _new_date.hasta;
                break;
            case "9":
                _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 2)}-01`;
                _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 2)}-30`;
                break;
            case "8":
                _new_date = calcNextWeek(_fecha);

                _desde = _new_date.desde;
                _hasta = _new_date.hasta;
                break;
            case "7":
                _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() - 5)}-01`;
                _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth())}-30`;
                break;
            case "6":
                _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() - 2)}-01`;
                _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth())}-30`;
                break;
            case "5":
                _desde = `${_fecha.getFullYear()}-01-01`;
                _hasta = `${_fecha.getFullYear()}-12-30`;
                break;
            case "4":
                _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 1)}-01`;
                _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 1)}-30`;
                break;
            case "3":
                _new_date = calcWeek(_fecha);

                _desde = _new_date.desde;
                _hasta = _new_date.hasta;
                break;
            case "2":
                _desde = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate() - 1);
                _hasta = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate() - 1);
                break;
            case "1":
                _desde = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate());
                _hasta = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate());
                break;
        }

        for (let i in _opt) {
            if (_opt[i].length >= 1) {
                _opciones[i] = _opt[i];
            }
        }

        _opciones.desde = _desde;
        _opciones.hasta = _hasta;

        setObjDates(_opciones);
        params.reload(_opciones);
    }

    React.useEffect(() => {
        let _fecha = new Date();
        let _desde, _hasta, _new_date, _obj_dates;

        localStorage.setItem('dates', dates);

        switch (dates) {
            case "10":
                _new_date = calcAllDates(_fecha);

                _desde = _new_date.desde;
                _hasta = _new_date.hasta;
                break;
            case "9":
                _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 2)}-01`;
                _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 2)}-30`;
                break;
            case "8":
                _new_date = calcNextWeek(_fecha);

                _desde = _new_date.desde;
                _hasta = _new_date.hasta;
                break;
            case "7":
                _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() - 5)}-01`;
                _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth())}-30`;
                break;
            case "6":
                _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() - 2)}-01`;
                _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth())}-30`;
                break;
            case "5":
                _desde = `${_fecha.getFullYear()}-01-01`;
                _hasta = `${_fecha.getFullYear()}-12-30`;
                break;
            case "4":
                _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 1)}-01`;
                _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 1)}-30`;
                break;
            case "3":
                _new_date = calcWeek(_fecha);

                _desde = _new_date.desde;
                _hasta = _new_date.hasta;
                break;
            case "2":
                _desde = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate() - 1);
                _hasta = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate() - 1);
                break;
            case "1":
                _desde = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate());
                _hasta = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate());
                break;
        }

        if (dates !== "11" && dates !== "0") {
            if (opFilter.client == "" &&
                opFilter.carrier == "" &&
                opFilter.status == "") {

                _obj_dates = {
                    dates: dates,
                    estado: params.estado,
                    desde: _desde,
                    hasta: _hasta,
                };

                setObjDates(_obj_dates);
                params.reload(_obj_dates);
            } else {
                let _opciones = {
                    search: "1",
                    desde: _desde,
                    hasta: _hasta
                };

                if (opFilter.status.length >= 1) {
                    _opciones.estado = opFilter.status;
                }

                if (opFilter.client.length >= 1) {
                    _opciones.consignee = opFilter.client;
                }

                if (opFilter.carrier.length >= 1) {
                    _opciones.carrier = opFilter.carrier;
                }

                setObjDates(_opciones);
                params.reload(_opciones);
            }
        }
    }, [dates]);

    React.useEffect(() => {
        if (dates === "11") {
            let _obj_dates = {
                dates: dates,
                estado: params.estado,
                desde: fechaDesde.getFullYear() + "-" + format(fechaDesde.getMonth() + 1) + "-" + format(fechaDesde.getDate()),
                hasta: fechaHasta.getFullYear() + "-" + format(fechaHasta.getMonth() + 1) + "-" + format(fechaHasta.getDate()),
            };

            setObjDates(_obj_dates);
            params.reload(_obj_dates);
        }
    }, [fechaDesde, fechaHasta]);

    return (
        <div className="tool_bar">
            <ReloadIcon className={params.showLoading ? "btn_refresh on_refresh" : "btn_refresh"} onClick={() => params.reload(objDates)} />
            {((addOpt && data_user.nivel < 4) || (addOpt && data_user.nivel == 5) || (addOpt && data_user.nivel == 6)) ? <PlusThickIcon onClick={params.crear} /> : null}
            {(opFilter.client == "" &&
                opFilter.carrier == "" &&
                opFilter.status == "") ?
                <FilterMenuIcon onClick={handleOpenFilter} />
                :
                <Iconos.FilterCheck onClick={handleOpenFilter} />}
            {/* {data_user.nivel < 4 && <MarkerIcon onClick={handleOpenZones} />} */}
            {(data_user.nivel == 4 && typeof android !== "undefined") && <BarcodeScanIcon onClick={handleScanner} />}

            <div className="_in_row">
                {typeof android === "undefined" && <p>Fechas:</p>}
                <select name="dates" defaultValue={dates} onChange={({ target: { value } }) => setDates(value)}>

                    <option value="1">Hoy</option>
                    <option value="2">Ayer</option>
                    <option value="3">Esta semana</option>
                    <option value="4">Este mes</option>
                    <option value="6">Últimos tres meses</option>
                    <option value="7">Últimos seis meses</option>
                    <option value="5">Este año</option>
                    {/* <option value="8">Siguiente semana</option> */}
                    {/* <option value="9">Siguiente mes</option> */}
                    <option value="10">Todos</option>
                    <option value="11">Personalizado</option>

                </select>
            </div>

            {dates === "11" &&
                <div className="_in_row">
                    <div className="calendar">
                        <DatePicker dateFormat="yyyy/MM/dd" name="desde" selected={fechaDesde} onChange={(date) => setFechaDesde(date)} />
                        <CalendarMonthIcon />
                    </div>

                    <div className="calendar">
                        <DatePicker dateFormat="yyyy/MM/dd" name="hasta" selected={fechaHasta} onChange={(date) => setFechaHasta(date)} />
                        <CalendarMonthIcon />
                    </div>

                    {/* <AutorenewIcon /> */}
                </div>}

            {showFilter && <Filter pos={pos}
                onliClient={onliClient}
                close={setShowFilter}
                call={callFilter}
                estado={params.estado} />}

            {showLoading && <Loading />}
        </div>
    )
}

export default ToolBar;