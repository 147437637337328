import * as React from "react";
import ReportsExcelAdmin from "./components/ReportsExcelAdmin";
import ResumenCierreAdmin from "./components/ResumenCierreAdmin";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import RecogidasAdmin from "./components/RecogidasAdmin";
import CerrarCajaIcon from "mdi-react/CashRegisterIcon";
import EntregasAdmin from "./components/EntregasAdmin";
import { Alert, Confirm, ResumenEntrega } from "shared/Alert";
import CierreCaja from "./components/CierreCaja";
import { SearchInReport } from "shared/Input";
import { Container, Row } from "reactstrap";
import funciones from "services/funciones";
import DatePicker from "react-datepicker";
import Cortes from "./components/Cortes";
import useTitle from "hooks/useTitle";
import * as Icon from "../../images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-extend-native: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

Date.prototype.getWeekNumber = function () {
  var d = new Date(+this);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));

  return Math.ceil((((d - new Date(d.getFullYear(), 0, 1)) / 8.64e7) + 1) / 7);
};

const ReportAdmin = (params) => {
  const { history } = params;
  const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
  const [listCountDelivery, setListCountDelivery] = React.useState([]);
  const [showLoading, setShowLoading] = React.useState(true);
  const [listPickups, setListPickups] = React.useState([]);
  const [resumenPago, setResumenPago] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const [userNameList, setUserNameList] = React.useState([]);
  const [userToClose, setUserToClose] = React.useState();
  const [showModal, setShowModal] = React.useState(false);
  const [modalView, setModalView] = React.useState(null);
  const [criterio, setCriterio] = React.useState("");
  const { setTitulo } = useTitle();

  

  const handleModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let _height = window.innerHeight;
    let _width = document.getElementById("root").offsetWidth;
    let _posx = (e.pageX + 300) > _width ? _width - 300 : e.pageX;
    let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;
    let _to_y = _to_border_bottom < 315 ? e.pageY - 150 : e.pageY - 80;
    let _pos = { top: _to_y + "px", left: _posx + "px", width: 260, maxWidth: 260 };

    setModalView(<CierreCaja pos={_pos} close={setShowModal} reload={getSales} data={{ ...userToClose, ...resumenPago[0] }} />);
    setShowModal(true);
  }

  const handleSelectUser = (_nombre) => {
    setCriterio(_nombre);

    let _item = userList.filter((item) => item.nombre + " " + item.apellido === _nombre);

    if (_item.length >= 1) {
      if (_item[0].id_user === "all") {
        Confirm("Ten en cuenta que la consulta de 'TODOS' los registros puede ser muy pesada y tardar mucho tiempo. \n\n¿Desea continuar con la consulta?", continuar => {
          if (continuar) {
            setUserToClose(_item[0]);
          } else {
            setCriterio(userToClose.nombre + " " + userToClose.apellido);
          }
        });
      } else {
        setUserToClose(_item[0]);
      }
    }
  }

  const handlePasedUser = (_lista_usuarios, _nombre) => {
    setCriterio(_nombre);

    let _item = _lista_usuarios.filter((item) => item.nombre + " " + item.apellido === _nombre);

    if (_item.length >= 1) {
      setUserToClose(_item[0]);
    }
  }

  const getUserList = async () => {
    setShowLoading(true);
    let _data = await api.getUsers("de_caja");
    let _users = ["Todos "];
    let _all_users = [];

    if (_data.response == 1) {
      _all_users.push({ id_user: "all", nivel: 4, nombre: "Todos", apellido: "" });

      _data.data.map(item => {
        _users.push(item.nombre + " " + item.apellido);
        _all_users.push(item);
      });

      setUserList(_all_users);
      setUserNameList(_users);

      if (params.location.state && params.location.state.nombre) {
        handlePasedUser(_data.data, params.location.state.nombre);
      } else {
        setShowLoading(false);
      }
    } else if (_data.response === -2) {
      Alert(_data.msg, "warning", () => history.push("/"));
      setShowLoading(false);
    } else {
      Alert(_data.msg, "warning");
      setShowLoading(false);
    }

    return _users;
  }

  const getPickups = async () => {
    let _nivel = "user";
    if (userToClose && userToClose.id_user) {

      switch (parseInt(data_user.nivel)) {
        case 1:
        case 2:
        case 3:
          _nivel = "admin";
          break;
        case 4:
          _nivel = "carrier";
          break;
        case 6:
          _nivel = "cliente";
          break;
        default:
          _nivel = "user";
          break;
      }

      let _data = await api.fetchJson({
        url: "reportes",
        data: {
          task: "recogidas",
          nivel: _nivel,
          user_close: userToClose ? userToClose.id_user : "",
          nivel_close: userToClose ? userToClose.nivel : ""
        }
      });

      if (_data.response == 1) {
        _data.data.map(item => {
          item.collect = parseInt(item.collect_product) || 0;
          return item;
        });

        setListPickups(_data.data);
      } else if (_data.response === -2) {
        Alert(_data.msg, "warning", () => history.push("/"));
      } else {
        Alert(_data.msg, "warning");
      }
    }
  }

  const getCountDeliverys = async () => {
    let _nivel = "user";

    if (userToClose && userToClose.id_user) {

      switch (parseInt(data_user.nivel)) {
        case 1:
        case 2:
        case 3:
          _nivel = "admin";
          break;
        case 4:
          _nivel = "carrier";
          break;
        case 6:
          _nivel = "cliente";
          break;
        default:
          _nivel = "user";
          break;
      }

      let _data = await api.fetchJson({
        url: "reportes",
        data: {
          task: "cuentaentregas",
          nivel: _nivel,
          user_close: userToClose ? userToClose.id_user : "",
          nivel_close: userToClose ? userToClose.nivel : ""
        }
      });

      if (_data.response == 1) {
        let _delivery = _data.data.map(item => {
          item.counter = parseInt(item.counter) || 0;
          return item;
        });

        setListCountDelivery(_delivery);
      } else if (_data.response === -2) {
        Alert(_data.msg, "warning", () => history.push("/"));
      } else {
        Alert(_data.msg, "warning");
      }
    }
  }

  const armaMensajero = (_resumen) => {
    // 304 378 3429
    let _sales = [
      {
        id: 0,
        title: 'Totales',
        abonos: _resumen.abonos,
        entregas: _resumen.entregado,
        recogidas: _resumen.pagos_recogidas,
        v_mensajero: _resumen.domicilios_entregas,
        v_mensajero_r: _resumen.domicilios_regogidas,
        subtolal: _resumen.tolal,
        efectivo: _resumen.efectivo,
        transferencia: _resumen.transferencia,
        domicilio_ko: _resumen.ko_dom_pago,
      },
    ];
    
    console.log('resumen mensajero', _resumen);

    setResumenPago(_sales);
  }

  const armaCliente = (_resumen) => {
    // 304 378 3429
    let _data = [];

    _resumen.map(item => {
      let _key = item.split(':');
      _data[_key[0]] = _key[1];
    });

    let _sales = [
      {
        id: 0,
        title: 'Totales',
        abonos: _data.abonos,
        entregas: _data.entregado,
        recogidas: _data.pagos_recogidas,
        v_domicilio: _data.domicilios_entregas,
        v_domicilio_r: _data.domicilios_regogidas,
        subtolal: _data.tolal,
        efectivo: _data.efectivo,
        transferencia: _data.transferencia,
        domicilio_ko: _data.ko_dom_pago,
      },
    ];

    console.log('resumen cliente', _data);

    setResumenPago(_sales);
  }

  const getSales = async () => {
    if (userToClose && userToClose.id_user) {
      setShowLoading(true);
      let _nivel = "user";

      switch (parseInt(data_user.nivel)) {
        case 1:
        case 2:
        case 3:
          _nivel = "admin";
          break;
        case 4:
          _nivel = "carrier";
          break;
        case 6:
          _nivel = "cliente";
          break;
        default:
          _nivel = "user";
          break;
      }

      let _data = await api.fetchJson({
        url: "cuentas",
        data: {
          task: "solo_resumen",
          nivel: _nivel,
          user_close: userToClose ? userToClose.id_user : "",
          nivel_close: userToClose ? userToClose.nivel : ""
        }
      });

      if (_data.response == 1) {

        if (userToClose.nivel == 4) {
          armaMensajero(JSON.parse(_data.data));
        }

        if (userToClose.nivel == 6) {
          armaCliente(_data.data.split(','));
        }

      } else if (_data.response === -2) {
        Alert(_data.msg, "warning", () => history.push("/"));
      } else {
        Alert(_data.msg, "warning");
      }
    }

    setShowLoading(false);
  }

  const cerrarCaja = async (e) => {
    e.preventDefault();

    if (userToClose && userToClose.id_user) {
      setShowLoading(true);
      let _nivel = "user";

      switch (parseInt(data_user.nivel)) {
        case 1:
        case 2:
        case 3:
          _nivel = "admin";
          break;
        case 4:
          _nivel = "carrier";
          break;
        case 6:
          _nivel = "cliente";
          break;
        default:
          _nivel = "user";
          break;
      }

      let _data = await api.fetchJson({
        url: "cerrar-caja",
        data: {
          task: _nivel,
          user_close: userToClose ? userToClose.id_user : "",
          nivel_close: userToClose ? userToClose.nivel : ""
        }
      });

      if (_data.response == 1) {
        let _no_have = userToClose.nivel == 4 ? "¡No devolverá paquetes!" : "¡No tiene devociones!";
        let _entregada = _data.data.cantidad_entregada > 0 ? <>{_data.data.cantidad_entregada} entrega{_data.data.cantidad_entregada < 2 ? "" : "s"}: <span>${funciones.formatPrice(_data.data.valor_entregado).format}</span></> : <>¡No tiene entregas!</>;
        let _devuelto = _data.data.candidad_devuelta > 0 ? `Devolverá ${_data.data.candidad_devuelta} paquete${_data.data.candidad_devuelta < 2 ? "" : "s"} a bodega` : _no_have;
        let _efectivo = _data.data.efectivo > 0 ? <>Efectivo: <span>${funciones.formatPrice(_data.data.efectivo).format}</span></> : <>¡Sin pagos en efectivo!</>;
        let _transferencias = _data.data.transferencia > 0 ? <>Transferencias: <span>${funciones.formatPrice(_data.data.transferencia).format}</span></> : <>¡Sin transferencias!</>;
        let _dev_ko = parseInt(_data.data.cantidad_ko_dv) > 0 ?
          <>
            {_data.data.ko_dv_cambio} {_data.data.resumen_ko_dv_cambio && <i><br />{_data.data.resumen_ko_dv_cambio}</i>}
            <br />
            {_data.data.ko_dv_sobrante} {_data.data.resumen_ko_dv_sobrante && <i><br />{_data.data.resumen_ko_dv_sobrante}</i>}
          </> : <>¡Sin entregas KO+DV!</>;


        if (userToClose.nivel == 4) {
          ResumenEntrega("Caja recibida con:", <>
            <p>{_entregada}</p>
            <p>{_efectivo}</p>
            <p>{_transferencias}</p>
            <p>{_dev_ko}</p>
            <p>{_devuelto} {_data.data.resumen_devoluciones && <><br /><i>{_data.data.resumen_devoluciones}</i></>}</p>
            <h5>Reabierta correctamente.</h5>
          </>, () => {
            getSales();
            getPickups();
            getCountDeliverys();
          });
        } else {
          ResumenEntrega("Corte realizado con:", <>
            <p>{_entregada}</p>
            <p>{_dev_ko}</p>
            <p>{_devuelto} {_data.data.resumen_devoluciones && <><br /><i>{_data.data.resumen_devoluciones}</i></>}</p>
          </>, () => {
            getSales();
            getPickups();
            getCountDeliverys();
          });
        }


      } else if (_data.response === -2) {
        Alert(_data.msg, "warning", () => history.push("/"));
      } else {
        Alert(_data.msg, "warning");
      }

      setShowLoading(false);
    }
  }

  const BtnClose = () => {

    if (userToClose) {

      if (showLoading) {
        return <button className="close-button _flotante"><Icon.Loading />Cargando...</button>;
      } else {

        if (userToClose.id_user === "all") {
          return <button className="close-button _flotante _deshabilitado"><CerrarCajaIcon />Recibir caja</button>;
        } else {
          return <>
            {userToClose.nivel == 6 ?
              <button className="close-button _flotante" onClick={handleModal}><CerrarCajaIcon />Corte de caja</button>
              :
              <button className="close-button _flotante" onClick={cerrarCaja}><CerrarCajaIcon />Recibir caja</button>
            }
          </>;
        }
      }

    } else {
      return <button className="close-button _flotante _deshabilitado"><CerrarCajaIcon />{(userToClose && userToClose.nivel == 6) ? "Corte de caja" : "Recibir caja"}</button>;
    }

  }

  React.useEffect(() => {
    if (data_user.nivel < 4) {
      setTitulo("Reportes de recogida y entrega");
      getUserList();
    } else {
      history.push("/home");
    }
  }, []);

  React.useEffect(() => {
    if (userToClose && userToClose.id_user) {
      getSales();
      getCountDeliverys();
      getPickups();
    }
  }, [userToClose]);


  return (
    <Container className="dashboard">
      <Row>
        <h3 className="page-title">Reportes de recogidas y entregas</h3>

        <SearchInReport
          name="Buscar"
          placeholder="Buscar vendedor o mensajero"
          value={criterio}
          list={userNameList}
          onChange={handleSelectUser} />

        <ReportsExcelAdmin userToClose={userToClose} sales={resumenPago}>
          <BtnClose />

          <Row className="content_select">
            {/* Se deja vacio para ajustar el espacio en la vercion movil   */}
          </Row>

          {userToClose && <ResumenCierreAdmin
            titulo="Total a entregar"
            userToClose={userToClose}
            data={resumenPago}
            onRefresh={() => getSales()} />}

        </ReportsExcelAdmin>
      </Row>

      <Row>
        <EntregasAdmin userToClose={userToClose} list={listCountDelivery} onRefresh={() => getCountDeliverys()} />
        <RecogidasAdmin userToClose={userToClose} list={listPickups} onRefresh={() => getPickups()} />
      </Row>

      <Row>
        {(userToClose && userToClose.id_user !== "all") && <Cortes data_user={userToClose} sales={resumenPago} />}
      </Row>

      {showModal && modalView}

    </Container>
  )
}

export default ReportAdmin;
