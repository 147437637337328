import * as React from "react";
import CloseOutlineIcon from "mdi-react/CloseCircleOutlineIcon";
import Information from "mdi-react/InformationOutlineIcon";
import { FileUploadImg } from "shared/FileUpload";
import { useHistory } from "react-router-dom";
import CashPlus from "mdi-react/CashPlusIcon";
import funciones from "services/funciones";
import * as Icon from "../../../images";
import { Alert } from "shared/Alert";
import { Input } from "shared/Input";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const DeliveryView = ({ data, pos, close, reload }) => {
    const [showLoading, setShowLoading] = React.useState(false);
    const [whoReceives, setWhoReceives] = React.useState("");
    const [titulo, setTitulo] = React.useState("Comentario");
    const [metodosPago, setMetodosPago] = React.useState([]);
    const [attachment, setAttachment] = React.useState("");
    const [objImg, setObjImg] = React.useState(null);
    const [estado, setEstado] = React.useState(0);
    const [notes, setNotes] = React.useState("");
    const [error, setError] = React.useState("");
    const [kodv, setKodv] = React.useState("");
    const history = useHistory();


    const handleClose = () => {
        close(false);
    }

    const handleState = (e) => {
        let { target: { value } } = e;
        let _titulo = "Comentario";

        switch (parseInt(value)) {
            case 10:
                _titulo = "Motivo de la reprogramación";
                break;
            case 9:
                _titulo = "Nota de la devolución";
                break;
            case 12:
            case 8:
            case 7:
                _titulo = "Motivo de la cancelación";
                break;
            case 6:
            default:
                _titulo = "Comentario";
                break;
        }

        setError("");
        setTitulo(_titulo);
        setEstado(value);
    }

    const RadioGoup = () => {

        const handleSelect = (e) => {
            setError("");
            setKodv(e.target.value)
        }

        return <div className="_radio_group">
            <p className="__titulo">Tipo de devolución</p>
            <label onClick={() => handleSelect({ target: { value: "cambio" } })}>
                <input type="radio" name="devolucion" value="cambio"
                    checked={kodv === "cambio"} onChange={handleSelect} />
                Cambio
            </label>

            <label onClick={() => handleSelect({ target: { value: "sobrante" } })}>
                <input type="radio" name="devolucion" value="sobrante"
                    checked={kodv === "sobrante"} onChange={handleSelect} />
                Sobrante
            </label>
        </div>;
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _metodos = [];
        let _error = false;
        let _suma = 0;

        setError("");

        if (estado == 0) {
            setError("¡Debes seleccionar un estado!");
            return;
        }

        if (estado == 6 || estado == 9) {

            if (estado == 9 && kodv == "") {
                setError("¡Debes indicar el tipo de devolución!");
                return;
            }

            if (estado == 9 && notes.length < 1) {
                setError(`¡Debes indicar una nota para el ${kodv}!`);
                return;
            }

            if (data.price == 0) {

                if (whoReceives.length < 1) {
                    setError("¡Debes indicar quien recibe!");
                    return;
                }

                if (attachment.length < 1) {
                    setError("¡Debes tomar una foto!");
                    return;
                }
            }

            _metodos = metodosPago.map(item => {
                let { metodo, nombre, valor } = item;
                valor = valor.replaceAll(".", "");
                item.valor = valor;
                _suma += parseInt(valor);

                if (parseInt(metodo) < 1) {
                    setError("¡Debes seleccionar un método de pago!");
                    _error = true;
                    return;
                }

                if (valor.length < 1) {
                    setError(`¡Debes indicar el monto de ${nombre}!`);
                    _error = true;
                    return;
                }

                return item;
            });

            if (_suma > data.price) {
                setError("¡La suma de las formas de pago no puede ser mayor al valor del producto!");
                return;
            }
        }

        if (estado != 6 && estado != 9) {

            if (estado == 12) {

                if (metodosPago.length < 1) {
                    setError("¡Debes agregar una forma de pago y el monto del domicilio!");
                    return;
                }

                _metodos = metodosPago.map(item => {
                    let { metodo, nombre, valor } = item;
                    valor = valor.replaceAll(".", "");
                    item.valor = valor;
                    _suma += parseInt(valor);


                    if (parseInt(metodo) < 1) {
                        setError("¡Debes seleccionar un método de pago!");
                        _error = true;
                        return;
                    }

                    if (valor.length < 1) {
                        setError(`¡Debes indicar el monto de ${nombre}!`);
                        _error = true;
                        return;
                    }

                    return item;
                });

                if (!_error && notes.length < 1) {
                    setError(`¡Debes indicar el ${titulo.toLowerCase()}!`);
                    return;
                }

                if (!_error && attachment.length < 1) {
                    setError("¡Debes tomar una foto!");
                    return;
                }
            } else {
                if (notes.length < 1) {
                    setError(`¡Debes indicar el ${titulo.toLowerCase()}!`);
                    return;
                }
            }

            if (estado == 8 && attachment.length < 1) {
                setError("¡Debes tomar una foto!");
                return;
            }
        }


        if (_error) {
            console.log("Tiene errores");
        } else {
            console.log("No tiene errores");
            marcaEntregado(_metodos);
        }
    }

    const marcaEntregado = async (_metodos) => {
        setShowLoading(true);
        let _suma = 0;
        let _datos = {
            id_pack: data.id,
            estado: estado,
            receive: whoReceives,
            devolucion: kodv,
            notes: notes,
            file: attachment
        };

        if (estado == 12) {
            let _domicilio_ko = data.domicilio_ko ? data.domicilio_ko + "," : "";
            _datos['domicilio'] = _domicilio_ko + _metodos[0].nombre + ": " + parseInt(_metodos[0].valor);
        } else {
            _datos['domicilio'] = data.domicilio_ko;

            _metodos.map(item => {
                let _nombre = item.nombre.toLowerCase();
                _nombre = _nombre.replaceAll(" ", "_");
                _datos[_nombre] = item.valor;

                _suma += parseInt(item.valor);
            });

            if (estado == 6 || estado == 9) {
                if (_metodos.length < 1) {
                    _datos['efectivo'] = data.price;
                } else {
                    if (_suma < data.price) {
                        if (_datos['efectivo'] == undefined) {
                            _datos['efectivo'] = data.price - _suma;
                        } else {
                            _datos['efectivo'] = parseInt(_datos['efectivo']) + (data.price - _suma);
                        }
                    }
                }
            }
        }

        let _data = await api.deliveredPackage(_datos);

        if (_data.response == 1) {
            reload();
            close(false);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    return (
        <>
            <div className="back_form"></div>
            <form className="win_modal _overflow _delivery" style={pos}>
                <h3 style={{ marginRight: -8 }}><CloseOutlineIcon onClick={handleClose} />Estado de la entrega </h3>
                <h5>Paquete: {data.codigo}</h5>
                <h5>Valor:  <b>${funciones.formatPrice(data.price).format}</b></h5>

                <div className="_in_column">

                    <div>
                        <p className="__titulo">Estado</p>
                        <select name="estado" value={estado} onChange={handleState}>
                            <option value="0">Selecciona...</option>
                            <option value="6">OK</option>
                            <option value="9">OK+DV</option>
                            <option value="7" >KO+CANCELADO</option>
                            <option value="8" >KO+DOM</option>
                            <option value="12">KO+DOM+PAGO</option>
                            <option value="10">KO+CF</option>
                            <option value="13">KO+REPROGRAMADO</option>
                        </select>
                    </div>

                    {estado == 9 && <RadioGoup />}

                    {(estado == 6 || estado == 9 || estado == 12) && <>
                        {estado != 12 && <div>
                            <label className="__titulo">Quien recibe</label>
                            <Input type="text" name="carrier"
                                value={whoReceives} onChange={setWhoReceives} />
                        </div>}

                        {metodosPago.map((item, index, array) => {
                            return <div key={index} style={{ position: "relative", paddingTop: 18 }}>
                                <CloseOutlineIcon className="close_option" onClick={() => {
                                    setMetodosPago(array.filter((_item, _index) => _index !== index));
                                }} />

                                {array[index]['metodo'] == "0" ?
                                    <>
                                        <p className="__titulo">Método de pago</p>
                                        <select name="estado" value={array[index]['metodo']} onChange={e => {
                                            array[index]['metodo'] = e.target.value;
                                            array[index]['nombre'] = e.target.options[e.target.selectedIndex].text;
                                            if (e.target.value == "5") {
                                                array[index]['valor'] = funciones.formatPrice(data.price).format;
                                            }
                                            setMetodosPago([...array]);
                                            setError("");
                                        }}>
                                            <option value="0">Selecciona...</option>
                                            <option value="1">Efectivo</option>
                                            <option value="2">Nequi</option>
                                            <option value="3">DaviPlata</option>
                                            <option value="4">Bold</option>
                                            <option value="5">Pago al vendedor</option>
                                        </select>
                                    </>
                                    :
                                    <>
                                        <p className="__titulo">{array[index]['nombre']}</p>
                                        <input type="tel" value={array[index]['valor']} onChange={e => {
                                            array[index]['valor'] = funciones.formatPrice(e.target.value).format;
                                            setMetodosPago([...array]);
                                            setError("");
                                        }} readOnly={array[index]['metodo'] == 5 ? true : false} />
                                    </>
                                }
                            </div>
                        })}

                        {(
                            (estado == 6 && metodosPago.length < 4) ||
                            (estado == 9 && metodosPago.length < 4) ||
                            (estado == 12 && metodosPago.length < 1)
                        ) && <h4 className="_button" onClick={() => {
                            setMetodosPago([...metodosPago, { metodo: "0", nombre: "", valor: "" }]);
                        }}>Agregar forma de pago<CashPlus /></h4>}
                    </>}

                    <div>
                        <p className="__titulo">{titulo}</p>
                        <textarea name="comment" rows="2"
                            value={notes} onChange={({ target: { value } }) => setNotes(value)} />
                    </div>

                </div>

                {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

                <FileUploadImg
                    img={objImg}
                    codigo={data.codigo}
                    loading={showLoading}
                    onStart={() => setShowLoading(true)}
                    onUpload={obj => {
                        setError("");
                        setObjImg(obj);
                        setAttachment(obj ? obj.url : "");
                        setShowLoading(false);
                    }} />

                {showLoading ?
                    <button className="btn btn-common width-static"><Icon.Loading /></button>
                    :
                    <button className="btn btn-common width-static" onClick={validateFields}>Guardar</button>
                }
            </form>
        </>
    );
}

export default DeliveryView;