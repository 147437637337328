import * as React from "react";
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import DatePicker from "react-datepicker";
import NewContainer from "./NewContainer";
import useTitle from "hooks/useTitle";
import { Input } from "shared/Input";
import Loading from "shared/Loading";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const NewPickUp = ({ history }) => {
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [consignee] = React.useState(data_user.nombre);
    const [collectPrice] = React.useState("");
    const [showLoading, setShowLoading] = React.useState(false);
    const [consigneeId] = React.useState(window.atob(data_user.auth));
    const [namePickupLocation, setNamePickupLocation] = React.useState("");
    const [addressPickupLocation, setAddressPickupLocation] = React.useState("");
    const [pickupId, setPickupId] = React.useState("");
    const [notes, setNotes] = React.useState("");
    const [fechaIngreso, setFechaIngreso] = React.useState(new Date());
    const [myUserList, setMyUserList] = React.useState([]);
    const [userList, setUserList] = React.useState([{ id_user: 0, nombre: "Cargando..." }]);
    const { setTitulo } = useTitle();
    // const [valDomc, setValDomc] = React.useState("8000");
    const [valDomc] = React.useState("8000");

    const [packages, setPackages] = React.useState({});
    const [allPaquages, setAllPaquages] = React.useState([]);
    const ref_form = React.useRef();

    const addPackage = (e) => {
        e.preventDefault();
        alertaRemove(ref_form.current.other);

        if (consigneeId !== "") {

            let _index = allPaquages.length + 1;
            let _pack = {
                "index": _index,
                "id": 0,
                "type": "N/A",
                "pieces": 1,
                "codigo": "",
                "valorp": "0",
                "valord": valDomc,
                "valorm": "5000",
                "descripcion": "",
                "attachments": "",
                "estado": 0,
            };

            setPackages({ ...packages, [_index]: _pack });

            setAllPaquages([...allPaquages, <Container key={_index} pack={_pack} />]);
        }
    }

    const removePackage = (_index) => {
        let _pack_list = [];

        setPackages(p => {
            let _new_list;

            for (let i in p) {
                if (p[i].index !== _index) {
                    _new_list = { ..._new_list, [i]: p[i] };
                    _pack_list.push(<Container key={p[i].index} pack={p[i]} />);
                }
            }

            return _new_list;
        });

        setAllPaquages(_pack_list);
    }

    const Container = ({ pack }) => {

        return (
            <NewContainer
                item={pack}
                remove={removePackage}
                update={item => setPackages(p => ({ ...p, [pack.index]: item }))} />
        );
    }

    const getAllList = async () => {
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: "miinfo",
                id: window.atob(data_user.auth)
            }
        });
        let _users = [];


        if (_data.response == 1) {

            _data.data.map(item => {
                item.nivel > "2" && _users.push(item);
            });

            setUserList(_users);
            setMyUserList(_users.map(({ nombre, apellido }) => (nombre + " " + apellido)));
            copyConsignee(_users);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        return _users;
    }

    const format = (_num) => {
        return _num < 10 ? "0" + _num : _num;
    }

    const saveOrder = async () => {
        setShowLoading(true);
        let _info = {
            estado: 0,
            date_pickup: fechaIngreso.getFullYear() + "-" + format(fechaIngreso.getMonth() + 1) + "-" + format(fechaIngreso.getDate()),
            consignee: consigneeId,
            carrier: "",
            pickup_name: namePickupLocation,
            pickup_address: addressPickupLocation,
            notes: notes,
            collect: collectPrice.replaceAll(".", ""),
            packages: packages
        };

        let _data = await api.savePickUpOrder(_info);

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => history.goBack());
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _is_empty = false;
        let _message = "";

        alertaRemove(ref_form.current.consignee);
        alertaRemove(ref_form.current.other);
        alertaRemove(ref_form.current.address_pickup_location);

        if (consignee.length < 1) {
            alerta(ref_form.current.consignee, "Debes ingresar un Cliente bts!");
            ref_form.current.consignee.focus();
            window.scrollTo({
                top: 80,
                behavior: 'smooth'
            });
            return;
        }

        if (consigneeId.length < 1) {
            alerta(ref_form.current.consignee, "Este usuario no se encuentra registrado! \nAgregalo desde el icono ( + )");
            ref_form.current.consignee.focus();
            window.scrollTo({
                top: 80,
                behavior: 'smooth'
            });
            return;
        }

        alertaRemove(ref_form.current.name_pickup_location);

        if (namePickupLocation.length < 1) {
            alerta(ref_form.current.name_pickup_location, "Debes ingresar el nombre de la persona \ndonde se recogerá el paquete!");
            ref_form.current.name_pickup_location.focus();
            window.scrollTo({
                top: 180,
                behavior: 'smooth'
            });
            return;
        }

        if (pickupId.length < 1) {
            alerta(ref_form.current.name_pickup_location, "Este usuario no se encuentra registrado! \nAgregalo desde el icono ( + )");
            ref_form.current.name_pickup_location.focus();
            window.scrollTo({
                top: 180,
                behavior: 'smooth'
            });
            return;
        }

        // if (typeof packages !== "object" || Object.entries(packages).length < 1) {
        //     alertDown(ref_form.current.other, "Debes agregar almenos un paquete!");
        //     return;
        // }

        for (let i in packages) {

            if (packages[i]["pieces"] < 1) {
                _is_empty = true;
                _message = `La cantidad de "piezas" no puede ser menor a uno!`;
                break
            }

            if (packages[i]["type"].length < 1) {
                _is_empty = true;
                _message = `Debes indicar el tipo de paquete`;
                break
            }
        }


        if (_is_empty) {
            Alert(_message);
            return;
        }

        saveOrder();
    }

    const copyConsignee = (_users) => {

        if (consignee.length > 1) {
            let item = _users[0];

            if (typeof item === "object") {
                alertaRemove(ref_form.current.name_pickup_location);

                setNamePickupLocation(item.nombre + " " + item.apellido);
                setAddressPickupLocation(item.address);
                setPickupId(item.id_user);
            } else {
                setNamePickupLocation("");
                setAddressPickupLocation("");
                setPickupId(-1);
            }
        }
    }

    const changePickup = (val) => {
        setNamePickupLocation(val);
        alertaRemove(ref_form.current.name_pickup_location);

        let item = userList[0];

        if (typeof item === "object") {
            let _name = item.nombre + " " + item.apellido;
            if (_name === val) {
                setPickupId(item.id_user);
            } else {
                setPickupId(-1);
            }
        } else {
            setPickupId(-1);
        }
    }

    const changePickupAddress = (val) => {
        setAddressPickupLocation(val);
        alertaRemove(ref_form.current.address_pickup_location);

        let item = userList[0];

        if (typeof item === "object") {
            let _name = item.nombre + " " + item.apellido;
            if (_name === namePickupLocation && item.address === val) {
                setPickupId(item.id_user);
            } else {
                setPickupId(-1);
            }
        } else {
            setPickupId(-1);
        }
    }

    React.useEffect(() => {
        setTitulo("Nueva orden de recogida");
        getAllList();
    }, []);

    return (
        <form className="pickup" ref={ref_form}>
            <h3 className="_title">Nueva orden de recogida</h3>

            <div className="pickup_container" style={{ marginBottom: 28 }}>

                <div className="pickup_row">

                    <div className="pickup_column static">

                        <div className="pickup_column m_less">
                            <label style={{ height: 24 }}>Fecha de recogida</label>
                            <div className="field_icon">
                                <DatePicker dateFormat="yyyy/MM/dd" name="pickup_date" selected={fechaIngreso} onChange={(date) => setFechaIngreso(date)} />
                                <CalendarMonthIcon />
                            </div>
                        </div>

                        <div className="pickup_column m_less">
                            <label style={{ marginTop: 12 }}>Nota</label>
                            <textarea name="notes" rows="2" placeholder="Ingresa una observación"
                                value={notes} onChange={({ target: { value } }) => setNotes(value)} />
                        </div>

                    </div>

                    <div className="pickup_column static">

                        <div className="pickup_column m_less">
                            <label style={{ height: 24 }}>Persona de contacto</label>
                            <Input
                                list={myUserList}
                                name="name_pickup_location"
                                placeholder="Ingresa usuario"
                                value={namePickupLocation}
                                onChange={changePickup}
                            />
                        </div>

                        <label style={{ marginTop: 12 }}>Dirección</label>
                        <div style={{ position: "relative" }}>

                            <textarea name="address_pickup_location" rows="2" placeholder="ingresa dirección."
                                value={addressPickupLocation} onChange={({ target: { value } }) => changePickupAddress(value)} />
                        </div>
                    </div>

                </div>

                {allPaquages}
            </div>

            <div className="boton_agregar">

                <div className="for_alert">
                    <button className={consigneeId !== "" ? "btn btn-common" : "btn btn-common _deshabilitado"}
                        type="button" name="other"
                        onClick={addPackage}>
                        <PlusCircleOutlineIcon /> Agregar paquete
                    </button>
                </div>

                <button type="button" className="btn btn-common width-static" onClick={validateFields}>Guardar</button>
                <button type="button" className="btn btn-common width-static" onClick={() => history.goBack()}>Cancelar</button>

            </div>

            {showLoading && <Loading />}
        </form >
    );
}

export default NewPickUp;