import * as React from "react";
import CloseOutlineIcon from "mdi-react/CloseCircleOutlineIcon";
import Information from "mdi-react/InformationOutlineIcon";
import { useHistory } from "react-router-dom";
import funciones from "services/funciones";
import * as Icon from "../../../images";
import { Alert } from "shared/Alert";
import { Input } from "shared/Input";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */

const CierreCaja = ({ data, pos, close, reload }) => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(false);
    const [transferencia, setTransferencia] = React.useState("");
    const [transactions, setTransactions] = React.useState([]);
    const [efectivo, setEfectivo] = React.useState("");
    const [error, setError] = React.useState("");
    const [msg, setMsg] = React.useState("");
    const history = useHistory();

    const handleAllCash = () => {
        let _efec = parseInt(data.subtolal || 0) - parseInt(data.transferencia || 0);
        setEfectivo(_efec + parseInt(data.transferencia || 0));
        setTransferencia("");
        setError("");
    }

    const handleAllTransfer = () => {
        let _efec = parseInt(data.subtolal || 0) - parseInt(data.transferencia || 0);
        setEfectivo("");
        setTransferencia(_efec + parseInt(data.transferencia || 0));
        setError("");
    }

    const handleClose = () => {
        reload();
        close(false);
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _error = false;

        setError("");

        if (parseInt(data.subtolal || 0) == 0) {
            setError("¡No hay contenido para realizar un corte!");
            return;
        }

        if (efectivo.length < 1 && transferencia.length < 1) {
            setError("¡Debes ingresar un valor en efectivo o transferencias!");
            return;
        }

        if (efectivo.length >= 1) {

            if (isNaN(efectivo)) {
                setError("¡El valor en efectivo solo admite numeros!");
                return;
            }

            if (parseInt(efectivo) < 0) {
                setError("¡El monto en efectivo no puede ser menor que cero!");
                return;
            }

        }

        if (transferencia.length >= 1) {

            console.log(isNaN(transferencia));
            if (isNaN(transferencia)) {
                setError("¡El valor en transferencias solo admite numeros!");
                return;
            }

            if (parseInt(transferencia) < 0) {
                setError("¡El monto en transferencias no puede ser menor que cero!");
                return;
            }

        }


        if (!_error) {
            pagaCaja();
        }
    }

    const pagaCaja = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "save-caja",
            data: {
                task: "corte_cliente",
                id_user: data.id_user,
                efectivo: efectivo == "" ? 0 : funciones.formatPrice(efectivo).int,
                transferencia: transferencia == "" ? 0 : funciones.formatPrice(transferencia).int
            }
        });

        if (_data.response == 1) {
            let _respuesta = _data.data[0];

            if (_respuesta.response == 1) {
                let _entregada = parseInt(_respuesta.cantidad_entregada) > 0 ? `${_respuesta.cantidad_entregada} entrega${_respuesta.cantidad_entregada < 2 ? "" : "s"} = $${funciones.formatPrice(_respuesta.valor_entregado).format}` : "¡Sin entregas!";
                let _devuelto = parseInt(_respuesta.candidad_devuelta) > 0 ? `${_respuesta.candidad_devuelta} paquete${_respuesta.candidad_devuelta < 2 ? "" : "s"} para devolver` : "¡Sin devociones!";
                let _efectivo = parseInt(_respuesta.efectivo) > 0 ? `En efectivo = $${funciones.formatPrice(_respuesta.efectivo).format}` : "";
                let _transferencias = parseInt(_respuesta.transferencia) > 0 ? `En transferencia = $${funciones.formatPrice(_respuesta.transferencia).format}` : "";
                // let _dev_ko = parseInt(_respuesta.cantidad_ko_dv) > 0 ? _respuesta.ko_dv : "¡Sin entregas KO+DV!";
                let _dev_ko = parseInt(_respuesta.cantidad_ko_dv) > 0 ?
                    <>
                        <b>{_respuesta.ko_dv_cambio}</b> {_respuesta.resumen_ko_dv_cambio && <i><br />{_respuesta.resumen_ko_dv_cambio}</i>}
                        <br />
                        <b>{_respuesta.ko_dv_sobrante}</b> {_respuesta.resumen_ko_dv_sobrante && <i><br />{_respuesta.resumen_ko_dv_sobrante}</i>}
                        <br />
                    </> : <>¡Sin entregas KO+DV!</>;

                let _mensaje = <p className="_msg_p">
                    <b>Corte realizado con:</b>
                    <br />
                    <br />{_entregada}
                    <br />{_efectivo}
                    <br />{_transferencias}
                    <br />
                    <br />{_dev_ko}
                    <br /><b>{_devuelto}</b> {_respuesta.resumen_devoluciones && <><br /><i>{_respuesta.resumen_devoluciones}</i></>}</p>;

                setMsg(_mensaje);
                setIsLoaded(true);
            } else {
                setError(_respuesta.msg);
            }
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            setError(_data.msg);
        }

        setShowLoading(false);
    }

    const BtnAceptar = () => {
        if (isLoaded) {
            return <button className="btn btn-common width-static" onClick={handleClose}>Aceptar</button>;
        } else {
            if (showLoading) {
                return <button className="btn btn-common width-static"><Icon.Loading /></button>;
            } else {
                return <button className="btn btn-common width-static" onClick={validateFields}>Pagar</button>;
            }
        }
    }

    React.useEffect(() => {
        setEfectivo(parseInt(data.subtolal || 0) - parseInt(data.transferencia || 0));
        setTransferencia(parseInt(data.transferencia || 0));

        if (data.transactions !== null) {
            let _tran = data.transactions.split("|");
            let _pagos = [];

            _tran.map(item => {
                let _arr = item.split(": ");
                _pagos.push({ entity: _arr[0], number: _arr[1] });
            });

            setTransactions(_pagos);
        }
    }, [data]);

    return (
        <>
            <div className="back_form"></div>
            <form className="win_modal _overflow _delivery" style={pos}>
                <h3 className="_titulo" style={{ marginBottom: 0 }}><CloseOutlineIcon onClick={() => close(false)} />Pago para <br />"{data.nombre} {data.apellido}"</h3>

                <div className="_in_column">
                    {isLoaded ?
                        <>
                            {msg}
                        </>
                        :
                        <>
                            {transactions.map((item, index) => <p key={index} className="_metodo_pago"><b>{item.entity}:</b><br /> {item.number}</p>)}

                            <h4 className="_precio">Total: $ {funciones.formatPrice(data.subtolal || 0).format}</h4>

                            <div>
                                <label className="__titulo">Efectivo</label>
                                <Input type="tel" name="efectivo" placeholder="ingresa el valor"
                                    value={funciones.formatPrice(efectivo).format} onChange={val => { setError(""); setEfectivo(val); }} />
                            </div>
                            <div>
                                <p className="_p_arrow"> <span onClick={handleAllCash}><Icon.ArrowCurveUp /> Todo en efectivo</span></p>
                            </div>

                            <div>
                                <label className="__titulo">Transferencias</label>
                                <Input type="tel" name="transferencia" placeholder="ingresa el valor"
                                    value={funciones.formatPrice(transferencia).format} onChange={val => { setError(""); setTransferencia(val); }} />
                            </div>
                            <div>
                                <p className="_p_arrow"> <span onClick={handleAllTransfer}><Icon.ArrowCurveUp /> Todo en transferencia</span></p>
                            </div>
                        </>
                    }
                </div>

                {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

                <BtnAceptar />
            </form>
        </>
    );
}

export default CierreCaja;