import * as React from 'react';
import Information from 'mdi-react/InformationOutlineIcon';
import CloseIcon from 'mdi-react/CloseCircleOutlineIcon';
import { useHistory } from 'react-router-dom';
import EyeOffIcon from "mdi-react/EyeOffIcon";
import EyeIcon from "mdi-react/EyeIcon";
import api from "services/api";
import CryptoJS from "crypto-js";
import { Alert } from "./Alert";
import { Input } from "shared/Input";
import funciones from 'services/funciones';

const media_movil = window.matchMedia('(max-width: 500px)').matches;

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Formulario = ({ position, toggle, change, rol }) => {
  const [nombre, setNombre] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [idtipe, setIdtipe] = React.useState("Cédula de Ciudadanía");
  const [identification, setIdentification] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPass] = React.useState("");
  const [city, setCity] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [error, setError] = React.useState("");
  const [role, setRole] = React.useState(rol);
  const [cityList, setCityList] = React.useState([]);
  const [showPass, setShowPass] = React.useState(false);
  const [task, setTask] = React.useState();
  const [pagos] = React.useState([]);

  const ref_name = React.useRef();
  const ref_lastname = React.useRef();
  const ref_identification = React.useRef();
  const ref_phone = React.useRef();
  const ref_email = React.useRef();
  const ref_address = React.useRef();

  const history = useHistory();


  /**
   * Niveles de los usuarios 
   * 
   * 1 Super Administrador
   * 2 Administrador
   * 3 Coordinador de zona
   * 4 Mensajero
   * 5 Recepcionista
   * 6 Cliente bts
   * 7 Usuario final
   * 
   */

  const changeTask = (value) => {
    let toCreate;

    switch (value) {
      case "1":
        toCreate = "super administrador";
        break;
      case "2":
        toCreate = "administrador";
        break;
      case "3":
        toCreate = "coordinador de zona";
        break;
      case "4":
        toCreate = "mensajero";
        break;
      case "5":
        toCreate = "recepcionista";
        break;
      case "6":
        toCreate = "cliente bts";
        break;
      case "7":
      default:
        toCreate = "usuario final";
        break;
    }

    setRole(parseInt(value));
    setTask(toCreate);
  }

  const getCityList = async () => {
    let _data = await api.getUsers("city");

    if (_data.response == 1) {

      setCityList(_data.data.map(item => (item.city)));
    } else if (_data.response === -2) {
      Alert(_data.msg, "warning", () => history.push("/"));
    } else {
      setError(_data.msg);
    }
  }

  const saveData = async () => {
    let _transactions = "";
    pagos.map(item => {
      _transactions += item.entity + ": " + item.number + "|";
    });
    setError("");
    let _pass = password.length > 1 ? CryptoJS.SHA256(password).toString() : null;
    let _info = {
      id: 0,
      nombre: nombre,
      lastname: lastname,
      idtipe: idtipe,
      identification: identification,
      phone: phone,
      email: email,
      city: city,
      address: address,
      password: _pass,
      rol: role,
      transactions: _transactions
    };

    let _data = await api.saveAgent(_info);

    if (_data.response == 1) {
      change(nombre + " " + lastname);
      toggle();
    } else if (_data.response === -2) {
      Alert(_data.msg, "warning", () => history.push("/"));
    } else {
      setError(_data.msg);
    }
  }

  const validateFields = (e) => {
    e.preventDefault();
    let _form = {
      name: ref_name.current,
      lastname: ref_lastname.current,
      identification: ref_identification.current,
      phone: ref_phone.current,
      email: ref_email.current,
      address: ref_address.current,
    };

    setError("");

    if (role == "0") {
      setError("Debes asignar un rol");
      return;
    }

    if (nombre.length < 1) {
      setError("El nombre no puede estar vacío");
      _form.name.focus();
      return;
    }

    if (role !== 7 && lastname.length < 1) {
      setError("El apellido no puede estar vacío");
      _form.lastname.focus();
      return;
    }

    if (role !== 7) {
      if (identification.length < 1) {
        setError("El numero del documento no puede estar vacío");
        _form.identification.focus();
        return;
      }
    }

    if (phone.length < 1) {
      setError("El teléfono no puede estar vacío");
      _form.phone.focus();
      return;
    }

    if (role !== 7) {
      if (email.length < 1) {
        setError("El correo no puede estar vacío");
        _form.email.focus();
        return;
      }
    }

    if (city.length < 1) {
      setError("La ciudad no puede estar vacía");
      return;
    }

    if (address.length < 1) {
      setError("La direccón no puede estar vacía");
      _form.address.focus();
      return;
    }


    if (!funciones.validaDireccion(address)) {
      setError("El formato de la direccón no es válido \nEjemplos: \nCra 94 # 69a - 42 \nCarrera 94a # 69 - 42 sur");
      _form.address.focus();
      return;
    }

    if (role === 6) {
      let _empty = false;
      pagos.map(item => {
        if (item.entity.length < 1 || item.number.length < 1) {
          _empty = true;
        }
      });

      if (_empty) {
        setError("La entidad y el número son requeridos en la transacción.");
        return;
      }
    }

    saveData();
  }

  React.useEffect(() => {
    getCityList();
  }, []);

  React.useEffect(() => {
    changeTask(rol);
  }, [rol]);

  return (
    <>
      <div className="back_form" onClick={toggle}></div>
      <div className="new_agent" style={position}>
        <h3>Nuevo {task} <CloseIcon onClick={toggle} /></h3>

        <input type="email" name="fake_email" style={{ display: "none" }} />
        {media_movil ?
          <div className="_in_column">

            <label>Nombre</label>
            <input type="text" name="name" value={nombre} ref={ref_name}
              onChange={({ target: { value } }) => setNombre(value)} />

            <label>Apellidos</label>
            <input type="text" name="lastname" value={lastname} ref={ref_lastname}
              onChange={({ target: { value } }) => setLastname(value)} />

            {role !== 7 && <>
              <label>Tipo de identificación</label>
              <select name="role" value={idtipe} onChange={({ target: { value } }) => setIdtipe(value)}>
                <option>Cédula de Ciudadanía</option>
                <option>Cédula de extranjería</option>
                <option>Pasapote</option>
                <option>NIT</option>
              </select>

              <label>Número de identificación</label>
              <input type="text" name="identification" value={identification} maxLength="12" ref={ref_identification}
                onChange={({ target: { value } }) => setIdentification(value)} />
            </>}

            <label>Teléfono</label>
            <input type="text" name="phone" value={phone} maxLength="15" ref={ref_phone}
              onChange={({ target: { value } }) => setPhone(value)} />

            {role !== 7 && <>
              <label>Correo</label>
              <input type="email" name="email" value={email} ref={ref_email}
                onChange={({ target: { value } }) => setEmail(value)} />

              <label>Contraseña</label>
              <div className="_toglle_pass">
                <input type={showPass ? "text" : "password"} name="password" value={password}
                  onChange={({ target: { value } }) => setPass(value)} />
                {showPass ? <EyeOffIcon onClick={() => setShowPass(false)} /> : <EyeIcon onClick={() => setShowPass(true)} />}
              </div>
            </>}
            <div className="_in_column">
              <label>Ciudad</label>
              <Input type="text" name="city" value={city} list={cityList}
                onChange={setCity} />
            </div>

            <div className="_in_column">
              <label>Dirección</label>
              <textarea name="address" rows="2" value={address} placeholder="Ingresa Dirección" ref={ref_address}
                onChange={({ target: { value } }) => setAddress(value)} />
            </div>


            {/* {role === 6 &&
              <>
                {pagos.map((item, index, array) => {
                  return <fieldset key={index}>
                    <legend>Transacción</legend>

                    <label>Entidad</label>
                    <input type="text" onChange={({ target: { value } }) => {
                      array[index]['entity'] = value;
                      setPagos([...array])
                    }} />

                    <label>Número</label>
                    <input type="text" onChange={({ target: { value } }) => {
                      array[index]['number'] = value;
                      setPagos([...array])
                    }} />
                  </fieldset>
                })}

                <h4 className="_button" onClick={() => {
                  setPagos([...pagos, { entity: "", number: "" }]);
                }}>Agregar transacción<CashPlus /></h4>

              </>
            } */}

          </div>
          :
          <div className="_in_row">

            <div className="_in_column">

              <label>Nombre</label>
              <input type="text" name="name" value={nombre} ref={ref_name}
                onChange={({ target: { value } }) => setNombre(value)} />

              <label>Apellidos</label>
              <input type="text" name="lastname" value={lastname} ref={ref_lastname}
                onChange={({ target: { value } }) => setLastname(value)} />


              <label>Teléfono</label>
              <input type="text" name="phone" value={phone} maxLength="15" ref={ref_phone}
                onChange={({ target: { value } }) => setPhone(value)} />

              {role !== 7 && <>
                <label>Correo</label>
                <input type="email" name="email" value={email} ref={ref_email}
                  onChange={({ target: { value } }) => setEmail(value)} />

                <label>Contraseña</label>
                <div className="_toglle_pass">
                  <input type={showPass ? "text" : "password"} name="password" value={password}
                    onChange={({ target: { value } }) => setPass(value)} />
                  {showPass ? <EyeOffIcon onClick={() => setShowPass(false)} /> : <EyeIcon onClick={() => setShowPass(true)} />}
                </div>
              </>}


              {/* {role === 6 &&
                <>
                  {pagos.map((item, index, array) => {
                    return <fieldset key={index}>
                      <legend>Transacción</legend>

                      <label>Entidad</label>
                      <input type="text" onChange={({ target: { value } }) => {
                        array[index]['entity'] = value;
                        setPagos([...array])
                      }} />

                      <label>Número</label>
                      <input type="text" onChange={({ target: { value } }) => {
                        array[index]['number'] = value;
                        setPagos([...array])
                      }} />
                    </fieldset>
                  })}

                  <h4 className="_button" onClick={() => {
                    setPagos([...pagos, { entity: "", number: "" }]);
                  }}>Agregar transacción<CashPlus /></h4>

                </>
              } */}
            </div>

            <div className="_in_column">

              {role !== 7 && <>
                <label>Tipo de identificación</label>
                <select name="role" value={idtipe} onChange={({ target: { value } }) => setIdtipe(value)}>
                  <option>Cédula de Ciudadanía</option>
                  <option>Cédula de extranjería</option>
                  <option>Pasapote</option>
                  <option>NIT</option>
                </select>

                <label>Número de identificación</label>
                <input type="text" name="identification" value={identification} maxLength="12" ref={ref_identification}
                  onChange={({ target: { value } }) => setIdentification(value)} />
              </>}

              <div className="_in_column">
                <label>Ciudad</label>
                <Input type="text" name="city" value={city} list={cityList}
                  onChange={setCity} />
              </div>

              <div className="_in_column">
                <label>Dirección</label>
                <textarea name="address" rows="2" value={address} placeholder="Ingresa Dirección" ref={ref_address}
                  onChange={({ target: { value } }) => setAddress(value)} />
              </div>
            </div>

          </div>
        }

        {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

        <div className="_in_row">
          <button className="btn btn-common width-static" onClick={validateFields}>Guardar</button>
          <button className="btn btn-common width-static" onClick={toggle}>Cancelar</button>
        </div>

      </div>
    </>
  );
}

const NewAgent = ({ children, change, rol }) => {
  const [_pos, setPos] = React.useState({ top: "0", left: "0" });
  const [show, sertShow] = React.useState(false);


  const handleAction = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let _val_int = media_movil ? 300 : 510;
    let _wrap = document.getElementsByClassName("container__wrap")[0].offsetWidth;
    let _win_opt = e.pageX + _val_int;
    let _posx = _win_opt > _wrap ? _wrap - _win_opt : 0;

    setPos({ top: "0px", left: _posx + "px", });
    sertShow(true);
  }

  return (
    <div className="btn_tool">

      <div className="icon_tool" onClick={handleAction}>
        {children}
      </div>

      {show && <Formulario
        position={_pos}
        toggle={() => sertShow(false)}
        change={change}
        rol={rol} />}

    </div>
  );
}


export default NewAgent;
