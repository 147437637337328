import * as React from "react";
import CloseOutlineIcon from "mdi-react/CloseCircleOutlineIcon";
import SwapVerticalIcon from "mdi-react/SwapVerticalIcon";
import { NewAgent } from "../Usuarios/components/Agent";
import PlugIcon from "mdi-react/AccountPlusIcon";
import Filter from "mdi-react/FilterMenuIcon";
import { SearchUsers } from "shared/Input";
import funciones from "services/funciones";
import MenuRow from "./components/MenuRow";
import useTitle from "hooks/useTitle";
import * as Icon from "../../images";
import Loading from "shared/Loading";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint no-unused-vars: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Clientes = (params) => {
    const { history, location } = params;
    const [rol, setRoll] = React.useState(7);
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [currentQuery, setCurrentQuery] = React.useState("pay_clients");
    const media_movil = window.matchMedia('(max-width: 576px)').matches;
    const [showLoading, setShowLoading] = React.useState(true);
    const [showLazy, setShowLazy] = React.useState(true);
    const [selected, setSelected] = React.useState({ id_user: 0 });
    const [showModal, setShowModal] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);
    const [dataList, setDataList] = React.useState([]);
    const [criterio, setCriterio] = React.useState("");
    const [asc, setAsc] = React.useState(false);
    const [task, setTask] = React.useState();
    const isMounted = React.useRef(false);
    const { setTitulo } = useTitle();
    const [pos, setPos] = React.useState({ top: "0", left: "0" });
    const [showFilter, setShowFilter] = React.useState(false);
    const [filtro, setFiltro] = React.useState(3);
    




    /**
     * Niveles de los usuarios 
     * 
     * 1 Super Administrador
     * 2 Administrador
     * 3 Coordinador de zona
     * 4 Mensajero
     * 5 Recepcionista
     * 6 Cliente bts
     * 7 Usuario final
     * 
     */

    const changeTask = (_rol) => {
        let toReturn;

        switch (parseInt(_rol)) {
            case 1:
                toReturn = "super administradores";
                break;
            case 2:
                toReturn = "administradores";
                break;
            case 3:
                toReturn = "coordinadores de zona";
                break;
            case 4:
                toReturn = "mensajeros";
                break;
            case 5:
                toReturn = "recepcionistas";
                break;
            case 6:
                toReturn = "clientes bts";
                break;
            case 7:
            default:
                toReturn = "usuarios finales";
                break;
        }

        setTask(toReturn);
        return toReturn;
    }

    const Row = ({ data, clase }) => {

        return (
            <MenuRow data={{ ...data, rol: rol }} clase={clase}
                history={history}
                current={selected.id_user}
                onSel={val => { isMounted.current && setSelected(val) }}
                reload={getList}>

                <td>{data.nombre} {data.apellido}</td>
                <td>{funciones.formatPrice(data.pagos).format}</td>
                <td>{data.telefono}</td>
            </MenuRow>
        );
    }

    const AllRow = () => {
        let _data = [];
        let _blue = 0;
        let _clase = "";

        const handleModal = (e) => {
            let _wrap = document.getElementsByClassName("container__wrap")[0].offsetWidth;
            let _win_opt;
            let _posx;

            if (media_movil) {
                _win_opt = e.pageX + 300;
                _posx = 12;
            } else {
                _win_opt = e.pageX + 500;
                _posx = _win_opt > _wrap ? _wrap - 530 : e.pageX;
            }

            let _window = <NewAgent
                data={{ rol: rol }}
                pos={{ top: "150px", left: _posx + "px" }}
                close={() => {
                    setShowModal(false);
                    getList();
                }} />;


            setModalView(_window);
            setShowModal(true);
        }

        dataList.map(item => {
            let _resum_obj = JSON.parse(item.resumen);

            item.pagos = parseInt(_resum_obj.tolal || 0);

            _clase = _blue == 0 ? "table_row bkg_blue" : "table_row";

            if (item.pagos > 0) {
                _clase = "table_row bkg_yellow";
            }

            if (item.pagos < 0) {
                _clase = "table_row bkg_red";
            }

            _data.push(<Row key={item.id_user} clase={_clase} data={item} />);
            _blue = _blue >= 1 ? 0 : _blue + 1;
        });

        if (_data.length < 1) {
            return <>
                <h4 className="_titulo">No hay {task}</h4>
                <h4 className="_button" onClick={handleModal}>Crear Nuevo <PlugIcon /></h4>

                {showModal && modalView}
            </>;
        } else {
            return <>
                <table className="table">
                    <tbody>

                        <tr className="table_title">
                            <th><SwapVerticalIcon onClick={() => sortBy("nombre")} />Nombre y apellido</th>
                            <th>Pagos</th>
                            <th>Teléfono</th>
                        </tr>

                        {_data}
                    </tbody>
                </table>

                {showLoading ?
                    <button className="btn_lazy_load"><Icon.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn_lazy_load" onClick={() => getListLazy()}>Cargar mas</button>
                            :
                            <h4 className="h4_lazy_load">¡No hay mas clientes!</h4>
                        }
                    </>
                }
            </>;
        }
    }

    const sortBy = (_key) => {

        let mapped = dataList.map((item, i) => {
            return { index: i, value: item[_key].toLowerCase() };
        });

        mapped.sort(function (a, b) {
            if (asc) {
                if (a.value > b.value) {
                    return 1;
                }

                if (a.value < b.value) {
                    return -1;
                }
            } else {
                if (a.value > b.value) {
                    return -1;
                }

                if (a.value < b.value) {
                    return 1;
                }
            }

            return 0;
        });

        let _new = mapped.map(item => {
            return dataList[item.index];
        });

        setAsc(!asc);
        setDataList(_new);
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: currentQuery,
                criterio: criterio,
                filtro: filtro,
                limit: 20,
                offset: dataList.length,
                last_id: dataList[dataList.length - 1].id_user
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setDataList(_packs);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const handleSearch = async (_val) => {
        _val = _val.toLowerCase();
        setCriterio(_val);

        setShowLoading(true);
        setCurrentQuery("search_clients");
        setDataList([]);
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: "search_clients",
                criterio: _val,
                limit: 20,
                offset: 0,
                last_id: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async () => {
        setShowLoading(true);
        setShowFilter(false);
        setCurrentQuery("pay_clients");
        setDataList([]);
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: "pay_clients",
                limit: 20,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const handleClose = (e) => {
        e.preventDefault();
        setShowFilter(false);
    }
    
    const handleFilter = async (e, _tipo) => {
        e.preventDefault();
        setShowFilter(false);

        setShowLoading(true);
        setCurrentQuery("filter_clients");
        setFiltro(_tipo);
        setDataList([]);
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: "filter_clients",
                filtro: _tipo,
                limit: 20,
                offset: 0,
                last_id: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 10 ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const handleOpenFilter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let _width = document.getElementById("root").offsetWidth;
        let _posx = (e.pageX + 216) > _width ? _width - 230 : e.pageX;

        setPos({ top: e.pageY, left: _posx + "px", });
        setShowFilter(true);
    }

    React.useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    React.useEffect(() => {
        if (data_user.nivel < 4) {
            if (location.state && location.state.rol) {
                let _rol = location.state.rol;
                let _task = changeTask(_rol);

                setRoll(_rol);
                setTitulo("Lista de " + _task);

                if (data_user.nivel <= 2) {
                    getList();
                }
            } else {
                history.push("/home");
            }
        } else {
            history.push("/home");
        }
    }, [location.state]);

    return (
        <>
            {data_user.nivel <= 2 ?
                <div className="cont_principal">
                    <div className="cont_title_bar">
                        <h3 className="_title">Lista de {task}</h3>

                        <Filter className="fliter_top" onClick={handleOpenFilter} />

                        <div className="search_bar">
                            <SearchUsers type="text" placeholder="Buscar por nombre"
                                value={criterio} onChange={handleSearch} />
                        </div>
                    </div>

                    <AllRow />
                </div>
                :
                <div className="cont_principal">
                    <h4 className="_titulo"> No cuentas con los permisos necesarios para ver este contenido</h4>
                </div>
            }

            {showFilter && <>
                <div className="back_form"></div>
                <div className="win_modal _overflow _for_color" style={pos}>
                    <h4><CloseOutlineIcon onClick={handleClose} /> Filtrar</h4>

                    <button onClick={e => getList()} type="button"><span style={{backgroundColor: "#000"}}></span>Todos</button>
                    <button onClick={e => handleFilter(e, 0)} type="button"><span style={{border: "1px solid #1e1e1e"}}></span>Sin entregas</button>
                    <button onClick={e => handleFilter(e, 1)} type="button"><span style={{backgroundColor: "rgba(250, 221, 60, 0.8)"}}></span>Por pagar</button>
                    <button onClick={e => handleFilter(e, 2)} type="button"><span style={{backgroundColor: "#f5320d"}}></span>Por cobrar</button>
                </div>
            </>}

            {showLoading && <Loading />}
        </>
    );
}

export default Clientes;
