import * as React from "react";
import BarcodeScanIcon from "mdi-react/BarcodeScanIcon";
import CajaIcon from "mdi-react/CashRegisterIcon";
import PlusIcon from "mdi-react/PlusThickIcon";
import { Alert, Confirm } from "shared/Alert";
import DeleteIcon from "mdi-react/DeleteIcon";
import { NewAgent, EditAgent } from "./Agent";
import * as Iconos from "../../../images";
import EditIcon from "mdi-react/EditIcon";
import PackageList from "./PackageList";
import OrderList from "./OrderList";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint no-unused-vars: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuRow = (params) => {
    const { history, data, blue, children, onSel, reload, current } = params;
    const [_pos, setPos] = React.useState({ top: "0", left: "0" });
    const [showModal, setShowModal] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);
    const [showMenu, setShowMenu] = React.useState(false);
    const [media_movil] = React.useState(window.matchMedia('(max-width: 576px)').matches);
    const [clases, setClases] = React.useState(blue == 0 ? "table_row bkg_blue" : "table_row");
    const row = React.useRef(null);


    const asignaPaquete = async (codigo) => {
        let _data = await api.fetchJson({
            url: "save-assign-package-code",
            data: {
                codigo: codigo,
                mensajero: data.id_user
            }
        });

        if (_data.response == 1) {
            Confirm(`El paquete ha sido asignado correctamente a "${data.nombre} ${data.apellido}" \n\n¿Quieres asignarle otro paquete?`, response => {
                if (response) {
                    callScanner();
                } else {
                    reload();
                }
            });
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const asignaRecogida = async (codigo) => {
        let toSend = codigo.split("-");
        let _data = await api.fetchJson({
            url: "save-assign-order-code",
            data: {
                mensajero: data.id_user,
                consignee: toSend[0],
                consecutive: toSend[1]
            }
        });

        if (_data.response == 1) {
            Confirm(`La recogida ha sido asignada correctamente a "${data.nombre} ${data.apellido}" \n\n¿Quieres asignarle otra recogida?`, response => {
                if (response) {
                    callScanner();
                } else {
                    reload();
                }
            });
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const asignaRecogidas = (e) => {
        e.preventDefault();
        e.stopPropagation();

        handleModal("pickup");
    }

    const asignaEntregas = (e) => {
        e.preventDefault();
        e.stopPropagation();

        handleModal("paquetes");
    }

    const callScanner = () => {
        if (typeof android !== "undefined") {

            // eslint-disable-next-line no-undef
            scanner.response = (codigo) => {
                if (codigo.substring(0, 3) === "OS-") {
                    console.log("Es una orden de servicio");
                    Alert("¡No se puede asignar una orden de servicio!", "warning");
                } else if (codigo.substring(0, 3) === "OR-") {
                    asignaRecogida(codigo.substring(3));
                } else {
                    asignaPaquete(codigo);
                }
            };

            // eslint-disable-next-line no-undef
            scanner.error = (format) => Alert(`Error de lectura: \nEl formato del código es "${format}" y no es compatible con esta función.`, "warning");

            // eslint-disable-next-line no-undef
            android.scannerQR();
        }
    }

    const handleScanner = (e) => {
        e.preventDefault();
        e.stopPropagation();

        callScanner();
        setShowMenu(false);
    }

    const goReport = (e) => {
        e.preventDefault()
        e.stopPropagation();

        history.push({
            pathname: "/reportes-admin",
            state: { nombre: data.nombre + " " + data.apellido }
        });
    }

    const goNew = (e) => {
        e.preventDefault()
        e.stopPropagation();

        handleModal("nuevo");
    }

    const goEdit = (e) => {
        e.preventDefault()
        e.stopPropagation();

        handleModal("editar");
    }

    const goBlockUser = (e) => {
        hiddenMenu(e);

        Confirm(`¿Estás seguro de bloquear la cuenta del mensajero "${data.nombre} ${data.apellido}"?`, async (response) => {
            if (response) {
                let _data = await api.deleteUser({ id_user: data.id_user });

                if (_data.response == 1) {
                    reload();
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => history.push("/"));
                } else {
                    Alert(_data.msg, "warning");
                }
            }
        });
    }

    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm(`¿Estás seguro de borrar la cuenta del mensajero "${data.nombre} ${data.apellido}"?`, async (response) => {
            if (response) {
                let _data = await api.deleteUser({ id_user: data.id_user });

                if (_data.response == 1) {
                    reload();
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => history.push("/"));
                } else {
                    Alert(_data.msg, "warning");
                }
            }
        });
    }

    const onSelect = (e) => {
        e.preventDefault()
        e.stopPropagation();

        onSel(data);
    }

    const stopClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let _height = window.innerHeight;
        let _wrap = document.getElementsByClassName("container__wrap")[0].offsetWidth;
        let _posx = (e.pageX + 200) > _wrap ? _wrap - 200 : e.pageX;
        let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;
        let _to_y = _to_border_bottom < 190 ? e.pageY - 190 : e.pageY;


        setPos({ top: _to_y + "px", left: _posx + "px", });
        setShowMenu(true);
    }

    const handleModal = (_option) => {
        let _wrap = document.getElementsByClassName("container__wrap")[0].offsetWidth;
        let _window;
        let _win_opt;
        let _posx;

        switch (_option) {
            case "pickup":
                if (media_movil) {
                    _win_opt = parseInt(_pos.left) + 360;
                    _posx = 12;
                } else {
                    _win_opt = parseInt(_pos.left) + 510;
                    _posx = _win_opt > _wrap ? _wrap - 510 : parseInt(_pos.left);
                }

                _window = <OrderList
                    data={{ ...data }}
                    pos={{ top: _pos.top, left: _posx + "px" }}
                    close={reload} />;
                break;
            case "paquetes":
                if (media_movil) {
                    _win_opt = parseInt(_pos.left) + 360;
                    _posx = 12;
                } else {
                    _win_opt = parseInt(_pos.left) + 510;
                    _posx = _win_opt > _wrap ? _wrap - 510 : parseInt(_pos.left);
                }

                _window = <PackageList
                    data={{ ...data }}
                    pos={{ top: _pos.top, left: _posx + "px" }}
                    close={reload} />;
                break;
            case "editar":
                if (media_movil) {
                    _win_opt = parseInt(_pos.left) + 360;
                    _posx = 12;
                } else {
                    _win_opt = parseInt(_pos.left) + 510;
                    _posx = _win_opt > _wrap ? _wrap - 510 : parseInt(_pos.left);
                }

                _window = <EditAgent
                    data={{ ...data, tipo: 1 }}
                    pos={{ top: _pos.top, left: _posx + "px" }}
                    close={reload} />;
                break;
            case "nuevo":
                if (media_movil) {
                    _win_opt = parseInt(_pos.left) + 360;
                    _posx = 12;
                } else {
                    _win_opt = parseInt(_pos.left) + 510;
                    _posx = _win_opt > _wrap ? _wrap - 510 : parseInt(_pos.left);
                }

                _window = <NewAgent
                    data={{ ...data, tipo: 1 }}
                    pos={{ top: _pos.top, left: _posx + "px" }}
                    close={reload} />;
                break;

            default:
                _window = null;
                break;
        }

        setShowMenu(false);
        setModalView(_window);
        setShowModal(true);
    }

    React.useEffect(() => {
        document.addEventListener("click", hiddenMenu);

        return () => {
            document.removeEventListener("click", hiddenMenu);
        }
    }, []);

    React.useEffect(() => {
        parseInt(data.sincorte) >= 1 && setClases(c => c + " bkg_orange");
    }, [data]);
    
    React.useEffect(() => {
        current === data.id_user && setClases(c => c + " bkg_select");
    }, [current]);

    return (
        <tr className={clases} ref={row}
            onDoubleClick={goReport}
            onClick={onSelect}
            onContextMenu={handleContextMenu}>

            {children}
            <td className="rowMenu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                {showMenu &&
                    <>
                        <div className="back_form" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu" style={_pos}>
                            <ul>
                                <li onClick={goReport}><CajaIcon /> <span><strong>Ver en cortes</strong></span></li>
                                <li onClick={goNew}><PlusIcon /> <span>Crear Nuevo</span></li>
                                <li onClick={goEdit}><EditIcon /> <span>Editar</span></li>

                                <hr />
                                <li onClick={asignaRecogidas}><Iconos.BoxMarker /> <span>Asignar recogidas</span></li>
                                <li onClick={asignaEntregas}><Iconos.BoxUser /> <span>Asignar entregas</span></li>
                                {typeof android !== "undefined" && <li onClick={handleScanner}><BarcodeScanIcon /> <span>Asigna con escaner</span></li>}

                                <hr />
                                {/* <li onClick={goBlockUser}><Iconos.Block /> <span>Bloquear</span></li> */}
                                <li onClick={goDelete}><DeleteIcon /> <span>Borrar</span></li>
                            </ul>
                        </nav>
                    </>}

                {showModal && modalView}

            </td>
        </tr>
    )

}

export default MenuRow;