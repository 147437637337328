import * as React from "react";
import Editcon from "mdi-react/PencilBoxMultipleOutlineIcon";
import ChartTimelineIcon from "mdi-react/ChartTimelineIcon";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import CashIcon from "mdi-react/CashMultipleIcon";
import FilerIcon from "mdi-react/FilterMenuIcon";
import { Card, CardBody, Col } from "reactstrap";
import FilterConcept from "./FilterConcept";
import funciones from "services/funciones";
import { useHistory } from "react-router";
import * as Icon from "../../../images";
import FilterDate from "./FilterDate";
import { Alert } from "shared/Alert";
import EditAbono from "./EditAbono";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const Movimientos = ({ valores, tipo_caja }) => {
    const [previousQuery, setPreviousQuery] = React.useState({ task: "movimientos", offset: 0 });
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [showLoading, setShowLoading] = React.useState(true);
    const [showModal, setShowModal] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);

    const history = useHistory();

    const handlerRefresh = (e) => {
        e.preventDefault();
        getList();
    }

    const handleModal = (e, _option, _data) => {
        e.preventDefault();
        e.stopPropagation();

        let _pos;
        let _to_y;
        let _window;
        let _height = window.innerHeight;
        let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;

        switch (_option) {
            case "abono":
                _to_y = _to_border_bottom < 335 ? e.pageY - 640 : e.pageY - 320;
                _pos = { top: _to_y + "px", left: (e.pageX - 24) + "px", maxWidth: 230 };

                _window = <EditAbono
                    pos={_pos}
                    data={_data}
                    close={setShowModal}
                    reload={reloadList} />;
                break;
            case "fil_fecha":
                _pos = { top: (e.pageY - 350) + "px", left: (e.pageX - 16) + "px", maxWidth: 230 };

                _window = <FilterDate
                    pos={_pos}
                    close={setShowModal}
                    call={filterList} />;
                break;
            case "fil_concepto":
                let _posx = e.pageX - 16;
                let _width = document.getElementById("root").offsetWidth;
                _posx = (_posx + 250) > _width ? _width - 250 : _posx;
                _pos = { top: (e.pageY - 350) + "px", left: _posx + "px", maxWidth: 230 };

                _window = <FilterConcept
                    pos={_pos}
                    close={setShowModal}
                    call={filterList} />;
                break;
            default:
                _window = null;
                break;
        }

        setModalView(_window);
        setShowModal(true);
    }

    const filterList = async (_criterio) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-caja",
            data: {
                task: _criterio.task,
                criterio: _criterio.criterio,
                caja: tipo_caja,
                offset: 0
            }
        });

        if (_data.response == 1) {
            if (_data.data.length < 1) {
                Alert(`No se encontraron resultados con \n"${_criterio.criterio}" intentalo de nuevo.`, "warning");
            } else {
                setPreviousQuery({ task: _criterio.task, offset: 0 });
                setShowLazy(_data.data.length < 50 ? false : true);
                setDataList(_data.data);
            }
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowModal(false);
        setShowLoading(false);
    }

    const reloadList = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-caja",
            data: {
                task: previousQuery.task,
                caja: tipo_caja,
                limit: dataList.length,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getOnly = async (e) => {
        e.preventDefault();
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-caja",
            data: {
                task: "abonos",
                caja: tipo_caja,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setPreviousQuery({ task: "abonos", offset: 0 });
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-caja",
            data: {
                task: previousQuery.task,
                caja: tipo_caja,
                offset: dataList.length
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setPreviousQuery({ task: previousQuery.task, offset: dataList.length });
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_packs);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-caja",
            data: {
                task: "movimientos",
                caja: tipo_caja,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setPreviousQuery({ task: "movimientos", offset: 0 });
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const History = () => {
        return <div className="dashboard__sales-table">
            {dataList.length < 1 ?
                <h4>No hay movimientos en esta caja</h4>
                :
                <>
                    <table className="table">
                        <tbody>

                            <tr className="table_title">
                                <th><FilerIcon className="to_filter" onClick={e => handleModal(e, "fil_fecha")} />Fecha</th>
                                <th>Tipo</th>
                                <th>Monto</th>
                                <th><FilerIcon className="to_filter" onClick={e => handleModal(e, "fil_concepto")} />Concepto</th>
                                <th style={{ whiteSpace: "nowrap" }}>Quien registró</th>
                            </tr>

                            {dataList.map((item, index) => {
                                let fecha_creado = new Date(item.fecha);

                                return <tr key={index}>
                                    <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</td>
                                    <td>{item.metodo == 1 ? "efectivo" : "transferencia"}</td>
                                    <td style={{ textAlign: "right" }} className="con_icon">
                                        {(item.destinatario && data_user.nivel < 3) && <Editcon onClick={e => handleModal(e, "abono", item)} />}
                                        {funciones.formatPrice(item.monto).format}
                                    </td>
                                    <td style={{ whiteSpace: "nowrap" }}>{item.concepto}</td>
                                    <td style={{ whiteSpace: "nowrap" }}>{item.gestor_name}</td>
                                </tr>
                            })}

                        </tbody>
                    </table>

                    {showLoading ?
                        <button className="btn_lazy_load"><Icon.Loading /></button>
                        :
                        <>
                            {showLazy ?
                                <button className="btn_lazy_load" onClick={() => getListLazy()}>Cargar mas</button>
                                :
                                <h4 className="h4_lazy_load">¡No hay mas información!</h4>
                            }
                        </>
                    }
                </>
            }
        </div>;
    }

    React.useEffect(() => {
        getList();
    }, [valores]);

    return (
        <div className="content_reportes">
            <Col className="col_table">
                <Card style={{ overflow: "hidden" }}>
                    <CardBody className="dashboard__card-widget">
                        <div className="card__title">
                            <h5 className="bold-text"><ChartTimelineIcon className="dashboard__money-icon" /> Movimientos</h5>

                            {showLoading ?
                                <button className="cash-button"><Icon.Loading /></button>
                                :
                                <button className="cash-button" onClick={getOnly}><CashIcon />Ver solo abonos</button>
                            }
                        </div>

                        <div className="panel__btns">

                            <button
                                className="panel__btn"
                                aria-label="panel__btn"
                                type="button"
                                onClick={handlerRefresh}>
                                <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                            </button>

                        </div>

                        <History />
                    </CardBody>
                </Card>
            </Col>

            {showModal && modalView}
        </div>
    )
}

export default Movimientos;