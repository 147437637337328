import * as React from "react";
import { NewAgent, EditAgent } from "../../Usuarios/components/Agent";
import CajaIcon from "mdi-react/CashRegisterIcon";
import PlusIcon from "mdi-react/PlusThickIcon";
import { Alert, Confirm } from "shared/Alert";
import DeleteIcon from "mdi-react/DeleteIcon";
import EditIcon from "mdi-react/EditIcon";
import PackageList from "./PackageList";
import History from "shared/History";
import OrderList from "./OrderList";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint no-unused-vars: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuRow = (params) => {
    const { history, data, clase, children, onSel, reload, current } = params;
    const [_pos, setPos] = React.useState({ top: "0", left: "0" });
    const [showModal, setShowModal] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);
    const [showMenu, setShowMenu] = React.useState(false);
    const [media_movil] = React.useState(window.matchMedia('(max-width: 576px)').matches);
    const [clases, setClases] = React.useState(clase);
    const row = React.useRef(null);

    const goReport = (e) => {
        e.preventDefault()
        e.stopPropagation();

        history.push({
            pathname: "/reportes-admin",
            state: { nombre: data.nombre + " " + data.apellido }
        });
    }

    const goNew = (e) => {
        e.preventDefault()
        e.stopPropagation();

        handleModal("nuevo");
    }

    const goEdit = (e) => {
        e.preventDefault()
        e.stopPropagation();

        handleModal("editar");
    }

    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm(`¿Estás seguro de borrar la cuenta de "${data.nombre} ${data.apellido}"?`, async (response) => {
            if (response) {
                let _data = await api.deleteUser({ id_user: data.id_user });

                if (_data.response == 1) {
                    reload();
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => history.push("/"));
                } else {
                    Alert(_data.msg, "warning");
                }
            }
        });
    }

    const onSelect = (e) => {
        e.preventDefault()
        e.stopPropagation();

        setTimeout(() => {
            onSel(data);
        }, 400);
    }

    const stopClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let _height = window.innerHeight;
        let _wrap = document.getElementsByClassName("container__wrap")[0].offsetWidth;
        let _posx = (e.pageX + 180) > _wrap ? _wrap - 180 : e.pageX;
        let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;
        let _to_y = _to_border_bottom < 120 ? e.pageY - 120 : e.pageY;

        setPos({ top: _to_y + "px", left: _posx + "px", });
        setShowMenu(true);
    }

    const handleModal = (_option) => {
        let _wrap = document.getElementsByClassName("container__wrap")[0].offsetWidth;
        let _window;
        let _win_opt;
        let _posx;

        switch (_option) {
            case "pickup":
                _win_opt = parseInt(_pos.left) + 510;
                _posx = _win_opt > _wrap ? _wrap - 510 : parseInt(_pos.left);

                _window = <OrderList
                    data={{ ...data }}
                    pos={{ top: _pos.top, left: _posx + "px" }}
                    close={reload} />;
                break;
            case "paquetes":
                _win_opt = parseInt(_pos.left) + 510;
                _posx = _win_opt > _wrap ? _wrap - 510 : parseInt(_pos.left);

                _window = <PackageList
                    data={{ ...data }}
                    pos={{ top: _pos.top, left: _posx + "px" }}
                    close={reload} />;
                break;
            case "history":
                _window = <History data={{ ...data, tipo: 1 }} pos={_pos} close={setShowModal} />;
                break;
            case "editar":
                if (media_movil) {
                    _win_opt = parseInt(_pos.left) + 300;
                    _posx = 12;
                } else {
                    _win_opt = parseInt(_pos.left) + 500;
                    _posx = _win_opt > _wrap ? _wrap - 530 : parseInt(_pos.left);
                }

                _window = <EditAgent
                    data={{ ...data, tipo: 1 }}
                    pos={{ top: _pos.top, left: _posx + "px" }}
                    close={reload} />;
                break;
            case "nuevo":
                if (media_movil) {
                    _win_opt = parseInt(_pos.left) + 300;
                    _posx = 12;
                } else {
                    _win_opt = parseInt(_pos.left) + 500;
                    _posx = _win_opt > _wrap ? _wrap - 530 : parseInt(_pos.left);
                }

                _window = <NewAgent
                    data={{ ...data, tipo: 1 }}
                    pos={{ top: _pos.top, left: _posx + "px" }}
                    close={reload} />;
                break;

            default:
                _window = null;
                break;
        }

        setShowMenu(false);
        setModalView(_window);
        setShowModal(true);
    }

    React.useEffect(() => {
        document.addEventListener("click", hiddenMenu);
        // document.addEventListener("contextmenu", hiddenMenu);

        return () => {
            document.removeEventListener("click", hiddenMenu);
            // document.removeEventListener("contextmenu", hiddenMenu);
        }
    }, []);

    React.useEffect(() => {
        current === data.id_user && setClases(c => c + " bkg_select");
    }, [current]);

    return (
        <tr className={clases} ref={row}
            onDoubleClick={goReport}
            onClick={onSelect}
            onContextMenu={handleContextMenu}>

            {children}
            <td className="rowMenu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                {showMenu &&
                    <>
                        <div className="back_form" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu" style={_pos}>
                            <ul>
                                <li onClick={goReport}><CajaIcon /> <span><strong>Ver en cortes</strong></span></li>
                                <li onClick={goNew}><PlusIcon /> <span>Crear Nuevo</span></li>
                                <li onClick={goEdit}><EditIcon /> <span>Editar</span></li>

                                <hr />
                                <li onClick={goDelete}><DeleteIcon /> <span>Borrar</span></li>
                            </ul>
                        </nav>
                    </>}

                {showModal && modalView}

            </td>
        </tr>
    );

}

export default MenuRow;