import * as React from "react";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import ReportsExcel from "./components/ReportsExcel";
import ResumenCierre from "./components/ResumenCierre";
import Recogidas from "./components/Recogidas";
import Entregas from "./components/Entregas";
import { Container, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import Cortes from "./components/Cortes";
import useTitle from "hooks/useTitle";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-unused-vars: 0 */
/* eslint no-extend-native: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

Date.prototype.getWeekNumber = function () {
  var d = new Date(+this);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));

  return Math.ceil((((d - new Date(d.getFullYear(), 0, 1)) / 8.64e7) + 1) / 7);
};

const format = (_num) => {
  return _num < 10 ? "0" + _num : _num;
}

const Reports = (params) => {
  const { history } = params;
  const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
  const [listCountDelivery, setListCountDelivery] = React.useState([]);
  const [showLoading, setShowLoading] = React.useState(true);
  const [listPickups, setListPickups] = React.useState([]);
  const [resumenPago, setResumenPago] = React.useState([]);
  const { setTitulo } = useTitle();

  const [dates, setDates] = React.useState("10");
  const [fechaDesde, setFechaDesde] = React.useState(new Date());
  const [fechaHasta, setFechaHasta] = React.useState(new Date());
  const [objDates, setObjDates] = React.useState({
    dates: dates,
    desde: fechaDesde.getFullYear() + "-" + format(fechaDesde.getMonth() + 1) + "-" + format(fechaDesde.getDate()),
    hasta: fechaHasta.getFullYear() + "-" + format(fechaHasta.getMonth() + 1) + "-" + format(fechaHasta.getDate()),
  });


  const getPickups = async () => {
    let _nivel = "user";

    switch (parseInt(data_user.nivel)) {
      case 1:
      case 2:
      case 3:
        _nivel = "admin";
        break;
      case 4:
        _nivel = "carrier";
        break;
      case 6:
        _nivel = "cliente";
        break;
      default:
        _nivel = "user";
        break;
    }

    let _data = await api.fetchJson({
      url: "reportes",
      data: {
        task: "recogidas",
        nivel: _nivel
      }
    });

    if (_data.response == 1) {
      _data.data.map(item => {
        item.collect = parseInt(item.collect) || 0;
        return item;
      });

      setListPickups(_data.data);
    } else if (_data.response === -2) {
      Alert(_data.msg, "warning", () => history.push("/"));
    } else {
      Alert(_data.msg, "warning");
    }
  }

  const getCountDeliverys = async () => {
    let _nivel = "user";

    switch (parseInt(data_user.nivel)) {
      case 1:
      case 2:
      case 3:
        _nivel = "admin";
        break;
      case 4:
        _nivel = "carrier";
        break;
      case 6:
        _nivel = "cliente";
        break;
      default:
        _nivel = "user";
        break;
    }

    let _data = await api.fetchJson({
      url: "reportes",
      data: {
        task: "cuentaentregas",
        nivel: _nivel
      }
    });

    if (_data.response == 1) {
      let _delivery = _data.data.map(item => {
        item.counter = parseInt(item.counter) || 0;
        return item;
      });

      setListCountDelivery(_delivery);
    } else if (_data.response === -2) {
      Alert(_data.msg, "warning", () => history.push("/"));
    } else {
      Alert(_data.msg, "warning");
    }
  }

  const armaMensajero = (_entregas, _recogidas, _abonos) => {
    let _sales = [
      {
        id: 0,
        title: 'Totales',
        abonos: 0,
        entregas: 0,
        recogidas: 0,
        v_domicilio: 0,
        v_mensajero: 0,
        v_mensajero_r: 0,
        subtolal: 0,
        pendiente: 0,
        efectivo: 0,
        transferencia: 0,
        domicilio_ko: 0,
      },
    ];

    _entregas.map(item => {

      if (item.estado == 6 || item.estado == 9) {
        _sales[0].entregas += parseInt(item.price);

        _sales[0].subtolal += parseInt(item.price);
        _sales[0].v_mensajero += parseInt(item.v_mensajero);

        _sales[0].efectivo += parseInt(item.efectivo || 0);
        _sales[0].transferencia += parseInt(item.nequi || 0) + parseInt(item.daviplata || 0) + parseInt(item.bold || 0);
      } else if (item.estado == 8) {
        _sales[0].v_mensajero += parseInt(item.v_mensajero);
      } else {
        if (item.estado == 12) {
          let _ko_obj = item.domicilio_ko ? item.domicilio_ko.split(",").pop() : [];
          let _ko_dom_value = _ko_obj ? parseInt(_ko_obj.split(":")[1]) : 0;
          let _ko_dom_name = _ko_obj ? _ko_obj.split(":")[0] : "";

          _sales[0].domicilio_ko += _ko_dom_value;
          _sales[0].subtolal += _ko_dom_value;
          _sales[0].v_mensajero += parseInt(item.v_mensajero);

          if (_ko_dom_name.length > 0) {
            if (_ko_dom_name == "Efectivo") {
              _sales[0].efectivo += _ko_dom_value;
            } else {
              _sales[0].transferencia += _ko_dom_value;
            }
          }

        } else {
          _sales[0].pendiente += parseInt(item.price);
        }
      }

    });

    _recogidas.map(item => {
      _sales[0].recogidas += parseInt(item.collect_product || 0);
      _sales[0].v_mensajero_r += parseInt(item.collect_carrier || 0);

      _sales[0].subtolal -= parseInt(item.collect_product || 0);
      _sales[0].subtolal -= parseInt(item.collect_carrier || 0);
      _sales[0].efectivo -= parseInt(item.collect_carrier || 0);
    });

    _sales[0].efectivo -= _sales[0].v_mensajero;
    _sales[0].subtolal -= _sales[0].v_mensajero;
    _sales[0].abonos = parseInt(_abonos.monto || 0);
    _sales[0].subtolal -= _sales[0].abonos;
    _sales[0].efectivo -= _sales[0].abonos;

    setResumenPago(_sales);
  }

  const armaCliente = (_entregas, _recogidas, _abonos) => {
    // 304 378 3429
    let _sales = [
      {
        id: 0,
        title: 'Totales',
        abonos: 0,
        entregas: 0,
        recogidas: 0,
        v_domicilio: 0,
        v_domicilio_r: 0,
        v_mensajero: 0,
        subtolal: 0,
        pendiente: 0,
        efectivo: 0,
        transferencia: 0,
        domicilio_ko: 0,
      },
    ];

    _entregas.map(item => {

      if (item.estado == 6 || item.estado == 9) {
        _sales[0].entregas += parseInt(item.price);

        _sales[0].subtolal += parseInt(item.price);
        _sales[0].v_domicilio += parseInt(item.v_domicilio);

        _sales[0].efectivo += parseInt(item.efectivo || 0);
        _sales[0].transferencia += parseInt(item.nequi || 0) + parseInt(item.daviplata || 0) + parseInt(item.bold || 0);
      } else {
        if (item.estado == 15) {
          let _ko_dom_all = item.domicilio_ko ? item.domicilio_ko.split(",") : [];


          _ko_dom_all.map(_ko_obj => {
            let _ko_dom_value = _ko_obj ? parseInt(_ko_obj.split(":")[1]) : 0;
            let _ko_dom_name = _ko_obj ? _ko_obj.split(":")[0] : "";

            _sales[0].v_domicilio += parseInt(item.v_domicilio);
            _sales[0].domicilio_ko += _ko_dom_value;
            _sales[0].subtolal += _ko_dom_value;

            if (_ko_dom_name.length > 0) {
              if (_ko_dom_name == "Efectivo") {
                _sales[0].efectivo += _ko_dom_value;
              } else {
                _sales[0].transferencia += _ko_dom_value;
              }
            }

          });
        } else {
          _sales[0].pendiente += parseInt(item.price);
        }
      }

    });

    _recogidas.map(item => {
      _sales[0].recogidas += parseInt(item.collect_product || 0);
      _sales[0].v_domicilio_r += parseInt(item.collect_domic || 0);

      _sales[0].subtolal -= parseInt(item.collect_product || 0);
      _sales[0].subtolal -= parseInt(item.collect_domic || 0);
      _sales[0].efectivo -= parseInt(item.collect_domic || 0);
    });

    _sales[0].efectivo -= _sales[0].v_domicilio;
    _sales[0].subtolal -= _sales[0].v_domicilio;
    _sales[0].abonos = parseInt(_abonos.monto || 0);
    _sales[0].subtolal += _sales[0].abonos;
    _sales[0].efectivo -= _sales[0].abonos;

    setResumenPago(_sales);
  }

  const getSales = async () => {
    setShowLoading(true);
    let _nivel = "user";

    switch (parseInt(data_user.nivel)) {
      case 1:
      case 2:
      case 3:
        _nivel = "admin";
        break;
      case 4:
        _nivel = "carrier";
        break;
      case 6:
        _nivel = "cliente";
        break;
      default:
        _nivel = "user";
        break;
    }

    let _data = await api.fetchJson({
      url: "reportes",
      data: {
        task: "ingresos",
        nivel: _nivel
      }
    });

    let _data_pickup = await api.fetchJson({
      url: "reportes",
      data: {
        task: "pagos_recogidas",
        nivel: _nivel
      }
    });

    let _data_abonos = await api.fetchJson({
      url: "reportes",
      data: {
        task: "abonos",
        nivel: _nivel
      }
    });

    if (_data.response == 1) {

      if (data_user.nivel == 4) {
        armaMensajero(_data.data, _data_pickup.data, _data_abonos.data[0]);
      }

      if (data_user.nivel == 6) {
        armaCliente(_data.data, _data_pickup.data, _data_abonos.data[0]);
      }

    } else if (_data.response === -2) {
      Alert(_data.msg, "warning", () => history.push("/"));
    } else {
      Alert(_data.msg, "warning");
    }

    setShowLoading(false);
  }

  const calcWeek = (_fecha) => {
    let _restantes = 6 - _fecha.getDay();
    let _min_left = (60 - _fecha.getMinutes()) * 60000;
    let _hours_left = ((24 - _fecha.getHours()) * 3600000) - _min_left;
    let _time = _fecha.getTime() + (_restantes * 86400000) + _hours_left;

    let _fecha_d = new Date(_time - ((7 * 86400000) - 2400000));
    let _fecha_h = new Date(_time);

    return {
      desde: _fecha_d.getFullYear() + "-" + format(_fecha_d.getMonth() + 1) + "-" + format(_fecha_d.getDate()),
      hasta: _fecha_h.getFullYear() + "-" + format(_fecha_h.getMonth() + 1) + "-" + format(_fecha_h.getDate()),
    }
  }

  const calcNextWeek = (_fecha = new Date()) => {
    let _restantes = 7 - _fecha.getDay();
    let _time = _fecha.getTime() + (_restantes * 86400000);
    let _fecha_d = new Date(_time);
    let _fecha_h = new Date(_time + (6 * 86400000));

    return {
      desde: _fecha_d.getFullYear() + "-" + format(_fecha_d.getMonth() + 1) + "-" + format(_fecha_d.getDate()),
      hasta: _fecha_h.getFullYear() + "-" + format(_fecha_h.getMonth() + 1) + "-" + format(_fecha_h.getDate()),
    }
  }

  const calcAllDates = (_fecha = new Date()) => {
    let _fecha_d = new Date(0);
    let _fecha_h = new Date((31556926 * 300) * 1000);

    return {
      desde: _fecha_d.getFullYear() + "-" + format(_fecha_d.getMonth() + 1) + "-" + format(_fecha_d.getDate()),
      hasta: _fecha_h.getFullYear() + "-" + format(_fecha_h.getMonth() + 1) + "-" + format(_fecha_h.getDate()),
    }
  }

  React.useEffect(() => {
    setTitulo("Reportes de recogida y entrega");
    getSales();
    getPickups();
    getCountDeliverys();

  }, []);

  React.useEffect(() => {
    let _fecha = new Date();
    let _desde, _hasta, _new_date, _obj_dates;

    switch (parseInt(dates)) {
      case 10:
        _new_date = calcAllDates(_fecha);

        _desde = _new_date.desde;
        _hasta = _new_date.hasta;
        break;
      case 9:
        _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 2)}-01`;
        _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 2)}-30`;
        break;
      case 8:
        _new_date = calcNextWeek(_fecha);

        _desde = _new_date.desde;
        _hasta = _new_date.hasta;
        break;
      case 7:
        _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() - 5)}-01`;
        _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth())}-30`;
        break;
      case 6:
        _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() - 2)}-01`;
        _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth())}-30`;
        break;
      case 5:
        _desde = `${_fecha.getFullYear()}-01-01`;
        _hasta = `${_fecha.getFullYear()}-12-30`;
        break;
      case 4:
        _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 1)}-01`;
        _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 1)}-30`;
        break;
      case 3:
        _new_date = calcWeek(_fecha);

        _desde = _new_date.desde;
        _hasta = _new_date.hasta;
        break;
      case 2:
        _desde = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate() - 1);
        _hasta = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate() - 1);
        break;
      case 1:
        _desde = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate());
        _hasta = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate());
        break;
    }

    if (parseInt(dates) !== 11) {
      _obj_dates = {
        dates: dates,
        desde: _desde,
        hasta: _hasta,
      };

      setObjDates(_obj_dates);
    }
  }, [dates]);

  React.useEffect(() => {
    if (parseInt(dates) === 11) {
      let _obj_dates = {
        dates: dates,
        desde: fechaDesde.getFullYear() + "-" + format(fechaDesde.getMonth() + 1) + "-" + format(fechaDesde.getDate()),
        hasta: fechaHasta.getFullYear() + "-" + format(fechaHasta.getMonth() + 1) + "-" + format(fechaHasta.getDate()),
      };

      setObjDates(_obj_dates);
    }
  }, [fechaDesde, fechaHasta]);

  return (
    <Container className="dashboard">
      <Row>
        <h3 className="page-title">Reportes de recogidas y entregas</h3>

        <ReportsExcel fechas={objDates} sales={resumenPago}>

          <Row className="content_select">
            <select name="dates" defaultValue={dates} onChange={e => setDates(e.target.value)}>

              <option value="1">Hoy</option>
              <option value="2">Ayer</option>
              <option value="3">Esta semana</option>
              <option value="4">Este mes</option>
              <option value="6">Últimos tres meses</option>
              <option value="7">Últimos seis meses</option>
              <option value="5">Este año</option>
              {/* <option value="8">Siguiente semana</option> */}
              {/* <option value="9">Siguiente mes</option> */}
              <option value="10">Todos</option>
              <option value="11">Personalizado</option>

            </select>

            {dates === "11" &&
              <div className="cont_calendar">

                <div className="calendar">
                  <DatePicker dateFormat="yyyy/MM/dd" name="desde" selected={fechaDesde} onChange={(date) => setFechaDesde(date)} />
                  <CalendarMonthIcon />
                </div>

                <div className="calendar">
                  <DatePicker dateFormat="yyyy/MM/dd" name="hasta" selected={fechaHasta} onChange={(date) => setFechaHasta(date)} />
                  <CalendarMonthIcon />
                </div>

              </div>}
          </Row>

          <ResumenCierre data={resumenPago} onRefresh={() => getSales()} />

        </ReportsExcel>
      </Row>

      <Row>
        <Entregas list={listCountDelivery} onRefresh={() => getCountDeliverys()} />
        <Recogidas list={listPickups} onRefresh={() => getPickups()} />
      </Row>

      <Row>
        <Cortes data_user={{ ...data_user, id_user: window.atob(data_user.auth) }} sales={resumenPago} />
      </Row>

    </Container>
  )
}

export default Reports;
