import * as React from 'react';
import { config as i18nextConfig } from '../translations';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { FilterProvider } from "./Contexts";
import 'bootstrap/dist/css/bootstrap.css';
import ScrollToTop from './ScrollToTop';
import Router from './Router';
import '../styles/app.scss';
import i18n from 'i18next';

i18n.init(i18nextConfig);

const App = () => {

  const handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();

    console.log(`x: ${e.pageX}px y: ${e.pageY}px`);
  }

  React.useEffect(() => {
    if (typeof android !== "undefined") {
      document.onselectstart = () => false;
    } else {
      if (process.env.NODE_ENV === 'production') {
        document.addEventListener("contextmenu", handleContextMenu);

        return () => {
          document.removeEventListener("contextmenu", handleContextMenu);
        }
      }
    }
  }, []);

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <ScrollToTop>
          <FilterProvider>
            <Router />
          </FilterProvider>
        </ScrollToTop>
      </I18nextProvider>
    </BrowserRouter>
  );
};

export default App;
