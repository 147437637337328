import * as React from 'react';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/* eslint eqeqeq: 0 */

const Menu = () => {
  const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };

  return (
    <ul>

      {data_user.nivel < 4 && <>
        <li>
          <span className="_item_menu">Ordenes
            <ul className="_sub_menu">
              <li>
                <Link className="_item_menu" to="/ordenes-de-recogida">{data_user.nivel == 4 ? "Recogidas" : "Recolección"}</Link>
              </li>

              <li>
                <Link className="_item_menu" to="/ordenes-de-recepcion">Recepción</Link>
              </li>

              <li>
                <Link className="_item_menu" to="/todos-los-paquetes">Paquetes</Link>
              </li>
            </ul>
          </span>
        </li>

        <li>
          <span className="_item_menu">Administración
            <ul className="_sub_menu">

              {data_user.nivel < 3 && <li>
                <Link className="_item_menu" to="/generar-codigos">Generar Códigos</Link>
              </li>}

              <li>
                <Link className="_item_menu" to="/reportes-admin">Reportes</Link>
              </li>

              <li>
                <Link className="_item_menu" to="/caja-mensajeros">Caja mensajeros</Link>
              </li>

              {data_user.nivel <= 3 && <li>
                <Link className="_item_menu" to="/caja-clientes">Caja clientes</Link>
              </li>}

              {data_user.nivel <= 3 && <li>
                <Link className="_item_menu" to="/caja-bts">Caja bts</Link>
              </li>}
            </ul>
          </span>
        </li>

        <li>
          <span className="_item_menu">Usuarios
            <ul className="_sub_menu">

              {data_user.nivel == 1 && <li>
                <Link className="_item_menu" to={{ pathname: "/administradores", state: { rol: 2 } }}>Administradores</Link>
              </li>}

              {data_user.nivel < 3 && <li>
                <Link className="_item_menu" to={{ pathname: "/coordinadores", state: { rol: 3 } }}>Coordinadores</Link>
              </li>}

              <li>
                <Link className="_item_menu" to="/mensajeros">Mensajeros</Link>
              </li>

              {data_user.nivel < 3 && <li>
                <Link className="_item_menu" to={{ pathname: "/recepcionistas", state: { rol: 5 } }}>Recepcionistas</Link>
              </li>}

              {data_user.nivel < 3 && <li>
                <Link className="_item_menu" to={{ pathname: "/cajeros", state: { rol: 8 } }}>Cajeros</Link>
              </li>}

              {data_user.nivel < 3 && <li>
                <Link className="_item_menu" to={{ pathname: "/clientes", state: { rol: 6 } }}>Clientes</Link>
              </li>}

            </ul>
          </span>
        </li>
      </>}

      {data_user.nivel == 4 && <>
        <li>
          <Link className="_item_menu" to="/ordenes-de-recogida">Recogidas</Link>
        </li>
        <li>
          <Link className="_item_menu" to="/todos-los-paquetes">Entregas</Link>
        </li>
      </>}

      {data_user.nivel == 5 && <>
        <li>
          <Link className="_item_menu" to="/ordenes-de-recepcion">Recepción</Link>
        </li>

        <li>
          <Link className="_item_menu" to="/todos-los-paquetes">Entregas</Link>
        </li>
      </>}

      {data_user.nivel == 8 && <>
        <li>
          <Link className="_item_menu" to="/reportes-mensajeros">Reportes</Link>
        </li>

        <li>
          <Link className="_item_menu" to="/caja-mensajeros">Caja</Link>
        </li>
      </>}

      {data_user.nivel == 6 && <>
        <li>
          <Link className="_item_menu" to="/mis-ordenes">Recogidas</Link>
        </li>
        <li>
          <Link className="_item_menu" to="/mis-paquetes">Entregas</Link>
        </li>
        <li>
          <Link className="_item_menu" to="/todos-mis-paquetes">Historial</Link>
        </li>
      </>}

      {(data_user.nivel == 4 || data_user.nivel == 6) && <li>
        <Link className="_item_menu" to="/reportes">Caja</Link>
      </li>}

      {(data_user.nivel == 1 ||
        data_user.nivel == 2 ||
        data_user.nivel == 3 ||
        data_user.nivel == 4 ||
        data_user.nivel == 6 ||
        data_user.nivel == 7) && <li>
          <Link className="_item_menu" to="/chat">Chat</Link>
        </li>}

    </ul>
  );
}

const Topbar = ({ changeTheme, changeMobileSidebarVisibility }) => (
  <div className="topbar">

    <div className="topbar__wrapper">
      <div className="topbar__left">
        <TopbarSidebarButton changeMobileSidebarVisibility={changeMobileSidebarVisibility} />
        <Link className="topbar__logo" to="/home" />
        <Menu />
      </div>
      <div className="topbar__right">
        <TopbarProfile changeTheme={changeTheme} />
      </div>
    </div>
  </div>
);

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
