import * as React from "react";
import SwapVerticalIcon from 'mdi-react/SwapVerticalIcon';
import PlugIcon from "mdi-react/AccountPlusIcon";
import { NewAgent } from "./components/Agent";
import { SearchUsers } from "shared/Input";
import MenuRow from "./components/MenuRow";
import useTitle from "hooks/useTitle";
import * as Icon from "../../images";
import Loading from "shared/Loading";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint no-unused-vars: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Usuarios = (params) => {
    const { history, location } = params;
    const [rol, setRoll] = React.useState(7);
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [currentQuery, setCurrentQuery] = React.useState(7);
    const media_movil = window.matchMedia('(max-width: 576px)').matches;
    const [selected, setSelected] = React.useState({ id_user: 0 });
    const [showLoading, setShowLoading] = React.useState(true);
    const [showLazy, setShowLazy] = React.useState(true);
    const [showModal, setShowModal] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);
    const [dataList, setDataList] = React.useState([]);
    const [criterio, setCriterio] = React.useState("");
    const [asc, setAsc] = React.useState(false);
    const [task, setTask] = React.useState();
    const { setTitulo } = useTitle();


    /**
     * Niveles de los usuarios 
     * 
     * 1 Super Administrador
     * 2 Administrador
     * 3 Coordinador de zona
     * 4 Mensajero
     * 5 Recepcionista
     * 6 Cliente bts
     * 7 Usuario final
     * 8 Cajero
     * 
     */

    const changeTask = (_rol) => {
        let toReturn;

        switch (parseInt(_rol)) {
            case 1:
                toReturn = "super administradores";
                break;
            case 2:
                toReturn = "administradores";
                break;
            case 3:
                toReturn = "coordinadores de zona";
                break;
            case 4:
                toReturn = "mensajeros";
                break;
            case 5:
                toReturn = "recepcionistas";
                break;
            case 6:
                toReturn = "clientes bts";
                break;
            case 8:
                toReturn = "cajeros";
                break;
            case 7:
            default:
                toReturn = "usuarios finales";
                break;
        }

        setTask(toReturn);
        return toReturn;
    }

    const Row = ({ data, blue }) => {

        return (
            <MenuRow data={{ ...data, rol: rol }} blue={blue}
                history={history}
                current={selected.id_user}
                onSel={setSelected}
                reload={() => getList(rol)}>

                <td>{data.nombre} {data.apellido}</td>
                <td>{data.correo}</td>
                <td>{data.telefono}</td>
            </MenuRow>
        );
    }

    const AllRow = () => {
        let _data = [];
        let _blue = 0;

        const handleModal = (e) => {
            let _wrap = document.getElementsByClassName("container__wrap")[0].offsetWidth;
            let _win_opt;
            let _posx;

            if (media_movil) {
                _win_opt = e.pageX + 300;
                _posx = 12;
            } else {
                _win_opt = e.pageX + 500;
                _posx = _win_opt > _wrap ? _wrap - 530 : e.pageX;
            }

            let _window = <NewAgent
                data={{ rol: rol }}
                pos={{ top: "150px", left: _posx + "px" }}
                close={() => {
                    setShowModal(false);
                    getList(rol);
                }} />;


            setModalView(_window);
            setShowModal(true);
        }

        dataList.map(item => {
            _data.push(<Row key={item.id_user} blue={_blue} data={item} />);
            _blue = _blue >= 1 ? 0 : _blue + 1;
        });

        if (_data.length < 1) {
            return <>
                <h4 className="_titulo">No hay {task}</h4>
                <h4 className="_button" onClick={handleModal}>Crear Nuevo <PlugIcon /></h4>

                {showModal && modalView}
            </>;
        } else {
            return <>
                <table className="table">
                    <tbody>

                        <tr className="table_title">
                            <th><SwapVerticalIcon onClick={() => sortBy("nombre")} />Nombre y apellido</th>
                            <th>Correo</th>
                            <th>Teléfono</th>
                        </tr>

                        {_data}
                    </tbody>
                </table>

                {showLoading ?
                    <button className="btn_lazy_load"><Icon.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn_lazy_load" onClick={() => getListLazy()}>Cargar mas</button>
                            :
                            <h4 className="h4_lazy_load">¡No hay mas {task}!</h4>
                        }
                    </>
                }
            </>;
        }
    }

    const sortBy = (_key) => {

        let mapped = dataList.map((item, i) => {
            return { index: i, value: item[_key].toLowerCase() };
        });

        mapped.sort(function (a, b) {
            if (asc) {
                if (a.value > b.value) {
                    return 1;
                }

                if (a.value < b.value) {
                    return -1;
                }
            } else {
                if (a.value > b.value) {
                    return -1;
                }

                if (a.value < b.value) {
                    return 1;
                }
            }

            return 0;
        });

        let _new = mapped.map(item => {
            return dataList[item.index];
        });

        setAsc(!asc);
        setDataList(_new);
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: currentQuery,
                nivel: rol,
                criterio: criterio,
                limit: 20,
                offset: dataList.length
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setDataList(_packs);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const handleSearch = async (_val) => {
        _val = _val.toLowerCase();
        setCriterio(_val);

        setShowLoading(true);
        setCurrentQuery("search");
        setDataList([]);
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: "search",
                nivel: rol,
                criterio: _val,
                limit: 20,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async (_rol) => {
        setShowLoading(true);
        setCurrentQuery(_rol);
        setDataList([]);
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: _rol,
                limit: 20,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        if (data_user.nivel < 4) {
            if (location.state && location.state.rol) {
                let _rol = location.state.rol;
                let _task = changeTask(_rol);

                setRoll(_rol);
                setCurrentQuery(_rol);
                setTitulo("Lista de " + _task);

                if (data_user.nivel <= 2) {
                    getList(_rol);
                }
            } else {
                history.push("/home");
            }
        } else {
            history.push("/home");
        }
    }, [location.state]);

    return (
        <>
            {data_user.nivel <= 2 ?
                <div className="cont_principal">
                    <div className="cont_title_bar">
                        <h3 className="_title">Lista de {task}</h3>

                        <div className="search_bar">
                            <SearchUsers type="text" placeholder="Buscar por nombre"
                                value={criterio} onChange={handleSearch} />
                        </div>
                    </div>

                    <AllRow />
                </div>
                :
                <div className="cont_principal">
                    <h4 className="_titulo"> No cuentas con los permisos necesarios para ver este contenido</h4>
                </div>
            }

            {showLoading && <Loading />}
        </>
    );
}

export default Usuarios;
