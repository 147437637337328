import * as React from "react";
import CloseOutlineIcon from "mdi-react/CloseCircleOutlineIcon";
import Information from "mdi-react/InformationOutlineIcon";
import { useHistory } from "react-router-dom";
import funciones from "services/funciones";
import * as Icon from "../../../images";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const EntregaCaja = ({ pos, close, reload }) => {
    const history = useHistory();
    const [valores, setValores] = React.useState({ valor_total: 0, valor_mensagero: 0, valor_cliente: 0, valor_bts: 0, efectivo: 0, transferencias: 0, caja_cliente: 0 });
    const [showLoading, setShowLoading] = React.useState(true);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [error, setError] = React.useState("");
    const [msg, setMsg] = React.useState("");


    const handleClose = () => {
        reload();
        close(false);
    }

    const entregarCaja = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "save-caja",
            data: {
                task: "entrega_caja"
            }
        });

        if (_data.response == 1) {
            setMsg(_data.data[0].mgs);
            setIsLoaded(true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            setError(_data.msg);
        }

        setShowLoading(false);
    }

    const getInfo = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-caja",
            data: {
                task: "registro"
            }
        });

        if (_data.response == 1) {
            let _vals = _data.data[0];

            _vals.valor_total = _vals.valor_total ?? 0;
            _vals.valor_mensagero = _vals.valor_mensagero ?? 0;
            _vals.valor_cliente = _vals.valor_cliente ?? 0;
            _vals.valor_bts = _vals.valor_bts ?? 0;
            _vals.efectivo = _vals.efectivo ?? 0;
            _vals.transferencias = _vals.transferencias ?? 0;
            _vals.caja_cliente = parseInt(_vals.efectivo) + parseInt(_vals.transferencias);
            _vals.caja_cliente = parseInt(_vals.caja_cliente) - parseInt(_vals.valor_bts);

            if (_vals.caja_cliente < 1) {
                setMsg("No tienes saldo pendiente de entregar.");
                setIsLoaded(true);
            }

            setValores(_vals);
            setShowLoading(false);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const BtnAceptar = () => {
        if (isLoaded) {
            return <button className="btn btn-common width-static" onClick={handleClose}>Aceptar</button>;
        } else {
            if (showLoading) {
                return <button className="btn btn-common width-static"><Icon.Loading /></button>;
            } else {
                return <button className="btn btn-common width-static" onClick={entregarCaja}>Entregar</button>;
            }
        }
    }

    React.useEffect(() => {
        getInfo();
    }, []);

    return (
        <>
            <div className="back_form"></div>
            <div className="win_modal _overflow _delivery" style={pos}>
                <h3 style={{ marginRight: -8, marginBottom: 8 }}><CloseOutlineIcon onClick={() => close(false)} />Entrega de caja</h3>

                <div className="_in_column _cont_entrega">

                    {showLoading ?
                        <div className="cont_loading"><Icon.Loading /></div>
                        :
                        <div>
                            {isLoaded ?
                                <h4 className="_precio _msg">{msg}</h4>
                                :
                                <>
                                    <i>Estos son los valores que se entregarán a la caja principal.</i>

                                    <h4 className="_precio">
                                        <hr />
                                        <span>Efectivo</span><br /> ${funciones.formatPrice(valores.efectivo).format}
                                        <br />
                                        <hr />
                                        <span>Transferencias</span><br /> ${funciones.formatPrice(valores.transferencias).format}
                                        <br />
                                        <hr />
                                        <span>Caja clientes</span><br /> ${funciones.formatPrice(valores.caja_cliente).format}
                                        <br />
                                        <hr />
                                        <span>Caja BTS</span><br /> ${funciones.formatPrice(valores.valor_bts).format}
                                        <hr />
                                    </h4>
                                </>
                            }
                        </div>
                    }

                </div>

                {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

                <BtnAceptar />
            </div>
        </>
    );
}

export default EntregaCaja;