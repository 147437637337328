import * as React from "react";
import ChartTimelineIcon from "mdi-react/ChartTimelineIcon";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import { Card, CardBody, Col } from "reactstrap";
import funciones from "services/funciones";
import { useHistory } from "react-router";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */


const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const Cortes = ({ data_user, sales }) => {
    const [showLoading, setShowLoading] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);
    const history = useHistory();

    const handlerRefresh = (e) => {
        e.preventDefault();
        if (data_user) {
            getList();
        }
    }

    const getList = async () => {
        setShowLoading(true);
        let _obj;

        switch (parseInt(data_user.nivel)) {
            case 1:
            case 2:
            case 3:
                _obj = {
                    task: "cortes",
                    propietario: data_user.id_user
                };
                break;
            case 4:
            case 6:
            default:
                _obj = {
                    task: "cortes",
                    propietario: data_user.id_user
                };
                break;
        }

        let _data = await api.fetchJson({
            url: "reportes",
            data: _obj
        });

        if (_data.response == 1) {
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const History = () => {
        if (data_user) {
            switch (parseInt(data_user.nivel)) {
                case 1:
                case 2:
                case 3:
                    return <div className="dashboard__sales-table">
                        <h4>No hay cortes en el historial</h4>
                    </div>;
                case 4:
                    return <div className="dashboard__sales-table">
                        {dataList.length < 1 ?
                            <h4>No hay cortes en el historial</h4>
                            :
                            <table className="table">
                                <tbody>

                                    <tr className="table_title">
                                        <th>Fecha Corte</th>
                                        <th>Cantidad</th>
                                        <th>Total</th>
                                        <th>Ganancia</th>
                                        <th>Subtotal</th>
                                        <th>Devuelto a bodega</th>
                                    </tr>

                                    {dataList.map((item, index) => {
                                        let fecha_creado = new Date(item.fecha);

                                        return <tr className="_tr_row" key={index} onClick={() => history.push({ pathname: "/report-history", state: { propietario: { id_user: data_user.id_user, nivel: data_user.nivel, fecha: item.fecha, nombre: data_user.nombre + " " + data_user.apellido } } })}>
                                            <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</td>
                                            <td>{funciones.formatPrice(item.cantidad_entregada).format}</td>
                                            <td>{funciones.formatPrice(item.valor_total).format}</td>
                                            <td>{funciones.formatPrice(item.domicilios).format}</td>
                                            <td>{funciones.formatPrice(item.valor_subtotal).format}</td>
                                            <td>{funciones.formatPrice(item.candidad_devuelta).format}</td>
                                        </tr>
                                    })}

                                </tbody>
                            </table>
                        }
                    </div>;
                case 6:
                    return <div className="dashboard__sales-table">
                        {dataList.length < 1 ?
                            <h4>No hay cortes en el historial</h4>
                            :
                            <table className="table">
                                <tbody>

                                    <tr className="table_title">
                                        <th>Fecha Corte</th>
                                        <th>Cantidad</th>
                                        <th>Total</th>
                                        <th>Domicilios</th>
                                        <th>Subtotal</th>
                                        <th>Efectivo</th>
                                        <th>Transferencia</th>
                                        <th>Devuelto</th>
                                    </tr>

                                    {dataList.map((item, index) => {
                                        let fecha_creado = new Date(item.fecha);

                                        return <tr className="_tr_row" key={index} onClick={() => history.push({ pathname: "/report-history", state: { propietario: { id_user: data_user.id_user, nivel: data_user.nivel, fecha: item.fecha, nombre: data_user.nombre + " " + data_user.apellido } } })}>
                                            <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</td>
                                            <td>{funciones.formatPrice(item.cantidad_entregada).format}</td>
                                            <td>{funciones.formatPrice(item.valor_total).format}</td>
                                            <td>{funciones.formatPrice(item.domicilios).format}</td>
                                            <td>{funciones.formatPrice(item.valor_subtotal).format}</td>
                                            <td>{funciones.formatPrice(item.efectivo || 0).format}</td>
                                            <td>{funciones.formatPrice(item.transferencia || 0).format}</td>
                                            <td>{funciones.formatPrice(item.candidad_devuelta).format}</td>
                                        </tr>;
                                    })}

                                </tbody>
                            </table>
                        }
                    </div>;
                default:
                    return <> </>;
            }
        } else {
            return <div className="dashboard__sales-table">
                <h4>Seleciona un vendedor o mensajero.</h4>
            </div>;
        }
    }

    React.useEffect(() => {
        if (data_user) {
            getList();
        } else {
            setShowLoading(false);
        }
    }, [data_user, sales]);

    return (
        <div className="content_reportes">
            <Col className="col_table">
                <Card style={{ overflow: "hidden" }}>
                    <CardBody className="dashboard__card-widget">
                        <div className="card__title">
                            <h5 className="bold-text"><ChartTimelineIcon className="dashboard__money-icon" /> Historial de cortes</h5>
                        </div>

                        <div className="panel__btns">

                            <button
                                className="panel__btn"
                                aria-label="panel__btn"
                                type="button"
                                onClick={handlerRefresh}>
                                <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                            </button>

                        </div>

                        <History />
                    </CardBody>
                </Card>
            </Col>
        </div>
    );
}

export default Cortes;