import * as React from "react";


const ContextFilter = React.createContext();
const ContextTitle = React.createContext();
const ContextLoc = React.createContext();

export function FilterProvider({ children }) {
    const [opFilter, setOpFilter] = React.useState({ client: "", carrier: "", status: "" });

    return <ContextFilter.Provider
        value={{ opFilter, setOpFilter }}>
        {children}
    </ContextFilter.Provider>
}

export function LocProvider({ children }) {
    const [locWarehouse, setLoc] = React.useState({ all: "" });
    const [isIndividual, setIsIndividual] = React.useState(true);

    const setLocWarehouse = (_val, _destino = "all") => {
        if (isIndividual) {
            setLoc(_loc => ({ ..._loc, [_destino]: _val }));
        } else {
            setLoc(_loc => {
                for (let key in _loc) {
                    _loc[key] = _val;
                }

                return { ..._loc };
            });
        }

    }

    return <ContextLoc.Provider
        value={{ locWarehouse, setLocWarehouse, isIndividual, setIsIndividual }}>
        {children}
    </ContextLoc.Provider>
}

export function TitleProvider({ children }) {
    const [titulo, setTitle] = React.useState(document.title);
    const [showTitle, setShowTitle] = React.useState(true);

    const setTitulo = (_titulo, _show = true) => {
        document.title = _titulo;
        setTitle(_titulo);
        setShowTitle(_show);

        if (typeof android !== "undefined") {

            // eslint-disable-next-line no-undef
            android.setLastPage(window.location.href);
        }
    }

    return <ContextTitle.Provider
        value={{ showTitle, titulo, setTitulo }}>
        {children}
    </ContextTitle.Provider>
}

export {
    ContextLoc,
    ContextTitle,
    ContextFilter
};