import * as React from "react";
import { Alert, alerta, alertArea, alertaRemove, alertDown, Confirm } from "shared/Alert";
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import useTitle from "hooks/useTitle";
import EditContainer from "./EditContainer";
import DatePicker from "react-datepicker";
import NewContainer from "./NewContainer";
import { Input } from "shared/Input";
import api from "services/api";
import funciones from "services/funciones";

/* eslint eqeqeq: 0 */
/* eslint no-unused-vars: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const EditPickUp = ({ history, location }) => {
    const data = location.state.data;
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [origin] = React.useState("Cliente");
    const [consignee, setConsignee] = React.useState("");
    const [collectPrice, setCollectPrice] = React.useState("");
    const [consigneeId, setConsigneeId] = React.useState("");
    const [namePickupLocation, setNamePickupLocation] = React.useState("");
    const [addressPickupLocation, setAddressPickupLocation] = React.useState("");
    const [pickupId, setPickupId] = React.useState("");
    const [notes, setNotes] = React.useState("");
    const [fechaIngreso, setFechaIngreso] = React.useState(new Date());
    const [myUserList, setMyUserList] = React.useState([]);
    const [userList, setUserList] = React.useState([{ id_user: 0, nombre: "Cargando..." }]);
    const [total, setTotal] = React.useState({ length: "", height: "", width: "" });
    const { setTitulo } = useTitle();
    const [valDomc, setValDomc] = React.useState("8000");

    const [packages, setPackages] = React.useState({});
    const [allPaquages, setAllPaquages] = React.useState([]);
    const ref_form = React.useRef();
    const _titulo = data_user.nivel == 4 ? "Agregar paquetes" : "Editar orden de recogida";


    const getPackagesList = async (_id) => {
        let _data = await api.getPackages(_id);

        if (_data.response == 1) {
            configPackages(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const configPackages = (_data) => {
        let _pack = {};
        let _allPack = [];
        let _index = 1;

        _data.map((item, index) => {
            _index = index + 1;
            item.index = _index;
            item.weight_total = item.weight;
            item.volume_total = item.volume;

            _pack = { ..._pack, [_index]: item };
            _allPack.push(<ContainerEdit key={_index} pack={item} />);
        });

        setPackages(_pack);
        setAllPaquages(_allPack);
    }

    const updatePackage = (pack, index) => {
        setPackages(p => ({ ...p, [index]: pack }))
    }

    const addPackage = (e) => {
        e.preventDefault();
        alertaRemove(ref_form.current.other);

        if (consigneeId !== "") {

            let _index = allPaquages.length + 1;
            let _pack = {
                "index": _index,
                "id": 0,
                "type": "N/A",
                "pieces": 1,
                "codigo": "",
                "valorp": "0",
                "valord": valDomc,
                "valorm": "5000",
                "descripcion": "",
                "attachments": "",
                "estado": data.estado
            };

            setPackages({ ...packages, [_index]: _pack });

            setAllPaquages([...allPaquages, <Container key={_index} pack={_pack} />]);
        }
    }

    const removePackage = (_index, _id) => {
        let _pack_list = [];
        let _action = () => {
            setPackages(p => {
                let _new_list;

                for (let i in p) {
                    if (p[i].index !== _index) {
                        _new_list = { ..._new_list, [i]: p[i] };
                        _pack_list.push(<ContainerEdit key={p[i].index} pack={p[i]} />);
                    }
                }

                return _new_list;
            });

            setAllPaquages(_pack_list);
        }

        if (_id != 0) {
            Confirm("Seguro de borrar este paquete", (response) => {
                if (response) {
                    _action();
                }
            });
        } else {
            _action();
        }

    }

    const Container = ({ pack }) => {

        return (
            <NewContainer
                item={pack}
                remove={removePackage}
                update={item => setPackages(p => ({ ...p, [pack.index]: item }))} />
        );
    }

    const ContainerEdit = ({ pack }) => {

        return (
            <EditContainer
                item={pack}
                remove={removePackage}
                update={(p, i) => updatePackage(p, i)} />
        );
    }

    const getAllList = async () => {
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: "miinfo",
                id: window.atob(data_user.auth)
            }
        });
        let _users = [];


        if (_data.response == 1) {

            _data.data.map(item => {
                item.nivel > "2" && _users.push(item);
            });

            setUserList(_users);
            setMyUserList(_users.map(({ nombre, apellido }) => (nombre + " " + apellido)));
            copyConsignee(_users);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        return _users;
    }

    const format = (_num) => {
        return _num < 10 ? "0" + _num : _num;
    }

    const saveOrder = async (asPickedUp) => {
        let _info = {
            order: data.id,
            estado: data_user.nivel == 4 ? asPickedUp : data.estado,
            date_pickup: fechaIngreso.getFullYear() + "-" + format(fechaIngreso.getMonth() + 1) + "-" + format(fechaIngreso.getDate()),
            origin: origin,
            consignee: consigneeId,
            pickup: pickupId,
            notes: notes,
            pickup_name: namePickupLocation,
            pickup_address: addressPickupLocation,
            collect: collectPrice.replaceAll(".", ""),
            pieces: total.pieces,
            weight: total.weight,
            volume: total.volume,
            packages: packages
        };
        console.log(_info);

        let _data = await api.editPickUpOrder(_info);

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => history.goBack());
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _is_empty = false;
        let _message = "";

        alertaRemove(ref_form.current.consignee);
        alertaRemove(ref_form.current.other);
        alertaRemove(ref_form.current.address_pickup_location);

        if (consignee.length < 1) {
            alerta(ref_form.current.consignee, "Debes ingresar un Cliente bts!");
            ref_form.current.consignee.focus();
            window.scrollTo({
                top: 80,
                behavior: 'smooth'
            });
            return;
        }

        if (consigneeId.length < 1) {
            alerta(ref_form.current.consignee, "Este usuario no se encuentra registrado! \nAgregalo desde el icono ( + )");
            ref_form.current.consignee.focus();
            window.scrollTo({
                top: 80,
                behavior: 'smooth'
            });
            return;
        }

        alertaRemove(ref_form.current.name_pickup_location);

        if (namePickupLocation.length < 1) {
            alerta(ref_form.current.name_pickup_location, "Debes ingresar el nombre de la persona \ndonde se recogerá el paquete!");
            ref_form.current.name_pickup_location.focus();
            window.scrollTo({
                top: 180,
                behavior: 'smooth'
            });
            return;
        }

        if (pickupId.length < 1) {
            alerta(ref_form.current.name_pickup_location, "Este usuario no se encuentra registrado! \nAgregalo desde el icono ( + )");
            ref_form.current.name_pickup_location.focus();
            window.scrollTo({
                top: 180,
                behavior: 'smooth'
            });
            return;
        }

        if (addressPickupLocation.length < 1) {
            alertArea(ref_form.current.address_pickup_location, "Debes ingresar la dirección de la persona \ndonde se recogerá el paquete!");
            ref_form.current.address_pickup_location.focus();
            window.scrollTo({
                top: 180,
                behavior: 'smooth'
            });
            return;
        }

        if (typeof packages !== "object" || Object.entries(packages).length < 1) {
            alertDown(ref_form.current.other, "Debes agregar almenos un paquete!");
            return;
        }

        for (let i in packages) {

            if (packages[i]["pieces"] < 1) {
                _is_empty = true;
                _message = `La cantidad de "piezas" no puede ser menor a uno!`;
                break
            }

            if (packages[i]["type"].length < 1) {
                _is_empty = true;
                _message = `Debes indicar el tipo de paquete`;
                break
            }
        }


        if (_is_empty) {
            Alert(_message);
            return;
        }

        saveOrder();
    }

    const calculaTotal = () => {
        let _pieces = 0;
        let _weight = 0;
        let _vol = 0;

        for (let k in packages) {
            var item = packages[k];

            _pieces += parseInt(item.pieces);
            _weight += parseFloat(item.weight_total);
            _vol += parseFloat(item.volume_total);
        }

        setTotal({
            "pieces": _pieces,
            "weight": _weight,
            "volume": _vol,
        });
    }

    const copyConsignee = () => {

        if (consignee.length > 1) {
            let item = userList.filter(({ nombre, apellido }) => nombre + " " + apellido === consignee);
            item = item[0];

            if (typeof item === "object") {
                alertaRemove(ref_form.current.name_pickup_location);

                setNamePickupLocation(item.nombre + " " + item.apellido);
                setAddressPickupLocation(item.address);
                setPickupId(item.id_user);
            } else {
                setNamePickupLocation("");
                setAddressPickupLocation("");
                setPickupId(-1);
            }
        }
    }

    const changePickup = (val) => {
        setNamePickupLocation(val);
        alertaRemove(ref_form.current.name_pickup_location);

        let item = userList[0];

        if (typeof item === "object") {
            let _name = item.nombre + " " + item.apellido;
            if (_name === val) {
                setPickupId(item.id_user);
            } else {
                setPickupId(-1);
            }
        } else {
            setPickupId(-1);
        }
    }

    const changePickupAddress = (val) => {
        setAddressPickupLocation(val);
        alertaRemove(ref_form.current.address_pickup_location);

        let item = userList[0];

        if (typeof item === "object") {
            let _name = item.nombre + " " + item.apellido;
            if (_name === namePickupLocation && item.address === val) {
                setPickupId(item.id_user);
            } else {
                setPickupId(-1);
            }
        } else {
            setPickupId(-1);
        }
    }

    React.useEffect(() => {
        let isMounted = true;
        setTitulo(_titulo + " " + data.consecutivo);

        if (isMounted) getAllList();

        return () => { isMounted = false };
    }, []);

    React.useEffect(() => {
        getPackagesList(data.id);

        setConsignee(data.consignee_name == null ? "" : data.consignee_name);
        setConsigneeId(data.consignee == null ? "" : data.consignee);
        setNamePickupLocation(data.pickup_name == null ? "" : data.pickup_name);
        setAddressPickupLocation(data.pickup_address == null ? "" : data.pickup_address);

        setPickupId(data.pickup_id == null ? "" : data.pickup_id);

        setNotes(data.notes == null ? "" : data.notes);

        setFechaIngreso(new Date(data.date_pickup + "T01:00:00"));

        if (data.collect > 1) {
            setCollectPrice(funciones.formatPrice(data.collect).format);
        }
    }, [data]);

    React.useEffect(() => {
        calculaTotal();
    }, [packages]);

    return (
        <form className="pickup" ref={ref_form}>
            <h3 className="_title">{_titulo} {data.consecutivo}</h3>

            <div className="pickup_container" style={{ marginBottom: allPaquages.length >= 1 ? 28 : 110 }}>
                <h5>Número: {data.consecutivo}</h5>


                <div className={consignee === "" ? "pickup_row" : "pickup_row _to_bottom"}>

                    <div className="pickup_column static">

                        <div className="pickup_column m_less">
                            <label style={{ height: 24 }}>Fecha de recogida</label>
                            <div className="field_icon">
                                <DatePicker dateFormat="yyyy/MM/dd" name="pickup_date" selected={fechaIngreso} onChange={(date) => setFechaIngreso(date)} />
                                <CalendarMonthIcon />
                            </div>
                        </div>

                        <div className="pickup_column m_less">
                            <label style={{ marginTop: 12 }}>Nota</label>
                            <textarea name="notes" rows="2" placeholder="Ingresa una observación"
                                value={notes} onChange={({ target: { value } }) => setNotes(value)} />
                        </div>

                    </div>

                    <div className="pickup_column static">

                        <div className="pickup_column m_less">

                            <label>Persona de contacto</label>
                            <Input
                                list={myUserList}
                                name="name_pickup_location"
                                placeholder="Ingresa usuario"
                                value={namePickupLocation}
                                onChange={changePickup}
                            />
                        </div>

                        <label style={{ marginTop: 12 }}>Dirección</label>
                        <div style={{ position: "relative" }}>

                            <textarea name="address_pickup_location" rows="2" placeholder="ingresa dirección."
                                value={addressPickupLocation} onChange={({ target: { value } }) => changePickupAddress(value)} />
                        </div>
                    </div>

                </div>

                {allPaquages}
            </div>

            {allPaquages.length >= 1 &&
                <div className="new_container">
                    <fieldset className="new_features">
                        <legend>Total</legend>

                        <div className="f_row">
                            <h4>{total.pieces} Pieces</h4>
                        </div>
                    </fieldset>
                </div>}

            <div className="boton_agregar">

                <div className="for_alert">
                    <button className="btn btn-common" type="button" name="other"
                        onClick={addPackage}>
                        <PlusCircleOutlineIcon /> Agregar paquete
                    </button>
                </div>

                <button type="button" className="btn btn-common width-static" onClick={validateFields}>Guardar</button>
                <button type="button" className="btn btn-common width-static" onClick={() => history.goBack()}>Cancelar</button>

            </div>

        </form >
    );
}

export default EditPickUp;