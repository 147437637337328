import * as React from "react";
import DeleteIcon from "mdi-react/DeleteOutlineIcon";
import EditIcon from "mdi-react/EditOutlineIcon";
import ChatIcon from "mdi-react/ChatOutlineIcon";
import HistoryIcon from "mdi-react/HistoryIcon";
import { Alert, Confirm } from "shared/Alert";
import * as Iconos from "../../../images";
import CourierList from "./CourierList";
import History from "shared/History";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuRow = (params) => {
    const { history, data, children, reload } = params;
    const [_pos, setPos] = React.useState({ top: "0", left: "0" });
    const [showModal, setShowModal] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);
    const [showMenu, setShowMenu] = React.useState(false);
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };

    const format = (_num) => {
        return _num < 10 ? "0" + _num : _num;
    }

    const countPackages = async () => {
        let _data = await api.getPackages(data.id);

        if (_data.response == 1) {
            return _data.data.length;
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }
        return 0;
    }

    const goChat = (e) => {
        e.preventDefault()
        e.stopPropagation();

        history.push({
            pathname: "/chat",
            state: {
                data: {
                    guest: {
                        id_user: data_user.nivel == 6 ? data.carrier : data.consignee,
                        nombre: data_user.nivel == 6 ? data.carrier_name : data.consignee_nombre,
                        apellido: data_user.nivel == 6 ? "" : data.consignee_apellido
                    },
                    fromOutside: true
                }
            }
        });
    }

    const goEdit = (e) => {
        e.preventDefault()
        e.stopPropagation();

        history.push({
            pathname: "/editar-orden-de-recogida",
            state: { data: data }
        });
    }

    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm(`Seguro de borrar la orden Nº ${data.consecutivo}?`, async (response) => {
            if (response) {
                let _data = await api.deleteOrder({
                    id_order: data.id,
                    tipo: "orden de recogida"
                });

                if (_data.response == 1) {
                    reload();
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => history.push("/"));
                } else {
                    Alert(_data.msg, "warning");
                }
            }
        });

    }

    const editarRecibir = async (e) => {
        e.preventDefault()
        e.stopPropagation();
        let _fecha = new Date();

        setShowMenu(false);

        history.push({
            pathname: "/editar-orden-de-servicio",
            state: {
                data: {
                    ...data,
                    date_received: _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate()),
                    comentario: "Se recibe en almacén"
                }
            }
        });
    }

    const cambiaEstado = async (e, _val) => {
        hiddenMenu(e);

        if (_val == 2) {
            let _packs = await countPackages();

            if (_packs < 1) {
                Alert("Primero debes agregar por lomenos un paquete!", "warning");
                return;
            }
        }

        Confirm("¿Seguro de cambiar el estado de la orden?", async (response) => {
            if (response) {
                let _data = await api.changeState({
                    type: 1,
                    id: data.id,
                    state: _val
                });

                if (_data.response == 1) {
                    reload();
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => history.push("/"));
                } else {
                    Alert(_data.msg, "warning");
                }
            }
        });
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let _height = window.innerHeight;
        let _width = document.getElementById("root").offsetWidth;
        let _posx = e.pageX - 16;
        let _posy = e.pageY - 70;
        _posx = (_posx + 220) > _width ? _width - 220 : _posx;
        let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;
        let _to_y = _to_border_bottom < 220 ? _posy - 220 : _posy;

        setPos({ top: _to_y + "px", left: _posx + "px", });
        setShowMenu(true);
    }

    const handleModal = (_option) => {
        let _window;

        switch (_option) {
            case "history":
                _window = <History data={{ ...data, tipo: 1 }} pos={_pos} close={setShowModal} />;
                break;
            case "assign_pickup":
                _window = <CourierList data={data} pos={_pos} close={setShowModal} reload={reload} />;
                break;
            default:
                _window = null;
                break;
        }

        setShowMenu(false);
        setModalView(_window);
        setShowModal(true);
    }

    const BtnMarkAs = () => {
        let _btn = <li><Iconos.PackWait color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Cambia a 'por recoger'</span></li>;

        if (data.estado == 1) {
            _btn = <li onClick={(e) => cambiaEstado(e, 2)}><Iconos.PackOk /> <span>Marcar como recogido</span></li>;
        } else if (data.estado == 2) {
            _btn = <li onClick={(e) => cambiaEstado(e, 1)}><Iconos.PackWait /> <span>Cambia a 'por recoger'</span></li>;
        }

        return (_btn);
    }

    const BtnReceive = () => {
        let _btn = <li><Iconos.PackReceive color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Recibir en almacén</span></li>;

        if (data.estado == 2) {
            _btn = <li onClick={editarRecibir}><Iconos.PackReceive /> <span>Recibir en almacén</span></li>;
        }

        return (_btn);
    }

    const BtnAssign = () => {
        let _btn = <li><Iconos.BoxMarker color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Cambiar mensajero</span></li>;

        switch (parseInt(data.estado)) {
            case 0:
            case 1:
            case 3:
            case 4:
            case 5:
            case 7:
            case 8:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
                if (data.carrier == null) {
                    _btn = <li onClick={() => handleModal("assign_pickup")}><Iconos.BoxMarker /> <span>Asignar recogida</span></li>;
                } else {
                    _btn = <li onClick={() => handleModal("assign_pickup")}><Iconos.BoxMarker /> <span>Cambiar mensajero</span></li>;
                }
                break;
        }

        return (_btn);
    }

    const BtnDelete = () => {
        let _btn = <li><DeleteIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Borrar</span></li>;

        switch (parseInt(data.estado)) {
            case 0:
            case 1:
            case 2:
            case 4:
            case 5:
            case 7:
            case 8:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
                if (data_user.nivel < 4 || data_user.nivel == 6) {
                    _btn = <li onClick={goDelete}><DeleteIcon /> <span>Borrar</span></li>;
                }
                break;
        }

        return (_btn);
    }

    const BtnChat = () => {
        let _btn = <li><ChatIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Abrir chat</span></li>;

        if (data_user.nivel < 4 && data.consignee != null) {
            _btn = <li onClick={goChat}><ChatIcon /> <span>Abrir chat</span></li>;
        }

        if (data_user.nivel == 6 && data.carrier != null) {
            _btn = <li onClick={goChat}><ChatIcon /> <span>Abrir chat</span></li>;
        }

        if (data_user.nivel == 6 && data.carrier != null) {
            _btn = <li onClick={goChat}><ChatIcon /> <span>Abrir chat</span></li>;
        }

        return (_btn);
    }

    const BtnEdit = () => {
        let _btn = <li><EditIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Editar</span></li>;

        switch (parseInt(data.estado)) {
            case 0:
            case 1:
            case 2:
            case 3:
                if (data_user.nivel == 1 ||
                    data_user.nivel == 2 ||
                    data_user.nivel == 3 ||
                    data_user.nivel == 5) {
                    _btn = <li onClick={goEdit}><EditIcon /> <span>Editar</span></li>;
                }

                if (data_user.nivel == 4 && data.carrier == window.atob(data_user.auth)) {
                    _btn = <li onClick={goEdit}><Iconos.PackAdd /> <span>Agregar paquetes</span></li>;
                }

                if (data_user.nivel == 6 && data.consignee == window.atob(data_user.auth)) {
                    _btn = <li onClick={goEdit}><EditIcon /> <span>Editar</span></li>;
                }
                break;
        }

        return (_btn);
    }

    React.useEffect(() => {
        document.addEventListener("click", hiddenMenu);

        return () => {
            document.removeEventListener("click", hiddenMenu);
        }
    }, []);

    return (
        <>
            <div className="main_container" onContextMenu={handleContextMenu}>

                {children}

                {showMenu &&
                    <>
                        <div className="back_form" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu" style={_pos}>
                            <ul>

                                <BtnEdit />
                                <BtnChat />

                                <hr />

                                {data_user.nivel == 4 && <BtnMarkAs />}
                                {data_user.nivel < 4 && <BtnAssign />}
                                {data_user.nivel < 4 && <BtnReceive />}
                                {data_user.nivel < 5 && <hr />}
                                <BtnDelete />
                                {data_user.nivel < 4 && <li onClick={() => handleModal("history")}><HistoryIcon /> <span>Historial</span></li>}

                            </ul>
                        </nav>
                    </>}
            </div>
            {showModal && modalView}
        </>
    );

}

export default MenuRow;