import * as React from "react";
import ToolBar from "./components/tool_bar";
import funciones from "services/funciones";
import MenuRow from "./components/MenuRow";
import useTitle from "hooks/useTitle";
import { Alert } from "shared/Alert";
import * as Icon from "../../images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const CommodityList = (params) => {
    const { history } = params;
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const media_movil = window.matchMedia('(max-width: 500px)').matches;
    const [showLoading, setShowLoading] = React.useState(false);
    const [showLazy, setShowLazy] = React.useState(true);
    const [selected, setSelected] = React.useState({ id: 0 });
    const [dataList, setDataList] = React.useState([]);
    const [queryOpt, setQueryOpt] = React.useState({});
    const [conteo, setConteo] = React.useState(0);
    const { setTitulo } = useTitle();


    const Row = ({ data, blue }) => {
        let status = funciones.getStatus(data);
        let fecha_creado = new Date(data.date_create + "T05:00:00");

        return (
            <MenuRow data={data} blue={blue}
                history={history}
                current={selected.id}
                onSel={setSelected}
                reload={getList}>

                {status}
                <td>{data.codigo}</td>
                {(!media_movil && data_user.nivel != 6) && <td>{data.client_name ?? "Sin especificar"}</td>}
                {data_user.nivel < 4 && !media_movil ? <td>{data.carrier_name ?? "Sin asignar"}</td> : null}
                {!media_movil && <td>{data.descripcion}</td>}
                <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()}</td>

            </MenuRow>
        );
    }

    const AllRow = () => {
        let _data = [];
        let _blue = 0;

        dataList.map(item => {
            _data.push(<Row key={item.id} blue={_blue} data={item} />);
            _blue = _blue >= 1 ? 0 : _blue + 1;
        });

        if (_data.length < 1) {
            return <h4 className="_titulo">Sin resultados</h4>;
        } else {
            return <>
                <table className="table">
                    <tbody>

                        <tr className="table_title">
                            <th>Estado</th>
                            <th>Código</th>
                            {(!media_movil && data_user.nivel != 6) && <th>Cliente</th>}
                            {(data_user.nivel < 4 && !media_movil) ? <th>Mensajero</th> : null}
                            {!media_movil && <th>Descripción</th>}
                            <th>Fecha Creado</th>
                        </tr>

                        {_data}
                    </tbody>
                </table>

                {showLoading ?
                    <button className="btn_lazy_load"><Icon.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn_lazy_load" onClick={() => getListLazy(queryOpt)}>Cargar mas</button>
                            :
                            <h4 className="h4_lazy_load">¡No hay mas paquetes!</h4>
                        }
                    </>
                }
            </>;
        }
    }

    const getListLazy = async (_filter) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-all-packages",
            data: { ..._filter, offset: dataList.length }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setConteo(parseInt(_data.conteo));
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_packs);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async (_filter) => {
        setShowLoading(true);

        let _info = typeof _filter === "undefined" ? queryOpt : { ..._filter };
        let _data = await api.fetchJson({
            url: "get-all-packages",
            data: { ..._info, offset: 0 }
        });

        if (_data.response == 1) {
            setConteo(parseInt(_data.conteo));
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_data.data);
            setQueryOpt(_info);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        setTitulo("Distribución de paquetes");
    }, []);

    return (
        <div className="cont_principal">
            <h3 className="_title _in_filters">{data_user.nivel < 4 ? "Distribución de paquetes" : `${conteo} paquetes`}</h3>
            <ToolBar {...params}
                showLoading={showLoading}
                addOpt={false}
                data={selected}
                reload={getList}
                estado="1"
                borrar={{ msg: "¿Seguro de borrar este paquete?", tipo: "package" }}
                editar=""
                crear={() => console.log("No aplica")} />

            <AllRow />
        </div>
    );

}

export default CommodityList;