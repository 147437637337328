import * as React from "react";
import PackageIcon from "mdi-react/PackageVariantClosedIcon";
import MenuRow from "./components/MenuRow";
import useTitle from "hooks/useTitle";
import ToolBar from "shared/tool_bar";
import { Alert } from "shared/Alert";
import * as Icon from "../../images";
import api from "services/api";


/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const Reception = (params) => {
    const { history } = params;
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const media_movil = window.matchMedia('(max-width: 500px)').matches;
    const [showLoading, setShowLoading] = React.useState(false);
    const [showLazy, setShowLazy] = React.useState(true);
    const [selected, setSelected] = React.useState({ id: 0 });
    const [dataList, setDataList] = React.useState([]);
    const [queryOpt, setQueryOpt] = React.useState({});
    const { setTitulo } = useTitle();


    const Row = ({ data, blue }) => {
        // let status = funciones.getStatus(data);
        let fecha_recibido = new Date(data.date_received + "T05:00:00");

        return (
            <MenuRow data={data} blue={blue}
                history={history}
                current={selected.id}
                onSel={setSelected}
                reload={getList}>

                {/* {status} */}
                <td><PackageIcon style={{ marginRight: 8 }} /> {data.consecutivo}</td>
                <td>{data.piezas}</td>
                <td>{format(fecha_recibido.getDate())}/{meses[fecha_recibido.getMonth()]}/{fecha_recibido.getFullYear()}</td>
                {!media_movil && <td>{data.consignee_name}</td>}
                {!media_movil && <td>{data.consignee_address}</td>}

            </MenuRow>
        );
    }

    const AllRow = () => {
        let _data = [];
        let _blue = 0;

        dataList.map(item => {
            _data.push(<Row key={item.id} blue={_blue} data={item} />);
            _blue = _blue >= 1 ? 0 : _blue + 1;
        });

        if (_data.length < 1) {
            return <h4 className="_titulo">No hay resultados</h4>;
        } else {
            return <>
                <table className="table">
                    <tbody>

                        <tr className="table_title">
                            {/* <th>Estado</th> */}
                            <th>Número</th>
                            <th>Piezas</th>
                            <th>Fecha</th>
                            {!media_movil && <th>Cliente bts</th>}
                            {!media_movil && <th>Dirección</th>}
                        </tr>

                        {_data}
                    </tbody>
                </table>

                {showLoading ?
                    <button className="btn_lazy_load"><Icon.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn_lazy_load" onClick={() => getListLazy(queryOpt)}>Cargar mas</button>
                            :
                            <h4 className="h4_lazy_load">¡No hay mas paquetes!</h4>
                        }
                    </>
                }
            </>;
        }
    }

    const getListLazy = async (_filter) => {
        setShowLoading(true);

        let _data = await api.getReceptionList({ ..._filter, offset: dataList.length });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_packs);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async (_filter) => {
        setShowLoading(true);

        let _info = typeof _filter === "undefined" ? queryOpt : { ..._filter, type: 1 };
        let _data = await api.getReceptionList({ ..._info, offset: 0 });

        if (_data.response == 1) {
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_data.data);
            setQueryOpt(_info);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        if (data_user.nivel == 1 ||
            data_user.nivel == 2 ||
            data_user.nivel == 3 ||
            data_user.nivel == 5) {
            setTitulo("Ordenes de recepción");
        } else {
            history.push("/home");
        }
    }, []);

    return (
        <div className="cont_principal">
            <h3 className="_title _in_filters">Ordenes de recepción</h3>
            <ToolBar {...params}
                showLoading={showLoading}
                onliClient={false}
                data={selected}
                reload={getList}
                estado="4"
                borrar={{ msg: "¿Seguro de borrar la orden Nº " + selected.consecutivo + "?", tipo: "orden de recepción" }}
                editar="/editar-orden-de-servicio"
                crear={() => history.push("/nueva-orden-de-servicio")} />

            <AllRow />
        </div>
    );
}

export default Reception;
