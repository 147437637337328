import CryptoJS from "crypto-js";
const WEB_API = process.env.REACT_APP_WEB_API;


class API {

    /**
     * Genera la firma de autenticación
     * const authorization = 'Digest auth="YWRtaW4=", time="1620369347", response="8531882e866fafe922b2d8636ff8a911"';
     * @return: 
     */
    getAuth() {
        let _data = JSON.parse(localStorage.getItem('usuario'));
        let auth = _data == null ? window.btoa(0) : _data.auth;
        let respuesta_valida;
        let live_time;

        if (typeof android !== "undefined") {
            live_time = new Date().getTime() / 1000;
            respuesta_valida = CryptoJS.MD5(auth + ":sarguero.com:" + live_time).toString();

            return `Digest auth="${auth}", time="${live_time}", response="${respuesta_valida}"`;
        } else {
            let current_time = new Date().getTime() / 1000;

            live_time = _data == null ? 0 : _data.time;

            console.log("Minutos: ", ((current_time - live_time) / 60).toFixed(0));

            if (current_time - live_time > 3600) {

                localStorage.removeItem('usuario');

                respuesta_valida = CryptoJS.MD5(auth + ":sarguero.com:" + current_time).toString();

                return `Digest auth="${auth}", time="${live_time}", response="${respuesta_valida}"`;
            } else {

                respuesta_valida = _data == null ? 0 : CryptoJS.MD5(auth + ":sarguero.com:" + current_time).toString();

                localStorage.setItem('usuario', JSON.stringify({
                    ..._data,
                    time: parseInt(current_time),
                    token: respuesta_valida
                }));

                return `Digest auth="${auth}", time="${current_time}", response="${respuesta_valida}"`;
            }
        }

    }

    /**
     * Verifica el estado de la respuesta en la consulta 
     * @param response: La respuesta generada en la consulta 
     */
    verificaEstado(response) {
        switch (response.status) {
            case 200:
            case 210:
                //console.log('Conexión corecta', response.status);
                break;
            case 401:
            case 403:
            case 422:
                console.log('Error', response.status);
                break;
            case 404:
                throw new Error('404 - No se encuentra el recurso!');
            case 500:
                throw new Error('500 - Ocurrio un error en la sintaxis!');
            case 503:
                throw new Error('503 - Servicio no disponible!');
            default:
                throw new Error(`(${response.status}) es un código inesperado, puedes reportarlo al correo "desarrollo@sarguero.com" para revisarlo mas a fondo`);
        }
    }


    /**
     * Hace una consulta al servidor 
     * @param {JSON} params: json con { `url`: string, `data`: json }
     * @return {Promise<JSON>}: `json` con los datos solicitados 
     */
    async fetchJson(params) {
        let datos;

        //console.log(`Url: ${params.url} data:`, params.data);

        await fetch(WEB_API + params.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(params.data)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                console.log('Ocurrio un error:', error);
                datos = { "response": -1, "msg": error.toString() };
            });

        //console.log("Respondio: ", datos);
        return datos;
    }

    /**
     * Envia datos al servidor 
     * @param {FormData} data: form-data 
     * @param {string} url: string
     * @return {Promise<JSON>}: json con la respusta
     */
    async fetchFormData(data, url) {
        let datos;
        //console.log(`Url: ${url} form-data:`, data);

        await fetch(WEB_API + url, {
            method: 'POST',
            body: data,
            progress: (e) => { console.log(`Progress: ${e.loaded / e.total}%`) }
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                console.log('1-Ocurrio un error:', error);
                datos = { "response": -1, "msg": error.toString() };
            });

        //console.log("Respondio: ", datos);
        return datos;
    }


    /**
     * Obtiene listas 
     * @param params: Los parametros en formato json
     * @return: json con los datos de la lista
     */
    async getPickUpList(params) {
        let datos;

        await fetch(`${WEB_API}pickup-list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(params)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    async getReceptionList(params) {
        let datos;

        await fetch(`${WEB_API}reception-list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(params)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    async getHistory(params) {
        let datos;

        await fetch(`${WEB_API}get-history`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(params)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    /**
     * Otiene los paquetes asociados a la orden
     * @param id: id de la orden
     * @return: json con la respuesta 
     */
    async getPackages(id) {
        let datos;

        await fetch(`${WEB_API}get-packages`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }


    /**
     * Guardar ordenes 
     * @param info: La información de la orden en formato json
     * @return: json con la respuesta 
     */
    async savePickUpOrder(info) {
        let datos;

        await fetch(`${WEB_API}new-pickup-order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    async saveReceptionOrder(info) {
        let datos;

        await fetch(`${WEB_API}new-reception-order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    async saveAssignPackage(info) {
        let datos;

        await fetch(`${WEB_API}save-assign-package`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    async saveAssignPackages(info) {
        let datos;

        await fetch(`${WEB_API}save-assign-packages`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    async saveAssignOrder(info) {
        let datos;

        await fetch(`${WEB_API}save-assign-order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    async saveAssignOrders(info) {
        let datos;

        await fetch(`${WEB_API}save-assign-orders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }


    /**
     * Editar ordenes 
     * @param info: La información de la orden en formato json
     * @return: json con la respuesta 
     */
    async editPickUpOrder(info) {
        let datos;

        await fetch(`${WEB_API}update-pickup-order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    async editReceptionOrder(info) {
        let datos;

        await fetch(`${WEB_API}update-reception-order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }


    /**
    * Borrar ordenes
    * @param info: La información de la orden en formato json
    * @return: json con la respuesta 
    */
    async deleteOrder(info) {
        let datos;

        await fetch(`${WEB_API}delete-order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    async deletePackage(info) {
        let datos;

        await fetch(`${WEB_API}delete-package`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    async deleteUser(info) {
        let datos;

        await fetch(`${WEB_API}delete-user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }


    /**
     * Verifica el correo y la clave en la base de datos 
     * @param {String} email: el correo del usuario en texto plano
     * @param {String} pass: La clave del usuario en texto plano
     * @return {Promise<JSON>}: Json con los datos del usuario 
     */
    async signIn(email, pass) {
        let clave = CryptoJS.SHA256(pass);
        let datos;
        let token = "";

        if (typeof android !== "undefined") {
            // eslint-disable-next-line no-undef
            token = await android.getToken();

            console.log("token: " + token);
        }

        await fetch(`${WEB_API}sign-in`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            body: JSON.stringify({
                email: email.trim(),
                pass: clave.toString(),
                token: token
            })
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    /**
     * Registra el usuario en la base de datos
     * @param {JSON} info: La información del usuario en formato json
     * @return {Promise<JSON>}: Json con la respuesta
     */
    async signUp(info) {
        let clave = CryptoJS.SHA256(info.pass);
        let datos;

        await fetch(`${WEB_API}sign-up`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            body: JSON.stringify({ ...info, pass: clave.toString() })
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    /**
     * Establece una nueva contraceña y envia un correo 
     * @param email: El correo al cual se enviara la nueva clave 
     * @return Json: {response, msg} el estado y la respuesta
     */
    async resetPassword(email) {
        let datos;

        await fetch(`${WEB_API}reset-pass`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify({
                email: email.trim()
            })
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        // if (datos.response == 1) {
        //     let _resp = await this.sendMail(1, email, clave);
        //     return _resp.response == -1 ? _resp : datos;
        // } else {
        //     return datos;
        // }
        return datos;
    }

    /**
     * Obtiene la lista de agencias
     * @return: json con la lista de agencias 
     */
    async getUsers(roll) {
        let datos;

        await fetch(`${WEB_API}get-users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify({
                roll: roll
            })
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    async getSession() {
        let datos;

        await fetch(`${WEB_API}valida-session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            }
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    /**
     * Guarda una nueva agencia
     * @param info: La información de la agencia en formato json
     * @return: json con la respuesta 
     */
    async saveAgent(info) {
        let datos;

        await fetch(`${WEB_API}save-agent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    /**
     * Marcar la orden como entregada
     * @param info: La información de la orden en formato json
     * @return: json con la respuesta 
     */
    async deliveredPackage(info) {
        let datos;

        await fetch(`${WEB_API}delivered-package`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    /**
     * Otros servicios
     */

    generaClave(length) {
        var caracteres = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
        var clave = "";
        for (var i = 0; i < length; i++) clave += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
        return clave;
    }

    async changeState(info) {
        let datos;

        await fetch(`${WEB_API}change-state`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    async getLocations() {
        let datos;

        await fetch(`${WEB_API}get-locations`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            }
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    async sendMail(type, email, clave) {
        let datos;
        let url = type === 1 ? "send-pass" : "send-validate";
        console.log(`Enviando correo desde https://partidoconservadorcolombiano.com.co/api/${url}`);

        await fetch(`https://partidoconservadorcolombiano.com.co/api/${url}`, {
            method: 'POST',
            body: JSON.stringify({
                correo: email,
                clave: clave
            })
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                console.log('Mail ocurrio un error:', error);
                datos = { "response": -1, "msg": error.toString() };
            });

        console.log("Respondio: ", datos);
        return datos;
    }
}

export default new API();