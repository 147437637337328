class Logs {
    d(msg, obj) {
        const Style = [
            "padding: 2px 4px",
            "color: rgb(255, 255, 255)",
            "background-color: rgb(0, 100, 255)",
            "font-weight: bold",
            "border-radius: 4px",
        ];

        const style = Style.join(';') + ';';

        if (typeof android !== "undefined") {
            obj ?
                console.log(`${msg}:`, obj)
                :
                console.log(msg);
        } else {
            obj ?
                console.log(`\n %c${msg}:`, style, obj, '\n\n')
                :
                console.log(`\n %c${msg}`, style, '\n\n');
        }
    }

    i(msg, obj) {
        const Style = [
            "padding: 2px 4px",
            "color: rgb(255, 255, 255)",
            "background-color: rgb(0, 128, 0)",
            "font-weight: bold",
            "border-radius: 4px",
        ];

        const style = Style.join(';') + ';';

        if (typeof android !== "undefined") {
            obj ?
                console.log(`${msg}:`, obj)
                :
                console.log(msg);
        } else {
            obj ?
                console.log(`\n %c${msg}:`, style, obj, '\n\n')
                :
                console.log(`\n %c${msg}`, style, '\n\n');
        }
    }

    w(msg, obj) {
        const Style = [
            "padding: 2px 4px",
            "color: rgb(40, 40, 40)",
            "background-color: rgb(0, 255, 0)",
            "font-weight: bold",
            "border-radius: 4px",
        ];

        const style = Style.join(';') + ';';

        if (typeof android !== "undefined") {
            obj ?
                console.log(`${msg}:`, obj)
                :
                console.log(msg);
        } else {
            obj ?
                console.log(`\n %c${msg}:`, style, obj, '\n\n')
                :
                console.log(`\n %c${msg}`, style, '\n\n');
        }
    }

    e(msg, obj) {
        const Style = [
            "padding: 2px 4px",
            "color: rgb(238, 238, 238)",
            "background-color: rgb(255, 58, 58)",
            "font-weight: bold",
            "border-radius: 4px",
        ];

        const style = Style.join(';') + ';';

        if (typeof android !== "undefined") {
            obj ?
                console.log(`${msg}:`, obj)
                :
                console.log(msg);
        } else {
            obj ?
                console.log(`\n %c${msg}:`, style, obj, '\n\n')
                :
                console.log(`\n %c${msg}`, style, '\n\n');
        }
    }
}

export default new Logs();