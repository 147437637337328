import * as React from "react";
import { Alert, alerta, alertaRemove, alertDown, Confirm } from "shared/Alert";
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import ClipboardIcon from "mdi-react/ClipboardArrowDownOutlineIcon";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import { Field, InputNum } from "shared/Input";
import EditContainer from "./EditContainer";
import funciones from "services/funciones";
import DatePicker from "react-datepicker";
import NewContainer from "./NewContainer";
import useTitle from "hooks/useTitle";
import Loading from "shared/Loading";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint no-unused-vars: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const EditPickUp = ({ history, location }) => {
    const data = location.state.data;
    const [carrier, setCarrier] = React.useState("");
    const [carrierId, setCarrierId] = React.useState("");
    const [consignee, setConsignee] = React.useState("");
    const [consigneeId, setConsigneeId] = React.useState("");
    const [valDomc, setValDomc] = React.useState("8000");
    const [collect, setCollect] = React.useState(false);
    const [payForBts, setPayForBts] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(false);
    const [collectProduct, setCollectProduct] = React.useState("");
    const [collectDomic, setCollectDomic] = React.useState("");
    const [collectCarrier, setCollectCarrier] = React.useState("");
    const [namePickupLocation, setNamePickupLocation] = React.useState("");
    const [addressPickupLocation, setAddressPickupLocation] = React.useState("");
    const [notes, setNotes] = React.useState("");
    const [fechaIngreso, setFechaIngreso] = React.useState(new Date());
    const [userList, setUserList] = React.useState([{ id_user: 0, nombre: "Cargando..." }]);
    const [carrierList, setCarrierList] = React.useState([{ id_user: 0, nombre: "Cargando..." }]);
    const { setTitulo } = useTitle();

    const [packages, setPackages] = React.useState({});
    const [allPaquages, setAllPaquages] = React.useState([]);
    const ref_form = React.useRef();
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const _titulo = data_user.nivel == 4 ? "Agregar paquetes" : "Editar orden de recogida";


    const getPackagesList = async (_id) => {
        let _data = await api.getPackages(_id);

        if (_data.response == 1) {
            configPackages(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const configPackages = (_data) => {
        let _pack = {};
        let _allPack = [];
        let _index;

        _data.map((item, index) => {
            _index = index + 1;
            item.index = _index;

            _pack = { ..._pack, [_index]: item };
            _allPack.push(<ContainerEdit key={_index} pack={item} />);
        });

        setPackages(_pack);
        setAllPaquages(_allPack);
    }

    const updatePackage = (pack, index) => {
        setPackages(p => ({ ...p, [index]: pack }))
    }

    const addPackage = (e) => {
        e.preventDefault();
        alertaRemove(ref_form.current.other);

        if (consigneeId !== "") {

            let _index = allPaquages.length + 1;
            let _pack = {
                "index": _index,
                "id": 0,
                "type": "N/A",
                "pieces": 1,
                "codigo": "",
                "valorp": "0",
                "valord": valDomc,
                "valorm": "5000",
                "descripcion": "",
                "attachments": "",
                "estado": data_user.nivel == 4 ? 2 : data.estado
            };

            setPackages({ ...packages, [_index]: _pack });

            setAllPaquages([...allPaquages, <Container key={_index} pack={_pack} />]);
        }
    }

    const removePackage = (_index, _id) => {
        let _pack_list = [];
        let _action = () => {
            setPackages(p => {
                let _new_list;

                for (let i in p) {
                    if (p[i].index !== _index) {
                        _new_list = { ..._new_list, [i]: p[i] };
                        _pack_list.push(<ContainerEdit key={p[i].index} pack={p[i]} />);
                    }
                }

                return _new_list;
            });

            setAllPaquages(_pack_list);
        }

        if (_id != 0) {
            Confirm("Seguro de borrar este paquete", (response) => {
                if (response) {
                    _action();
                }
            });
        } else {
            _action();
        }

    }

    const Container = ({ pack }) => {

        return (
            <NewContainer
                item={pack}
                remove={removePackage}
                update={item => setPackages(p => ({ ...p, [pack.index]: item }))} />
        );
    }

    const ContainerEdit = ({ pack }) => {

        return (
            <EditContainer
                item={pack}
                remove={removePackage}
                update={(p, i) => updatePackage(p, i)} />
        );
    }

    const getClients = async () => {
        let _data = await api.getUsers("clients");
        let _users = [];


        if (_data.response == 1) {

            _data.data.map(item => {
                item.nivel > "2" && _users.push(item);
            });

            setUserList(_users);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        return _users;
    }

    const getCarrier = async () => {
        let _data = await api.getUsers("carrier");
        let _users = [];


        if (_data.response == 1) {
            let _lista = _data.data;

            _lista.map(item => {
                item.nivel > "2" && _users.push(item);
            });

            if (data.carrier !== null) {
                let item = _lista.filter(({ id_user }) => id_user == data.carrier);
                item = item[0];

                setCarrier(item.nombre + " " + item.apellido);
                setCarrierId(data.carrier);
            }

            setCarrierList(_users);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        return _users;
    }

    const format = (_num) => {
        return _num < 10 ? "0" + _num : _num;
    }

    const saveOrder = async (asPickedUp) => {
        setShowLoading(true);
        let _info = {
            order: data.id,
            estado: data_user.nivel == 4 ? asPickedUp : data.estado,
            date_pickup: fechaIngreso.getFullYear() + "-" + format(fechaIngreso.getMonth() + 1) + "-" + format(fechaIngreso.getDate()),
            consignee: consigneeId,
            carrier: carrierId,
            pickup_name: namePickupLocation,
            pickup_address: addressPickupLocation,
            notes: notes,
            collect_product: collectProduct.replaceAll(".", ""),
            collect_domic: collectDomic.replaceAll(".", ""),
            collect_carrier: collectCarrier.replaceAll(".", ""),
            collect_pay: payForBts ? 1 : 0,
            packages: packages
        };

        let _data = await api.editPickUpOrder(_info);

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => history.goBack());
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => history.push("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _is_empty = false;
        let _message = "";

        alertaRemove(ref_form.current.consignee);
        collect && alertaRemove(ref_form.current.collect_product);
        collect && alertaRemove(ref_form.current.collect_domic);
        collect && alertaRemove(ref_form.current.collect_carrier);
        alertaRemove(ref_form.current.other);

        if (consignee.length < 1) {
            alerta(ref_form.current.consignee, "Debes ingresar un Cliente bts!");
            ref_form.current.consignee.focus();
            window.scrollTo({
                top: 80,
                behavior: 'smooth'
            });
            return;
        }

        alertaRemove(ref_form.current.name_pickup_location);

        if (namePickupLocation.length < 1) {
            alerta(ref_form.current.name_pickup_location, "Debes ingresar el nombre de la persona \ndonde se recogerá el paquete!");
            ref_form.current.name_pickup_location.focus();
            window.scrollTo({
                top: 180,
                behavior: 'smooth'
            });
            return;
        }

        if (collect) {
            if (collectProduct.length < 1 && collectDomic.length < 1 && collectCarrier.length < 1) {
                // alerta(ref_form.current.collect_product, "¡Debes indicar uno de estos valores!");
                // alerta(ref_form.current.collect_domic, "¡Debes indicar uno de estos valores!");
                // alerta(ref_form.current.collect_carrier, "¡Debes indicar uno de estos valores!");
                Alert("Debes indicar un valor para producto, domicilio o mensajero", "warning");
                ref_form.current.collect_product.focus();
                window.scrollTo({
                    top: 380,
                    behavior: 'smooth'
                });
                return;
            }
        }

        if (data_user.nivel == 4 && Object.entries(packages).length < 1) {
            alertDown(ref_form.current.other, "Debes agreag por lo menos un paquete!");
            return;
        }

        if (Object.entries(packages).length >= 1) {

            for (let i in packages) {

                if (packages[i]["pieces"] < 1) {
                    _is_empty = true;
                    _message = `La cantidad de "piezas" no puede ser menor a uno!`;
                    break
                }

                if (packages[i]["attachments"].length < 1) {
                    _is_empty = true;
                    _message = `Debes ingresar almenos una imagen para cada paquete`;
                    break
                }
            }

        }

        if (_is_empty) {
            Alert(_message);
            return;
        }

        if (data_user.nivel == 4 && data.estado == 1) {
            Confirm("¿Marcar esta orden como recogida?", (resp) => {
                if (resp) {
                    saveOrder(2);
                } else {
                    saveOrder(1);
                }
            });
        } else {
            saveOrder(data.estado);
        }
    }

    const addCarrier = async (val) => {
        setCarrier(val);
        alertaRemove(ref_form.current.carrier);

        let lista = await getCarrier();

        let item = lista.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];

        if (typeof item === "object") {
            setCarrierId(item.id_user);
        } else {
            setCarrierId("");
        }
    }

    const addConsignee = async (val) => {
        setConsignee(val);
        alertaRemove(ref_form.current.consignee);

        let lista = await getClients();

        let item = lista.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];

        if (typeof item === "object") {
            setConsigneeId(item.id_user);
            setValDomc(item.domicilio);
        } else {
            setConsigneeId("");
            setValDomc("8000");
        }
    }

    const copyConsignee = () => {

        if (consignee.length > 1) {
            let item = userList.filter(({ nombre, apellido }) => nombre + " " + apellido === consignee);
            item = item[0];

            alertaRemove(ref_form.current.name_pickup_location);

            if (typeof item === "object") {
                setNamePickupLocation(item.nombre + " " + item.apellido);
                setAddressPickupLocation(item.address);
            } else {
                setNamePickupLocation("");
                setAddressPickupLocation("");
            }
        }
    }

    const changeCarrier = (val) => {
        setCarrier(val);
        alertaRemove(ref_form.current.carrier);

        let item = carrierList.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];

        if (typeof item === "object") {
            setCarrierId(item.id_user);
        } else {
            setCarrierId("");
        }
    }

    const changeConsignee = (val) => {
        setConsignee(val);
        alertaRemove(ref_form.current.consignee);

        let item = userList.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];

        if (typeof item === "object") {
            setConsigneeId(item.id_user);
            setValDomc(item.domicilio);
        } else {
            setConsigneeId("");
            setValDomc("8000");
        }
    }

    React.useEffect(() => {
        let isMounted = true;
        setTitulo(_titulo + " " + data.consecutivo);

        getPackagesList(data.id);

        setValDomc(data.consignee_domicilio == null ? "8000" : data.consignee_domicilio);
        setConsignee(data.consignee_name == null ? "" : data.consignee_name);
        setConsigneeId(data.consignee == null ? "" : data.consignee);
        setNamePickupLocation(data.pickup_name);
        setAddressPickupLocation(data.pickup_address ?? "...");

        setNotes(data.notes == null ? "" : data.notes);

        setFechaIngreso(new Date(data.date_pickup + "T01:00:00"));

        if (data.collect_product || data.collect_domic || data.collect_carrier) {
            setCollect(true);
            data.collect_product && setCollectProduct(funciones.formatPrice(data.collect_product).format);
            data.collect_domic && setCollectDomic(funciones.formatPrice(data.collect_domic).format);
            data.collect_carrier && setCollectCarrier(funciones.formatPrice(data.collect_carrier).format);

            setPayForBts(data.collect_pay == 0 ? false : true);
        }

        if (isMounted) {
            getClients();
            getCarrier();
        }

        return () => { isMounted = false };
    }, [data]);

    return (
        <form className="pickup" ref={ref_form}>
            <h3 className="_title">{_titulo}</h3>

            <div className="pickup_container" style={{ marginBottom: allPaquages.length >= 1 ? 28 : 110 }}>
                <h5>Número: <b>{data.consecutivo}</b></h5>

                {data_user.nivel == 4 ?
                    <>
                        <h6 className="_title"> <span style={{ fontWeight: "normal" }}>Cliente bts: </span>{consignee}</h6>

                        <div className={consignee === "" ? "pickup_row" : "pickup_row _to_bottom"}>
                            <div className="pickup_row">

                                <div className="_column">
                                    <label>Fecha de recogida</label>
                                    <label className="_marco">{format(fechaIngreso.getDate())}/{meses[fechaIngreso.getMonth()]}/{fechaIngreso.getFullYear()}</label>
                                </div>

                                <div className="_column">
                                    <label>Nombre de contacto</label>
                                    <label className="_marco">{namePickupLocation}</label>
                                </div>

                                <div className="_column">
                                    <label>Dirección de recogida</label>
                                    <label className="_marco">{addressPickupLocation}</label>
                                </div>
                            </div>
                        </div>

                        <h6 style={{ marginTop: 16, marginBottom: 64 }}><b>Observaciones</b> <br />{data.notes}</h6>
                    </>
                    :
                    <div className={consignee === "" ? "pickup_row" : "pickup_row _to_bottom"}>

                        <div className="pickup_column static">
                            <Field
                                rol="6"
                                name="consignee"
                                titulo="Cliente bts"
                                list={userList}
                                placeholder="Ingresa Cliente bts"
                                value={consignee}
                                onChange={changeConsignee}
                                onAdd={addConsignee}
                            />

                            {consignee != "" &&
                                <div className="pickup_column m_less">
                                    <h3 className="text_clipboard">Lugar de recogida <ClipboardIcon onClick={copyConsignee} /></h3>

                                    <label style={{ height: 24 }}>Nombre de contacto</label>
                                    <div className="input">
                                        <input type="text" name="name_pickup_location" autoComplete="off" placeholder="Ingresa nombre de contacto"
                                            value={namePickupLocation} onChange={e => setNamePickupLocation(e.target.value)} />
                                    </div>

                                    <label style={{ marginTop: 12 }}>Dirección</label>
                                    <textarea name="address_pickup_location" rows="2" placeholder="Ingresa la dirección de recogida."
                                        value={addressPickupLocation} onChange={e => setAddressPickupLocation(e.target.value)} />
                                </div>}
                        </div>

                        <div className="pickup_column static">
                            <Field
                                rol="4"
                                name="carrier"
                                titulo="Mensajero"
                                list={carrierList}
                                placeholder="Ingresa el mensajero"
                                value={carrier}
                                onChange={changeCarrier}
                                onAdd={addCarrier}
                            />

                            <hr style={{ height: 30, backgroundColor: "transparent" }} />

                            <div className="pickup_column m_less">
                                <label style={{ height: 24 }}>Fecha de recogida</label>

                                <div className="field_icon">
                                    <DatePicker dateFormat="yyyy/MM/dd" name="pickup_date" selected={fechaIngreso} onChange={(date) => setFechaIngreso(date)} />
                                    <CalendarMonthIcon />
                                </div>
                            </div>

                            <div className="pickup_column m_less">
                                <label style={{ marginTop: 12 }}>Observaciones</label>
                                <textarea name="notes" rows="2" placeholder="Ingresa una observación"
                                    value={notes} onChange={({ target: { value } }) => setNotes(value)} />
                            </div>

                        </div>

                    </div>
                }

                {data_user.nivel < 4 && <fieldset className={collect ? "new_features" : "new_features _no_border"} style={{ marginTop: 32 }}>
                    <label className="check_collect _legend">
                        <input type="checkbox" name="collect" checked={collect} onChange={e => setCollect(e.target.checked)} />
                        Pago por recogida
                    </label>

                    {collect && <>
                        <label className="label_collect">Valor producto</label>
                        <InputNum
                            name="collect_product"
                            value={collectProduct}
                            placeholder="Valor producto"
                            onChange={val => { alertaRemove(ref_form.current.collect_product); setCollectProduct(val); }}
                        />

                        <label className="label_collect">Valor domicilio</label>
                        <InputNum
                            name="collect_domic"
                            value={collectDomic}
                            placeholder="Valor domicilio"
                            onChange={val => { alertaRemove(ref_form.current.collect_domic); setCollectDomic(val); }}
                        />

                        <label className="label_collect">Valor mensajero</label>
                        <InputNum
                            name="collect_carrier"
                            value={collectCarrier}
                            placeholder="Valor mensajero"
                            onChange={val => { alertaRemove(ref_form.current.collect_carrier); setCollectCarrier(val); }}
                        />
                        <label className="check_collect"><input type="checkbox" name="pay_for_bts" checked={payForBts} onChange={e => setPayForBts(e.target.checked)} />Pagado por BTS</label>
                    </>}
                </fieldset>}

                {allPaquages}
            </div>

            <div className="boton_agregar">
                <div className="for_alert">
                    <button className={consigneeId !== "" ? "btn btn-common" : "btn btn-common _deshabilitado"}
                        type="button" name="other"
                        onClick={addPackage}>
                        <PlusCircleOutlineIcon /> Agregar paquete
                    </button>
                </div>

                <button type="button" className="btn btn-common width-static" onClick={validateFields}>Guardar</button>
                <button type="button" className="btn btn-common width-static" onClick={() => history.goBack()}>Cancelar</button>

            </div>

            {showLoading && <Loading />}
        </form >
    );
}

export default EditPickUp;