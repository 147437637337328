import * as React from "react";
import DeleteIcon from "mdi-react/DeleteOutlineIcon";
import ChatIcon from "mdi-react/ChatOutlineIcon";
// import EditIcon from "mdi-react/EditOutlineIcon";
import OpenIcon from "mdi-react/OpenInNewIcon";
import { Alert, Confirm } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuRow = (params) => {
    const { history, data, blue, children, onSel, reload, current } = params;
    const [clases, setClases] = React.useState(blue == 0 ? "table_row bkg_blue" : "table_row");
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [_pos, setPos] = React.useState({ top: "0", left: "0" });
    const [showMenu, setShowMenu] = React.useState(false);
    const row = React.useRef(null);


    const goOpen = (e) => {
        e.preventDefault()
        e.stopPropagation();

        history.push({
            pathname: "/orden-de-recogida",
            state: { data: data }
        });
    }

    const goChat = (e) => {
        e.preventDefault()
        e.stopPropagation();

        history.push({
            pathname: "/chat",
            state: {
                data: {
                    guest: {
                        id_user: data_user.nivel == 6 ? data.carrier : data.consignee,
                        nombre: data_user.nivel == 6 ? data.carrier_name : data.consignee_nombre,
                        apellido: data_user.nivel == 6 ? "" : data.consignee_apellido
                    },
                    fromOutside: true
                }
            }
        });
    }

    // const goEdit = (e) => {
    //     e.preventDefault()
    //     e.stopPropagation();

    //     history.push({
    //         pathname: "/editar-mi-orden",
    //         state: { data: data }
    //     });
    // }

    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm(`Seguro de borrar la orden Nº ${data.consecutivo}?`, async (response) => {
            if (response) {
                let _data = await api.deleteOrder({
                    id_order: data.id,
                    tipo: "orden de recogida"
                });

                if (_data.response == 1) {
                    reload();
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => history.push("/"));
                } else {
                    Alert(_data.msg, "warning");
                }
            }
        });

    }

    const onSelect = (e) => {
        e.preventDefault()
        e.stopPropagation();

        onSel(data);
    }

    const stopClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let _height = window.innerHeight;
        let _width = document.getElementById("root").offsetWidth;
        let _posx = (e.pageX + 220) > _width ? _width - 220 : e.pageX;
        let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;
        let _to_y = _to_border_bottom < 220 ? e.pageY - 220 : e.pageY;

        setPos({ top: _to_y + "px", left: _posx + "px", });
        setShowMenu(true);
    }

    const BtnDelete = () => {
        let _btn = <li onClick={goDelete}><DeleteIcon /> <span>Borrar</span></li>;

        return (_btn);
    }

    const BtnChat = () => {
        let _btn = <li><ChatIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Abrir chat</span></li>;

        if (data_user.nivel < 4 && data.consignee != null) {
            _btn = <li onClick={goChat}><ChatIcon /> <span>Abrir chat</span></li>;
        }
        
        if (data_user.nivel == 6 && data.carrier != null) {
            _btn = <li onClick={goChat}><ChatIcon /> <span>Abrir chat</span></li>;
        }

        return (_btn);
    }

    React.useEffect(() => {
        document.addEventListener("click", hiddenMenu);
        // document.addEventListener("contextmenu", hiddenMenu);

        return () => {
            document.removeEventListener("click", hiddenMenu);
            // document.removeEventListener("contextmenu", hiddenMenu);
        }
    }, []);

    React.useEffect(() => {
        current === data.id && setClases(c => c + " bkg_select");
    }, [current]);

    return (
        <tr className={clases} ref={row}
            onDoubleClick={goOpen}
            onClick={onSelect}
            onContextMenu={handleContextMenu}>

            {children}
            <td className="rowMenu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                {showMenu &&
                    <>
                        <div className="back_form" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu" style={_pos}>
                            <ul>
                                <li onClick={goOpen}><OpenIcon /> <span><strong>Abrir</strong></span></li>
                                {/* <li onClick={goEdit}><EditIcon /> <span>Editar</span></li> */}
                                <BtnChat />
                                <hr />
                                <BtnDelete />
                            </ul>
                        </nav>
                    </>}

            </td>
        </tr>
    );

}

export default MenuRow;