import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import VisorImg from 'shared/VisorImg';

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const DropZoneField = ({ codigo, onChange, onDelete, files }) => {

  const onDrop = React.useCallback(loaded => {
    let _file = loaded.map(file => {
      let _name = file.name;
      let _ext = _name.split(".");
      _ext = _ext[_ext.length - 1];
      _name = `PACK_${codigo}_${(new Date().getTime() / 1000).toFixed(0)}.${_ext}`;

      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        nameinserver: _name,
      });
    });

    onChange(_file);
  }, []);

  const removeFile = (index, e) => {
    e.preventDefault();
    // let _file = files.filter((val, i) => i !== index);

    onDelete(files[index]);
  }

  const handleZoom = (e, imagen) => {
    e.preventDefault();

    VisorImg([imagen]);
  }

  const Aside = () => {
    let _content = [];
    let _aside_files = [];

    if (files && files.length > 0) {
      files.forEach(file => {

        _aside_files.push(file);
      });
    }

    if (_aside_files.length == 1 && _aside_files[0].type.split("/")[0] == "image") {
      _content.push(<img key="1" src={process.env.REACT_APP_SERVER + _aside_files[0].preview} alt="drop-img" />);
      _content.push(<p key="2" className="dropzone__img-name" onClick={e => handleZoom(e, _aside_files[0])}>{_aside_files[0].nameinserver}</p>);
      _content.push(<button key="3" className="dropzone__img-delete" type="button" onClick={e => removeFile(0, e)}>Borrar</button>);
    } else {
      _aside_files.map((item, index) => {
        _content.push(
          <h3 className="dropzone__h3" key={index}>
            <span onClick={e => handleZoom(e, item)}>
              <button className="dropzone__file-delete" type="button" onClick={e => removeFile(index, e)}>Borrar</button>
              {item.nameinserver}
            </span>
          </h3>);
      });
    }

    return (
      <>
        <aside className="dropzone__img">
          {_content}
        </aside>
      </>
    );
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <div className="dropzone dropzone--single">
        <div {...getRootProps()} className="dropzone__input">

          {files.length == 0 && (
            <div className="dropzone__drop-here">
              <span className="lnr lnr-upload" />
              <p>Clic aquí o arrastra tus archivos para agregarlos</p>
            </div>
          )}

          <input {...getInputProps()} />
        </div>
        {files.length > 0 && <Aside />}
      </div>
    </>);
}

export default DropZoneField;
