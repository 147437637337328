import * as React from "react";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import CashMinusIcon from "mdi-react/CashMinusIcon";
import Movimientos from "./components/Movimientos";
import CashPlusIcon from "mdi-react/CashPlusIcon";
import { Container, Row } from "reactstrap";
import funciones from "services/funciones";
import Entrada from "./components/Entrada";
import Salida from "./components/Salida";
import useTitle from "hooks/useTitle";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-extend-native: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const CajaClientes = (params) => {
  const { history } = params;
  const { setTitulo } = useTitle();
  const [valores, setValores] = React.useState({ transferencias: 0, efectivo: 0 });
  const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
  const [showLoading, setShowLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [modalView, setModalView] = React.useState(null);

  const handleModal = (e, _option) => {
    e.preventDefault();
    e.stopPropagation();

    let _window;
    let _height = window.innerHeight;
    let _width = document.getElementById("root").offsetWidth;
    let _posx = (e.pageX + 280) > _width ? _width - 280 : e.pageX;
    let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;
    let _to_y = _to_border_bottom < 315 ? e.pageY - 150 : e.pageY - 80;
    let _pos = { top: _to_y + "px", left: _posx + "px", maxWidth: 230 };


    switch (_option) {
      case "entrada":
        _window = <Entrada
          pos={_pos}
          close={setShowModal}
          reload={getInfo} />;
        break;
      case "salida":
        _window = <Salida
          pos={_pos}
          close={setShowModal}
          reload={getInfo} />;
        break;
      default:
        _window = null;
        break;
    }

    setModalView(_window);
    setShowModal(true);
  }

  const getInfo = async () => {
    setShowLoading(true);
    /**
     * caja
     *
     * 1 caja mensajeros
     * 2 caja clientes
     * 3 caja bts
    */
    let _data = await api.fetchJson({
      url: "get-caja",
      data: {
        task: "all",
        caja: 3
      }
    });

    if (_data.response == 1) {
      let _list = { transferencias: 0, efectivo: 0 };

      _data.data.map(item => {
        if (item.metodo == 1) {
          _list.efectivo = item.monto;
        }
        if (item.metodo == 2) {
          _list.transferencias = item.monto;
        }
      });

      setValores(_list);
      setShowLoading(false);
    } else if (_data.response === -2) {
      Alert(_data.msg, "warning", () => history.push("/"));
    } else {
      Alert(_data.msg, "warning");
    }
  }

  React.useEffect(() => {
    setTitulo("Caja BTS");
    if (data_user.nivel < 4) {
      getInfo();
    } else {
      history.push("/home");
    }
  }, []);

  return (
    <Container className="dashboard _caja">
      <Row>
        <h3 className="_titulo">Caja BTS</h3>

        <div className="panel__btns _en_caja">

          <button
            className="panel__btn"
            type="button"
            onClick={getInfo}>
            <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
          </button>

        </div>

        <h4 className="_precio"><span>Disponible</span><br />
          <span>Efectivo</span> ${funciones.formatPrice(valores.efectivo).format}
          <br />
          <span>Transferencias</span> ${funciones.formatPrice(valores.transferencias).format}
        </h4>

        <div className="cont_btns_caja">
          <button className="cash-button" onClick={e => handleModal(e, "salida")}><CashMinusIcon />Retiro</button>
          <button className="cash-button" onClick={e => handleModal(e, "entrada")}><CashPlusIcon />Entrada</button>
        </div>
      </Row>

      <Row>
        <Movimientos
          valores={valores}
          tipo_caja="3" />
      </Row>

      {showModal && modalView}

    </Container>
  );
};

export default CajaClientes;
